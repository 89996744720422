import React, { JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { bodyMaxHeight } from '../../../theme/theme';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';

interface AppProps {
  name: string;
  description?: string;
  app: SystemglueAppName;
}

const App = ({ name, description, app }: AppProps): JSX.Element => {
  const navigate = useNavigate();

  const onConfigure = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`./${app}`);
  };

  return (
    <Box
      sx={{
        height: 100,
        width: 250,
        backgroundColor: 'lavender',
        border: 1,
        textAlign: 'center',
        borderRadius: 3,
        boxShadow: 3,
        padding: 1,
        margin: 1,
      }}
    >
      <Typography variant={'h6'}>{name}</Typography>
      {description !== undefined && (
        <Typography variant={'body1'}>{description}</Typography>
      )}
      <Button
        variant={'contained'}
        aria-label="Save"
        component="label"
        size={'small'}
        onClick={onConfigure}
        color={'success'}
      >
        Configure
      </Button>
    </Box>
  );
};

const AppListView = (): JSX.Element => {
  return (
    <Box
      flexWrap={'wrap'}
      display={'flex'}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <App
        name={'Bank Payments'}
        app={SystemglueAppName.PAYMENT_INTEGRATION_APP}
      />
      <App
        name={'Bank Transactions'}
        app={SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP}
      />
    </Box>
  );
};

export default AppListView;
