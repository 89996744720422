import {
  ApiEndpointTriggerNode,
  ApiResultNode,
  DispatchNode,
  ErrorTriggerNode,
  InputTransformations,
  IntegrationNode,
  IntegrationNodeType,
  NodeHandler,
  OperationNode,
  PollingTriggerNode,
  WebhookTriggerNode,
  WorkflowResultContext,
  WorkflowRunContext,
  WorkflowSchemaHandler,
} from './interface';
import { logRecordFor } from '../../utils/src/logger';
import {
  IntegrationEventStatus,
  WorkflowNodeError,
  WorkflowNodeResult,
} from '../../api/interface/integration-service';
import {
  EmbeddedIntegrationOperation,
  RunContext,
  runContextSchema,
} from '../../integrations/src/interface';
import { InternalServerError } from '../../utils/src/error';
import { JSONSchema7 } from 'json-schema';
import {
  applyInputTransformations,
  asNodeIdentifier,
  filterPreviousResults,
  getPreviousResults,
  isTrigger,
  makeApiResultRunner,
  makeConditionChecker,
  makeDispatchRunner,
  makeInputDataFilter,
  makeOperationRunner,
  transformToInputData,
  undoInputTransformations,
} from './integration-functions';
import { isEmpty, merge } from 'lodash';
import { get } from 'object-path';
import { WorkflowResultHandler } from './workflow-result-handler';
import { failedIntegrationActionSchema } from '../../api/schemas/integration-service';
import { validate } from '../../utils/src/validation';
import { getFinalResult } from '@apus/common-lib/utils/src/data-utils';

const debug = logRecordFor('handler-factory', 'debug');
const errorLog = logRecordFor('handler-factory', 'error');

interface InternalHandlerResult {
  integrationContext: RunContext;
  results: WorkflowNodeResult[];
  /**
   * @deprecated is this needed at all?
   */
  output: unknown | undefined;
  status: IntegrationEventStatus;
}
type InternalHandlerRunFunc = (
  ctx: WorkflowRunContext
) => Promise<InternalHandlerResult>;

type InternalHandlerRunAfterErrorFunc = (
  ctx: WorkflowRunContext,
  errorResult: WorkflowNodeResult
) => Promise<void>;

interface InternalNodeHandler extends WorkflowSchemaHandler {
  getNode: () => IntegrationNode;
  run: InternalHandlerRunFunc;
  runAfterError: InternalHandlerRunAfterErrorFunc;
}

function resultBase<T extends IntegrationNode>(
  node: T,
  input: unknown | undefined,
  output: unknown | undefined,
  status: IntegrationEventStatus
): WorkflowNodeResult {
  return {
    workflowNodeId: node.id,
    nodeType: node.nodeType,
    started: new Date().toISOString(),
    ended: new Date().toISOString(),
    input: isEmpty(input) ? undefined : input,
    output: isEmpty(output) ? undefined : output,
    status,
  };
}

class BaseTriggerNodeHandler {
  protected readonly integrationContextSchema: JSONSchema7;
  protected readonly outputSchema: JSONSchema7 | undefined;

  constructor(
    integrationContextSchema: JSONSchema7,
    outputSchema: JSONSchema7 | undefined
  ) {
    this.integrationContextSchema = integrationContextSchema;
    this.outputSchema = outputSchema;
  }

  getFullTransformedInputSchema(): JSONSchema7 | undefined {
    return this.integrationContextSchema;
  }

  getFullTransformedOutputSchema(): JSONSchema7 | undefined {
    const outputSchema = this.getOutputSchema();

    debug('getFullTransformedOutputSchema', { outputSchema });

    return merge({}, this.integrationContextSchema, {
      properties: {
        trigger: outputSchema,
      },
    });
  }

  getFullInputSchema(): JSONSchema7 | undefined {
    return this.getFullTransformedInputSchema();
  }

  getFullOutputSchema(): JSONSchema7 | undefined {
    const outputSchema = this.getOutputSchema();
    debug('getOutputSchema', { outputSchema });
    return merge({}, this.integrationContextSchema, {
      properties: {
        trigger: outputSchema ?? {},
      },
    });
  }

  getOutputSchema(): JSONSchema7 | undefined {
    return this.outputSchema;
  }

  getTransformedOutputSchema(): JSONSchema7 | undefined {
    return this.outputSchema;
  }
}

class BaseOperationNodeHandler {
  protected readonly integrationContextSchema: JSONSchema7;
  protected readonly outputSchema?: JSONSchema7;
  protected readonly inputTransformations?: InputTransformations;
  protected readonly name: string;

  constructor(
    integrationContextSchema: JSONSchema7,
    outputSchema: JSONSchema7 | undefined,
    inputTransformations: InputTransformations | undefined,
    name: string
  ) {
    this.integrationContextSchema = integrationContextSchema;
    this.outputSchema = outputSchema;
    this.inputTransformations = inputTransformations;
    this.name = name;
  }

  protected generateRequired(): string[] {
    const required: string[] = get(
      this.integrationContextSchema,
      'properties.operations.required',
      []
    );

    return this.outputSchema === undefined
      ? required
      : required.concat(asNodeIdentifier(this.name));
  }

  protected defineOutput(results: WorkflowNodeResult[]): unknown {
    if (this.inputTransformations?.iterate === true) {
      return results.map(r => r.output);
    }

    return results[0].output;
  }

  getFullTransformedInputSchema(): JSONSchema7 | undefined {
    return applyInputTransformations(
      this.integrationContextSchema,
      this.inputTransformations
    );
  }

  getFullTransformedOutputSchema(): JSONSchema7 | undefined {
    if (this.outputSchema === undefined) return this.integrationContextSchema;

    return merge({}, this.integrationContextSchema, {
      properties: {
        operations: {
          properties: {
            [asNodeIdentifier(this.name)]: this.outputSchema,
          },
          required: this.generateRequired(),
        },
      },
    });
  }

  getFullInputSchema(): JSONSchema7 | undefined {
    return this.integrationContextSchema;
  }

  getFullOutputSchema(): JSONSchema7 | undefined {
    const outputSchema = this.getOutputSchema();
    if (outputSchema === undefined) return this.integrationContextSchema;

    return merge({}, this.integrationContextSchema, {
      properties: {
        operations: {
          properties: {
            [asNodeIdentifier(this.name)]: outputSchema,
          },
          required: this.generateRequired(),
        },
      },
    });
  }

  getOutputSchema(): JSONSchema7 | undefined {
    return undoInputTransformations(
      this.outputSchema,
      this.inputTransformations
    );
  }

  getTransformedOutputSchema(): JSONSchema7 | undefined {
    return this.outputSchema;
  }
}

class WebhookTriggerNodeHandler
  extends BaseTriggerNodeHandler
  implements InternalNodeHandler
{
  private readonly node: WebhookTriggerNode;

  constructor({
    node,
    integrationContextSchema,
  }: {
    node: WebhookTriggerNode;
    integrationContextSchema: JSONSchema7;
  }) {
    super(integrationContextSchema, node.triggerSchema.content.jsonSchema);
    this.node = node;
  }

  getNode() {
    return this.node;
  }

  runAfterError(): Promise<void> {
    return Promise.resolve();
  }

  run(ctx: WorkflowRunContext): Promise<InternalHandlerResult> {
    const data = ctx.integrationContext.trigger;

    // check if there are previous results
    const previousResults = getPreviousResults(
      this.node.id,
      ctx.previousResults,
      true
    );

    const results: WorkflowNodeResult[] =
      previousResults.length === 0
        ? [
            {
              workflowNodeId: this.node.id,
              nodeType: this.node.nodeType,
              started: new Date().toISOString(),
              ended: new Date().toISOString(),
              input: isEmpty(data) ? undefined : data,
              output: isEmpty(data) ? undefined : data,
              status: 'Finished',
            },
          ]
        : // if there are previous results, use them
          previousResults;

    return Promise.resolve({
      integrationContext: ctx.integrationContext,
      results,
      status: 'Finished' as IntegrationEventStatus,
      output: undefined,
    });
  }
}

class ApiEndpointTriggerNodeHandler
  extends BaseTriggerNodeHandler
  implements InternalNodeHandler
{
  private readonly node: ApiEndpointTriggerNode;

  constructor({
    node,
    integrationContextSchema,
  }: {
    node: ApiEndpointTriggerNode;
    integrationContextSchema: JSONSchema7;
  }) {
    super(integrationContextSchema, node.triggerSchema.content.jsonSchema);
    this.node = node;
  }

  getNode() {
    return this.node;
  }

  runAfterError(): Promise<void> {
    return Promise.resolve();
  }

  run(ctx: WorkflowRunContext): Promise<InternalHandlerResult> {
    const data = ctx.integrationContext.trigger;

    // check if there are previous results
    const previousResults = getPreviousResults(
      this.node.id,
      ctx.previousResults,
      true
    );

    const results: WorkflowNodeResult[] =
      previousResults.length === 0
        ? [
            {
              workflowNodeId: this.node.id,
              nodeType: this.node.nodeType,
              started: new Date().toISOString(),
              ended: new Date().toISOString(),
              input: isEmpty(data) ? undefined : data,
              output: isEmpty(data) ? undefined : data,
              status: 'Finished',
            },
          ]
        : // if there are previous results, use them
          previousResults;

    return Promise.resolve({
      integrationContext: ctx.integrationContext,
      results,
      status: 'Finished' as IntegrationEventStatus,
      output: undefined,
    });
  }
}

class PollingTriggerNodeHandler
  extends BaseTriggerNodeHandler
  implements InternalNodeHandler
{
  private readonly node: PollingTriggerNode;

  protected readonly integrationContextSchema: JSONSchema7;

  constructor({
    node,
    integrationContextSchema,
  }: {
    node: PollingTriggerNode;
    integrationContextSchema: JSONSchema7;
  }) {
    super(
      integrationContextSchema,
      node.operation.outputSchema?.content.jsonSchema ??
        (node.operation as EmbeddedIntegrationOperation)?.prototype
          ?.outputSchema?.content.jsonSchema
    );
    this.node = node;
    this.integrationContextSchema = integrationContextSchema;
  }

  getNode() {
    return this.node;
  }

  runAfterError(): Promise<void> {
    return Promise.resolve();
  }

  private async execute(
    ctx: WorkflowRunContext
  ): Promise<WorkflowNodeResult[]> {
    const schema = this.getFullTransformedInputSchema();
    if (schema === undefined)
      throw new InternalServerError(
        `Cannot run handler - input schema not defined`
      );

    const executeOperation = makeOperationRunner(
      ctx,
      this.node.operation,
      this.node.id,
      schema
    );

    const resultHandler = new WorkflowResultHandler({
      workflowNodeId: this.node.id,
      nodeType: this.node.nodeType,
      operationId: this.node.operation.operationId,
      errorMessage: `Operation ${this.node.operation.operationId} handling in polling trigger failed`,
    });

    resultHandler.add(await executeOperation(ctx.integrationContext));

    return resultHandler.getResults();
  }

  async run(ctx: WorkflowRunContext): Promise<InternalHandlerResult> {
    const schema = this.getFullTransformedInputSchema();
    if (schema === undefined)
      throw new InternalServerError(
        `Cannot run handler - input schema not defined`
      );

    // check if there are previous results
    const previousResults = getPreviousResults(
      this.node.id,
      ctx.previousResults,
      true
    );

    const results: WorkflowNodeResult[] =
      previousResults.length === 0 ? await this.execute(ctx) : previousResults;

    if (results.length < 1)
      throw new InternalServerError(
        `Operation ${this.node.operation.operationId} returned no results during polling trigger handling`
      );

    if (results.length > 1)
      throw new InternalServerError(
        `Operation ${this.node.operation.operationId} returned multiple results during polling trigger handling`
      );

    const result = results[0];

    return Promise.resolve({
      results: results,
      output: result.output,
      status: result.status,
      integrationContext: merge({}, ctx.integrationContext, {
        trigger: result.output,
      }),
      integrationContextSchema: this.getFullOutputSchema() ?? {},
    });
  }
}

class ErrorTriggerNodeHandler
  extends BaseTriggerNodeHandler
  implements InternalNodeHandler
{
  private readonly node: ErrorTriggerNode;

  constructor({
    node,
    integrationContextSchema,
  }: {
    node: ErrorTriggerNode;
    integrationContextSchema: JSONSchema7;
  }) {
    super(
      integrationContextSchema,
      node.triggerSchema?.content.jsonSchema ?? failedIntegrationActionSchema
    );
    this.node = node;
  }

  getNode() {
    return this.node;
  }

  runAfterError(): Promise<void> {
    return Promise.resolve();
  }

  run(ctx: WorkflowRunContext): Promise<InternalHandlerResult> {
    const data = ctx.integrationContext.trigger;

    // check if there are previous results
    const previousResults = getPreviousResults(
      this.node.id,
      ctx.previousResults,
      true
    );

    const results: WorkflowNodeResult[] =
      previousResults.length === 0
        ? [
            {
              workflowNodeId: this.node.id,
              nodeType: this.node.nodeType,
              started: new Date().toISOString(),
              ended: new Date().toISOString(),
              input: isEmpty(data) ? undefined : data,
              output: isEmpty(data) ? undefined : data,
              status: 'Finished',
            },
          ]
        : // if there are previous results, use them
          previousResults;

    return Promise.resolve({
      results: results,
      integrationContext: ctx.integrationContext,
      output: data,
      status: results[0].status,
    });
  }
}

class OperationNodeHandler
  extends BaseOperationNodeHandler
  implements InternalNodeHandler
{
  protected readonly node: OperationNode;

  constructor({
    node,
    integrationContextSchema,
  }: {
    node: OperationNode;
    integrationContextSchema: JSONSchema7;
  }) {
    super(
      integrationContextSchema,
      node.operation.outputSchema?.content.jsonSchema ??
        (node.operation as EmbeddedIntegrationOperation)?.prototype
          ?.outputSchema?.content.jsonSchema,
      node.inputTransformations,
      node.name
    );
    this.node = node;
  }

  getNode() {
    return this.node;
  }

  private async execute(
    transformedInputData: unknown | undefined,
    ctx: WorkflowRunContext
  ): Promise<WorkflowNodeResult[]> {
    debug(`Executing operation handler`, { transformedInputData });
    if (transformedInputData === undefined) return [];

    const schema = this.getFullTransformedInputSchema();
    if (schema === undefined)
      throw new InternalServerError(
        `Cannot run handler - input schema not defined`
      );

    const executeOperation = makeOperationRunner(
      ctx,
      this.node.operation,
      this.node.id,
      schema
    );

    const resultHandler = new WorkflowResultHandler({
      workflowNodeId: this.node.id,
      nodeType: this.node.nodeType,
      operationId: this.node.operation.operationId,
      errorMessage: `Operation ${this.node.operation.operationId} handling failed`,
    });

    if (this.node.inputTransformations?.iterate === true) {
      if (!Array.isArray(transformedInputData))
        throw new InternalServerError(
          `Operation ${this.node.operation.operationId} handling failed - node is set to iterated, but input is not an array`
        );

      const conditionalFilter = makeInputDataFilter(
        this.node.inputTransformations.filter,
        schema
      );

      for (const item of transformedInputData.filter(conditionalFilter)) {
        resultHandler.add(await executeOperation(item));
      }
    } else {
      resultHandler.add(await executeOperation(transformedInputData));
    }

    return resultHandler.getResults();
  }

  runAfterError(): Promise<void> {
    return Promise.resolve();
  }

  async run(ctx: WorkflowRunContext): Promise<InternalHandlerResult> {
    debug('OperationHandler');

    const transformedInputData = transformToInputData(
      ctx.integrationContext,
      this.node.inputTransformations,
      this.getFullInputSchema() ?? {}
    );

    // TODO: maybe add an option for disabling the use of previous results to the node?
    const previousResults = getPreviousResults(
      this.node.id,
      ctx.previousResults
    );

    const runCondition = makeConditionChecker(
      this.node,
      ctx.integrationContextSchema
    );

    if (!runCondition(ctx.integrationContext)) {
      return Promise.resolve({
        integrationContext: ctx.integrationContext,
        output: undefined,
        results: [
          ...previousResults,
          resultBase(this.node, transformedInputData, undefined, 'Omitted'),
        ],
        status: 'Omitted',
      });
    }

    const newResults = await this.execute(
      filterPreviousResults(transformedInputData, previousResults),
      ctx
    );

    const results = [...previousResults, ...newResults];

    const output = this.defineOutput(results);

    const integrationContext =
      output === undefined
        ? ctx.integrationContext
        : merge({}, ctx.integrationContext, {
            operations: {
              [asNodeIdentifier(this.node.name)]: output,
            },
          });

    return Promise.resolve({
      integrationContext,
      output,
      results: results,
      status: results.length > 0 ? getFinalResult(results).status : 'Finished',
    });
  }
}

class DispatchNodeHandler
  extends BaseOperationNodeHandler
  implements InternalNodeHandler
{
  private readonly node: DispatchNode;

  constructor({
    node,
    integrationContextSchema,
  }: {
    node: DispatchNode;
    integrationContextSchema: JSONSchema7;
  }) {
    super(
      integrationContextSchema,
      // dispatch does not have an output schema
      undefined,
      node.inputTransformations,
      node.name
    );
    this.node = node;
  }

  getNode() {
    return this.node;
  }

  private async execute(
    transformedInputData: unknown | undefined,
    ctx: WorkflowRunContext
  ): Promise<WorkflowNodeResult[]> {
    debug(`Executing dispatch handler`, { transformedInputData });

    if (transformedInputData === undefined) return [];

    const schema = this.getFullTransformedInputSchema();

    if (schema === undefined)
      throw new InternalServerError(
        `Cannot run handler - input schema not defined`
      );

    const executeDispatch = makeDispatchRunner(
      ctx,
      this.node.id,
      this.node.integrationId,
      schema,
      this.node.triggerSchema,
      this.node.mappingSchema,
      this.node.isSynchronous
    );

    const resultHandler = new WorkflowResultHandler({
      workflowNodeId: this.node.id,
      nodeType: this.node.nodeType,
      operationId: undefined,
      errorMessage: `Dispatch to ${this.node.integrationId} handling failed`,
    });

    if (this.node.inputTransformations?.iterate === true) {
      if (!Array.isArray(transformedInputData))
        throw new InternalServerError(
          `Dispatch to ${this.node.integrationId} handling failed - node is set to iterated, but input is not an array`
        );

      const conditionalFilter = makeInputDataFilter(
        this.node.inputTransformations.filter,
        schema
      );

      for (const item of transformedInputData.filter(conditionalFilter)) {
        resultHandler.add(await executeDispatch(item));
      }
    } else {
      resultHandler.add(await executeDispatch(transformedInputData));
    }

    return resultHandler.getResults();
  }

  runAfterError(): Promise<void> {
    return Promise.resolve();
  }

  async run(ctx: WorkflowRunContext): Promise<InternalHandlerResult> {
    debug('runDispatch');

    const transformedInputData = transformToInputData(
      ctx.integrationContext,
      this.node.inputTransformations,
      this.getFullInputSchema() ?? {}
    );

    // TODO: maybe add an option for disabling the use of previous results to the node?
    const previousResults = getPreviousResults(
      this.node.id,
      ctx.previousResults
    );

    const runCondition = makeConditionChecker(
      this.node,
      ctx.integrationContextSchema
    );

    if (!runCondition(ctx.integrationContext)) {
      return Promise.resolve({
        integrationContext: ctx.integrationContext,
        output: undefined,
        results: [
          ...previousResults,
          resultBase(this.node, transformedInputData, undefined, 'Omitted'),
        ],
        status: 'Omitted',
      });
    }

    const newResults = await this.execute(
      filterPreviousResults(transformedInputData, previousResults),
      ctx
    );

    const results = [...previousResults, ...newResults];

    return Promise.resolve({
      integrationContext: ctx.integrationContext,
      output: undefined,
      results: results,
      status: results.length > 0 ? getFinalResult(results).status : 'Finished',
    });
  }
}

class ApiResultNodeHandler
  extends BaseOperationNodeHandler
  implements InternalNodeHandler
{
  private readonly node: ApiResultNode;

  constructor({
    node,
    integrationContextSchema,
  }: {
    node: ApiResultNode;
    integrationContextSchema: JSONSchema7;
  }) {
    super(
      integrationContextSchema,
      node.resultSchema.content.jsonSchema,
      // result does not provide input transformations
      undefined,
      node.name
    );
    this.node = node;
  }

  getNode() {
    return this.node;
  }

  private async execute(
    transformedInputData: unknown | undefined,
    ctx: WorkflowRunContext
  ): Promise<WorkflowNodeResult[]> {
    debug(`Executing api result handler`, { transformedInputData });

    if (transformedInputData === undefined) return [];

    const schema = this.getFullTransformedInputSchema();

    if (schema === undefined)
      throw new InternalServerError(
        `Cannot run handler - input schema not defined`
      );

    const generateResult = makeApiResultRunner(
      ctx,
      this.node.id,
      schema,
      this.node.resultSchema,
      this.node.mappingSchema
    );

    const resultHandler = new WorkflowResultHandler({
      workflowNodeId: this.node.id,
      nodeType: this.node.nodeType,
      operationId: undefined,
      errorMessage: `Api result data handling failed`,
    });

    resultHandler.add(await generateResult(transformedInputData));

    return resultHandler.getResults();
  }

  async runAfterError(
    ctx: WorkflowRunContext,
    errorResult: WorkflowNodeResult
  ): Promise<void> {
    debug('Generating error result data', { errorResult });

    if (errorResult.error === undefined)
      throw new InternalServerError(
        `Cannot generate error result for api -call - no error found in result`,
        { errorResult }
      );

    await ctx.apiResultHandler({
      result: {
        resultStatus: 'error',
        error: {
          message: errorResult.error.message,
          // TODO: http code...
          details: errorResult.error.error?.body,
        },
      },
      eventContext: ctx.eventContext,
    });

    return Promise.resolve();
  }

  async run(ctx: WorkflowRunContext): Promise<InternalHandlerResult> {
    debug('run api result');

    const transformedInputData = transformToInputData(
      ctx.integrationContext,
      undefined,
      this.getFullInputSchema() ?? {}
    );

    // TODO: maybe add an option for disabling the use of previous results to the node?
    const previousResults = getPreviousResults(
      this.node.id,
      ctx.previousResults
    );

    const newResults = await this.execute(
      filterPreviousResults(transformedInputData, previousResults),
      ctx
    );

    const results = [...previousResults, ...newResults];

    return Promise.resolve({
      integrationContext: ctx.integrationContext,
      output: undefined,
      results: results,
      status: getFinalResult(results).status,
    });
  }
}

class ValidatingNodeHandler implements NodeHandler {
  private readonly handler: InternalNodeHandler;
  private error: WorkflowNodeError | undefined;

  async validate(data: unknown, schema: JSONSchema7) {
    debug('validating', { data, schema });
    try {
      validate(data, schema);
      debug('validation succeeded');
      return true;
    } catch (e) {
      errorLog('Validation failed', { e });
      if (e instanceof Error) {
        this.error = {
          message: e.message,
          error: e,
        };
      } else {
        this.error = {
          message: 'Input validation failed to unknown cause',
          error: e as Error,
        };
      }
      return false;
    }
  }

  private getOperationId(node: IntegrationNode): string | undefined {
    switch (node.nodeType) {
      case IntegrationNodeType.Operation:
      case IntegrationNodeType.PollingTrigger:
        return node.operation.operationId;
      default:
        return undefined;
    }
  }

  buildValidationErrorResult(
    inputData: unknown,
    outputData: unknown
  ): WorkflowNodeResult {
    if (this.error === undefined)
      throw new InternalServerError(
        `Cannot build validation error result - no error found`
      );

    return {
      workflowNodeId: this.handler.getNode().id,
      nodeType: this.handler.getNode().nodeType,
      operationId: this.getOperationId(this.handler.getNode()),
      started: new Date().toISOString(),
      ended: new Date().toISOString(),
      status: 'Error',
      input: isEmpty(inputData) ? undefined : inputData,
      output: isEmpty(outputData) ? undefined : outputData,
      error: this.error,
    };
  }

  constructor(handler: InternalNodeHandler) {
    this.handler = handler;
  }

  private async runAndValidate(
    ctx: WorkflowRunContext
  ): Promise<
    [
      status: IntegrationEventStatus,
      ctx: RunContext,
      results: WorkflowNodeResult[]
    ]
  > {
    // reset error
    this.error = undefined;

    const { integrationContext, results, status } = await this.handler.run(ctx);

    const nodeOutput = isTrigger(this.handler.getNode())
      ? integrationContext.trigger
      : integrationContext.operations[
          asNodeIdentifier(this.handler.getNode().name)
        ];
    const nodeOutputSchema = this.handler.getOutputSchema();

    if (
      nodeOutputSchema !== undefined &&
      !isEmpty(nodeOutputSchema) &&
      !this.handler.getNode().disableValidation &&
      !(await this.validate(nodeOutput, nodeOutputSchema))
    ) {
      return [
        'Error',
        integrationContext,
        [
          ...ctx.results,
          this.buildValidationErrorResult(integrationContext, undefined),
        ],
      ];
    } else {
      debug('Not validating node', {
        nodeOutputSchema: nodeOutputSchema ?? 'undefined',
        disableValidation: this.handler.getNode().disableValidation,
      });
    }

    return [status, integrationContext, results];
  }

  async runAfterError(
    ctx: WorkflowRunContext,
    errorResult: WorkflowNodeResult
  ): Promise<void> {
    await this.handler.runAfterError(ctx, errorResult);
  }

  async run(ctx: WorkflowRunContext): Promise<WorkflowResultContext> {
    const [status, integrationContext, results] = await this.runAndValidate(
      ctx
    );

    return {
      status: status,
      integrationContext,
      integrationContextSchema: this.getFullOutputSchema() ?? {},
      results: [...ctx.results, ...results],
    };
  }

  getFullInputSchema(): JSONSchema7 | undefined {
    return this.handler.getFullInputSchema();
  }

  getFullOutputSchema(): JSONSchema7 | undefined {
    return this.handler.getFullOutputSchema();
  }

  getFullTransformedInputSchema(): JSONSchema7 | undefined {
    return this.handler.getFullTransformedInputSchema();
  }

  getFullTransformedOutputSchema(): JSONSchema7 | undefined {
    return this.handler.getFullTransformedOutputSchema();
  }

  getOutputSchema(): JSONSchema7 | undefined {
    return this.handler.getOutputSchema();
  }

  getTransformedOutputSchema(): JSONSchema7 | undefined {
    return this.handler.getTransformedOutputSchema();
  }
}

function internalNodeHandlerFactory(
  node: IntegrationNode,
  integrationContextSchema: JSONSchema7
): InternalNodeHandler {
  switch (node.nodeType) {
    case IntegrationNodeType.WebhookTrigger:
      return new WebhookTriggerNodeHandler({ node, integrationContextSchema });

    case IntegrationNodeType.PollingTrigger:
      return new PollingTriggerNodeHandler({
        node,
        integrationContextSchema,
      });

    case IntegrationNodeType.ErrorTrigger:
      return new ErrorTriggerNodeHandler({ node, integrationContextSchema });

    case IntegrationNodeType.ApiEndpointTrigger:
      return new ApiEndpointTriggerNodeHandler({
        node,
        integrationContextSchema,
      });

    case IntegrationNodeType.Operation:
      return new OperationNodeHandler({
        node,
        integrationContextSchema,
      });

    case IntegrationNodeType.Dispatch:
      return new DispatchNodeHandler({
        node,
        integrationContextSchema,
      });

    case IntegrationNodeType.ApiResult:
      return new ApiResultNodeHandler({
        node,
        integrationContextSchema,
      });
  }
}

export function nodeHandlerFactory(
  node: IntegrationNode,
  integrationContextSchema: JSONSchema7 = runContextSchema
): NodeHandler {
  const handler = internalNodeHandlerFactory(node, integrationContextSchema);
  return new ValidatingNodeHandler(handler);
}
