import { ApiError } from '@apus/common-lib/api/interface/common';
import { ApiCommandResult } from '@apus/common-lib/api/interface/integration-service';
import useExposedApiCommandResult from '@apus/common-ui/hooks/useExposedApiCommandResult';

type Input = undefined;

export type Output = {
  accountInternalId: string;
  accountDescription?: string;
  accountNumber: string;
  accountName: string;
  accountType?: string;
}[];

function useListNetsuiteAccounts(
  depends: Array<any> = []
): [boolean, ApiCommandResult<Output> | undefined, ApiError | undefined] {
  return useExposedApiCommandResult<Input, Output>(
    {
      input: undefined,
      apiId: 'bc88b7e1-341a-468b-bf64-0278c31a492e',
      group: '7f4af4d6-280e-4b95-9d6f-d97c99b188a4',
      path: '6909ba33-d8de-4e8a-98ea-5c19e9b95e45',
    },
    depends
  );
}

export default useListNetsuiteAccounts;
