import React, { JSX, useCallback, useEffect, useState } from 'react';
import { formatQuery, parseJsonLogic, QueryBuilder } from 'react-querybuilder';

import { Grid, Typography } from '@mui/material';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import { JSONSchema7 } from 'json-schema';
import { isEmpty, isEqual } from 'lodash';

import { fieldsFromSchema, GroupedObjectProperties } from './json-schema-util';

import 'react-querybuilder/dist/query-builder.css';
import './styles.css';

interface Props {
  allowRuleGroups?: boolean;
  showJson?: boolean;
  disabled?: boolean;
  groupedProperties?: GroupedObjectProperties;
  hiddenProperties?: string[];
  useSchemaTitles?: boolean;
  inputSchema: JSONSchema7;
  condition?: string;
  onChange: (condition?: string) => void;
}

// Note: there is something rotten (at least for Webstorm) with the typings, so we need to return an 'any' here
// instead of the RuleGroupTypeIC which would be correct. Using the correct type leads to some kind of constant loop
// within the code analysis -engine.
function parseQuery(rule: string | undefined): any {
  return parseJsonLogic(rule ?? '', {
    independentCombinators: true,
  });
}

function toJsonLogic(changed: any) {
  return formatQuery(changed, { format: 'jsonlogic' });
}

const DefineJsonLogicCondition = ({
  disabled,
  allowRuleGroups = false,
  showJson = false,
  groupedProperties,
  hiddenProperties,
  useSchemaTitles,
  inputSchema,
  condition,
  onChange,
}: Props): JSX.Element => {
  const [workingCondition, setWorkingCondition] = useState<any>();

  const updateWorkingCondition = useCallback(
    (changed: string | undefined) => {
      if (changed !== undefined) {
        setWorkingCondition(parseQuery(changed));
      } else setWorkingCondition({ rules: [] });

      if (!isEqual(condition, changed)) {
        onChange(changed);
      }
    },
    [setWorkingCondition, condition, onChange]
  );

  useEffect(() => {
    updateWorkingCondition(condition);
  }, [condition, updateWorkingCondition]);

  function queryChanged(query: any) {
    const jsonLogic = toJsonLogic(query);

    if (jsonLogic === false || jsonLogic === undefined || isEmpty(jsonLogic)) {
      updateWorkingCondition(undefined);
    } else {
      updateWorkingCondition(JSON.stringify(jsonLogic));
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {workingCondition !== undefined && (
          <QueryBuilderMaterial>
            <QueryBuilder
              fields={fieldsFromSchema(inputSchema, {
                groupedProperties,
                hiddenProperties,
                useSchemaTitles,
              })}
              query={workingCondition}
              onQueryChange={queryChanged}
              autoSelectField={true}
              autoSelectOperator={true}
              resetOnFieldChange={false}
              independentCombinators={true}
              resetOnOperatorChange
              showCombinatorsBetweenRules
              disabled={disabled}
              controlElements={{
                ...(allowRuleGroups === false && {
                  addGroupAction: () => null,
                }),
              }}
            />
          </QueryBuilderMaterial>
        )}
      </Grid>
      {showJson && (
        <Grid item xs={12}>
          {workingCondition !== undefined && (
            <Typography>
              {JSON.stringify(toJsonLogic(workingCondition), null, 2)}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default DefineJsonLogicCondition;
