/**
 * Validation schemas for integration-service -related objects
 */
import { JSONSchema7 } from 'json-schema';

export const integrationDefinitionSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    integrationId: {
      type: 'string',
      minLength: 1,
    },
    name: {
      type: 'string',
      minLength: 1,
    },
    description: {
      type: 'string',
      minLength: 1,
    },
    configuration: {
      type: 'object',
      properties: {
        debugging: {
          type: 'object',
          required: [],
          properties: {
            recordApiCalls: {
              type: 'boolean',
            },
          },
        },
        storage: {
          type: 'object',
          required: ['numberOfDaysToStore'],
          properties: {
            numberOfDaysToStore: {
              type: 'number',
            },
          },
        },
        retrying: {
          type: 'object',
          required: ['enabled', 'strategy', 'delay', 'retries'],
          properties: {
            enabled: {
              type: 'boolean',
            },
            strategy: {
              type: 'string',
              // ajv doesn't handle nullable enums really well...
              //enum: ['Exponential', 'Fixed'],
            },
            delay: {
              type: 'integer',
            },
            retries: {
              type: 'integer',
            },
          },
        },
        general: {
          type: 'object',
          properties: {
            storeFilesSeparately: {
              type: 'boolean',
            },
          },
        },
      },
    },
  },
  required: ['integrationId', 'name'],
};

export const failedIntegrationActionSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    queueId: {
      type: 'string',
      minLength: 1,
    },
    actionId: {
      type: 'string',
      minLength: 1,
    },
    effectiveOn: {
      type: 'string',
      minLength: 1,
    },
    status: {
      type: 'string',
      enum: [
        'Available',
        'Initiated',
        'Omitted',
        'Processed',
        'Failed',
        'Retried',
      ],
      description: 'Status of the action',
      minLength: 1,
    },
    content: {
      type: 'object',
      additionalProperties: {},
    },
    createdFromActionId: {
      type: 'string',
      minLength: 1,
    },
    retriedFromActionId: {
      type: 'string',
      minLength: 1,
    },
    retriedInActionId: {
      type: 'string',
      minLength: 1,
    },
    correlationId: {
      type: 'string',
      minLength: 1,
    },
    retryCount: {
      type: 'number',
    },
    actionType: {
      type: 'string',
      minLength: 1,
    },
    tenantId: {
      type: 'string',
      minLength: 1,
    },
    integration: {
      type: 'object',
      properties: {
        triggerType: {
          type: 'string',
          // ajv doesn't handle nullable enums really well...
          //enum: ['PollingTrigger', 'WebhookTrigger'],
        },
        integrationId: {
          type: 'string',
          minLength: 1,
        },
        name: {
          type: 'string',
          minLength: 1,
        },
      },
      required: ['integrationId', 'name'],
    },
    error: {
      type: 'object',
      properties: {
        message: {
          type: 'string',
          minLength: 1,
        },
        nonRetryable: {
          type: 'boolean',
        },
        error: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
            },
            message: { type: 'string' },
            body: { type: 'object', additionalProperties: {} },
          },
        },
      },
      required: ['message'],
    },
  },
  required: [
    'actionId',
    'actionType',
    'effectiveOn',
    'error',
    'integration',
    'queueId',
    'status',
  ],
};
