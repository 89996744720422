import { JSX } from 'react';

import {
  Rule,
  RuleGroup,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import { List } from '@mui/material';

import { BankTransactionRuleConfigurationData } from './interface';
import RuleListItem from './RuleListItem';
import { PriorityArray } from '@apus/common-lib/utils/src/collections';

interface Props<T extends RuleGroup> {
  rules: PriorityArray<Rule<T, any>>;
  configurationData?: BankTransactionRuleConfigurationData;
  onChange: (rule: Rule<T, any>) => void;
  onMoveUp: (rule: Rule<T, any>) => void;
  onMoveDown: (rule: Rule<T, any>) => void;
  edit?: boolean;
}

const RuleList = <T extends RuleGroup>({
  rules,
  configurationData,
  onChange,
  onMoveUp,
  onMoveDown,
  edit,
}: Props<T>): JSX.Element => {
  return (
    <List>
      {rules.list().map(rule => {
        return (
          <RuleListItem
            key={`rule-${rule.ruleId}`}
            rule={rule}
            configurationData={configurationData}
            onChange={onChange}
            edit={edit}
            toolbar={{
              moveDown: {
                onClick: onMoveDown,
                disabled: rules.isLast(rule),
              },
              moveUp: {
                onClick: onMoveUp,
                disabled: rules.isFirst(rule),
              },
            }}
          />
        );
      })}
    </List>
  );
};

export default RuleList;
