import { Dispatch } from 'react';

import {
  initialAccessTokenState,
  AccessTokenState,
} from './accessTokenContext';

export enum AccessTokenActionEnum {
  AccessTokenRefreshed = 'accessTokenRefreshed',
}

export type AccessTokenAction = {
  type: AccessTokenActionEnum.AccessTokenRefreshed;
  accessToken: string | undefined;
};

export type AccessTokenReducerContext = [
  AccessTokenState,
  Dispatch<AccessTokenAction>
];

export const accessTokenRefreshed = (
  accessToken: string | undefined
): AccessTokenAction => {
  return {
    type: AccessTokenActionEnum.AccessTokenRefreshed,
    accessToken,
  };
};

export const defaultDispatch: Dispatch<AccessTokenAction> = () =>
  initialAccessTokenState;

const actualAccessTokenContextReducer = (
  state: AccessTokenState,
  action: AccessTokenAction
): AccessTokenState => {
  switch (action.type) {
    case AccessTokenActionEnum.AccessTokenRefreshed: {
      return {
        ...state,
        accessToken: action.accessToken,
      };
    }
    default:
      return state;
  }
};

export const accessTokenContextReducer = (
  state: AccessTokenState,
  action: AccessTokenAction
): AccessTokenState => {
  return actualAccessTokenContextReducer(state, action);
};
