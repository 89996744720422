const configuration = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: `${process.env.REACT_APP_REGION}`,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    //signUpVerificationMethod: 'link', // 'code' | 'link'

    // // OPTIONAL - Configuration for cookie storage
    // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'localhost:3000',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    //   sameSite: 'strict',
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true,
    // },
  },
};

export default configuration;
