import { Tenant } from '@apus/common-lib/api/interface/tenant-service';

import { TenantState, initialTenantState } from './tenantContext';
import { isEqual } from 'lodash';

export enum TenantActionEnum {
  SetCurrentTenant = 'setCurrentTenant',
  SetTenants = 'setTenants',
}

export type TenantAction =
  | {
      type: TenantActionEnum.SetCurrentTenant;
      tenant: Tenant;
    }
  | {
      type: TenantActionEnum.SetTenants;
      tenants: Tenant[];
      updatedAt: Date;
    };

/**
 * Set the currently used tenant
 *
 * This will reset the tenant-related state information such as integrations and search results
 *
 * @param tenant
 */
export const setCurrentTenant = (tenant: Tenant): TenantAction => {
  return {
    type: TenantActionEnum.SetCurrentTenant,
    tenant,
  };
};

export const setTenants = (
  tenants: Tenant[],
  updatedAt: Date = new Date()
): TenantAction => {
  return {
    type: TenantActionEnum.SetTenants,
    tenants,
    updatedAt,
  };
};

export type TenantReducerContext = [TenantState, React.Dispatch<TenantAction>];

export const defaultDispatch: React.Dispatch<TenantAction> = () =>
  initialTenantState;

export const tenantContextReducer = (
  state: TenantState,
  action: TenantAction
): TenantState => {
  switch (action.type) {
    case TenantActionEnum.SetCurrentTenant: {
      localStorage.setItem(`tenant-id`, action.tenant.tenantId);

      if (isEqual(state.tenant, action.tenant)) return state;

      return {
        ...state,
        tenant: action.tenant,
      };
    }
    case TenantActionEnum.SetTenants:
      return {
        ...state,
        tenants: action.tenants,
        lastUpdated: {
          ...state.lastUpdated,
          tenants: action.updatedAt,
        },
      };
    default:
      return state;
  }
};
