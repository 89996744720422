import { X2jOptions, XMLBuilder, XMLParser } from 'fast-xml-parser';
import { logRecordFor } from '@apus/common-lib/utils/src/logger';
import { JSONSchema7 } from 'json-schema';

const infoLog = logRecordFor('netsuite/api', 'info');

export function toJSON<OUT>(
  text: string,
  options: X2jOptions = {},
  jsonSchema?: JSONSchema7
): OUT {
  const parser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: '@_',
    ...options,
  });

  infoLog('toJSON', { xml: text });
  return parser.parse(text);
}

export function toXML(data: any): string {
  const builder = new XMLBuilder({
    ignoreAttributes: false,
    attributeNamePrefix: '@_',
  });

  return builder.build(data);
}
