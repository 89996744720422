import { SignedInUser } from '@apus/common-lib/api/interface/tenant-service';
import { UserRole } from '@apus/common-lib/api/interface/integration-service';

export class SystemglueUser implements SignedInUser {
  private readonly _userId: string;
  private readonly _userName: string;
  private readonly _roles: UserRole[];
  private readonly _tenantId: string;

  constructor({
    userId,
    userName,
    roles,
    tenantId,
  }: {
    userId: string;
    userName: string;
    roles: string[];
    tenantId: string;
  }) {
    this._userId = userId;
    this._userName = userName;
    this._roles = roles.map(r => r as UserRole);
    this._tenantId = tenantId;
  }

  get userId(): string {
    return this._userId;
  }

  get userName(): string {
    return this._userName;
  }

  get roles(): UserRole[] {
    return this._roles;
  }

  get tenantId(): string {
    return this._tenantId;
  }
}
