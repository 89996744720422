import React, { JSX, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  Rule,
  RuleGroup,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Grid } from '@mui/material';
import { isEqual } from 'lodash';

import { RuleConditionProps } from '../condition/interface';
import {
  BankTransactionRuleConfigurationData,
  ConfigurationComponent,
} from '../interface';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';

interface Props<T extends RuleGroup> {
  disabled?: boolean;
  rule: Rule<T, any>;
  configurationData?: BankTransactionRuleConfigurationData;
  form: UseFormReturn<any>;
  conditionComponent: React.ComponentType<RuleConditionProps>;
  configurationComponent?: React.ComponentType<ConfigurationComponent>;
  onChange: (rule: Rule<T, any>) => void;
}

const DefineRule = <T extends RuleGroup>(props: Props<T>): JSX.Element => {
  const { rule, form, disabled, onChange, configurationData } = props;

  useEffect(() => {
    const current = {
      name: form.getValues('name'),
      description: form.getValues('description'),
      condition: form.getValues('condition'),
      disableAutomatedHandling: form.getValues('disableAutomatedHandling'),
    };

    const incoming = {
      name: rule.name,
      description: rule.description,
      condition: rule.condition,
      disableAutomatedHandling: rule.disableAutomatedHandling,
    };

    if (isEqual(current, incoming)) {
      return;
    }

    if (form.getValues('name') !== rule.name) form.setValue('name', rule.name);
    if (form.getValues('description') !== rule.description)
      form.setValue('description', rule.description);
    if (form.getValues('condition') !== rule.condition)
      form.setValue('condition', rule.condition);
    if (
      form.getValues('disableAutomatedHandling') !==
      rule.disableAutomatedHandling
    )
      form.setValue('disableAutomatedHandling', rule.disableAutomatedHandling);
  }, [rule, form]);

  function nameChanged(value?: string) {
    onChange({
      ...rule,
      ...form.getValues(),
      name: value,
    });
  }

  function disableAutomatedHandlingChanged(value?: boolean) {
    onChange({
      ...rule,
      ...form.getValues(),
      disableAutomatedHandling: value,
    });
  }

  function descriptionChanged(value?: string) {
    onChange({
      ...rule,
      ...form.getValues(),
      description: value,
    });
  }

  function configurationChanged(value?: any) {
    onChange({
      ...rule,
      ...form.getValues(),
      configuration: value,
    });
  }

  function conditionChanged(value: string) {
    onChange({
      ...rule,
      ...form.getValues(),
      condition: value,
    });
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <HookFormTextInput
          name={'name'}
          form={form}
          title={'Rule name'}
          helperText={'Rule name'}
          fullWidth
          disabled={disabled}
          onValueChange={nameChanged}
        />
      </Grid>
      <Grid item xs={5}>
        <HookFormTextInput
          name={'description'}
          form={form}
          title={'Rule description'}
          helperText={'Rule description'}
          multiline={true}
          rows={7}
          fullWidth
          disabled={disabled}
          onValueChange={descriptionChanged}
        />
      </Grid>
      <Grid item xs={7}>
        {props.configurationComponent !== undefined ? (
          <props.configurationComponent
            configuration={props.rule.configuration}
            configurationData={configurationData}
            form={form}
            disabled={disabled}
            onChange={configurationChanged}
          />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        <HookFormCheckbox
          name={'disableAutomatedHandling'}
          form={form}
          // 'When rule matches perfectly, it will normally be automatically handled. When this is set, handling requires manual acceptance by end users.'
          label={'Automated handling disabled'}
          disabled={disabled}
          onValueChange={disableAutomatedHandlingChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          name={'condition'}
          render={({ field }) => {
            return (
              <props.conditionComponent
                condition={field.value ?? rule.condition}
                onChange={condition => {
                  field.onChange(condition);
                  conditionChanged(condition ?? '');
                }}
                disabled={disabled}
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DefineRule;
