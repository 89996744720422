import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { nodeHandlerFactory } from '@apus/common-lib/integration-engine/src/handler-factory';
import {
  OperationNode,
  PollingTriggerNode,
} from '@apus/common-lib/integration-engine/src/interface';
import { ConfigurationSchema } from '@apus/common-lib/integrations/src/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import { Stack } from '@mui/material';
import { JSONSchema7 } from 'json-schema';

import ConfigurationSchemaForm from '../../../input/ConfigurationSchemaForm';

interface Props {
  integrationContextSchema: JSONSchema7;
  node?: OperationNode | PollingTriggerNode;
  configurationSchema?: ConfigurationSchema;
  onChange: (configuration: SourceObject) => void;
}

interface ConfigurationForm {
  runtimeConfiguration?: SourceObject;
}

const DefineRuntimeConfiguration = ({
  integrationContextSchema,
  node,
  configurationSchema,
  onChange,
}: Props): JSX.Element => {
  const form = useForm<ConfigurationForm>({
    defaultValues: { runtimeConfiguration: undefined },
  });
  const [inputSchema, setInputSchema] = useState<JSONSchema7 | undefined>(
    undefined
  );
  const [outputSchema, setOutputSchema] = useState<JSONSchema7 | undefined>(
    undefined
  );

  useEffect(() => {
    if (node !== undefined) {
      form.setValue(
        'runtimeConfiguration',
        node.operation.runtimeConfiguration
      );
      setInputSchema(
        nodeHandlerFactory(
          node,
          integrationContextSchema
        ).getFullTransformedInputSchema()
      );
      setOutputSchema(
        nodeHandlerFactory(
          node,
          integrationContextSchema
        ).getTransformedOutputSchema()
      );
    } else {
      setInputSchema(undefined);
      setOutputSchema(undefined);
    }
  }, [node, integrationContextSchema, form, setInputSchema, setOutputSchema]);

  if (node === undefined) return <></>;

  return (
    <Stack spacing={2}>
      <ConfigurationSchemaForm
        form={form}
        inputSchema={inputSchema}
        outputSchema={outputSchema}
        configurationSchema={configurationSchema}
        propertyPath={'runtimeConfiguration'}
        onChange={onChange}
      />
    </Stack>
  );
};

export default DefineRuntimeConfiguration;
