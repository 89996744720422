import parserTypeScript from 'prettier/parser-typescript';
import { format as prettify } from 'prettier';
import { DEFAULT_OPTIONS, Options } from './index';

export function format(
  code: string,
  _options: Options = DEFAULT_OPTIONS
): string {
  return prettify(code, { parser: 'typescript', plugins: [parserTypeScript] });
}
