import { JSX } from 'react';

import TenantList from '../../../components/tenants/TenantList';
import useSetAppBarRoutes from '../../../hooks/useSetAppBarRoutes';
import { tenantRoutes } from '../../../routes/service-provider-routes';

const TenantsView = (): JSX.Element => {
  useSetAppBarRoutes(tenantRoutes);

  return <TenantList />;
};

export default TenantsView;
