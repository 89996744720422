import { JSONSchema7, JSONSchema7TypeName } from 'json-schema';
import {
  SupportedMappingSchemaDefinition,
  SupportedSchemaDefinition,
} from '../../api/interface/files';
import { EventContext } from '../../integrations/src/interface';

export enum PropertyType {
  Array = 'array',
  Object = 'object',
  String = 'string',
  Boolean = 'boolean',
  Number = 'number',
}

export interface PropertyMapping extends Omit<ExtendedJSON7Schema, 'mapping'> {
  // map property to this name
  name?: string;
  // map property to this type (not applicable to objects) - by default same type is used
  type?: 'string' | 'number' | 'boolean';
  // omit mapping if value is any of provided strings (case-insensitive)
  omit?: string[];
  // if value is a date -string, use format to transform to another date -string
  format?: string;
  // use this when no value is defined
  default?: string | number | boolean;
  // map source values to another string
  valueMapping?: Record<string, string>;
}

export interface ExtendedJSON7Schema
  extends Omit<
    JSONSchema7,
    '$defs' | 'items' | 'properties' | 'dependencies' | 'definitions'
  > {
  /**
   * Mapping directives
   */
  mapping?: PropertyMapping;
  $defs?:
    | {
        [key: string]: ExtendedJSON7Schema;
      }
    | undefined;
  items?: ExtendedJSON7Schema | ExtendedJSON7Schema[] | undefined;
  properties?:
    | {
        [key: string]: ExtendedJSON7Schema;
      }
    | undefined;
  dependencies?:
    | {
        [key: string]: ExtendedJSON7Schema | string[];
      }
    | undefined;
  definitions?:
    | {
        [key: string]: ExtendedJSON7Schema;
      }
    | undefined;
}

export interface MappingRule {
  sourcePaths?: string[];
  coalesce?: boolean;
  strict?: boolean;
  constant?: number | string | boolean;
}

export interface MappingRules {
  [destPath: string]: MappingRule;
}

export type SourceObject = Record<string | number | symbol, unknown>;

export interface ParsedSchemaPath {
  context?: string;
  path: string;
}

export type MappingType =
  | 'objectProperties'
  | 'object'
  | 'objectArray'
  | 'primitive'
  | 'primitiveArray';

export interface SchemaMappingRuleProperties {
  [destPath: string]: SchemaMappingRule;
}

export interface SchemaMappingRule {
  id?: string;
  mappingType?: MappingType;
  sources?: SchemaMappingRule[];
  sourcePath?: string;
  fullSourcePath?: string;
  targetPath?: string;
  fullTargetPath?: string;
  properties?: SchemaMappingRuleProperties;
  // treat all sub-rules as new objects i.e. don't expand with parent path
  root?: boolean;
  // coalesce multiple arrays into one
  coalesce?: boolean;
  // TODO: what is the strict mode
  strict?: boolean;
  // define destPath as a constant value - when provided, sourcePaths will be ignored
  constant?: number | string | boolean;
  // if value is a date -string, use format to transform to another date -string
  format?: string;
  // use this when no value is defined
  default?: string | number | boolean;
  // map source values to another string
  valueMapping?: Record<string, string>;
}

export type ObjectPath = string;
export type SchemaPath = string;

export interface MappingAsGridTreeRow
  extends Pick<
    SchemaMappingRule,
    'coalesce' | 'format' | 'default' | 'valueMapping'
  > {
  id: string;
  hierarchy: string[];
  type: JSONSchema7TypeName;
  source?: boolean;
}

export interface SchemaItem {
  id: string;
  path: string;
  schemaPath: string;
  objectPath: string;
  relativeSchemaPath: string;
  relativeObjectPath: string;
  type: JSONSchema7TypeName;
  isRoot: boolean;
  isArray: boolean;
  isRequired?: boolean;
  isImmutable?: boolean;
  enum?: (string | number | boolean | null)[];
  const?: string | number | boolean;
  minimum?: number;
  maximum?: number;
  minItems?: number;
  maxItems?: number;
  minLength?: number;
  maxLength?: number;
  title?: string;
  pattern?: string;
  format?: string;
  default?: string | number | boolean;
  properties: SchemaItem[];
}

export interface MappingItem {
  id: string;
  path: string;
  objectPath?: string;
  schemaPath?: string;
  relativeObjectPath?: string;
  relativeSchemaPath?: string;
  parentId?: string;
  type: JSONSchema7TypeName;
  isArray: boolean;
  isSource?: boolean;
  isRequired?: boolean;
  isUnmapped?: boolean;
  properties?: MappingItem[];
  sources?: MappingItem[];
  priority?: number;
  aggregated?: boolean;
  format?: string;
  default?: string | number | boolean;
  valueMapping?: Record<string, string>;
  constant?: string | number | boolean;
}

export interface MappingTreeViewModel {
  mapped: MappingItem[];
  unmapped: MappingItem[];
}

export type MappingSchemaDefinition = Pick<
  SupportedMappingSchemaDefinition,
  'content' | 'contentType'
>;

export interface MappingFunctionArgs {
  data: SourceObject;
  mappingDefinition: MappingSchemaDefinition;
  inputSchema: SupportedSchemaDefinition;
  outputSchema: SupportedSchemaDefinition;
  eventContext: EventContext;
}

export type MappingFunction = ({
  data,
  mappingDefinition,
  inputSchema,
  outputSchema,
  eventContext,
}: MappingFunctionArgs) => SourceObject;

export enum SupportedFileType {
  'Json' = 'JSON -file',
  'JsonSchema' = 'JSON Schema -file',
}
