import React, { JSX } from 'react';
import { Routes, Route } from 'react-router-dom';

import ApiCommandView from './apis/ApiCommandView';
import ApiCreateView from './apis/ApiCreateView';
import ApiListView from './apis/ApiListView';
import ApiView from './apis/ApiView';
import IntegrationCreateView from './integrations/IntegrationCreateView';
import IntegrationView from './integrations/IntegrationView';
import IntegrationList from '../../components/integration/IntegrationList';
import useSetAppBarRoutes from '../../hooks/useSetAppBarRoutes';
import { integrationRoutes } from '../../routes/service-provider-routes';

const IntegrationsView = (): JSX.Element => {
  useSetAppBarRoutes(integrationRoutes);

  return (
    <Routes>
      <Route path="/" element={<IntegrationList />} />
      <Route path="create" element={<IntegrationCreateView />} />
      <Route path=":integrationId" element={<IntegrationView />} />
      <Route path="apis/" element={<ApiListView />} />
      <Route path="apis/create" element={<ApiCreateView />} />
      <Route path="apis/:apiId" element={<ApiView />} />
      <Route path="apis/command/:integrationId" element={<ApiCommandView />} />
    </Routes>
  );
};

export default IntegrationsView;
