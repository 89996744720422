import { createContext } from 'react';

import { IntegrationActionLifecycleNotification } from '@apus/common-lib/api/interface/integration-service';

import { WebsocketReducerContext } from './websocketReducer';
import { LimitedQueue } from '@apus/common-lib/utils/src/collections';

export interface WebsocketState {
  events: LimitedQueue<IntegrationActionLifecycleNotification>;
  isOpening: boolean;
  isOpen: boolean;
  openConnection: () => void;
}

export const initialWebsocketState: WebsocketState = {
  events: new LimitedQueue<IntegrationActionLifecycleNotification>(),
  isOpening: false,
  isOpen: false,
  openConnection: () => {
    throw new Error(`No implementation provided`);
  },
};

export const WebsocketContext = createContext<WebsocketReducerContext>([
  initialWebsocketState,
  () => initialWebsocketState,
]);
