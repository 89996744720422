import React, { JSX } from 'react';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fi from 'date-fns/locale/fi';

type Props<FormType extends FieldValues> = {
  name: string;
  onValueChange?: (value?: string) => void;
  key?: string;
  form: UseFormReturn<FormType>;
} & DateTimePickerProps<Date>;

const HookFormDateTimePicker = <FormType extends FieldValues>({
  name,
  form,
  key,
  ...otherProps
}: Props<FormType>): JSX.Element => {
  return (
    <Controller
      control={form.control}
      name={name as Path<FormType>}
      key={key !== undefined ? `${key}-controller` : `${name}-controller`}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
          <DateTimePicker
            {...otherProps}
            value={field.value}
            onChange={value => {
              if (value != null) field.onChange(new Date(value));
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default HookFormDateTimePicker;
