import React, { JSX, useEffect, useState } from 'react';

import {
  IntegrationDefinition,
  IntegrationWorkflowDefinition,
} from '@apus/common-lib/api/interface/integration-service';
import IntegrationEngine from '@apus/common-lib/integration-engine/src/integration-engine';
import {
  IntegrationNode,
  IntegrationNodeType,
  LinkableNode,
} from '@apus/common-lib/integration-engine/src/interface';
import {
  IntegrationModule,
  IntegrationOperation,
  IntegrationOperationPrototype,
  RequestAuthorizer,
  runContextSchema,
} from '@apus/common-lib/integrations/src/interface';
import {
  asAuthorizers,
  asOperations,
  asPrototypes,
} from '@apus/common-lib/utils/src/data-utils';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Alert, AlertColor, Box, Snackbar } from '@mui/material';
import { JSONSchema7 } from 'json-schema';

import AddWorkflowNode, { NewNode } from './AddWorkflowNode';
import WorkflowNode from './WorkflowNode';
import DefineApiEndpointTriggerNode from '../node/DefineApiEndpointTriggerNode';
import DefineApiResultNode from '../node/DefineApiResultNode';
import DefineDispatchNode from '../node/DefineDispatchNode';
import DefineErrorTriggerNode from '../node/DefineErrorTriggerNode';
import DefineOperationNode from '../node/DefineOperationNode';
import DefinePollingTriggerNode from '../node/DefinePollingTriggerNode';
import DefineWebhookTriggerNode from '../node/DefineWebhookTriggerNode';

interface Props {
  workflow?: IntegrationWorkflowDefinition;
  onChange: (workflow: IntegrationWorkflowDefinition) => void;
  modules: IntegrationModule[];
  integrations: IntegrationDefinition[];
}

interface NodeFormProps {
  expanded?: boolean;
  nodeType: IntegrationNodeType;
  integrationContextSchema: JSONSchema7;
  sourceId?: string;
  node?: IntegrationNode;
  integrations: IntegrationDefinition[];
  operations: IntegrationOperation[];
  prototypes: IntegrationOperationPrototype[];
  authorizers: RequestAuthorizer[];
  onSave: (node: IntegrationNode) => void;
  onDelete: (node: IntegrationNode) => void;
  onMoveUp?: (node: IntegrationNode) => void;
  onMoveDown?: (node: IntegrationNode) => void;
  onCancel: () => void;
}

const NodeForm = ({
  expanded,
  integrationContextSchema,
  sourceId,
  nodeType,
  node,
  integrations,
  operations,
  prototypes,
  authorizers,
  onSave,
  onDelete,
  onCancel,
  onMoveUp,
  onMoveDown,
}: NodeFormProps) => {
  const props = {
    expanded,
    integrationContextSchema,
    integrations,
    authorizers,
    operations,
    prototypes,
    sourceId,
    onSave,
    onCancel,
    onDelete,
    onMoveUp,
    onMoveDown,
  };

  if (node !== undefined)
    switch (node.nodeType) {
      case 'ErrorTrigger': {
        return <DefineErrorTriggerNode {...props} node={node} />;
      }
      case 'WebhookTrigger': {
        return <DefineWebhookTriggerNode {...props} node={node} />;
      }
      case 'PollingTrigger': {
        return <DefinePollingTriggerNode {...props} node={node} />;
      }
      case 'ApiEndpointTrigger': {
        return <DefineApiEndpointTriggerNode {...props} node={node} />;
      }
      case 'Dispatch': {
        if (sourceId === undefined)
          throw new Error(`Cannot show dispatch - missing sourceId`);
        return (
          <DefineDispatchNode {...props} node={node} sourceId={sourceId} />
        );
      }
      case 'Operation': {
        if (sourceId === undefined)
          throw new Error(`Cannot show dispatch - missing sourceId`);
        return (
          <DefineOperationNode {...props} node={node} sourceId={sourceId} />
        );
      }
      case 'ApiResult': {
        if (sourceId === undefined)
          throw new Error(`Cannot show dispatch - missing sourceId`);
        return (
          <DefineApiResultNode {...props} node={node} sourceId={sourceId} />
        );
      }
    }
  else {
    switch (nodeType) {
      case 'ErrorTrigger': {
        return <DefineErrorTriggerNode {...props} />;
      }
      case 'WebhookTrigger': {
        return <DefineWebhookTriggerNode {...props} />;
      }
      case 'PollingTrigger': {
        return <DefinePollingTriggerNode {...props} />;
      }
      case 'ApiEndpointTrigger': {
        return <DefineApiEndpointTriggerNode {...props} />;
      }
      case 'Dispatch': {
        if (sourceId === undefined)
          throw new Error(`Cannot show dispatch - missing sourceId`);
        return <DefineDispatchNode {...props} sourceId={sourceId} />;
      }
      case 'Operation': {
        if (sourceId === undefined)
          throw new Error(`Cannot show dispatch - missing sourceId`);
        return <DefineOperationNode {...props} sourceId={sourceId} />;
      }
      case 'ApiResult': {
        if (sourceId === undefined)
          throw new Error(`Cannot show dispatch - missing sourceId`);
        return <DefineApiResultNode {...props} sourceId={sourceId} />;
      }
    }
  }
  return <></>;
};

interface AlertMessage {
  severity: AlertColor;
  message: string;
}

const DefineWorkflow = ({
  workflow,
  onChange,
  modules,
  integrations,
}: Props): JSX.Element => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage | undefined>();
  const [operations, setOperations] = useState<IntegrationOperation[]>([]);
  const [prototypes, setPrototypes] = useState<IntegrationOperationPrototype[]>(
    []
  );
  const [authorizers, setAuthorizers] = useState<RequestAuthorizer[]>([]);
  const [engine, setEngine] = useState<IntegrationEngine>();
  const [workingWorkflow, setWorkingWorkflow] =
    useState<Partial<IntegrationWorkflowDefinition>>();
  const [newNode, setNewNode] = useState<NewNode | undefined>();

  const [expandedNodeId, setExpandedNodeId] = useState<string | undefined>();

  useEffect(() => {
    if (workflow !== undefined) {
      setWorkingWorkflow(workflow);
    } else {
      setWorkingWorkflow({
        nodes: [],
        trigger: undefined,
      });
    }
  }, [workflow]);

  useEffect(() => {
    if (workingWorkflow !== undefined && workingWorkflow.trigger !== undefined)
      setEngine(
        IntegrationEngine.parse(
          workingWorkflow as IntegrationWorkflowDefinition
        )
      );
    else {
      setEngine(new IntegrationEngine());
    }
  }, [workingWorkflow]);

  useEffect(() => {
    setOperations(asOperations(modules));
    setPrototypes(asPrototypes(modules));
    setAuthorizers(asAuthorizers(modules));
  }, [modules, setPrototypes, setOperations]);

  function updateWorkingWorkflow(workflow: IntegrationWorkflowDefinition) {
    setWorkingWorkflow(workflow);
    onChange(workflow);
    setExpandedNodeId(undefined);
    setAlertMessage({ message: 'Workflow updated', severity: 'success' });
    setShowMessage(true);
  }

  const updateNode = (node: IntegrationNode) => {
    if (engine === undefined)
      throw new Error('Cannot update node - engine is undefined');

    switch (node.nodeType) {
      case IntegrationNodeType.Dispatch:
        engine.updateDispatchNode(node);
        break;
      case IntegrationNodeType.Operation:
        engine.updateOperationNode(node);
        break;
      case IntegrationNodeType.ApiResult:
        engine.updateApiResultNode(node);
        break;
      case IntegrationNodeType.PollingTrigger:
        engine.updatePollingTriggerNode(node);
        break;
      case IntegrationNodeType.WebhookTrigger:
        engine.updateWebhookTriggerNode(node);
        break;
      case IntegrationNodeType.ErrorTrigger:
        engine.updateErrorTriggerNode(node);
        break;
      case IntegrationNodeType.ApiEndpointTrigger:
        engine.updateApiEndpointTriggerNode(node);
        break;
    }
    updateWorkingWorkflow(engine.asDefinition());
  };

  const createNode = (node: IntegrationNode) => {
    if (engine === undefined)
      throw new Error('Cannot create node - engine is undefined');

    switch (node.nodeType) {
      case IntegrationNodeType.Dispatch:
        engine.addDispatchNode(node);
        break;
      case IntegrationNodeType.Operation:
        engine.addOperationNode(node);
        break;
      case IntegrationNodeType.ApiResult:
        engine.addApiResultNode(node);
        break;
      case IntegrationNodeType.PollingTrigger:
        engine.addPollingTriggerNode(node);
        break;
      case IntegrationNodeType.WebhookTrigger:
        engine.addWebhookTriggerNode(node);
        break;
      case IntegrationNodeType.ErrorTrigger:
        engine.addErrorTriggerNode(node);
        break;
      case IntegrationNodeType.ApiEndpointTrigger:
        engine.addApiEndpointTriggerNode(node);
        break;
    }
    updateWorkingWorkflow(engine.asDefinition());
  };

  const deleteNode = (node: IntegrationNode) => {
    if (engine === undefined)
      throw new Error('Cannot delete node - engine is undefined');

    switch (node.nodeType) {
      case IntegrationNodeType.Dispatch:
        engine.deleteDispatchNode(node);
        break;
      case IntegrationNodeType.Operation:
        engine.deleteOperationNode(node);
        break;
      case IntegrationNodeType.ApiResult:
        engine.deleteApiResultNode(node);
        break;
      case IntegrationNodeType.PollingTrigger:
        throw new Error(`Cannot delete polling trigger node`);
      case IntegrationNodeType.WebhookTrigger:
        throw new Error(`Cannot delete webhook trigger node`);
      case IntegrationNodeType.ErrorTrigger:
        throw new Error(`Cannot delete error trigger node`);
      case IntegrationNodeType.ApiEndpointTrigger:
        throw new Error(`Cannot delete api endpoint trigger node`);
    }
    updateWorkingWorkflow(engine.asDefinition());
  };

  const onSave = (node: IntegrationNode) => {
    if (engine === undefined)
      throw new Error('Cannot handle node save - engine is undefined');

    if (engine.containsNode(node)) {
      updateNode(node);
    } else {
      createNode(node);
    }
    updateWorkingWorkflow(engine.asDefinition());
  };

  const onDelete = (node: IntegrationNode) => {
    if (engine === undefined)
      throw new Error('Cannot handle node delete - engine is undefined');

    if (engine.containsNode(node)) {
      deleteNode(node);
    }
    updateWorkingWorkflow(engine.asDefinition());
  };

  const onCancel = () => {
    setExpandedNodeId(undefined);
  };

  const onMoveUp = (node: IntegrationNode) => {
    if (engine === undefined)
      throw new Error('Cannot move node up - engine is undefined');

    switch (node.nodeType) {
      case IntegrationNodeType.Dispatch:
      case IntegrationNodeType.Operation:
      case IntegrationNodeType.ApiResult:
        engine.moveUp(node);
        break;
      default:
        throw new Error(
          `Cannot move node up - unsupported node type ${node.nodeType}`
        );
    }
    updateWorkingWorkflow(engine.asDefinition());
  };

  const onMoveDown = (node: IntegrationNode) => {
    if (engine === undefined)
      throw new Error('Cannot move node down - engine is undefined');

    switch (node.nodeType) {
      case IntegrationNodeType.Dispatch:
      case IntegrationNodeType.Operation:
      case IntegrationNodeType.ApiResult:
        engine.moveDown(node);
        break;
      default:
        throw new Error(
          `Cannot move node down - unsupported node type ${node.nodeType}`
        );
    }
    updateWorkingWorkflow(engine.asDefinition());
  };

  const onAddNewNode = (node: NewNode) => {
    setExpandedNodeId(node.id);
    setNewNode(node);
  };

  const handleAlertMessageClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowMessage(false);
  };

  const nodeFormParams = {
    integrations,
    operations,
    prototypes,
    authorizers,
    onSave,
    onDelete,
    onCancel,
    onMoveUp,
    onMoveDown,
  };

  if (operations.length === 0 || engine === undefined) return <></>;

  return (
    <Box>
      {alertMessage !== undefined && (
        <Snackbar
          open={showMessage}
          autoHideDuration={6000}
          onClose={handleAlertMessageClose}
        >
          <Alert
            onClose={handleAlertMessageClose}
            severity={alertMessage.severity}
            sx={{ width: '100%' }}
          >
            {alertMessage.message}
          </Alert>
        </Snackbar>
      )}
      <Timeline
        sx={{
          padding: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {workingWorkflow?.trigger !== undefined && (
          <>
            <WorkflowNode
              node={workingWorkflow.trigger}
              onExpand={id => setExpandedNodeId(id)}
              onCollapse={() => setExpandedNodeId(undefined)}
              expanded={expandedNodeId === workingWorkflow.trigger.id}
              key={workingWorkflow.trigger.id}
            >
              <NodeForm
                expanded={expandedNodeId === workingWorkflow.trigger.id}
                integrationContextSchema={runContextSchema}
                node={workingWorkflow.trigger}
                nodeType={workingWorkflow.trigger.nodeType}
                {...nodeFormParams}
              />
            </WorkflowNode>

            {workingWorkflow.nodes?.map(node => {
              return (
                <WorkflowNode
                  node={node}
                  onExpand={id => setExpandedNodeId(id)}
                  onCollapse={() => setExpandedNodeId(undefined)}
                  expanded={expandedNodeId === node.id}
                  key={node.id}
                >
                  <NodeForm
                    expanded={expandedNodeId === node.id}
                    integrationContextSchema={engine?.generateIntegrationContextSchema(
                      (node as LinkableNode).prev
                    )}
                    sourceId={(node as LinkableNode).prev}
                    node={node}
                    nodeType={node.nodeType}
                    {...nodeFormParams}
                  />
                </WorkflowNode>
              );
            })}
          </>
        )}
        {newNode !== undefined && (
          <WorkflowNode
            node={newNode}
            onExpand={id => setExpandedNodeId(id)}
            onCollapse={() => {
              setExpandedNodeId(undefined);
            }}
            expanded={expandedNodeId === newNode.id}
            key={newNode.id}
          >
            <NodeForm
              expanded={expandedNodeId === newNode.id}
              integrationContextSchema={engine?.generateIntegrationContextSchema(
                (newNode as LinkableNode).prev
              )}
              nodeType={newNode.nodeType}
              sourceId={(newNode as LinkableNode).prev}
              node={newNode.isPasted ? (newNode as IntegrationNode) : undefined}
              {...nodeFormParams}
              onSave={node => {
                onSave(node);
                setNewNode(undefined);
                setExpandedNodeId(undefined);
              }}
              onMoveUp={undefined}
              onMoveDown={undefined}
              onCancel={() => {
                onCancel();
                setNewNode(undefined);
                setExpandedNodeId(undefined);
              }}
            />
          </WorkflowNode>
        )}

        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <AddWorkflowNode
            parent={engine.getLastNode()}
            onAddNewNode={onAddNewNode}
            disabled={newNode !== undefined}
          />
        </Box>
      </Timeline>
    </Box>
  );
};

export default DefineWorkflow;
