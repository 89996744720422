import { MouseEventHandler, useState } from 'react';

import { OperationBase } from '@apus/common-lib/integrations/src/interface';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Autocomplete, Box, Grid, IconButton, TextField } from '@mui/material';

interface Props<T extends OperationBase> {
  name: string;
  label: string;
  operations: T[];
  onOperationSelected?: (operation: T) => void;
  operation?: T;
  disabled?: boolean;
  displayButton?: boolean;
}

/**
 * use empty operation as the initial value for autocomplete so that we don't get those annoying
 * controlled/uncontrolled -state change warnings and errors
 */
const emptyOperation = {
  description: '',
  moduleId: '',
  operationId: '',
  title: '',
};

const OperationSelector = <T extends OperationBase>({
  onOperationSelected,
  name,
  label,
  operations,
  operation,
  disabled,
  displayButton = true,
  ...props
}: Props<T>): JSX.Element => {
  const [selectedOperation, setSelectedOperation] = useState<T | undefined>();

  const propagateOperation = (operation?: T) => {
    if (disabled) throw new Error('Operation selector is disabled');
    if (operation !== undefined && onOperationSelected !== undefined)
      onOperationSelected(operation);
  };

  const onAdd: MouseEventHandler<Element> = e => {
    e.preventDefault();
    propagateOperation(selectedOperation);
  };

  if (operations === undefined || operations.length === 0)
    return <Grid container />;

  return (
    <Box
      flexGrow={1}
      display={'flex'}
      alignContent={'flex-end'}
      alignItems={'center'}
    >
      <Autocomplete
        disablePortal
        disabled={disabled}
        id={`combo-box-${name}`}
        options={[emptyOperation as T].concat(operations)}
        getOptionLabel={option => {
          return option.title;
        }}
        value={operation ?? (emptyOperation as T)}
        isOptionEqualToValue={(option: T, value: T) =>
          option?.operationId === value?.operationId
        }
        groupBy={option => option.moduleId}
        sx={{ width: '100%' }}
        renderInput={params => <TextField {...params} label={label} />}
        onChange={(event: any, value: T | null) => {
          setSelectedOperation(
            value != null && value?.operationId !== '' ? value : undefined
          );
          if (!displayButton && value != null) propagateOperation(value);
        }}
        {...props}
      />
      {displayButton && (
        <IconButton
          color="success"
          aria-label="Add operation"
          component="label"
          onClick={onAdd}
          disabled={disabled}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default OperationSelector;
