import { JSX } from 'react';
import { Routes, Route } from 'react-router-dom';

import ApiCommandCreateView from './apis/ApiCommandCreateView';
import ApiCommandView from './apis/ApiCommandView';
import ApiCreateView from './apis/ApiCreateView';
import ApiListView from './apis/ApiListView';
import ApiView from './apis/ApiView';
import useSetAppBarRoutes from '../../hooks/useSetAppBarRoutes';
import { apiRoutes } from '../../routes/service-provider-routes';

const ApisLanding = (): JSX.Element => {
  useSetAppBarRoutes(apiRoutes);

  return (
    <Routes>
      <Route path="/" element={<ApiListView />} />
      <Route path="/create" element={<ApiCreateView />} />
      <Route path="/:apiId" element={<ApiView />} />
      <Route
        path=":apiId/:groupId/commands/create/"
        element={<ApiCommandCreateView />}
      />
      <Route
        path=":apiId/:groupId/commands/:integrationId"
        element={<ApiCommandView />}
      />
    </Routes>
  );
};

export default ApisLanding;
