import { JSONSchema7, JSONSchema7TypeName } from 'json-schema';
import { SchemaItem } from './interface';
import { resolveObjectPath } from './schema-utils';
import { cloneDeep, isEmpty, merge } from 'lodash';

const ROOT_ID = '00000000-0000-0000-0000-000000000000';

const parseFromSchemaItem = (item: SchemaItem): JSONSchema7 => {
  if (item.isArray) {
    return {
      type: 'array',
      ...(item.maxItems && { maxItems: item.maxItems }),
      ...(item.minItems && { minItems: item.minItems }),
      items: parseFromSchemaItem({
        ...item,
        isArray: false,
        maxItems: undefined,
        minItems: undefined,
      }),
    };
  }
  return {
    // Root is always an object
    type: item.isRoot ? 'object' : item.type,
    ...(item.enum && { enum: item.enum }),
    ...(item.const && { const: item.const }),
    ...(item.minimum && { minimum: item.minimum }),
    ...(item.maximum && { maximum: item.maximum }),
    ...(item.maxItems && { maxItems: item.maxItems }),
    ...(item.minItems && { minItems: item.minItems }),
    ...(item.minLength && { minLength: item.minLength }),
    ...(item.maxLength && { maxLength: item.maxLength }),
    ...(item.title && { title: item.title }),
    ...(item.pattern && { pattern: item.pattern }),
    ...(item.format && { format: item.format }),
    ...(item.default && { default: item.default }),
    ...(item.properties !== undefined &&
      item.properties.filter(prop => prop.isRequired).length > 0 && {
        required: item.properties
          .filter(prop => prop.isRequired)
          .map(prop => prop.path),
      }),
    ...(item.properties !== undefined &&
      item.properties.length > 0 && {
        properties: item.properties.reduce((res, child) => {
          return {
            ...res,
            [child.path]: parseFromSchemaItem(child),
          };
        }, {}),
      }),
  };
};

const fromSchemaItem = (item: SchemaItem): JSONSchema7 => {
  return parseFromSchemaItem(item);
};

const toSchemaItem = (
  schema: JSONSchema7,
  options?: { mandatorySchema?: JSONSchema7 }
): SchemaItem => {
  const isRoot = (schemaPath: string) =>
    schemaPath === '/' || schemaPath === '' || schemaPath === '/items';

  const isMandatory = (schemaPath: string) => {
    if (options?.mandatorySchema === undefined) {
      return false;
    }

    // Root cannot be mandatory
    if (isRoot(schemaPath)) return false;

    return resolveObjectPath(options.mandatorySchema, schemaPath) !== undefined;
  };

  const isPropertyRequired = (
    property: string,
    required: string[] | undefined
  ) => {
    if (required === undefined) return false;
    return required.includes(property);
  };

  const parseToSchemaItem = (
    schema: JSONSchema7,
    pathStack: string[] = [],
    pointerStack: string[] = [],
    path: string = '',
    pointer: string = '',
    isArray: boolean = false,
    isRequired: boolean = false
  ): SchemaItem => {
    const schemaPath = '/' + pointerStack.join('/');

    const objectPath = pathStack.join('.');
    const result: SchemaItem = {
      id: isRoot(schemaPath) ? ROOT_ID : objectPath,
      path: [...pathStack].pop() ?? '',
      schemaPath: schemaPath,
      objectPath: objectPath,
      relativeSchemaPath: pointer,
      relativeObjectPath: path,
      type: schema.type as JSONSchema7TypeName,
      isRoot: isRoot(schemaPath),
      isArray,
      isRequired: isRoot(schemaPath) ? true : isRequired,
      isImmutable: isMandatory(schemaPath),
      // @ts-ignore
      enum: schema.enum,
      // @ts-ignore
      const: schema.const,
      minimum: schema.minimum,
      maximum: schema.maximum,
      maxItems: schema.maxItems,
      minItems: schema.minItems,
      maxLength: schema.maxLength,
      title: schema.title,
      pattern: schema.pattern,
      format: schema.format,
      // @ts-ignore
      default: schema.default,
      properties: [],
    };

    if (
      schema.type === 'object' &&
      schema.properties !== undefined &&
      !isEmpty(schema.properties)
    ) {
      result.properties = Object.entries(schema.properties)
        .filter(([k, v]) => k !== undefined && v !== undefined)
        .map(([k, v]) => {
          const propPointerStack = isArray
            ? [...pointerStack, 'items', 'properties', k]
            : [...pointerStack, 'properties', k];

          return parseToSchemaItem(
            v as JSONSchema7,
            [...pathStack, k],
            propPointerStack,
            k,
            '/properties/' + k,
            false,
            isPropertyRequired(k, schema.required)
          );
        });
    } else if (
      schema.type === 'array' &&
      schema.items !== undefined &&
      !isEmpty(schema.items)
    ) {
      return parseToSchemaItem(
        schema.items as JSONSchema7,
        [...pathStack],
        [...pointerStack],
        path,
        pointer,
        true,
        isRequired
      );
    }
    return result;
  };

  return parseToSchemaItem(schema);
};

const EMPTY_SCHEMA_ITEM: SchemaItem = {
  id: ROOT_ID,
  path: '',
  schemaPath: '/',
  objectPath: '',
  relativeSchemaPath: '',
  relativeObjectPath: '',
  type: 'object',
  isRoot: true,
  isArray: false,
  properties: [],
};

interface JsonSchemaManipulationToolOptions {
  caseSensitive?: boolean;
}

export class JsonSchemaManipulationTool {
  private root: SchemaItem;
  private readonly schema?: JSONSchema7;
  private readonly options: JsonSchemaManipulationToolOptions;

  constructor({
    schema,
    mandatorySchema,
    options = {},
  }: {
    schema?: JSONSchema7;
    mandatorySchema?: JSONSchema7;
    options?: JsonSchemaManipulationToolOptions;
  }) {
    this.options = options;

    if (mandatorySchema !== undefined) {
      this.schema = merge({}, schema, mandatorySchema);
      this.root = toSchemaItem(this.schema, { mandatorySchema });
    } else {
      this.schema = schema;
      this.root =
        schema !== undefined
          ? toSchemaItem(schema)
          : cloneDeep(EMPTY_SCHEMA_ITEM);
    }

    this.root.id = ROOT_ID;
  }

  private equals(p1: string, p2: string): boolean {
    if (this.options.caseSensitive) return p1 === p2;
    return p1.toLowerCase() === p2.toLowerCase();
  }

  private resolveParent(id: string) {
    const _traverse = (item: SchemaItem): SchemaItem | undefined => {
      for (const prop of item.properties ?? []) {
        if (prop.id === id) return item;
        const result = _traverse(prop);
        if (result !== undefined) return result;
      }
      return undefined;
    };

    return _traverse(this.root);
  }

  private resolveItem(id: string): SchemaItem | undefined {
    const _traverse = (item: SchemaItem): SchemaItem | undefined => {
      if (item.id === id) return item;

      for (const prop of item.properties ?? []) {
        const result = _traverse(prop);
        if (result !== undefined) return result;
      }
      return undefined;
    };

    return this.root.properties.reduce<SchemaItem | undefined>(
      (res: SchemaItem | undefined, cur) => {
        if (res !== undefined) return res;
        return _traverse(cur);
      },
      undefined
    );
  }

  private resolveItemByObjectPath(objectPath: string): SchemaItem | undefined {
    const _traverse = (item: SchemaItem): SchemaItem | undefined => {
      if (this.equals(item.objectPath, objectPath)) return item;

      for (const prop of item.properties ?? []) {
        const result = _traverse(prop);
        if (result !== undefined) return result;
      }
      return undefined;
    };

    return this.root.properties.reduce<SchemaItem | undefined>(
      (res: SchemaItem | undefined, cur) => {
        if (res !== undefined) return res;
        return _traverse(cur);
      },
      undefined
    );
  }

  private resolveItemBySchemaPath(schemaPath: string): SchemaItem | undefined {
    const _traverse = (item: SchemaItem): SchemaItem | undefined => {
      if (this.equals(item.schemaPath, schemaPath)) return item;

      for (const prop of item.properties ?? []) {
        const result = _traverse(prop);
        if (result !== undefined) return result;
      }
      return undefined;
    };

    return this.root.properties.reduce<SchemaItem | undefined>(
      (res: SchemaItem | undefined, cur) => {
        if (res !== undefined) return res;
        return _traverse(cur);
      },
      undefined
    );
  }

  getItemBySchemaPath(schemaPath: string) {
    return this.resolveItemBySchemaPath(schemaPath);
  }

  getItemByObjectPath(objectPath: string) {
    return this.resolveItemByObjectPath(objectPath);
  }

  addItem(parent: SchemaItem | undefined, item: SchemaItem): SchemaItem {
    if (parent === undefined || parent.isRoot) {
      // make sure that item's schema path does not start with a double slash
      const fixed: SchemaItem = {
        ...item,
        schemaPath: item.schemaPath.startsWith('//')
          ? item.schemaPath.slice(1)
          : item.schemaPath,
      };
      this.root.properties.push(fixed);
    } else {
      const parentItem = this.resolveItem(parent.id);
      if (parentItem === undefined) {
        throw new Error(`Cannot add item: parent not found`);
      }
      parentItem.properties.push(item);
    }

    return this.root;
  }

  updateItem(updated: SchemaItem) {
    if (updated.isImmutable)
      throw new Error(`Cannot update item: item is immutable`);

    if (updated.isRoot) {
      this.root = updated;
      return this.root;
    }

    const parentItem = this.resolveParent(updated.id);
    if (parentItem === undefined)
      throw new Error(`Cannot update item: parent not found`);

    const index = parentItem.properties.findIndex(p => p.id === updated.id);
    if (index < 0) throw new Error(`Cannot update item: list index not found`);

    parentItem.properties.splice(index, 1, updated);

    return this.root;
  }

  deleteItem(item: SchemaItem) {
    if (item.isImmutable)
      throw new Error(`Cannot delete item: item is immutable`);

    const parentItem = this.resolveParent(item.id);
    if (parentItem === undefined)
      throw new Error(`Cannot delete item: parent not found`);

    const index = parentItem.properties.findIndex(p => p.id === item.id);
    if (index < 0) throw new Error(`Cannot delete item: list index not found`);

    parentItem.properties.splice(index, 1);

    return this.root;
  }

  asItem(): SchemaItem {
    return this.root;
  }

  asSchema(): JSONSchema7 {
    return fromSchemaItem(this.root);
  }

  static from(item: SchemaItem) {
    const tool = new JsonSchemaManipulationTool({});
    tool.root = cloneDeep(item);

    return tool;
  }
}
