import { JSX } from 'react';

import { Grid } from '@mui/material';

import TenantEditFormView from '../../../components/tenants/TenantEditFormView';
import { bodyMaxHeight } from '../../../theme/theme';

const TenantsView = (): JSX.Element => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={12}>
        <TenantEditFormView editMode={true} />
      </Grid>
      <Grid xs={10} />
    </Grid>
  );
};

export default TenantsView;
