import { JSONSchema7 } from 'json-schema';
import {
  CustomContentMediaType,
  JsonSchemaDefinition,
} from '@apus/common-lib/api/interface/files';
import { fromJsonSchemaDefinition } from '@apus/common-lib/utils/src/data-utils';
import { parseSchema } from '@apus/common-lib/json-data-mapper/src/schema-utils';
import { SupportedFileType } from '@apus/common-lib/json-data-mapper/src/interface';
import { toJSON } from '@apus/common-lib/utils/src/xml-utils';

async function parseJson(text: string): Promise<JSONSchema7> {
  const content = JSON.parse(text);

  const schema =
    content.hasOwnProperty('contentType') &&
    content.contentType === CustomContentMediaType.JsonSchema
      ? fromJsonSchemaDefinition(content as JsonSchemaDefinition)
      : content;

  if (schema.hasOwnProperty('type') && typeof schema['type'] === 'string') {
    return await parseSchema(text, SupportedFileType.JsonSchema);
  } else {
    return await parseSchema(text, SupportedFileType.Json);
  }
}

async function parseXml(text: string): Promise<JSONSchema7> {
  return await parseSchema(toJSON(text), SupportedFileType.Json);
}

function identifyContentType(text: string): 'json' | 'xml' {
  if (text.trim().startsWith('<')) {
    return 'xml';
  }

  return 'json';
}

export async function parseJsonSchemaFromString(
  text: string
): Promise<JSONSchema7> {
  try {
    switch (identifyContentType(text)) {
      case 'json':
        return await parseJson(text);
      case 'xml':
        return await parseXml(text);
    }
  } catch (e) {
    throw new Error(
      `Cannot parse json schema from string - error occurred: ` +
        JSON.stringify(e)
    );
  }
  throw new Error(`Cannot parse json schema from string - unidentified type`);
}
