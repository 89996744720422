import React, { JSX, useContext, useEffect, useState } from 'react';

import { ExposedApiCommand } from '@apus/common-lib/api/interface/exposed-api';
import {
  ApiResultNode,
  IntegrationNodeType,
} from '@apus/common-lib/integration-engine/src/interface';
import {
  compile,
  DEFAULT_OPTIONS,
} from '@apus/common-lib/schema-to-interface/src';
import Editor from '@monaco-editor/react';
import { JSONSchema7 } from 'json-schema';
import { camelCase, isEmpty } from 'lodash';

import { AppContext } from '../../state/appContext';

interface Props {
  apiId: string;
  groupId: string;
  command: ExposedApiCommand;
}

async function generateType(schema: JSONSchema7 | undefined, typeName: string) {
  if (schema === undefined) return `type ${typeName} = undefined;`;

  return await compile(
    {
      ...schema,
      title: typeName,
    },
    typeName,
    {
      ...DEFAULT_OPTIONS,
    }
  );
}

function asHookName(name: string) {
  const base = camelCase(name);
  return `use${base.charAt(0).toUpperCase() + base.slice(1)}`;
}

async function generate({
  input,
  output,
  name,
  apiId,
  groupId,
  commandId,
}: {
  input?: JSONSchema7;
  output?: JSONSchema7;
  name: string;
  apiId: string;
  groupId: string;
  commandId: string;
}) {
  const inputType = await generateType(input, 'Input');
  const outputType = await generateType(output, 'Output');
  const hookName = asHookName(name);
  return `
import { ApiError } from '@apus/common-lib/api/interface/common';
import { ApiCommandResult } from '@apus/common-lib/api/interface/integration-service';

import useExposedApiCommandResult from '../../../../../../hooks/useExposedApiCommandResult';

${inputType}

${outputType}

function ${hookName}(
  ${input === undefined ? '' : 'input: Input,'}
  depends: Array<any> = []
): [
  boolean,
  ApiCommandResult<Output> | undefined,
  ApiError | undefined
] {
  return useExposedApiCommandResult<Input, Output>(
    {
      input: ${input !== undefined ? 'input' : 'undefined'},
      apiId: '${apiId}',
      group: '${groupId}',
      path: '${commandId}',
    },
    depends
  );
}

export default ${hookName};
`;
}

const GenerateApiCommandHook = ({
  command,
  apiId,
  groupId,
}: Props): JSX.Element => {
  const [appState] = useContext(AppContext);
  const [code, setCode] = useState<string>();

  useEffect(() => {
    (async () => {
      const integration = appState.integrations.find(
        i => i.integrationId === command.integration?.integrationId
      );

      if (
        integration !== undefined &&
        integration.workflow.trigger.nodeType !==
          IntegrationNodeType.PollingTrigger
      ) {
        const triggerNode = integration.workflow.trigger;
        const reverseNodes = [...integration.workflow.nodes].reverse();
        const apiResultNode = reverseNodes.find(
          n => n.nodeType === IntegrationNodeType.ApiResult
        ) as ApiResultNode;

        const input =
          triggerNode.triggerSchema.content.jsonSchema === undefined ||
          isEmpty(triggerNode.triggerSchema.content.jsonSchema) ||
          (Array.isArray(
            triggerNode.triggerSchema.content.jsonSchema.properties
          ) &&
            triggerNode.triggerSchema.content.jsonSchema.properties.length ===
              0)
            ? undefined
            : triggerNode.triggerSchema.content.jsonSchema;

        const output =
          apiResultNode.resultSchema.content.jsonSchema === undefined ||
          isEmpty(apiResultNode.resultSchema.content.jsonSchema) ||
          (Array.isArray(
            apiResultNode.resultSchema.content.jsonSchema.properties
          ) &&
            apiResultNode.resultSchema.content.jsonSchema.properties.length ===
              0)
            ? undefined
            : apiResultNode.resultSchema.content.jsonSchema;

        const hook = await generate({
          name: command.commandName,
          apiId,
          groupId,
          commandId: command.commandId,
          input,
          output,
        });
        setCode(hook);
      } else {
        setCode(undefined);
      }
    })();
  }, [command, setCode, appState, apiId, groupId]);

  return (
    <Editor
      value={code}
      height={800}
      language="typescript"
      options={{
        minimap: { enabled: false },
        lineNumbers: 'off',
        readOnly: true,
      }}
      keepCurrentModel={false}
      saveViewState={false}
    />
  );
};

export default GenerateApiCommandHook;
