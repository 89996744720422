import React, { JSX } from 'react';

import { Grid } from '@mui/material';
import { JSONSchema7 } from 'json-schema';

import DefineTypescriptMapping from './DefineTypescriptMapping';
import {
  SupportedMappingSchemaFileEdit,
  TypescriptFunctionFileEdit,
} from '../../types/stored-files';

interface Props {
  targetSchema: JSONSchema7;
  sourceSchema: JSONSchema7;
  value?: SupportedMappingSchemaFileEdit;
  onChange: (mappingSchema: SupportedMappingSchemaFileEdit) => void;
}

const DefineMapping = ({
  value,
  targetSchema,
  sourceSchema,
  onChange,
}: Props): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DefineTypescriptMapping
          value={value as TypescriptFunctionFileEdit}
          sourceSchema={sourceSchema}
          targetSchema={targetSchema}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default DefineMapping;
