import React, { useState } from 'react';

import { ApiError } from '@apus/common-lib/api/interface/common';
import {
  IntegrationOperation,
  IntegrationOperationPrototype,
  SupportedModule,
} from '@apus/common-lib/integrations/src/interface';
import useManagementService from '@apus/common-ui/hooks/useManagementService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { Button, Grid } from '@mui/material';

import OperationSourceFileInput, {
  SupportedFileType,
} from './OperationSourceFileInput';
import OperationTransferList from './OperationTransferList';

const OperationImportTool = (): JSX.Element => {
  const managementService = useManagementService();

  const [operations, setOperations] = useState<IntegrationOperation[]>([]);
  const [_apiCallPending, setApiCallPending] = useState<boolean>(false);
  const [_apiCallError, setApiCallError] = useState<ApiError | undefined>(
    undefined
  );

  const [selectedOperations, setSelectedOperations] = useState<
    IntegrationOperation[]
  >([]);

  const onFileChange = async (
    data: string,
    fileType: SupportedFileType,
    options?: {
      module?: SupportedModule;
      prototype?: IntegrationOperationPrototype;
    }
  ) => {
    setOperations([]);
    setApiCallPending(false);
    setApiCallError(undefined);

    if (fileType === SupportedFileType.OpenAPI) {
      const module = options?.module;
      const prototype = options?.prototype;

      if (module === undefined || prototype === undefined)
        throw new Error('Missing module or prototype');

      await executeApiCall<IntegrationOperation[]>({
        callFunction: () =>
          managementService.parseOpenAPIDocument({
            document: data,
            module: module,
            prototype: prototype,
          }),
        setPending: setApiCallPending,
        setResult: setOperations,
        setError: setApiCallError,
      });
    }
  };

  const onSave = async () => {
    await executeApiCall<void>({
      callFunction: () =>
        managementService.createIntegrationOperations(selectedOperations),
      setPending: setApiCallPending,
      setError: setApiCallError,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OperationSourceFileInput onChange={onFileChange} />
      </Grid>
      <Grid item xs={12}>
        <OperationTransferList
          operations={operations}
          onSelected={setSelectedOperations}
        />
      </Grid>
      <Grid item xs={11}></Grid>
      <Grid item xs={1}>
        <Button disabled={selectedOperations.length === 0} onClick={onSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default OperationImportTool;
