import React, { JSX, useEffect, useState } from 'react';

import { JsonSchemaDefinition } from '@apus/common-lib/api/interface/files';
import { SupportedFileType } from '@apus/common-lib/json-data-mapper/src/interface';
import { parseSchema } from '@apus/common-lib/json-data-mapper/src/schema-utils';
import {
  fromJsonSchemaDefinition,
  toJsonSchemaDefinition,
} from '@apus/common-lib/utils/src/data-utils';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import ContentPasteGoTwoToneIcon from '@mui/icons-material/ContentPasteGoTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Popover,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { JSONSchema7 } from 'json-schema';
import { isEqual } from 'lodash';

import JsonSchemaEditor from './JsonSchemaEditor';
import SchemaSourceFileInput from '../../input/SchemaSourceFileInput';
import { parseJsonSchemaFromString } from '@apus/common-lib/utils/src/json-utils';

interface Props {
  allowRootModification?: boolean;
  allowUndefined?: boolean;
  readOnly?: boolean;
  title?: string;
  propertyName: string;
  mandatorySchema?: JsonSchemaDefinition;
  schema?: JsonSchemaDefinition;
  defaultSchemaTitle?: string;
  defaultSchema?: JSONSchema7;
  onSchemaSave: (schema: JsonSchemaDefinition | undefined) => void;
}

const DefineSchema = ({
  allowRootModification = true,
  allowUndefined = false,
  readOnly = false,
  title,
  propertyName,
  mandatorySchema,
  schema,
  defaultSchemaTitle,
  defaultSchema,
  onSchemaSave,
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUndefined, setIsUndefined] = useState<boolean>(
    allowUndefined && schema === undefined && mandatorySchema === undefined
  );
  const [workingSchema, setWorkingSchema] = useState<
    JsonSchemaDefinition | undefined
  >(undefined);

  useEffect(() => {
    setIsUndefined(
      allowUndefined && schema === undefined && mandatorySchema === undefined
    );
  }, [allowUndefined, schema, mandatorySchema, setIsUndefined]);

  useEffect(() => {
    if (isUndefined) {
      setWorkingSchema(undefined);
    } else {
      setWorkingSchema(schema);
    }
  }, [isUndefined, schema, setWorkingSchema]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFileChange = async (data: string, fileType: SupportedFileType) => {
    onSchemaChange(await parseSchema(data, fileType));
  };

  const onSchemaChange = (jsonSchema: JSONSchema7 | undefined) => {
    const changed: JsonSchemaDefinition | undefined =
      jsonSchema !== undefined
        ? {
            ...workingSchema,
            ...toJsonSchemaDefinition(jsonSchema),
          }
        : undefined;

    // don't generate a changed-notification if there is no real change
    if (isEqual(changed?.content.jsonSchema, workingSchema?.content.jsonSchema))
      return;

    setWorkingSchema(changed);
    onSchemaSave(changed);
  };

  async function readSchemaFromString(text: string) {
    return onSchemaChange(await parseJsonSchemaFromString(text));
  }

  return (
    <Grid
      container
      sx={{
        border: 0.5,
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={7} display={'flex'} alignItems={'center'}>
            <Typography>{title ?? propertyName}</Typography>
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  size={'small'}
                  disabled={readOnly || !allowUndefined}
                  value={isUndefined}
                  onChange={event => {
                    setIsUndefined(event.target.checked);
                    if (event.target.checked) onSchemaChange(undefined);
                    // TODO: we need a temporary storage for schema so that if user clicks this by accident, schema isn't lost
                    else onSchemaChange(schema ?? defaultSchema);
                  }}
                />
              }
              label="Undefined"
            />
          </Grid>
          <Grid item xs={2}>
            <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
              <IconButton
                aria-label="more"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <IconButton
                disabled={workingSchema === undefined}
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    JSON.stringify(
                      fromJsonSchemaDefinition(
                        workingSchema as JsonSchemaDefinition
                      )
                    )
                  );
                }}
              >
                <ContentCopyTwoToneIcon color={'success'} />
              </IconButton>
              <IconButton
                disabled={readOnly}
                onClick={async () => {
                  const clipboard = await navigator.clipboard.readText();
                  if (clipboard !== undefined && clipboard.trim() !== '') {
                    await readSchemaFromString(clipboard);
                  }
                }}
              >
                <ContentPasteGoTwoToneIcon color={'warning'} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {!readOnly && (
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    padding: 20,
                  },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {defaultSchema !== undefined && (
                      <>
                        <Button onClick={() => onSchemaChange(defaultSchema)}>
                          {defaultSchemaTitle ??
                            'Initialize with default schema?'}
                        </Button>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
                      Load schema from file?
                    </Typography>
                    <SchemaSourceFileInput onChange={onFileChange} />
                  </Grid>
                </Grid>
              </Popover>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!isUndefined && (
          <JsonSchemaEditor
            allowRootModification={allowRootModification}
            readOnly={readOnly}
            propertyName={propertyName}
            mandatorySchema={mandatorySchema?.content.jsonSchema}
            schema={schema?.content.jsonSchema}
            onSchemaChange={onSchemaChange}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DefineSchema;
