import { JSX } from 'react';

import { v4 as uuid } from 'uuid';

import DefineExposedApi from '../../../components/apis/DefineExposedApi';
import useSetAppBarRoutes from '../../../hooks/useSetAppBarRoutes';
import { apiRoutes } from '../../../routes/service-provider-routes';

const ApiCreateView = (): JSX.Element => {
  useSetAppBarRoutes(apiRoutes);

  return (
    <DefineExposedApi
      api={{
        apiDescription: '',
        apiId: uuid(),
        apiName: '',
        apiVersion: '1.0.0',
        groups: [],
      }}
      isNew={true}
    />
  );
};

export default ApiCreateView;
