import React, { JSX, useEffect, useState } from 'react';

import {
  IntegrationOperation,
  IntegrationOperationPrototype,
  WorkflowOperation,
  WorkflowOperationType,
} from '@apus/common-lib/integrations/src/interface';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import RestartAltTwoToneIcon from '@mui/icons-material/RestartAltTwoTone';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import Button from '@mui/material/Button';

import DefineEmbeddedOperation from './DefineEmbeddedOperation';
import DefineOperationInstance from './DefineOperationInstance';

interface Props {
  operation?: WorkflowOperation;
  prototypes: IntegrationOperationPrototype[];
  operations: IntegrationOperation[];
  onChange: (operation?: WorkflowOperation) => void;
}

const DefineWorkflowOperation = ({
  operation,
  operations,
  prototypes,
  onChange,
}: Props): JSX.Element => {
  const [isEmbedded, setIsEmbedded] = useState<boolean>(true);
  const [workingOperation, setWorkingOperation] = useState<
    WorkflowOperation | undefined
  >();

  useEffect(() => {
    if (operation !== undefined) {
      setWorkingOperation({
        ...operation,
        ...(operation.operationType === undefined && {
          // default is instance
          operationType: WorkflowOperationType.INSTANCE,
        }),
      });
    } else {
      setWorkingOperation(undefined);
    }
  }, [operation]);

  useEffect(() => {
    if (workingOperation !== undefined) {
      setIsEmbedded(
        workingOperation.operationType === WorkflowOperationType.EMBEDDED
      );
    }
  }, [workingOperation, setIsEmbedded]);

  const updateOperationSchemas = () => {
    if (operation === undefined)
      throw new Error('Cannot update schema - operation is undefined');

    if (workingOperation !== undefined)
      setWorkingOperation({
        ...workingOperation,
        inputSchema: operation.inputSchema,
        outputSchema: operation.outputSchema,
      });
  };

  const resetOperation = () => {
    if (workingOperation !== undefined) {
      onChange(undefined);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'end'}
      >
        <FormControlLabel
          control={
            <Switch
              disabled={workingOperation !== undefined}
              checked={isEmbedded}
              onChange={event => setIsEmbedded(event.target.checked)}
            />
          }
          label="Embedded operation"
        />
        <Button
          variant={'text'}
          startIcon={<RefreshTwoToneIcon color={'info'} />}
          onClick={updateOperationSchemas}
        >
          Update
        </Button>

        <Button
          startIcon={<RestartAltTwoToneIcon color={'error'} />}
          variant={'text'}
          onClick={resetOperation}
        >
          Reset
        </Button>
      </Grid>

      {workingOperation === undefined && (
        <Grid item xs={12}>
          {isEmbedded && (
            <DefineEmbeddedOperation
              instance={workingOperation}
              prototypes={prototypes}
              onChange={onChange}
            />
          )}
          {!isEmbedded && (
            <DefineOperationInstance
              instance={workingOperation}
              operations={operations}
              prototypes={prototypes}
              onChange={onChange}
            />
          )}
        </Grid>
      )}

      {workingOperation !== undefined && (
        <Grid item xs={12}>
          {workingOperation?.operationType ===
            WorkflowOperationType.EMBEDDED && (
            <DefineEmbeddedOperation
              instance={workingOperation}
              prototypes={prototypes}
              onChange={onChange}
            />
          )}
          {workingOperation?.operationType ===
            WorkflowOperationType.INSTANCE && (
            <DefineOperationInstance
              instance={workingOperation}
              operations={operations}
              prototypes={prototypes}
              onChange={onChange}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default DefineWorkflowOperation;
