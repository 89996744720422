import { Field, Option } from 'react-querybuilder';

import {
  resolveObjectPath,
  resolveSchema,
} from '@apus/common-lib/json-data-mapper/src/schema-utils';
import { JSONSchema7 } from 'json-schema';

type GroupName = string;

export interface Options {
  groupedProperties?: GroupedObjectProperties;
  hiddenProperties?: string[];
  useSchemaTitles?: boolean;
}

export interface GroupedObjectProperties {
  [schemaPath: string]: GroupName;
}

function cleanPointer(pointer: string) {
  return pointer.startsWith('//') ? pointer.slice(1) : pointer;
}

function makeOptionResolver(schema: JSONSchema7, options?: Options) {
  return function asOption(name: string, pointer: string): Option {
    const objectPath = resolveObjectPath(schema, pointer);
    if (objectPath === undefined)
      throw new Error(`Cannot resolve object path for ${pointer}`);

    const childSchema = resolveSchema(schema, pointer);
    if (childSchema === undefined)
      throw new Error(`Cannot resolve child schema for ${pointer}`);

    const schemaPointer = cleanPointer(pointer);
    const label =
      options?.useSchemaTitles === true && childSchema.title !== undefined
        ? childSchema.title
        : objectPath;

    return {
      name: schemaPointer,
      label,
    };
  };
}

function resolveDefaultInputType(schema: JSONSchema7): string | undefined {
  switch (schema.type) {
    case 'number':
      return 'number';
    case 'string':
      switch (schema.format) {
        case 'date-time':
          return 'datetime';
        case 'time':
          return 'time';
        case 'date':
          return 'date';
      }
  }
  return 'text';
}

function resolveValueEditorType(
  schema: JSONSchema7
):
  | 'text'
  | 'select'
  | 'checkbox'
  | 'radio'
  | 'textarea'
  | 'switch'
  | 'multiselect'
  | null {
  switch (schema.type) {
    case 'number':
      if (Array.isArray(schema.enum)) return 'select';
      // normal numbers are handled by our custom value editor - see ValueEditor.tsx
      return null;
    case 'string': {
      if (Array.isArray(schema.enum)) return 'select';
      return 'text';
    }
    case 'boolean':
      return 'switch';
  }
  return null;
}

function resolveGroup(
  schemaPointer: string,
  groupedProperties?: GroupedObjectProperties
):
  | (Pick<Field, 'comparator' | 'valueSources'> & { groupNumber?: string })
  | undefined {
  if (groupedProperties === undefined) return undefined;
  const groupNumber = groupedProperties[schemaPointer];
  if (groupNumber !== undefined)
    return {
      comparator: 'groupNumber',
      groupNumber,
      valueSources: ['field', 'value'],
    };
  return {
    valueSources: ['value'],
  };
}

function fieldFromPrimitive(
  schema: JSONSchema7,
  base: Option,
  options?: Options
): Field {
  const inputType = resolveDefaultInputType(schema);
  const valueEditorType = resolveValueEditorType(schema);

  if (schema.const !== undefined && schema.const !== null) {
    return {
      ...base,
      inputType,
      valueEditorType,
      values: [{ name: String(schema.const), label: String(schema.const) }],
      defaultValue: schema.const,
    };
  } else if (Array.isArray(schema.enum)) {
    return {
      ...base,
      inputType,
      valueEditorType,
      values: schema.enum.map(value => {
        return { name: String(value), label: String(value) };
      }),
      defaultValue: String(schema.default),
    };
  }

  return {
    ...base,
    ...resolveGroup(base.name, options?.groupedProperties),
    inputType,
    valueEditorType,
  };
}

function fieldFromArray(schema: JSONSchema7, base: Option): Field {
  return base;
}

function fieldsFromObject(
  schema: JSONSchema7,
  pointer: string,
  result: Array<Field>,
  asOption: (name: string, pointer: string) => Option,
  options?: Options
) {
  if (schema.properties === undefined) return {};

  Object.keys(schema.properties).forEach(key => {
    const childSchema = schema.properties?.[key] as JSONSchema7;
    const childPointer = cleanPointer(`${pointer}/properties/${key}`);

    if (options?.hiddenProperties?.includes(childPointer)) return;

    if (childSchema === undefined)
      throw new Error(
        `getTemplate: cannot traverse object property - schema missing`
      );

    if (childSchema.type === 'object') {
      fieldsFromObject(childSchema, childPointer, result, asOption, options);
    } else if (childSchema.type === 'array') {
      result.push(fieldFromArray(childSchema, asOption(key, childPointer)));
    } else {
      result.push(
        fieldFromPrimitive(childSchema, asOption(key, childPointer), options)
      );
    }
  });
}

export function fieldsFromSchema(
  schema: JSONSchema7,
  options?: Options
): Array<Field> {
  if (schema == null) {
    throw new Error(`fieldsFromSchema: missing schema`);
  }

  if (schema.type !== 'object')
    throw new Error(`fieldsFromSchema: only object -type is allowed`);

  const fields: Array<Field> = [];

  fieldsFromObject(
    schema,
    '/',
    fields,
    makeOptionResolver(schema, options),
    options
  );

  return fields;
}
