import { JSX } from 'react';

import { ApiCommandResult } from './interface';
import { CircularProgress, Typography } from '@mui/material';

interface Props<T> {
  loadingText: string;
  command: ApiCommandResult<T>;
  component: JSX.Element;
}

const DisplayApiCommandResult = ({
  loadingText,
  command,
  component,
}: Props<any>): JSX.Element => {
  if (command.error)
    return (
      <Typography display={'flex'} alignItems={'center'}>
        {loadingText} failed: {command.error.message}
      </Typography>
    );

  if (command.pending)
    return (
      <Typography display={'flex'} alignItems={'center'}>
        {loadingText}... <CircularProgress size={20} sx={{ marginLeft: 2 }} />
      </Typography>
    );

  return component;
};

export default DisplayApiCommandResult;
