import React, { JSX, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  BankingFileTemplate,
  BankingFileType,
  SystemGluePayment,
  SystemGluePaymentStatus,
} from '@apus/common-lib/api/interface/banking-service';
import { ApiError } from '@apus/common-lib/api/interface/common';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import useBankingService from '@apus/common-ui/hooks/useBankingService';
import useFreemarkerService from '@apus/common-ui/hooks/useFreemarkerService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { faker } from '@faker-js/faker';
import Editor from '@monaco-editor/react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';

import Collapsible from '../../surface/Collapsible';

function mockAddress() {
  const address = {
    countryCode: faker.address.countryCode(),
    streetAddress1: faker.address.streetAddress(),
    streetAddress2: faker.address.buildingNumber(),
    city: faker.address.city(),
    postalCode: faker.address.zipCode(),
    stateCode: faker.address.state(),
  };

  return {
    ...address,
    line: `${address.streetAddress1}\n${address.streetAddress2}\n${address.postalCode} ${address.city}\n${address.stateCode}\n${address.countryCode}`,
  };
}

function mockSystemGluePayment(): SystemGluePayment {
  return {
    actionId: faker.datatype.uuid(),
    tenantId: faker.datatype.uuid(),
    created: faker.date.recent().toISOString(),
    modified: faker.date.recent().toISOString(),
    subscriptionId: faker.datatype.uuid(),
    record: {
      payment: {
        amount: faker.datatype.number(),
        bank: {
          bic: faker.finance.bic(),
          iban: faker.finance.iban(),
        },
        dueDate: faker.date.soon().toISOString(),
        internalId: faker.datatype.bigInt().toString(),
        reference: 'RF1298749',
        templateId: 'template-name',
        transactionId: faker.datatype.bigInt().toString(),
        currencyCode: faker.finance.currencyCode(),
      },
      subsidiary: {
        address: mockAddress(),
        name: faker.company.name(),
      },
      vendor: {
        address: mockAddress(),
        bank: { bic: faker.finance.bic(), iban: faker.finance.iban() },
        internalId: faker.datatype.bigInt().toString(),
        name: faker.company.name(),
      },
    },
    paymentStatus: SystemGluePaymentStatus.RECEIVED,
  };
}

function generateMockPaymentRecord(): string {
  return JSON.stringify(mockSystemGluePayment(), null, 2);
}

interface FreemarkerTemplateError {
  statusCode: number;
  cause: string;
  lineNum: number;
}

function asFreemarkerTemplateError(
  error: ApiError
): FreemarkerTemplateError | undefined {
  const parseDetail = (name: string): string =>
    error.details?.[name] !== undefined ? (error.details[name] as string) : '';

  if (
    error.details !== undefined &&
    error.details['cause'] !== undefined &&
    error.details['lineNum'] !== undefined
  ) {
    return {
      cause: parseDetail('cause'),
      lineNum: Number.parseInt(parseDetail('lineNum')),
      statusCode: error.statusCode,
    };
  }

  return undefined;
}

interface Props {
  value?: BankingFileTemplate;
  edit?: boolean;
  onSave: (template: BankingFileTemplate) => void;
  onCancel: () => void;
}

const BankingFileTemplateEditor = ({
  value,
  edit,
  onSave,
  onCancel,
}: Props): JSX.Element => {
  const bankingService = useBankingService();
  const freemarkerService = useFreemarkerService();

  const form = useForm<Partial<BankingFileTemplate>>({
    //resolver: getResolver<Partial<BankingFileTemplate>>(bankingFileTemplate),
  });

  const [isNew, setIsNew] = useState<boolean>(true);
  const [template, setTemplate] = useState<string | undefined>();
  const [output, setOutput] = useState<string | undefined>();
  const [jsonData, setJsonData] = useState<string | undefined>(
    generateMockPaymentRecord()
  );
  const [freemarkerServiceError, setFreemarkerServiceError] = useState<
    ApiError | undefined
  >();
  const [testPending, setTestPending] = useState<boolean>(false);
  const [bankingServiceError, setBankingServiceError] = useState<
    ApiError | undefined
  >();
  const [bankingCallPending, setBankingCallPending] = useState<boolean>(false);

  useEffect(() => {
    setBankingServiceError(undefined);

    if (value !== undefined) {
      setIsNew(true);
      form.setValue('id', value.id);
      form.setValue('description', value.description);
      form.setValue('template', value.template);
      form.setValue('type', value.type);
      setTemplate(value.template);
    } else {
      setIsNew(false);
      form.setValue('id', '');
      form.setValue('description', '');
      form.setValue('template', '');
      form.setValue('type', 'xml');
    }
  }, [value, setTemplate, form, setBankingServiceError, setIsNew]);

  const onRunTest = async () => {
    setOutput('');
    setFreemarkerServiceError(undefined);

    if (template !== undefined && jsonData !== undefined)
      await executeApiCall({
        callFunction: () => freemarkerService.render({ template, jsonData }),
        setResult: setOutput,
        setError: setFreemarkerServiceError,
        setPending: setTestPending,
      });
  };

  const onResetOutput = () => {
    setOutput('');
    setFreemarkerServiceError(undefined);
    setBankingServiceError(undefined);
  };

  const save = async (data: Partial<BankingFileTemplate>) => {
    setBankingServiceError(undefined);

    const getValue = (prop: string | undefined, defaultValue: string): string =>
      prop !== undefined && prop.trim() !== '' ? prop : defaultValue;

    if (onSave !== undefined) {
      const updated: BankingFileTemplate = {
        id: getValue(data.id, uuid()),
        description: getValue(data.description, ''),
        template: getValue(data.template, ''),
        type: getValue(data.type, 'xml') as BankingFileType,
      };

      await executeApiCall<void>({
        callFunction: () =>
          data.id !== undefined
            ? bankingService.updateTemplate(updated)
            : bankingService.createTemplate(updated),
        setPending: setBankingCallPending,
        setError: setBankingServiceError,
      });

      onSave(updated);

      form.reset({
        id: '',
        description: '',
        template: '',
        type: 'xml',
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {bankingServiceError !== undefined && (
          <Alert severity={'error'}>
            <Typography>
              There was an error during saving template: $
              {bankingServiceError.message}
            </Typography>
          </Alert>
        )}
      </Grid>
      <Grid item xs={8}>
        <HookFormTextInput
          name={'id'}
          label={'Template identifier'}
          helperText={
            'This is a unique identifier which cannot be modified after creation. Use this in Netsuite to refer to a given template.'
          }
          disabled={isNew || edit !== true}
          form={form}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={2}>
        <HookFormAutocomplete
          name={'type'}
          label={'File type'}
          disabled={edit !== true}
          form={form}
          options={['xml', 'json', 'csv', 'other']}
        />
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={2} direction={'row'}>
          <LoadingButton
            loading={bankingCallPending}
            disabled={edit !== true}
            onClick={form.handleSubmit(save)}
          >
            Save
          </LoadingButton>
          <Button disabled={edit !== true} onClick={() => onCancel()}>
            Cancel
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <HookFormTextInput
          name={'description'}
          label={'Template description'}
          disabled={edit !== true}
          form={form}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12}>
        {freemarkerServiceError !== undefined && (
          <Alert severity={'error'}>
            <Typography>
              {asFreemarkerTemplateError(freemarkerServiceError) !== undefined
                ? `There was an error on line ${
                    asFreemarkerTemplateError(freemarkerServiceError)?.lineNum
                  }: ${
                    asFreemarkerTemplateError(freemarkerServiceError)?.cause
                  }`
                : `There was an error: ${freemarkerServiceError.message}`}
            </Typography>
          </Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <Collapsible
          title={'Test template'}
          defaultExpanded={false}
          disabled={template === undefined}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack spacing={2} direction={'row'}>
                <LoadingButton onClick={onRunTest} loading={testPending}>
                  Run
                </LoadingButton>
                <Button onClick={onResetOutput}>Reset</Button>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography>Data model</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    height={600}
                    value={jsonData}
                    language={'json'}
                    options={{
                      readOnly: edit !== true,
                      lineNumbers: 'off',
                      minimap: { enabled: false },
                    }}
                    onChange={setJsonData}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box>
                    <Typography>Generated file</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    height={600}
                    value={output}
                    language={'xml'}
                    options={{
                      readOnly: true,
                      lineNumbers: 'off',
                      minimap: { enabled: false },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Collapsible>
      </Grid>
      <Grid item xs={12}>
        <Collapsible title={'Define template'} defaultExpanded={true}>
          <Controller
            control={form.control}
            name={'template'}
            render={({ field }) => (
              <Editor
                height={900}
                value={value?.template}
                language={'freemarker2'}
                options={{
                  autoIndent: 'full',
                  readOnly: edit !== true,
                  minimap: { enabled: false },
                }}
                onChange={template => {
                  setTemplate(template);
                  field.onChange(template);
                }}
              />
            )}
          />
        </Collapsible>
      </Grid>
    </Grid>
  );
};

export default BankingFileTemplateEditor;
