/**
 * Contains types and interfaces that can be used in UI for example - this does not leak dependencies.
 */

export type QueueId = string;
export type ReferenceId = string;
export type ISODate = string;
export type ActionId = string;

export const ActionStatusNames = [
  // Action is pending processing
  'Available',
  // Action was taken into processing
  'Initiated',
  // Action was taken into processing, but no action was taken due to external reason (e.g. integration was paused)
  'Omitted',
  // Action has been processed
  'Processed',
  // Action was taken into processing, but it failed
  'Failed',
  // Action was copied with modified content and sent to queue for processing
  'Edited',
  // Action was copied and sent to queue for processing
  'Retried',
] as const;

export type ActionStatus = (typeof ActionStatusNames)[number];

export type AttributeMap = Record<string, unknown>;
export type QueueContextType = AttributeMap | undefined;

export interface QueueContext<T extends QueueContextType> {
  queueId: QueueId;
  effectiveOn: ISODate;
  lastActionId?: ActionId;
  data: T;
}

/**
 * Action that has not yet been created in the queue
 */
export interface Action<T> {
  actionType: string;
  referenceId?: ReferenceId;
  correlationId?: string;
  createdFromActionId?: ActionId;
  retriedFromActionId?: ActionId;
  /**
   * Number of retries made (including this one)
   */
  retryCount?: number;
  content: T;
}

/**
 * Scheduled action that has not yet been created in the queue
 */
export interface ScheduledAction<T> extends Action<T> {
  effectiveOn: ISODate;
}

/**
 * Header information for queue action
 *
 * QueueAction differs from Action by being created in a queue.
 */
export interface QueueAction {
  /**
   * Queue id in which this action belongs to
   */
  queueId: QueueId;
  /**
   * Optional and freely defined reference id - can be used to query actions
   */
  referenceId?: ReferenceId;
  /**
   * Optional correlation id which can be used to unambiguously identify actions
   *
   * This will be provided in case an error is thrown during action handling
   */
  correlationId?: string;
  /**
   * Action id - generated automatically
   */
  actionId: ActionId;
  /**
   * Id of the action this created from
   */
  createdFromActionId?: ActionId;
  /**
   * Id of the action this is a retry of
   */
  retriedFromActionId?: ActionId;
  /**
   * Id of the action made as retry of this
   */
  retriedInActionId?: ActionId;
  /**
   * Number of retries made (including this one)
   */
  retryCount?: number;
  /**
   * Auto-incremented number used as part of the action id
   */
  autoIncrementId: string;
  /**
   * The datetime (UTC) when action must be run
   */
  effectiveOn: ISODate;
  /**
   * Freely defined action type
   */
  actionType: string;
  /**
   * Status of the action
   */
  status: ActionStatus;
  /**
   * If true, content is stored in an S3-bucket
   */
  contentInS3?: boolean;
}

/**
 * Queue action with content included
 */
export interface FullQueueAction<T> extends QueueAction {
  /**
   * The actual action content
   */
  content: T;
}
