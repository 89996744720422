import SideNavItems, { MapperRoute } from './SideNavItems';
import { Box } from '@mui/material';

interface Props {
  open: boolean;
  onClose: () => void;
  disabled?: boolean;
  routes: MapperRoute[];
  width: number;
}

export default function SideNav({ open, routes, width }: Props) {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 102,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
        overflowX: 'hidden',
        width,
      }}
    >
      <SideNavItems open={open} routes={routes} />
    </Box>
  );
}
