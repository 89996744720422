import { Dispatch, useContext, useMemo } from 'react';

import axios, { AxiosInstance } from 'axios';

import { AccessTokenContext } from '../state/accessTokenContext';
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  AccessTokenAction,
  accessTokenRefreshed,
} from '../state/accessTokenReducer';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { isPast } from 'date-fns';
import { isPastOrNow } from '@apus/common-lib/utils/src/data-utils';

async function getAccessToken(
  user: CognitoUser,
  dispatch: Dispatch<AccessTokenAction>
) {
  const sessionUser = user?.getSignInUserSession();

  if (sessionUser == null) {
    return undefined;
  }

  const token = sessionUser.getIdToken();

  const tokenExpirationTime = new Date(token.getExpiration() * 1000);

  if (isPastOrNow(tokenExpirationTime)) {
    await user.refreshSession(sessionUser.getRefreshToken(), (err, session) => {
      user.setSignInUserSession(session);
      const jwtToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
      dispatch(accessTokenRefreshed(jwtToken));

      if (err != null) {
        // TODO: decide what else should be done
        console.log(
          `Got error when refreshing access token: ${JSON.stringify(err)}`
        );
        dispatch(accessTokenRefreshed(undefined));
      }
    });
  } else {
    return token.getJwtToken();
  }
}

export default function useAxios(): AxiosInstance {
  const { user } = useAuthenticator(context => [context.user]);
  const [, dispatch] = useContext(AccessTokenContext);

  return useMemo(() => {
    const client = axios.create({
      baseURL: `${process.env.REACT_APP_REST_API_ENDPOINT}`,
      timeout: 30000,
    });

    client.interceptors.request.use(async config => {
      const accessToken = await getAccessToken(user, dispatch);
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });

    return client;
  }, [user]);
}
