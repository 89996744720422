import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SupportedMappingSchemaDefinition } from '@apus/common-lib/api/interface/files';
import {
  InputTransformations,
  IntegrationNodeType,
  OperationNode,
  RunCondition,
} from '@apus/common-lib/integration-engine/src/interface';
import {
  ConfigurationSchema,
  IntegrationOperation,
  IntegrationOperationPrototype,
  WorkflowOperation,
  WorkflowOperationType,
} from '@apus/common-lib/integrations/src/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import { splitConfigurationSchema } from '@apus/common-lib/utils/src/data-utils';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { resolvePrototypeForWorkflowOperationOrThrow } from '@apus/common-ui/utils/data-utils';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { Box, Grid, Stack } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

import DefineRuntimeConfiguration from './configuration/DefineRuntimeConfiguration';
import DefineInputTransformations from './inputTransformations/DefineInputTransformations';
import { NodeBaseComponent } from './interface';
import DefineOperationMapping from './mapping/DefineOperationMapping';
import NodeFooter from './NodeFooter';
import NodeToolBar from './NodeToolBar';
import DefineWorkflowOperation from './operation/DefineWorkflowOperation';
import DefineJsonLogicRule from './rule/DefineJsonLogicRule';
import Collapsible from '../../surface/Collapsible';

interface NodeForm {
  name: string;
  description?: string;
  disableValidation?: boolean;
}

interface Props extends NodeBaseComponent<OperationNode> {
  operations: IntegrationOperation[];
  prototypes: IntegrationOperationPrototype[];
  integrationContextSchema: JSONSchema7;
  sourceId: string;
}

type WorkingOperationNode = Omit<OperationNode, 'operation'> & {
  operation?: WorkflowOperation;
};

const DefineOperationNode = ({
  expanded,
  operations,
  prototypes,
  integrationContextSchema,
  sourceId,
  node,
  onCancel,
  onDelete,
  onSave,
  onMoveUp,
  onMoveDown,
}: Props): JSX.Element => {
  const [needsSaving, setNeedsSaving] = useState<boolean>(false);
  const [runtimeConfigurationSchema, setRuntimeConfigurationSchema] = useState<
    ConfigurationSchema | undefined
  >();
  const [workingNode, setWorkingNode] = useState<
    WorkingOperationNode | undefined
  >(undefined);

  const [allowedOperations, setAllowedOperations] = useState<
    IntegrationOperation[]
  >([]);
  const [mappingDisabled, setMappingDisabled] = useState<boolean>();

  const form = useForm<NodeForm>({
    resolver: ajvResolver<NodeForm>({
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'name must be at least 1 characters long',
          },
          nullable: false,
        },
        description: {
          type: 'string',
          nullable: true,
        },
        disableValidation: {
          type: 'boolean',
          nullable: true,
        },
      },
      required: ['name'],
    }),
    defaultValues: {
      name: '',
      description: undefined,
    },
  });

  const setRuntimeConfigurationSchemaAndOperation = useCallback(
    (workflowOperation: WorkflowOperation) => {
      const prototype = resolvePrototypeForWorkflowOperationOrThrow(
        workflowOperation,
        operations,
        prototypes
      );

      const { runtimeConfigurationSchema } = splitConfigurationSchema(
        prototype.configurationSchema
      );
      if (workflowOperation.operationType === WorkflowOperationType.EMBEDDED)
        setMappingDisabled(workflowOperation.prototype.noMapping);
      else {
        setMappingDisabled(prototype.noMapping);
      }
      setRuntimeConfigurationSchema(runtimeConfigurationSchema);
    },
    [operations, prototypes, setRuntimeConfigurationSchema]
  );

  useEffect(() => {
    setAllowedOperations(operations);
  }, [operations]);

  useEffect(() => {
    if (node !== undefined) {
      form.setValue('name', node.name);
      form.setValue('description', node.description);
      form.setValue('disableValidation', node.disableValidation);

      setWorkingNode(node);
      setRuntimeConfigurationSchemaAndOperation(node.operation);
    }
  }, [node, form, operations, setRuntimeConfigurationSchemaAndOperation]);

  useEffect(() => {
    if (node === undefined) {
      setWorkingNode({
        id: uuid(),
        description: '',
        nodeType: IntegrationNodeType.Operation,
        name: `Operation node`,
        prev: sourceId,
        disableValidation: false,
        runCondition: undefined,
      });
    }
  }, [node, setWorkingNode, sourceId]);

  const updateInputTransformations = (
    inputTransformations?: InputTransformations
  ) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        inputTransformations,
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateOperation = (operation?: WorkflowOperation) => {
    if (workingNode !== undefined) {
      if (operation !== undefined) {
        const updated = {
          ...workingNode,
          operation: {
            ...workingNode.operation,
            ...operation,
          },
        };

        setWorkingNode(updated);
        setRuntimeConfigurationSchemaAndOperation(operation);
        updateNeedsSaving(updated);
      } else {
        const updated = {
          ...workingNode,
          operation: undefined,
        };

        setWorkingNode(updated);
        updateNeedsSaving(updated);
      }
    }
  };

  const updateMapping = (mappingSchema?: SupportedMappingSchemaDefinition) => {
    if (workingNode !== undefined && workingNode.operation !== undefined) {
      const updated = {
        ...workingNode,
        operation: {
          ...workingNode.operation,
          mappingSchema,
        },
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateRuntimeConfiguration = (runtimeConfiguration?: SourceObject) => {
    if (workingNode !== undefined && workingNode.operation !== undefined) {
      const updated = {
        ...workingNode,
        operation: {
          ...workingNode.operation,
          runtimeConfiguration,
        },
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateRunCondition = (runCondition?: RunCondition) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        runCondition,
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  function updateNeedsSaving(current: WorkingOperationNode | undefined) {
    if (node !== undefined) {
      setNeedsSaving(!isEqual(node, current));
    } else {
      setNeedsSaving(true);
    }
  }

  function formUpdated() {
    const current =
      workingNode !== undefined
        ? {
            ...workingNode,
            ...form.getValues(),
          }
        : undefined;
    updateNeedsSaving(current);
  }

  const submit = async (data: NodeForm) => {
    if (workingNode !== undefined && workingNode.operation !== undefined) {
      onSave({
        ...workingNode,
        operation: workingNode.operation,
        name: data.name,
        description: data.description,
        disableValidation: data.disableValidation,
      });
    }
  };

  const deleteNode = () => {
    if (workingNode !== undefined && onDelete !== undefined) {
      onDelete(workingNode as OperationNode);
    }
  };

  const moveUp = () => {
    if (workingNode !== undefined && onMoveUp !== undefined) {
      onMoveUp(workingNode as OperationNode);
    }
  };

  const moveDown = () => {
    if (workingNode !== undefined && onMoveDown !== undefined) {
      onMoveDown(workingNode as OperationNode);
    }
  };

  if (expanded !== true) return <></>;

  return (
    <Box sx={{ minWidth: 1024, padding: 2 }}>
      <NodeToolBar
        save={{
          onClick: form.handleSubmit(submit),
          disabled: !needsSaving,
        }}
        cancel={{
          onClick: onCancel,
        }}
        delete={{
          disabled: workingNode?.id === undefined,
          onClick: deleteNode,
        }}
        moveDown={{
          disabled: workingNode?.next === undefined || onMoveDown === undefined,
          onClick: moveDown,
        }}
        moveUp={{
          disabled: workingNode?.prev === undefined || onMoveUp === undefined,
          onClick: moveUp,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Stack spacing={2}>
            <HookFormTextInput
              name="name"
              form={form}
              label="Workflow operation identifier"
              helperText="Results from this workflow operation will be provided in the workflow context using this identifier"
              onValueChange={formUpdated}
            />
            <HookFormCheckbox
              name={'disableValidation'}
              label={'Disable validation'}
              form={form}
              onValueChange={formUpdated}
            />
          </Stack>
        </Grid>
        <Grid item xs={7}>
          <HookFormTextInput
            name="description"
            form={form}
            label="Workflow operation description"
            fullWidth={true}
            multiline={true}
            rows={9}
            onValueChange={formUpdated}
          />
        </Grid>
      </Grid>

      <Collapsible
        title="Integration operation"
        subtitle="Define the actual integration operation to be invoked by this workflow operation node"
        defaultExpanded={false}
      >
        <DefineWorkflowOperation
          operation={workingNode?.operation}
          operations={allowedOperations}
          prototypes={prototypes}
          onChange={updateOperation}
        />
      </Collapsible>

      {workingNode !== undefined && workingNode.operation !== undefined && (
        <>
          <Collapsible title={'Define a run condition'} defaultExpanded={false}>
            <DefineJsonLogicRule
              runContextSchema={integrationContextSchema}
              condition={workingNode?.runCondition}
              onChange={updateRunCondition}
            />
          </Collapsible>
          <Collapsible
            title="Define input modifications"
            subtitle="Operation is always provided with the current integration context. With input modifications, this can be transformed. The transformed input schema will then be used for mapping."
            defaultExpanded={false}
          >
            <DefineInputTransformations
              integrationContextSchema={integrationContextSchema}
              node={workingNode as OperationNode}
              onChange={updateInputTransformations}
            />
          </Collapsible>
          <Collapsible
            title="Define mapping"
            subtitle="Operations have their own interfaces. Define how the input data is to be mapped to the input schema expected by the operation."
            disabledSubtitle={
              workingNode.operation?.inputSchema === undefined
                ? 'Operation does not define an input schema'
                : 'Operation does not require mapping'
            }
            disabled={
              mappingDisabled ||
              workingNode.operation?.inputSchema === undefined
            }
            defaultExpanded={false}
          >
            <DefineOperationMapping
              integrationContextSchema={integrationContextSchema}
              node={workingNode as OperationNode}
              targetSchema={
                workingNode.operation?.inputSchema?.content.jsonSchema ?? {}
              }
              onChange={updateMapping}
            />
          </Collapsible>
          <Collapsible
            title="Configure operation"
            subtitle="Define the runtime configuration required by the integration operation"
            disabled={runtimeConfigurationSchema === undefined}
            defaultExpanded={false}
          >
            <DefineRuntimeConfiguration
              integrationContextSchema={integrationContextSchema}
              configurationSchema={runtimeConfigurationSchema}
              node={workingNode as OperationNode}
              onChange={updateRuntimeConfiguration}
            />
          </Collapsible>
        </>
      )}
      <NodeFooter />
    </Box>
  );
};

export default DefineOperationNode;
