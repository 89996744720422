import React, { useReducer, JSX, useEffect, useState } from 'react';

import {
  IntegrationDefinition,
  ModuleConfiguration,
} from '@apus/common-lib/api/interface/integration-service';
import { IntegrationModule } from '@apus/common-lib/integrations/src/interface';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import useTenant from '@apus/common-ui/hooks/useTenant';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { Alert, Snackbar } from '@mui/material';

import { initialAppState, AppContext } from '../../state/appContext';
import {
  appContextReducer,
  setIntegrationConnections,
  setIntegrationModules,
  setIntegrations,
} from '../../state/appReducer';

interface Props {
  children: JSX.Element | JSX.Element[];
}

const AppContextProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(appContextReducer, initialAppState);
  const [loading, setLoading] = useState<boolean>(false);

  const tenant = useTenant();
  const integrationService = useIntegrationService();

  useEffect(() => {
    (async () => {
      if (tenant !== undefined) {
        // put integrations to app context
        await executeApiCall<IntegrationDefinition[]>({
          callFunction: integrationService.listIntegrations,
          setResult: integrations => dispatch(setIntegrations(integrations)),
          setPending: setLoading,
        });

        // put connections to app context
        await executeApiCall<ModuleConfiguration[]>({
          callFunction: integrationService.listModuleConfigurations,
          setResult: connections =>
            dispatch(setIntegrationConnections(connections)),
          setPending: setLoading,
        });

        // put integration modules to app context
        await executeApiCall<IntegrationModule[]>({
          callFunction: integrationService.listModules,
          setResult: modules => dispatch(setIntegrationModules(modules)),
          setPending: setLoading,
        });
      }
    })();
  }, [tenant, integrationService, dispatch, setLoading]);

  const handleAlertMessageClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  return (
    <AppContext.Provider value={[state, dispatch]}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={loading}
        onClose={handleAlertMessageClose}
      >
        <Alert
          onClose={handleAlertMessageClose}
          severity={'success'}
          sx={{ width: '100%' }}
        >
          Loading tenant data
        </Alert>
      </Snackbar>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
