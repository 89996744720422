import { JSX } from 'react';

import { resolveTransactionDataOutput } from '@apus/common-lib/integrations/schema/banking';
import { JSONSchema7 } from 'json-schema';

import { RuleConditionProps } from './interface';
import DefineJsonLogicCondition from '../../../../../../input/editor/jsonlogic/DefineJsonLogicCondition';

const DefineBankTransactionHandlingRuleCondition = ({
  disabled,
  condition,
  onChange,
}: RuleConditionProps): JSX.Element => {
  return (
    <DefineJsonLogicCondition
      condition={condition}
      inputSchema={resolveTransactionDataOutput as JSONSchema7}
      groupedProperties={{
        '/properties/bankTransaction/properties/message': 'referenceGroup',
        '/properties/bankTransaction/properties/reference': 'referenceGroup',
        '/properties/bankTransaction/properties/rfReference': 'referenceGroup',
        '/properties/erpTransaction/properties/reference': 'referenceGroup',
        '/properties/erpTransaction/properties/number': 'referenceGroup',
        '/properties/erpEntity/properties/entityId': 'entityGroup',
        '/properties/erpEntity/properties/entityName': 'entityGroup',
        '/properties/erpTransaction/properties/entityId': 'entityGroup',
        '/properties/bankTransaction/properties/entityName': 'entityGroup',
        '/properties/bankTransaction/properties/amount': 'amountGroup',
        '/properties/roundingTolerance': 'amountGroup',
        '/properties/resolvedTotalTransactionSum': 'amountGroup',
        '/properties/resolvedTotalDifference': 'amountGroup',
      }}
      hiddenProperties={[
        '/properties/bankTransaction/properties/account/properties/iban',
        '/properties/bankTransaction/properties/account/properties/bic',
        '/properties/bankTransaction/properties/debtorName',
        '/properties/bankTransaction/properties/creditorName',
      ]}
      useSchemaTitles={true}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default DefineBankTransactionHandlingRuleCondition;
