import React, { JSX } from 'react';

import SystemglueBaseEditor from '../base/SystemglueBaseEditor';

interface Props {
  allowUndefined?: boolean;
  readOnly?: boolean;
  title?: string;
  helperText?: string;
  value?: string;
  onChange: (value: string | undefined) => void;
  height?: number;
}

const DefineLiquidJsTemplateHandler = ({
  allowUndefined = false,
  readOnly = false,
  title = 'Define LiquidJS -template',
  helperText,
  value,
  onChange,
  height = 400,
}: Props): JSX.Element => {
  return (
    <SystemglueBaseEditor
      allowUndefined={allowUndefined}
      readOnly={readOnly}
      title={title}
      helperText={helperText}
      value={value ?? ''}
      minHeight={height}
      language="liquid"
      onValueChange={onChange}
      options={{
        readOnly: readOnly,
        minimap: { enabled: false },
        lineNumbers: 'off',
      }}
    />
  );
};

export default DefineLiquidJsTemplateHandler;
