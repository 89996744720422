import React, { useState } from 'react';

import { BankingFileTemplate } from '@apus/common-lib/api/interface/banking-service';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface Props {
  templates?: BankingFileTemplate[];
  edit?: boolean;
  onSelect: (test: BankingFileTemplate | undefined) => void;
  onEdit: (test: BankingFileTemplate | undefined) => void;
  onAdd: () => void;
  onDelete?: (test: BankingFileTemplate) => void;
}

const BankingFileTemplateList = ({
  templates,
  edit,
  onSelect,
  onEdit,
  onAdd,
  onDelete,
}: Props) => {
  const [selectedTestIndex, setSelectedTestIndex] = useState<
    number | undefined
  >();

  const deleteTemplate = () => {
    if (onDelete === undefined) throw new Error('Delete is not supported yet');
    if (templates === undefined || templates.length === 0)
      throw new Error('Cannot delete - no template defined');
    if (
      selectedTestIndex === undefined ||
      templates[selectedTestIndex] === undefined
    )
      throw new Error('Cannot delete - no template selected');

    onDelete(templates[selectedTestIndex]);
  };

  const selectTemplate = (index: number) => {
    setSelectedTestIndex(index);
    onSelect(
      index !== undefined && templates !== undefined && templates.length > index
        ? templates[index]
        : undefined
    );
  };

  const editTemplate = () => {
    if (selectedTestIndex !== undefined) {
      onEdit(
        templates !== undefined && templates.length > selectedTestIndex
          ? templates[selectedTestIndex]
          : undefined
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
          <IconButton
            disabled={
              edit ||
              onDelete === undefined ||
              templates === undefined ||
              selectedTestIndex === undefined ||
              templates[selectedTestIndex] === undefined
            }
            onClick={deleteTemplate}
          >
            <DeleteTwoToneIcon color={'error'} />
          </IconButton>
          <IconButton
            disabled={edit || selectedTestIndex === undefined}
            onClick={editTemplate}
          >
            <EditTwoToneIcon color={'info'} />
          </IconButton>
          <IconButton disabled={edit} onClick={onAdd}>
            <AddBoxTwoToneIcon color={'success'} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <List>
          {templates?.map((test, index) => (
            <ListItem key={index} disableGutters={true}>
              <ListItemButton
                selected={edit}
                onClick={() => selectTemplate(index)}
                disableGutters={true}
              >
                <ListItemText
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                  primary={test.id}
                  secondaryTypographyProps={{
                    noWrap: true,
                  }}
                  secondary={test.description}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BankingFileTemplateList;
