import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  CustomContentMediaType,
  SupportedSchemaDefinition,
} from '@apus/common-lib/api/interface/files';
import {
  ApiEndpointTriggerNode,
  IntegrationNodeType,
} from '@apus/common-lib/integration-engine/src/interface';
import { RequestAuthorizer } from '@apus/common-lib/integrations/src/interface';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { Box, Grid, Stack } from '@mui/material';
import { isEqual, kebabCase } from 'lodash';
import { v4 as uuid } from 'uuid';

import DefineWebhookAuthorizer from './authorizer/DefineTriggerAuthorizer';
import { NodeBaseComponent } from './interface';
import NodeFooter from './NodeFooter';
import NodeToolBar from './NodeToolBar';
import DefineSchema from '../../json/schema/DefineSchema';
import Collapsible from '../../surface/Collapsible';

interface Props extends NodeBaseComponent<ApiEndpointTriggerNode> {
  authorizers: RequestAuthorizer[];
}

interface NodeForm {
  name: string;
  description?: string;
  internal?: boolean;
  disableValidation?: boolean;
}

const DefineApiEndpointTriggerNode = ({
  expanded,
  node,
  authorizers,
  onSave,
  onCancel,
}: Props): JSX.Element => {
  const [needsSaving, setNeedsSaving] = useState<boolean>(false);
  const [isInternal, setIsInternal] = useState<boolean>(false);
  const form = useForm<NodeForm>({
    resolver: ajvResolver<NodeForm>({
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'name must be at least 1 characters long',
          },
          nullable: false,
        },
        description: {
          type: 'string',
          nullable: true,
        },
        internal: {
          type: 'boolean',
          nullable: true,
        },
        disableValidation: {
          type: 'boolean',
          nullable: true,
        },
      },
      required: ['name'],
    }),
    defaultValues: {
      name: '',
      description: undefined,
    },
  });

  const [workingNode, setWorkingNode] = useState<
    ApiEndpointTriggerNode | undefined
  >(undefined);

  useEffect(() => {
    if (node !== undefined) {
      form.setValue('name', node.name);
      form.setValue('description', node.description);
      form.setValue('internal', node.internal);
      form.setValue('disableValidation', node.disableValidation);

      setWorkingNode(node);
    } else {
      setWorkingNode({
        id: uuid(),
        description: '',
        nodeType: IntegrationNodeType.ApiEndpointTrigger,
        name: `api-endpoint`,
        // by default, api endpoint is public
        internal: false,
        // by default, validation should always be on
        disableValidation: false,
        triggerSchema: {
          content: {
            jsonSchema: {},
          },
          contentType: CustomContentMediaType.JsonSchema,
        },
      });
      form.setValue('name', `api-endpoint`);
      form.setValue('description', '');
      form.setValue('internal', false);
    }
  }, [node, form]);

  const updateAuthorizer = (authorizer: RequestAuthorizer | undefined) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        authorizer,
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateSchema = (schema?: SupportedSchemaDefinition) => {
    if (workingNode !== undefined && schema !== undefined) {
      const updated = {
        ...workingNode,
        triggerSchema: schema,
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  function updateNeedsSaving(current: ApiEndpointTriggerNode | undefined) {
    if (node !== undefined) {
      setNeedsSaving(!isEqual(node, current));
    } else {
      setNeedsSaving(true);
    }
  }

  function formUpdated() {
    const current =
      workingNode !== undefined
        ? {
            ...workingNode,
            ...form.getValues(),
          }
        : undefined;
    updateNeedsSaving(current);
  }

  const submit = async (data: NodeForm) => {
    if (workingNode !== undefined) {
      if (workingNode.triggerSchema === undefined)
        throw new Error(
          `Cannot save api endpoint node - triggerSchema missing`
        );

      onSave({
        ...workingNode,
        name: kebabCase(data.name),
        description: data.description,
        disableValidation: data.disableValidation,
      } as ApiEndpointTriggerNode);
    }
  };

  if (expanded !== true) return <></>;

  return (
    <Box sx={{ minWidth: 1024, padding: 2 }}>
      <NodeToolBar
        save={{
          onClick: form.handleSubmit(submit),
          disabled: !needsSaving,
        }}
        cancel={{
          onClick: onCancel,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Stack spacing={2}>
            <HookFormTextInput
              name="name"
              form={form}
              label="Workflow operation identifier"
              helperText="Name for trigger"
              onValueChange={formUpdated}
            />
            <HookFormCheckbox
              name={'disableValidation'}
              label={'Disable validation'}
              form={form}
              onValueChange={formUpdated}
            />
            <HookFormCheckbox
              name={'internal'}
              label={'Internal trigger (not exposed in the rest api)'}
              form={form}
              onValueChange={value => setIsInternal(value ?? false)}
            />
            <DefineWebhookAuthorizer
              authorizer={workingNode?.authorizer}
              authorizers={authorizers}
              onChange={updateAuthorizer}
              disabled={isInternal}
            />
          </Stack>
        </Grid>
        <Grid item xs={7}>
          <HookFormTextInput
            name="description"
            form={form}
            label="Trigger description"
            multiline={true}
            rows={10}
            fullWidth={true}
            onValueChange={formUpdated}
          />
        </Grid>
        <Collapsible
          title={'Define the input data model'}
          defaultExpanded={false}
        >
          <Stack spacing={2}>
            <DefineSchema
              propertyName={`triggerSchema`}
              title="Define trigger schema"
              schema={workingNode?.triggerSchema}
              onSchemaSave={updateSchema}
            />
          </Stack>
        </Collapsible>
      </Grid>
      <NodeFooter />
    </Box>
  );
};

export default DefineApiEndpointTriggerNode;
