import React, { useEffect, useState, JSX, useCallback } from 'react';

import { BankingFileTemplate } from '@apus/common-lib/api/interface/banking-service';
import { ApiError } from '@apus/common-lib/api/interface/common';
import useBankingService from '@apus/common-ui/hooks/useBankingService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { Alert, Box, Grid, Typography } from '@mui/material';

import BankingFileTemplateList from './BankingFileTemplateList';
import BankingFileTemplateEditor from './form/BankingFileTemplateEditor';
import { bodyMaxHeight } from '../../theme/theme';

const BankingFileTemplateManagementTool = (): JSX.Element => {
  const bankingService = useBankingService();

  const [edit, setEdit] = useState<boolean>(false);
  const [template, setTemplate] = useState<BankingFileTemplate | undefined>();
  const [templateList, setTemplateList] = useState<
    BankingFileTemplate[] | undefined
  >();
  const [bankingServiceError, setBankingServiceError] = useState<
    ApiError | undefined
  >();

  const refreshTemplates = useCallback(
    async () =>
      await executeApiCall<BankingFileTemplate[]>({
        callFunction: () => bankingService.listTemplates(),
        setResult: setTemplateList,
        setError: setBankingServiceError,
      }),
    [bankingService]
  );

  useEffect(() => {
    (async () => {
      await refreshTemplates();
    })();
  }, [refreshTemplates]);

  const onSelect = (template: BankingFileTemplate | undefined) => {
    setTemplate(template);
  };

  const onEdit = (template: BankingFileTemplate | undefined) => {
    setTemplate(template);
    setEdit(true);
  };

  const onAdd = () => {
    setTemplate(undefined);
    setEdit(true);
  };

  const onSave = async () => {
    setEdit(false);
    await refreshTemplates();
  };
  const onCancel = () => {
    setEdit(false);
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
      >
        <Grid item xs={12}>
          {bankingServiceError !== undefined && (
            <Alert severity={'error'}>
              <Typography>
                There was an error during loading templates: $
                {bankingServiceError.message}
              </Typography>
            </Alert>
          )}
        </Grid>
        <Grid item xs={2}>
          <BankingFileTemplateList
            onSelect={onSelect}
            onAdd={onAdd}
            onEdit={onEdit}
            templates={templateList}
          />
        </Grid>
        <Grid item xs={10}>
          <BankingFileTemplateEditor
            edit={edit}
            value={template}
            onSave={onSave}
            onCancel={onCancel}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankingFileTemplateManagementTool;
