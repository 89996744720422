import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { SupportedSchemaDefinition } from '@apus/common-lib/api/interface/files';
import {
  ConfigurationSchema,
  IntegrationOperation,
  IntegrationOperationPrototype,
  SupportedModule,
  SupportedModuleNames,
} from '@apus/common-lib/integrations/src/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import {
  generateConfiguration,
  splitConfigurationSchema,
  toJsonSchemaDefinition,
} from '@apus/common-lib/utils/src/data-utils';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import useTenant from '@apus/common-ui/hooks/useTenant';
import {
  getResolver,
  resolvePrototypeOrThrow,
} from '@apus/common-ui/utils/data-utils';
import {
  Drawer,
  FormControl,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { JSONSchemaType } from 'ajv';
import { JSONSchema7 } from 'json-schema';

import TestIntegrationOperation from './TestIntegrationOperation';
import ConfigurationSchemaForm from '../input/ConfigurationSchemaForm';
import DefineSchema from '../json/schema/DefineSchema';

export interface IntegrationOperationEdit {
  moduleId?: string;
  operationId?: string;
  title?: string;
  description?: string;
  prototype: IntegrationOperationPrototype;
  configuration?: SourceObject;
  configurationSchema?: ConfigurationSchema;
  inputSchema?: SupportedSchemaDefinition;
  outputSchema?: SupportedSchemaDefinition;
}

/**
 * AJV-compatible validation schema for the form data
 *
 * This is really complicated because ajv has some really annoying design choices
 */
const integrationOperationEditSchema = (
  operation: Pick<IntegrationOperation, 'prototype'>
): JSONSchemaType<
  Pick<IntegrationOperationEdit, 'description' | 'configuration'> & {
    // re-define operationId and title as non-optional properties so that ajv's type checking doesn't go off the rails
    operationId: string;
    title: string;
  }
> => {
  const { configurationSchema } = splitConfigurationSchema(
    operation.prototype.configurationSchema
  );

  const schema = {
    type: 'object',
    properties: {
      operationId: {
        type: 'string',
        pattern: '^[a-zA-Z0-9]+$',
        minLength: 4,
        errorMessage: {
          minLength: 'operationId must be at least 4 characters',
          pattern: 'operationId can only contain alpha-numeric characters',
        },
      },
      moduleId: {
        type: 'string',
        minLength: 1,
        errorMessage: {
          minLength: 'moduleId must be at least 1 characters',
        },
      },
      title: {
        type: 'string',
        minLength: 1,
        errorMessage: {
          minLength: 'title must be at least 1 characters',
        },
      },
      description: {
        type: 'string',
        nullable: true,
      },
      configuration: {
        type: 'object',
        ...configurationSchema,
        nullable: configurationSchema === undefined,
      },
    },
    required:
      configurationSchema === undefined
        ? ['operationId', 'title']
        : ['operationId', 'title', 'configuration'],
    additionalProperties: true,
  };

  return schema as JSONSchemaType<
    Pick<IntegrationOperationEdit, 'description' | 'configuration'> & {
      // re-define operationId and title as non-optional properties so that ajv's type checking doesn't go off the rails
      operationId: string;
      title: string;
    }
  >;
};

export type OperationEditMode = 'create' | 'modify';

interface Props {
  mode?: OperationEditMode;
  readOnly?: boolean;
  operation: IntegrationOperation;
  prototypes: IntegrationOperationPrototype[];
  onSave?: (operation: IntegrationOperation) => void;
  onCancel?: () => void;
}

const asIntegrationOperation = (
  data: IntegrationOperationEdit
): IntegrationOperation => {
  return {
    description: data.description ?? '',
    inputSchema: data.inputSchema,
    moduleId: data.moduleId as SupportedModule,
    operationId: data.operationId ?? '',
    outputSchema: data.outputSchema,
    prototype: data.prototype as IntegrationOperationPrototype,
    configuration: data.configuration,
    title: data.title ?? '',
  };
};

const DefineIntegrationOperation = ({
  mode = 'modify',
  readOnly,
  operation,
  prototypes,
  onSave,
  onCancel,
}: Props): JSX.Element => {
  const form = useForm<IntegrationOperationEdit>({
    resolver: getResolver(integrationOperationEditSchema(operation)),
    defaultValues: {
      configurationSchema: undefined,
      description: undefined,
      moduleId: undefined,
      operationId: undefined,
      title: undefined,
      inputSchema: undefined,
      outputSchema: undefined,
      prototype: undefined,
      configuration: undefined,
    },
  });
  const [showTestForm, setShowTestForm] = useState<boolean>(false);
  const [inputSchema, setInputSchema] = useState<
    SupportedSchemaDefinition | undefined
  >();
  const [outputSchema, setOutputSchema] = useState<
    SupportedSchemaDefinition | undefined
  >();
  const [workingOperation, setWorkingOperation] =
    useState<IntegrationOperationEdit>();

  const tenant = useTenant();

  useEffect(() => {
    const prototype = resolvePrototypeOrThrow(operation, prototypes);

    const { configurationSchema } = splitConfigurationSchema(
      prototype.configurationSchema
    );
    const input =
      operation.inputSchema !== undefined
        ? operation.inputSchema
        : prototype.inputSchema;
    const output =
      operation.outputSchema !== undefined
        ? operation.outputSchema
        : prototype.outputSchema;

    const edit: IntegrationOperationEdit = {
      moduleId: operation.moduleId,
      operationId: operation.operationId,
      description: operation.description,
      title: operation.title,
      inputSchema: input,
      outputSchema: output,
      prototype: prototype,
      configuration: generateConfiguration(
        operation.configuration,
        configurationSchema
      ),
      configurationSchema,
    };

    form.setValue('moduleId', edit.moduleId);
    form.setValue('operationId', edit.operationId);
    form.setValue('title', edit?.title);
    form.setValue('description', edit?.description);
    form.setValue('prototype', edit.prototype);
    form.setValue('inputSchema', edit.inputSchema);
    form.setValue('outputSchema', edit.outputSchema);
    form.setValue('configuration', edit.configuration);
    form.setValue('configurationSchema', edit.configurationSchema);

    setInputSchema(input);
    setOutputSchema(output);
    setWorkingOperation(edit);
  }, [
    operation,
    prototypes,
    form,
    setInputSchema,
    setOutputSchema,
    setWorkingOperation,
  ]);

  const submit = async (data: IntegrationOperationEdit) => {
    if (onSave !== undefined) onSave(asIntegrationOperation(data));
  };

  const onOverrideOutputSchema = (schema: JSONSchema7) => {
    if (schema !== undefined) {
      const changed: SupportedSchemaDefinition = {
        ...outputSchema,
        ...toJsonSchemaDefinition(schema),
      };
      setOutputSchema(changed);
      form.setValue('outputSchema', changed);
    }
  };

  return (
    <Grid container spacing={2}>
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: false,
        }}
        open={showTestForm}
        anchor={'right'}
        onClose={() => setShowTestForm(false)}
        // TODO: instead of using margin, we should bind to another component
        PaperProps={{ sx: { marginTop: '48px' } }}
      >
        {workingOperation !== undefined && tenant !== undefined && (
          <TestIntegrationOperation
            allowSchemaOverride={!readOnly}
            operation={
              form.getValues()?.operationId !== undefined
                ? asIntegrationOperation(
                    form.getValues() as IntegrationOperationEdit
                  )
                : asIntegrationOperation(workingOperation)
            }
            tenant={tenant}
            onOverrideOutputSchema={onOverrideOutputSchema}
          />
        )}
      </Drawer>
      <Grid item xs={12}>
        <FormControl fullWidth margin="normal">
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Stack spacing={2} direction={'column'}>
                  <HookFormAutocomplete
                    name={'moduleId'}
                    form={form}
                    options={[''].concat(SupportedModuleNames)}
                    disabled={readOnly || mode === 'modify'}
                    label="Operation module"
                    helperText={'Operation is part of this module'}
                    fullWidth={true}
                  />
                  <HookFormTextInput
                    name={'operationId'}
                    form={form}
                    disabled={readOnly || mode === 'modify'}
                    label="Operation id"
                    helperText={
                      'Case-sensitive and unique identifier for the operation'
                    }
                    defaultValue={''}
                    fullWidth={true}
                  />
                  <HookFormTextInput
                    name={'title'}
                    form={form}
                    disabled={readOnly}
                    label="Name"
                    helperText={'Human-readable name for the operation'}
                    defaultValue={''}
                    fullWidth={true}
                  />
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <HookFormTextInput
                  name={'description'}
                  form={form}
                  label="Description"
                  helperText={'Operation description'}
                  disabled={readOnly}
                  defaultValue={''}
                  multiline={true}
                  rows={9}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </FormGroup>
          <FormGroup>
            <Typography variant={'h6'} sx={{ marginTop: 2, marginBottom: 2 }}>
              Data model
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Controller
                  name={`inputSchema`}
                  control={form.control}
                  render={({ field }) => (
                    <DefineSchema
                      title="Input schema"
                      propertyName={`inputSchema`}
                      mandatorySchema={
                        operation.prototype?.inputSchema ?? undefined
                      }
                      readOnly={readOnly}
                      schema={field.value}
                      onSchemaSave={jsonSchema => {
                        field.onChange(jsonSchema);
                        setInputSchema(jsonSchema);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`outputSchema`}
                  control={form.control}
                  render={({ field }) => (
                    <DefineSchema
                      allowUndefined={true}
                      propertyName={`outputSchema`}
                      title="Output schema"
                      mandatorySchema={
                        operation?.prototype?.outputSchema ?? undefined
                      }
                      readOnly={readOnly}
                      schema={field.value}
                      onSchemaSave={jsonSchema => {
                        field.onChange(jsonSchema);
                        setOutputSchema(jsonSchema);
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormGroup>
          {workingOperation?.configurationSchema !== undefined && (
            <FormGroup>
              <Typography variant={'h6'} sx={{ marginTop: 2, marginBottom: 2 }}>
                Prototype-based configuration
              </Typography>
              <Stack spacing={2}>
                <ConfigurationSchemaForm
                  readOnly={readOnly}
                  form={form}
                  configurationSchema={workingOperation?.configurationSchema}
                  propertyPath={'configuration'}
                  inputSchema={inputSchema?.content.jsonSchema}
                  outputSchema={outputSchema?.content.jsonSchema}
                />
              </Stack>
            </FormGroup>
          )}
        </FormControl>
      </Grid>
      {!readOnly && (
        <Grid item xs={12}>
          <Typography>
            Click save to{' '}
            {operation.operationId !== undefined
              ? 'save the updated '
              : 'create new '}
            operation to the database
          </Typography>
          <Stack spacing={1} direction={'row'}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={form.handleSubmit(submit)}>Save</Button>
            {tenant !== undefined && (
              <Button onClick={() => setShowTestForm(true)}>Test</Button>
            )}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default DefineIntegrationOperation;
