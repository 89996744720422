import { JSX } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { Typography } from '@mui/material';

interface Props {
  requireRoles: string[];
  children: JSX.Element;
}

export function RequireRole({ requireRoles, children }: Props): JSX.Element {
  const { authStatus, user } = useAuthenticator(context => [
    context.authStatus,
    context.user,
  ]);

  const userGroups: string[] =
    user?.getSignInUserSession()?.getIdToken()?.payload?.['cognito:groups'] ??
    [];

  if (
    authStatus !== 'authenticated' ||
    !requireRoles.some(role => userGroups.includes(role))
  ) {
    return <Typography>Forbidden - insufficient user roles</Typography>;
  }
  return children;
}
