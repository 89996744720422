import { useEffect, JSX } from 'react';
import { useNavigate, useLocation } from 'react-router';

import {
  Authenticator,
  useAuthenticator,
  ThemeProvider,
  Theme,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

export function Login(): JSX.Element {
  const { route } = useAuthenticator(context => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  const theme: Theme = {
    name: 'Auth Theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            '10': { value: '#1976d22F' },
            '80': { value: '#1976d2BA' },
            '90': { value: '#1976d2CF' },
            '100': { value: '#1976d2FF' },
          },
        },
      },
    },
  };

  const from = location.state?.from?.pathname ?? '/';

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <ThemeProvider theme={theme}>
      <Authenticator hideSignUp={true} />
    </ThemeProvider>
  );
}
