import { JSX, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IntegrationDefinition } from '@apus/common-lib/api/interface/integration-service';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { Box, CircularProgress, Typography } from '@mui/material';

import DefineIntegration from '../../../components/workflow/timeline/DefineIntegration';
import { AppContext } from '../../../state/appContext';
import { setIntegrations } from '../../../state/appReducer';

const IntegrationView = (): JSX.Element => {
  const integrationService = useIntegrationService();
  const [appState, dispatch] = useContext(AppContext);

  const [integration, setIntegration] = useState<IntegrationDefinition>();

  const { integrationId } = useParams();

  const getIntegration = useCallback(() => {
    if (integrationId !== undefined)
      return integrationService.getIntegration(integrationId);
    return Promise.resolve(undefined);
  }, [integrationService, integrationId]);

  useEffect(() => {
    (async () => {
      await executeApiCall({
        callFunction: getIntegration,
        setResult: setIntegration,
      });
    })();
  }, [getIntegration]);

  async function onSave() {
    dispatch(setIntegrations(await integrationService.listIntegrations()));
  }

  if (appState.integrationModules === undefined)
    return (
      <Box>
        <Typography variant={'subtitle1'}>Loading modules...</Typography>
        <CircularProgress />
      </Box>
    );

  return (
    <DefineIntegration
      modules={appState.integrationModules}
      integrations={appState.integrations}
      integration={integration}
      onSave={onSave}
    />
  );
};

export default IntegrationView;
