import React, { useCallback, useEffect, useState } from 'react';

import { TextField } from '@mui/material';
import cronstrue from 'cronstrue';

interface Props {
  schedule?: string;
  onChange: (schedule: string | undefined) => void;
}

const DefineCronSchedule = ({ schedule, onChange }: Props) => {
  const [cronValue, setCronValue] = useState<string | undefined>();
  const [cronText, setCronText] = useState<string>('');

  const scheduleUpdated = useCallback(
    (newSchedule: string) => {
      if (newSchedule !== schedule) {
        try {
          const cronText = cronstrue.toString(newSchedule, {
            throwExceptionOnParseError: true,
          });
          onChange(newSchedule);
          setCronText(cronText);
        } catch (e) {
          onChange(undefined);
          setCronText(JSON.stringify(e));
        }
      }
      setCronValue(newSchedule);
    },
    [schedule, onChange, setCronText, setCronValue]
  );

  useEffect(() => {
    if (schedule !== undefined) {
      scheduleUpdated(schedule);
    }
  }, [schedule, scheduleUpdated]);

  return (
    <TextField
      label={'When should the trigger run? Define as a cron-expression'}
      value={cronValue ?? ''}
      onChange={event => scheduleUpdated(event.target.value ?? '')}
      helperText={cronText}
    />
  );
};

export default DefineCronSchedule;
