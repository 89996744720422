import { Tenant } from '@apus/common-lib/api/interface/tenant-service';
import { AxiosInstance } from 'axios';

export interface TenantService {
  createTenant(tenant: Tenant): Promise<void>;

  updateTenant(tenant: Tenant): Promise<void>;

  getTenant(tenantId: string): Promise<Tenant>;

  listTenants(): Promise<Tenant[]>;
}

export const createTenantService = (client: AxiosInstance): TenantService => {
  return {
    createTenant(tenant: Tenant): Promise<void> {
      return client.post(`/tenants`, tenant);
    },

    updateTenant(tenant: Tenant): Promise<void> {
      return client.put(`/tenants/${tenant.tenantId}`, tenant);
    },

    getTenant(tenantId: string): Promise<Tenant> {
      return client
        .get<Tenant>(`/tenants/${tenantId}`)
        .then(({ data }) => data);
    },

    listTenants(): Promise<Tenant[]> {
      return client.get<Tenant[]>(`/tenants`).then(({ data }) => data);
    },
  };
};
