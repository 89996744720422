import React, { useEffect, useState, JSX, useCallback } from 'react';

import { TypescriptFunction } from '@apus/common-lib/api/interface/files';
import { eventContextSchema as defaultEventContextSchema } from '@apus/common-lib/integrations/src/interface';
import {
  parseFunction,
  toTypescriptFunction,
} from '@apus/common-lib/runtime-typescript/src/function-utils';
import * as typesModule from '@apus/common-lib/runtime-typescript/src/type-declaration';
import {
  compile,
  DEFAULT_OPTIONS,
} from '@apus/common-lib/schema-to-interface/src';
import Editor, { Monaco } from '@monaco-editor/react';
import { Grid } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import { editor, MarkerSeverity } from 'monaco-editor';

import SystemglueBaseEditor from '../base/SystemglueBaseEditor';

interface Props {
  allowUndefined?: boolean;
  readOnly?: boolean;
  title?: string;
  helperText?: string;
  /**
   * The name of the function which must be defined in the code block
   */
  functionName?: string;
  runContextTypeName?: string;
  eventContextTypeName?: string;
  outputTypeName?: string;
  functionComment?: string;
  inputTypeComment?: string;
  outputTypeComment?: string;
  eventContextTypeComment?: string;
  inputSchema?: JSONSchema7;
  eventContextSchema?: JSONSchema7;
  outputSchema?: JSONSchema7;
  value?: TypescriptFunction;
  onChange: (value: TypescriptFunction) => void;
}

const defaultSchema: JSONSchema7 = {
  type: 'object',
};

const defaultInputTypeName = 'RunContext';
const defaultEventContextTypeName = 'EventContext';
const defaultOutputTypeName = 'Output';

const defaultImports = `import { ${typesModule.moduleExports.join(
  ', '
)} } from '${typesModule.moduleName}';
`;

const defaultFunctionCommentTemplate = `/**
 * This function is called during workflow operations and must be defined
 */`;

const defaultInputTypeCommentTemplate = `/**
 * RunContext defines the data which is passed to the operation during workflow
 * 
 * The data is produced by mapping the current input context into the format required by the operation. 
 */`;

const defaultEventContextTypeCommentTemplate = `/**
 * EventContext defines the data which is provided to the operation at the beginning of the processing
 * 
 * The data defines the fixed, tenant-specific context such as tenantId, integrationId, etc. 
 */`;

const defaultOutputTypeCommentTemplate = `/**
 * Output defines the data which will be added to the workflow context after the operation has run
 */`;

async function generateType(
  schema: JSONSchema7,
  typeName: string,
  comment: string
) {
  const code = await compile(
    {
      ...schema,
      title: typeName,
    },
    typeName,
    {
      ...DEFAULT_OPTIONS,
    }
  );

  return [comment, code].join('\n');
}

const DefineTypescriptHandler = ({
  allowUndefined = false,
  readOnly = false,
  title = 'Define typescript',
  helperText,
  functionName = 'run',
  runContextTypeName = defaultInputTypeName,
  outputTypeName = defaultOutputTypeName,
  eventContextTypeName = defaultEventContextTypeName,
  functionComment = defaultFunctionCommentTemplate,
  inputTypeComment = defaultInputTypeCommentTemplate,
  outputTypeComment = defaultOutputTypeCommentTemplate,
  eventContextTypeComment = defaultEventContextTypeCommentTemplate,
  inputSchema = defaultSchema,
  eventContextSchema = defaultEventContextSchema,
  outputSchema = defaultSchema,
  value,
  onChange,
}: Props): JSX.Element => {
  // Don't export the function - it will be handled later when executing the code (see executeFunction in js-utils.ts)
  const functionTemplate = `function ${functionName}(runContext: ${runContextTypeName}, eventContext: ${eventContextTypeName}): ${outputTypeName} { 
  return {};
}`;

  const [editorContent, setEditorContent] = useState<string | undefined>();
  const [, setEditorErrors] = useState<editor.IMarker[]>([]);
  const [generatedRunContext, setGeneratedRunContext] = useState<
    string | undefined
  >(undefined);
  const [generatedEventContext, setGeneratedEventContext] = useState<
    string | undefined
  >(undefined);
  const [generatedOutput, setGeneratedOutput] = useState<string | undefined>(
    undefined
  );
  const [editorValue, setEditorValue] = useState<string | undefined>();
  const [viewerValue, setViewerValue] = useState<string | undefined>();
  const [monaco, setMonaco] = useState<Monaco>();

  useEffect(() => {
    (async () => {
      const generated = await generateType(
        inputSchema,
        runContextTypeName,
        inputTypeComment
      );
      setGeneratedRunContext(generated);
    })();
  }, [
    inputSchema,
    runContextTypeName,
    inputTypeComment,
    setGeneratedRunContext,
  ]);

  useEffect(() => {
    (async () => {
      const generated = await generateType(
        eventContextSchema,
        eventContextTypeName,
        eventContextTypeComment
      );
      setGeneratedEventContext(generated);
    })();
  }, [
    eventContextSchema,
    eventContextTypeName,
    eventContextTypeComment,
    setGeneratedEventContext,
  ]);

  useEffect(() => {
    (async () => {
      const generated = await generateType(
        outputSchema,
        outputTypeName,
        outputTypeComment
      );
      setGeneratedOutput(generated);
    })();
  }, [outputSchema, outputTypeName, outputTypeComment, setGeneratedOutput]);

  useEffect(() => {
    if (editorContent !== undefined) return;

    if (value === undefined) {
      setEditorValue(
        [defaultImports, functionComment, functionTemplate].join('\n')
      );
    } else {
      setEditorValue(value.typescriptCode);
    }
  }, [editorContent, value, functionTemplate, functionComment]);

  function checkErrors(markers: editor.IMarker[]): editor.IMarker[] {
    if (editorContent === undefined) return [];

    const errors = markers.filter(m => m.severity === 8);

    try {
      parseFunction(editorContent, functionName);
    } catch (e) {
      console.log('Could not parse function');
      return [
        {
          message: `Function ${functionName} is not defined`,
          severity: MarkerSeverity.Error,
        } as editor.IMarker,
      ].concat(errors);
    }

    return errors;
  }

  const onEditorValidated = (markers: editor.IMarker[]) => {
    if (editorContent === undefined) return;

    const errors = checkErrors(markers);
    setEditorErrors(errors);

    if (errors.length === 0) {
      onChange(toTypescriptFunction(editorContent));
    }
  };

  const generateExtraLibsAndUpdateViewer = useCallback((): {
    content: string;
    filePath?: string;
  }[] => {
    if (
      editorValue !== undefined &&
      generatedRunContext !== undefined &&
      generatedEventContext !== undefined &&
      generatedOutput !== undefined
    ) {
      const types = [
        generatedOutput,
        generatedRunContext,
        generatedEventContext,
      ]
        .join('\n')
        .replace(/export /g, '');

      setViewerValue([types, typesModule.moduleContent].join('\n'));

      return [
        { content: typesModule.moduleDeclaration, filePath: 'systemglue.d.ts' },
        {
          content: [
            types,
            "declare module 'global' { export { RunContext, EventContext, Output} }",
          ].join('\n'),
          filePath: 'global.d.ts',
        },
      ];
    }
    setViewerValue('');
    return [];
  }, [
    editorValue,
    generatedRunContext,
    generatedEventContext,
    generatedOutput,
    setViewerValue,
  ]);

  const beforeEditorMounted = (instance: Monaco) => {
    if (instance !== undefined) {
      // monaco instance from the hook is not yet available, so use the provided instance to dispose of all existing models
      // we are generating multiple versions of the same types, so without this operation, those types will clash
      instance.editor.getModels().forEach(model => {
        model.dispose();
      });

      instance.languages.typescript.typescriptDefaults.setExtraLibs(
        generateExtraLibsAndUpdateViewer()
      );

      setMonaco(instance);
    }
  };

  useEffect(() => {
    if (monaco !== undefined) {
      monaco.languages.typescript.typescriptDefaults.setExtraLibs(
        generateExtraLibsAndUpdateViewer()
      );
    }
  }, [monaco, generateExtraLibsAndUpdateViewer]);

  return (
    <Grid container>
      <SystemglueBaseEditor
        allowUndefined={allowUndefined}
        readOnly={readOnly}
        title={title}
        helperText={helperText}
        value={editorValue}
        height="100%"
        language="typescript"
        onValueChange={content => {
          setEditorContent(content);
        }}
        options={{
          minimap: { enabled: false },
          lineNumbers: 'off',
          readOnly: readOnly,
        }}
        onValidate={onEditorValidated}
        beforeMount={beforeEditorMounted}
        keepCurrentModel={false}
        saveViewState={false}
      >
        {viewerValue !== undefined && (
          <Editor
            value={viewerValue}
            height="100%"
            language="typescript"
            options={{
              minimap: { enabled: false },
              lineNumbers: 'off',
              readOnly: true,
            }}
            keepCurrentModel={false}
            saveViewState={false}
          />
        )}
      </SystemglueBaseEditor>
    </Grid>
  );
};

export default DefineTypescriptHandler;
