export class LimitedQueue<T> {
  private items: T[];
  private maxSize: number = 100;

  constructor(maxSize = 100) {
    this.items = [];
    this.maxSize = maxSize;
  }

  /**
   * Add new item to the start of the queue or if predicate is provided, possible duplicate is replaced
   *
   * Will adjust the size of the queue to the max size defined
   *
   * @param item item to add
   * @param predicate function to determine duplicate items
   */
  add(item: T, predicate?: (item: T) => boolean) {
    const duplicateIdx =
      predicate !== undefined ? this.items.findIndex(predicate) : -1;

    if (duplicateIdx >= 0) {
      const copy = [...this.items];
      copy.splice(duplicateIdx, 1, item);
      this.items = copy;
    } else {
      this.items = [item, ...this.items].slice(0, this.maxSize);
    }
    return this;
  }

  /**
   * Return current queue contents as an array
   */
  getItems() {
    return this.items;
  }

  /**
   * Set the desired max size
   *
   * Will cause re-adjustment of current queue
   *
   * @param maxSize max number of items allowed
   */
  setMaxSize(maxSize: number) {
    this.maxSize = maxSize;
    this.items = this.items.slice(0, this.maxSize);
    return this;
  }
}

export class LimitedStack<T> {
  private readonly base: T;
  private items: T[];
  private maxSize: number = 10;

  constructor({ base, maxSize = 10 }: { base: T; maxSize?: number }) {
    this.base = base;
    this.items = [];
    this.maxSize = maxSize;
  }

  top(): T {
    if (this.isEmpty()) return this.base;
    const item = this.items[this.items.length - 1];
    if (item === undefined) throw new Error(`LimitedStack is out of sync`);
    return item;
  }

  pop(): T {
    if (this.isEmpty()) return this.base;
    const item = this.items.pop();
    if (item === undefined) throw new Error(`LimitedStack is out of sync`);
    return item;
  }

  push(item: T) {
    this.items = [...this.items, item];
    return this;
  }

  isEmpty() {
    return this.items.length === 0;
  }
}

export class PriorityArray<T, V = unknown> {
  private readonly identityFunc: (item: T) => V;
  private items: T[];

  constructor(identityFunc: (item: T) => V, items: T[] = []) {
    this.identityFunc = identityFunc;
    this.items = [];
    items.forEach(item => this.push(item));
  }

  private findIndex(item: T) {
    return this.items.findIndex(
      (cmp: T) => this.identityFunc(cmp) === this.identityFunc(item)
    );
  }

  get(id: V) {
    const idx = this.items.findIndex((cmp: T) => this.identityFunc(cmp) === id);
    return idx >= 0 ? this.items[idx] : undefined;
  }

  update(updated: T) {
    const idx = this.findIndex(updated);

    if (idx < 0) {
      throw new Error(`Item ${this.identityFunc(updated)} does not exist`);
    }

    const copy = [...this.items];
    copy.splice(idx, 1, updated);
    this.items = copy;
    return this;
  }

  push(item: T) {
    if (this.findIndex(item) >= 0)
      throw new Error(`Item ${this.identityFunc(item)} already exists`);

    this.items = [...this.items, item];
    return this;
  }

  putFirst(item: T) {
    if (this.findIndex(item) >= 0)
      throw new Error(`Item ${this.identityFunc(item)} already exists`);

    this.items = [item, ...this.items];
    return this;
  }

  pushAfter(item: T, previous: T) {
    if (this.findIndex(item) >= 0)
      throw new Error(`Item ${this.identityFunc(item)} already exists`);

    const previousIndex = this.findIndex(previous);

    if (previousIndex < 0) throw new Error(`Previous not found`);

    if (previousIndex === this.items.length - 1) return this.push(item);

    const copy = [...this.items];

    this.items = [
      ...copy.slice(0, previousIndex + 1),
      item,
      ...copy.slice(previousIndex + 1),
    ];

    return this;
  }

  remove(item: T) {
    const idx = this.findIndex(item);
    if (idx < 0) return this;

    const copy = [...this.items];
    copy.splice(idx, 1);
    this.items = copy;

    return this;
  }

  isFirst(item: T) {
    return this.findIndex(item) === 0;
  }

  isLast(item: T) {
    return this.findIndex(item) === this.items.length - 1;
  }

  up(item: T) {
    const idx = this.findIndex(item);
    if (idx === 0) return this;

    const copy = [...this.items];
    // swap with item before
    [copy[idx - 1], copy[idx]] = [copy[idx], copy[idx - 1]];
    this.items = copy;

    return this;
  }

  down(item: T) {
    const idx = this.findIndex(item);
    if (idx === this.items.length - 1) return this;

    const copy = [...this.items];

    // swap with item after
    [copy[idx + 1], copy[idx]] = [copy[idx], copy[idx + 1]];
    this.items = copy;

    return this;
  }

  list() {
    return this.items;
  }

  clone() {
    return new PriorityArray<T, V>(this.identityFunc, this.items);
  }
}

interface Linkable {
  id: string;
  next?: string;
}

interface DoublyLinkable {
  id: string;
  prev: string;
  next?: string;
}

export class NodeList<T extends Linkable, V extends DoublyLinkable> {
  private first: T;
  private items: PriorityArray<V, string>;

  constructor(first: T) {
    this.first = first;
    this.items = new PriorityArray<V, string>(item => item.id);
  }

  private updateItem(item: V | T) {
    if (item.id === this.first.id) {
      this.first = item as T;
    } else {
      this.items.update(item as V);
    }
  }

  private link(prev: V | T, next?: V) {
    if (next !== undefined) {
      prev.next = next.id;
      next.prev = prev.id;
    } else {
      prev.next = undefined;
    }
  }

  private getOrThrow(id: string): V {
    const current = this.items.get(id);
    if (current === undefined) throw new Error(`Node not found with id ${id}`);
    return current;
  }

  get(id: string): V | undefined {
    return this.items.get(id);
  }

  add(node: V) {
    const next = node.next !== undefined ? this.get(node.next) : undefined;

    if (node.prev === this.first.id) {
      this.link(this.first, node);
      this.link(node, next);
      this.items.putFirst(node);
    } else {
      const prev = this.getOrThrow(node.prev);
      this.link(prev, node);
      this.link(node, next);
      this.items.pushAfter(node, prev);
    }
  }

  remove(node: V) {
    const next = node.next !== undefined ? this.get(node.next) : undefined;

    if (node.prev === this.first.id) {
      this.link(this.first, next);
    } else {
      const prev = this.getOrThrow(node.prev);
      this.link(prev, next);
    }

    this.items.remove(node);
  }

  getFirst(): T {
    return this.first;
  }

  getLast(): V {
    return this.items.list()[this.items.list().length - 1];
  }

  updateFirst(node: T) {
    if (this.first.next !== node.next)
      throw new Error(
        `Changing 'first.next' is not allowed - change items instead`
      );
    this.first = node;
  }

  update(node: V) {
    const current = this.getOrThrow(node.id);

    this.remove(current);
    this.add(node);
  }

  list(until?: string): (T | V)[] {
    if (until !== undefined) {
      const idx = this.items.list().findIndex(item => item.id === until);
      return [this.first, ...this.items.list().slice(0, idx + 1)];
    }
    return [this.first, ...this.items.list()];
  }

  up(node: V) {
    if (node.prev === this.first.id) return;

    const prev = this.getOrThrow(node.prev);
    const prevParent =
      prev.prev === this.first.id ? this.first : this.getOrThrow(prev.prev);

    const next = node.next ? this.get(node.next) : undefined;

    this.link(prevParent, node);
    this.link(node, prev);
    this.link(prev, next);

    this.updateItem(prevParent);
    this.updateItem(node);
    this.updateItem(prev);
    if (next !== undefined) this.updateItem(next);

    this.items.up(node);
  }

  down(node: V) {
    if (node.next === undefined) return;

    const prev =
      node.prev === this.first.id ? this.first : this.getOrThrow(node.prev);
    const next = this.getOrThrow(node.next);
    const nextChild = next?.next ? this.get(next.next) : undefined;

    this.link(prev, next);
    this.link(next, node);
    this.link(node, nextChild);

    this.items.down(node);
  }
}
