import { useContext } from 'react';

import { SignedInUser } from '@apus/common-lib/api/interface/tenant-service';

import { UserContext } from '../state/userContext';

function useUser(): SignedInUser | undefined {
  const [state] = useContext(UserContext);

  return state.user;
}

export default useUser;
