import { JSX } from 'react';
import { Routes, Route } from 'react-router-dom';

import ActionsLiveView from './ActionsLiveView';
import ActionsSearchView from './ActionsSearchView';
import useSetAppBarRoutes from '../../../hooks/useSetAppBarRoutes';
import { monitorRoutes } from '../../../routes/service-provider-routes';

const ActionsMonitorView = (): JSX.Element => {
  useSetAppBarRoutes(monitorRoutes);

  return (
    <Routes>
      <Route path="/" element={<ActionsLiveView />} />
      <Route path="/search" element={<ActionsSearchView />} />
    </Routes>
  );
};

export default ActionsMonitorView;
