import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SystemglueConfiguration } from '@apus/common-lib/integrations/src/internal/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';

const AWSConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<SystemglueConfiguration>();

  useEffect(() => {
    form.reset({
      apiKeyHeader: 'x-api-key',
      apiKey: '',
    });

    if (moduleConfiguration !== undefined) {
      form.reset(
        moduleConfiguration.configuration as unknown as SystemglueConfiguration
      );
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (formData: SystemglueConfiguration) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  const onCopyExternalId = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const value = form.getValues('apiKey');
    if (value !== undefined) {
      await navigator.clipboard.writeText(String(value));
    }
  };

  return (
    <Grid container>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Systemglue -interface
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="apiKeyHeader"
            form={form}
            label="Name of the HTTP header in which the api key will be provided"
            defaultValue={''}
            helperText={'This will be used for authorizing incoming webhooks'}
            disabled={!edit}
          />
          <Box>
            <HookFormTextInput
              name="apiKey"
              form={form}
              label="Api key"
              defaultValue={''}
              helperText={'This will be used for authorizing incoming webhooks'}
              disabled={!edit}
            />
            <IconButton
              aria-label="Copy api key to clipboard"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={onCopyExternalId}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Stack>
      </Grid>
      <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
        Save
      </Button>
    </Grid>
  );
};

export default AWSConfigurationForm;
