import React, { useEffect, useState, JSX } from 'react';
import { UseFormReturn } from 'react-hook-form';

import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack } from '@mui/material';

import { EditableSchemaItem } from './interface';

interface Props {
  form: UseFormReturn<EditableSchemaItem>;
  readOnly?: boolean;
}

const NumberForm = ({ form, readOnly }: Props): JSX.Element => {
  const [isEnum, setIsEnum] = useState<boolean>(false);

  useEffect(() => {
    setIsEnum(form.getValues('useEnum') ?? false);
  }, [form, setIsEnum]);

  return (
    <Stack spacing={2}>
      <HookFormTextInput
        name="default"
        disabled={readOnly}
        form={form}
        type={'number'}
        label="Default value"
        defaultValue={''}
      />
      <HookFormCheckbox
        name="useEnum"
        disabled={readOnly}
        form={form}
        label={'Is this an enum?'}
        defaultChecked={false}
        onValueChange={value => setIsEnum(value ?? false)}
      />
      <HookFormTextInput
        name="enum"
        form={form}
        type="number"
        multiline={true}
        minRows={5}
        label="Enum values - strings separated by line break"
        defaultValue={''}
        disabled={readOnly || !isEnum}
      />
    </Stack>
  );
};

export default NumberForm;
