import { JSX } from 'react';
import Logo from '../images/systemglue-black1.png';
import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';

const Landing = (): JSX.Element => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        marginTop={'10%'}
        flexDirection={'row'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box
          component="img"
          sx={{
            height: 64,
          }}
          alt="Systemglue"
          src={Logo}
        />
      </Grid>
      <Grid
        item
        xs={12}
        marginTop={1}
        flexDirection={'row'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography variant={'h5'}>Our flow will make you grow</Typography>
      </Grid>
    </Grid>
  );
};

export default Landing;
