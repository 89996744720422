import React, { JSX, useState } from 'react';

import {
  IntegrationWorkflowDefinition,
  WorkflowNodeResult,
} from '@apus/common-lib/api/interface/integration-service';
import { EventContext } from '@apus/common-lib/integrations/src/interface';
import { tryToGetFinalResult } from '@apus/common-lib/utils/src/data-utils';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Box } from '@mui/material';

import WorkflowNode from './WorkflowNode';
import WorkflowNodeResultViewer from './WorkflowNodeResult';
import WorkflowNodeStatusIcon from './WorkflowNodeStatusIcon';

interface Props {
  workflow: IntegrationWorkflowDefinition;
  eventContext?: EventContext;
  results: WorkflowNodeResult[];
}

const WorkflowResult = ({
  workflow,
  eventContext,
  results,
}: Props): JSX.Element => {
  const [expandedNodeId, setExpandedNodeId] = useState<string | undefined>();

  const triggerResults = results.filter(
    r => r.workflowNodeId === workflow.trigger.id
  );

  return (
    <Box>
      <Timeline
        sx={{
          padding: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <WorkflowNode
          node={workflow.trigger}
          onExpand={id => setExpandedNodeId(id)}
          onCollapse={() => setExpandedNodeId(undefined)}
          expanded={expandedNodeId === workflow.trigger.id}
          key={workflow.trigger.id}
          disabled={triggerResults.length === 0}
          statusIcon={
            <WorkflowNodeStatusIcon
              sx={{ fontSize: '0.9rem' }}
              status={tryToGetFinalResult(triggerResults)?.status}
            />
          }
        >
          <WorkflowNodeResultViewer
            node={workflow.trigger}
            workflowResults={triggerResults}
          />
        </WorkflowNode>

        {workflow.nodes?.map(node => {
          const nodeResults = results.filter(r => r.workflowNodeId === node.id);

          return (
            <WorkflowNode
              node={node}
              onExpand={id => setExpandedNodeId(id)}
              onCollapse={() => setExpandedNodeId(undefined)}
              expanded={expandedNodeId === node.id}
              key={node.id}
              disabled={nodeResults.length === 0}
              statusIcon={
                <WorkflowNodeStatusIcon
                  sx={{ fontSize: '0.9rem' }}
                  status={tryToGetFinalResult(nodeResults)?.status}
                />
              }
            >
              <WorkflowNodeResultViewer
                eventContext={eventContext}
                node={node}
                workflowResults={nodeResults}
              />
            </WorkflowNode>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default WorkflowResult;
