import { useEffect, useState } from 'react';

import { nodeHandlerFactory } from '@apus/common-lib/integration-engine/src/handler-factory';
import {
  DispatchNode,
  FilterCondition,
  InputTransformations,
  OperationNode,
  SchemaChrootConfiguration,
} from '@apus/common-lib/integration-engine/src/interface';
import { Grid } from '@mui/material';
import { JSONSchema7 } from 'json-schema';

import DefineChroot from './DefineChroot';
import DefineIterate from './DefineIterate';
import DefineFilter from './DefineFilter';

interface Props {
  integrationContextSchema: JSONSchema7;
  node?: OperationNode | DispatchNode;
  onChange: (inputTransformations?: InputTransformations) => void;
}

const DefineInputTransformations = ({
  integrationContextSchema,
  node,
  onChange,
}: Props): JSX.Element => {
  const [chroot, setChroot] = useState<SchemaChrootConfiguration | undefined>(
    undefined
  );
  const [iterate, setIterate] = useState<boolean | undefined>(undefined);
  const [filter, setFilter] = useState<FilterCondition | undefined>(undefined);

  const [schemaAfterChroot, setSchemaAfterChroot] = useState<
    JSONSchema7 | undefined
  >(undefined);
  const [schemaAfterIterate, setSchemaAfterIterate] = useState<
    JSONSchema7 | undefined
  >(undefined);

  useEffect(() => {
    if (node !== undefined) {
      setChroot(node.inputTransformations?.chroot);
      setIterate(node.inputTransformations?.iterate);
      setFilter(node.inputTransformations?.filter);

      setSchemaAfterChroot(
        nodeHandlerFactory(
          {
            ...node,
            inputTransformations: {
              chroot: node.inputTransformations?.chroot,
            },
          },
          integrationContextSchema
        ).getFullTransformedInputSchema()
      );

      setSchemaAfterIterate(
        nodeHandlerFactory(
          node,
          integrationContextSchema
        ).getFullTransformedInputSchema()
      );
    }
  }, [node, setChroot, setIterate, setFilter, integrationContextSchema]);

  if (node === undefined) return <></>;

  return (
    <Grid container>
      <Grid item xs={5}>
        <DefineChroot
          schema={integrationContextSchema}
          chroot={chroot}
          onChange={chroot =>
            onChange({ ...node.inputTransformations, chroot })
          }
        />
      </Grid>
      <Grid item xs={5}>
        <DefineIterate
          schema={schemaAfterChroot}
          iterate={iterate}
          transformedSchema={schemaAfterIterate}
          onChange={iterate =>
            onChange({ ...node.inputTransformations, iterate })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <DefineFilter
          iterate={iterate}
          transformedSchema={schemaAfterIterate}
          onChange={filter =>
            onChange({ ...node.inputTransformations, filter })
          }
          filter={filter}
        />
      </Grid>
    </Grid>
  );
};

export default DefineInputTransformations;
