import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack, Typography, Grid } from '@mui/material';

import {
  ModuleConfigurationEdit,
  ModuleConfigurationFormProps,
} from './interface';
import { MicrosoftGraphConfiguration } from '@apus/common-lib/integrations/src/microsoft-graph/interface';
import useTenant from '@apus/common-ui/hooks/useTenant';
import Button from '@mui/material/Button';

const MicrosoftGraphConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<ModuleConfigurationEdit<MicrosoftGraphConfiguration>>();
  const tenant = useTenant();

  useEffect(() => {
    if (moduleConfiguration !== undefined) {
      const configuration =
        moduleConfiguration.configuration as unknown as MicrosoftGraphConfiguration;
      form.reset({
        configuration,
      });
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (
    formData: ModuleConfigurationEdit<MicrosoftGraphConfiguration>
  ) => {
    if (tenant === undefined)
      throw new Error(`Can not submit - no tenant defined`);
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId: moduleId,
      configuration: formData.configuration as unknown as SourceObject,
    };

    await onSave(saved);
  };

  return (
    <Grid container spacing={2}>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Microsoft Graph API -connection for tenant
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="configuration.tokenServerEndpoint"
            form={form}
            label="Token server endpoint"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="configuration.authorizationServerEndpoint"
            form={form}
            label="Authorization server endpoint"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="configuration.tenantId"
            form={form}
            label="Tenant id provided by Microsoft"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="configuration.clientId"
            form={form}
            label="Client id provided by Microsoft"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="configuration.clientSecret"
            form={form}
            label="Client secret provided by Microsoft"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="configuration.scopes"
            form={form}
            label="Scopes separated by space"
            defaultValue={''}
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item>
        <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default MicrosoftGraphConfigurationForm;
