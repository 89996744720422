export default `
declare interface UnknownAppSubscription {
  id: string;
  app: {
    name: "systemglue-integration-app" | "payment-integration-app";
    configuration: {
      [k: string]: unknown | undefined;
    };
  };
  status:
    | "Created"
    | "Configuring"
    | "Configured"
    | "ConfigurationFailed"
    | "Active"
    | "Cancelling"
    | "Cancelled"
    | "Deleted";
  subscriptionDetails: {
    /**
     * User-definable name for the integration
     */
    name?: string;
    disabled?: boolean;
    startDate: string;
    endDate?: string;
    createdDate: string;
    updatedDate: string;
  };
}

declare interface PaymentIntegrationAppSubscription {
  id: string;
  app: {
    name: "payment-integration-app";
    configuration: {
      connections: SystemConnections;
      subsidiary: ERPSubsidiaryDetails;
      bank: BankDetails;
      account: BankAccountDetails;
    };
  };
  status:
    | "Created"
    | "Configuring"
    | "Configured"
    | "ConfigurationFailed"
    | "Active"
    | "Cancelling"
    | "Cancelled"
    | "Deleted";
  subscriptionDetails: {
    /**
     * User-definable name for the integration
     */
    name?: string;
    disabled?: boolean;
    startDate: string;
    endDate?: string;
    createdDate: string;
    updatedDate: string;
  };
}
declare interface SystemConnections {
  erp: "netsuite";
  bank: "osuuspankki";
}
declare interface ERPSubsidiaryDetails {
  subsidiaryId: string;
}
declare interface BankDetails {
  name: string;
  addressLine1: string;
  addressLine2?: string;
  countryCode: string;
}
declare interface BankAccountDetails {
  /**
   * This is used to match incoming payments to configurations
   */
  iban: string;
  bic: string;
  routingNumber?: string;
  organisationIdentification?: string;
}

declare interface SystemglueIntegrationAppSubscription {
  id: string;
  app: {
    name: "systemglue-integration-app";
    configuration: {
      monthlyActions: {
        serviceLevel: "Basic" | "Custom";
        customValue?: number;
      };
      actionStorageDuration: {
        serviceLevel: "Basic" | "Custom";
        customValue?: number;
      };
    };
  };
  status:
    | "Created"
    | "Configuring"
    | "Configured"
    | "ConfigurationFailed"
    | "Active"
    | "Cancelling"
    | "Cancelled"
    | "Deleted";
  subscriptionDetails: {
    /**
     * User-definable name for the integration
     */
    name?: string;
    disabled?: boolean;
    startDate: string;
    endDate?: string;
    createdDate: string;
    updatedDate: string;
  };
}

`;
