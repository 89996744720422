import React, { JSX, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IntegrationActionLifecycleNotification } from '@apus/common-lib/api/interface/integration-service';
import HookFormDateTimePicker from '@apus/common-ui/components/hook-form/HookFormDateTimePicker';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack } from '@mui/material';
import { subHours } from 'date-fns';
import { omit } from 'lodash';

import { SearchActionParams } from '@apus/common-ui/api-client/integration-service';
import HookFormMultiSelect from '@apus/common-ui/components/hook-form/HookFormMultiSelect';
import { AppContext } from '../../state/appContext';
import { ActionStatusNames } from '@apus/common-lib/aws-action-queue/src/interface-public';

interface Props {
  setActions: (actions: IntegrationActionLifecycleNotification[]) => void;
}

const ActionSearchToolbar = ({ setActions }: Props): JSX.Element => {
  const [appState] = useContext(AppContext);

  const integrationService = useIntegrationService();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm<SearchActionParams>({
    defaultValues: {
      from: subHours(new Date(), 1),
      until: new Date(),
      filterByIntegration: [],
      filterByStatus: [],
    },
  });

  const onSearchByMultipleParams = async () => {
    const params = omit(form.getValues(), 'actionId');

    await executeApiCall({
      callFunction: () => integrationService.searchActions(params),
      // todo: error?
      setResult: setActions,
      setPending: setIsLoading,
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
          <Grid item xs={12}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
              <Stack spacing={2} direction={'row'}>
                <HookFormMultiSelect
                  form={form}
                  name={'filterByIntegration'}
                  label={'Filter by integration'}
                  options={appState.integrations}
                  pickValue={option => option.integrationId}
                  pickLabel={option => option.name}
                />
                <HookFormMultiSelect
                  form={form}
                  name={'filterByStatus'}
                  label={'Filter by status'}
                  options={[...ActionStatusNames]}
                  pickValue={option => option}
                  pickLabel={option => option}
                />{' '}
                <HookFormDateTimePicker
                  form={form}
                  name={'from'}
                  label={'From'}
                  sx={{ width: 200 }}
                />
                <HookFormDateTimePicker
                  form={form}
                  name={'until'}
                  label={'To'}
                  sx={{ width: 200 }}
                />
              </Stack>
            </Box>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
          <LoadingButton loading={isLoading} onClick={onSearchByMultipleParams}>
            <SearchIcon />
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ActionSearchToolbar;
