import React, { JSX, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IntegrationDefinition } from '@apus/common-lib/api/interface/integration-service';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';

import DefineIntegration from '../../../components/workflow/timeline/DefineIntegration';
import { AppContext } from '../../../state/appContext';
import { setIntegrations } from '../../../state/appReducer';
import { bodyMaxHeight } from '../../../theme/theme';

const ApiCommandCreateView = (): JSX.Element => {
  const { apiId, groupId } = useParams();
  const navigate = useNavigate();

  const integrationService = useIntegrationService();
  const [appState, dispatch] = useContext(AppContext);

  async function onSave(integration: IntegrationDefinition) {
    if (apiId === undefined || groupId === undefined)
      throw new Error(`Cannot save integration to api - api details missing`);

    await integrationService.addIntegrationToApi(apiId, {
      commandId: uuid(),
      commandName: integration.workflow.trigger.name,
      groupId: groupId,
      integrationId: integration.integrationId,
    });
    dispatch(setIntegrations(await integrationService.listIntegrations()));
  }

  if (appState.integrationModules === undefined)
    return (
      <Box>
        <Typography variant={'subtitle1'}>Loading modules...</Typography>
        <CircularProgress />
      </Box>
    );

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={1}>
        <Box flexGrow={1} flexDirection={'row'} display={'flex'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            aria-label="Back"
            component="label"
            size={'small'}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
      </Grid>
      <Grid item xs={11}></Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <DefineIntegration
          modules={appState.integrationModules}
          integrations={appState.integrations}
          onSave={onSave}
        />
      </Grid>
    </Grid>
  );
};

export default ApiCommandCreateView;
