import { BadRequest } from '../../utils/src/error';
import { TypescriptFunction } from '../../api/interface/files';
import ts, { ModuleKind, ScriptTarget } from 'typescript';

export function typescriptFunctionToString(value: TypescriptFunction): string {
  return JSON.stringify(value);
}

export function typescriptFunctionFromString(
  value: string
): TypescriptFunction {
  return JSON.parse(value) as TypescriptFunction;
}

/**
 * Parse function definition from provided typescript code
 *
 * Note: at the moment, this searches only functions that have format 'function [name](runContext: RunContext, eventContext: EventContext): Output {}'
 *
 * @param typescript code
 * @param functionName function name
 * @param runContextType runContext type name
 * @param eventContextType eventContext type name
 * @param outputType output type name
 */
export function parseFunction(
  typescript: string,
  functionName: string,
  runContextType = 'RunContext',
  eventContextType = 'EventContext',
  outputType = 'Output'
): string {
  const regexpString = `(function ${functionName}[\\s\\S]*?\\([\\s\\S]*?runContext:[\\s\\S]*?${runContextType},[\\s\\S]*?eventContext:[\\s\\S]*?${eventContextType}\\):[\\s\\S]*?${outputType}[\\s\\S]*?\\{[\\s\\S]*\\})`;

  const typescriptFunctionRegex = new RegExp(regexpString, 'gm');

  const parts = typescript
    .split(typescriptFunctionRegex)
    .filter(s => s.trim() !== '');

  const code = parts.find(s => s.includes(`function ${functionName}`));

  if (code === undefined)
    throw new BadRequest(
      `Typescript does not contain function "${functionName}"`,
      { typescript, parsed: parts }
    );

  return code;
}

export function toTypescriptFunction(
  typescriptCode: string
): TypescriptFunction {
  const transpiledCode = ts.transpile(typescriptCode, {
    target: ScriptTarget.ESNext,
    module: ModuleKind.CommonJS,
    allowNonTsExtensions: true,
    noEmit: true,
    strict: true,
  });

  return {
    typescriptVersion: ts.versionMajorMinor,
    typescriptCode: typescriptCode,
    transpiledCode,
  };
}
