import { SupportedModule } from '../../../integrations/src/interface';

export const bankConnections: SupportedModule[] = ['osuuspankki'];
export const erpConnections: SupportedModule[] = ['netsuite'];

// TODO: schema generation doesn't (yet) handle the typeof -solution
type SupportedBank = 'osuuspankki'; //(typeof bankConnections)[number];
type SupportedErp = 'netsuite'; //(typeof erpConnections)[number];

export interface ErpAccount {
  id: string;
}

export enum RuleGroup {
  ERP_ENTITY_IDENTIFICATION_RULE = 'erp-entity-identification-rule',
  ERP_TRANSACTION_IDENTIFICATION_RULE = 'erp-transaction-identification-rule',
  BANK_TRANSACTION_HANDLING_RULE = 'bank-transaction-handling-rule',
}

export enum ErpEntityIdentificationRuleHandler {
  IDENTIFY_ENTITY_AUTOMATICALLY = 'identify-entity-automatically',
  IDENTIFY_ENTITY_BY_EXCEPTION = 'identify-entity-by-exception',
}

export enum ErpTransactionIdentificationRuleHandler {
  IDENTIFY_TRANSACTION_AUTOMATICALLY = 'identify-transaction-automatically',
}

export enum BankTransactionHandlingRuleHandler {
  GENERATE_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION = 'generate-customer-payments-per-identified-transaction',
  GENERATE_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE = 'generate-customer-payments-per-identified-transaction-and-a-journal-entry-of-difference',
  GENERATE_VENDOR_PAYMENT_PER_IDENTIFIED_TRANSACTION = 'generate-vendor-payments-per-identified-transaction',
  GENERATE_VENDOR_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE = 'generate-vendor-payments-per-identified-transaction-and-a-journal-entry-of-difference',
  GENERATED_CONSOLIDATED_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION = 'generate-consolidated-customer-payments-per-identified-transaction',
}

export interface Rule<
  RULE_GROUP extends RuleGroup,
  HANDLER =
    | ErpEntityIdentificationRuleHandler
    | ErpTransactionIdentificationRuleHandler
    | BankTransactionHandlingRuleHandler,
  CONFIGURATION = undefined
> {
  /**
   * Human-readable name for the rule
   */
  name: string;
  /**
   * Human-readable description for the rule
   */
  description?: string;
  /**
   * Internal id (UUID) for the rule
   */
  ruleId: string;
  /**
   * Configure Json Logic rule as the condition
   */
  condition: string;
  /**
   * Is this the default rule
   *
   * Default rules can be overridden by making a customised version of the use case
   */
  isDefault?: boolean;
  /**
   * Rule type
   */
  ruleGroup: RULE_GROUP;
  /**
   * Rule configuration
   */
  configuration?: CONFIGURATION;
  handler: HANDLER;
  disableAutomatedHandling?: boolean;
}

export interface ErpEntityIdentificationRule<
  HANDLER extends ErpEntityIdentificationRuleHandler,
  CONFIGURATION = undefined
> extends Rule<
    RuleGroup.ERP_ENTITY_IDENTIFICATION_RULE,
    HANDLER,
    CONFIGURATION
  > {}

export interface ErpTransactionIdentificationRule<
  HANDLER extends ErpTransactionIdentificationRuleHandler,
  CONFIGURATION = undefined
> extends Rule<
    RuleGroup.ERP_TRANSACTION_IDENTIFICATION_RULE,
    HANDLER,
    CONFIGURATION
  > {}

export interface BankTransactionHandlingRule<
  HANDLER extends BankTransactionHandlingRuleHandler,
  CONFIGURATION = undefined
> extends Rule<
    RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    HANDLER,
    CONFIGURATION
  > {}

export interface BankTransactionRules {
  erpEntityIdentification: ErpEntityIdentificationRule<
    ErpEntityIdentificationRuleHandler,
    any
  >[];
  erpTransactionIdentification: ErpTransactionIdentificationRule<
    ErpTransactionIdentificationRuleHandler,
    any
  >[];
  bankTransactionHandling: BankTransactionHandlingRule<
    BankTransactionHandlingRuleHandler,
    any
  >[];
}

export interface BankTransactionIntegrationAppConfiguration {
  /**
   * @title System connections
   */
  connections: {
    /**
     * @title Used ERP
     */
    erp: SupportedErp;
    /**
     * @title Used bank
     */
    bank: SupportedBank;
  };
  /**
   * @title Bank account details
   */
  account: {
    /**
     * @title Subsidiary's bank account number in IBAN -format
     * @description This is used to match incoming payments to configurations
     */
    iban: string;
    /**
     * @title Subsidiary's bank identification code in BIC -format
     */
    bic: string;
  };
  transactions: {
    referenceFieldName: string;
    referenceFieldName2?: string;
    referenceFieldName3?: string;
    referenceFieldName4?: string;
    referenceFieldName5?: string;
    numberFieldName: string;
    numberFieldName2?: string;
    numberFieldName3?: string;
    numberFieldName4?: string;
    numberFieldName5?: string;
  };
  general: {
    dateFormat: string;
    roundingTolerance?: number;
  };
  rules?: BankTransactionRules;
}

export interface BankTransactionIntegrationConfigurationTrigger {
  subscriptionId: string;
  configuration: BankTransactionIntegrationAppConfiguration;
}
