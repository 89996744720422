import React, { useState, JSX } from 'react';

import { SchemaItem } from '@apus/common-lib/json-data-mapper/src/interface';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import RemoveCircleTwoToneIcon from '@mui/icons-material/RemoveCircleTwoTone';
import { TreeView } from '@mui/lab';
import TreeItem, { TreeItemProps } from '@mui/lab/TreeItem';
import { Box, IconButton, SvgIconProps } from '@mui/material';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

export enum SchemaItemAction {
  AddProperty = 'Add new property',
  OpenProperty = 'Open property',
  DeleteProperty = 'Delete property',
}

type AddButtonTreeItemProps = TreeItemProps & {
  labelIcon?: React.ElementType<SvgIconProps>;
  labelStyle?: SxProps<Theme>;
  readOnly?: boolean;
  edit?: boolean;
  parent?: SchemaItem;
  onAction: (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: SchemaItemAction
  ) => void;
};

const AddButtonTreeItem = (props: AddButtonTreeItemProps): JSX.Element => {
  const { parent, edit, readOnly, onAction, ...other } = props;

  const doAction = (event: React.MouseEvent) => {
    event.stopPropagation();
    onAction(undefined, parent, SchemaItemAction.AddProperty);
  };

  return edit ? (
    <TreeItem
      label={
        <Button
          variant={'text'}
          aria-label="Add property"
          component="label"
          size={'small'}
          disabled={readOnly}
          onClick={doAction}
          startIcon={<AddCircleTwoToneIcon color={'success'} />}
        >
          Add property
        </Button>
      }
      {...other}
    />
  ) : (
    <></>
  );
};

type JsonSchemaTreeItemProps = TreeItemProps & {
  labelIcon?: React.ElementType<SvgIconProps>;
  labelStyle?: SxProps<Theme>;
  edit?: boolean;
  item: SchemaItem;
  parent?: SchemaItem;
  onAction: (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: SchemaItemAction
  ) => void;
  selected: boolean;
};

const SchemaTreeItem = (props: JsonSchemaTreeItemProps): JSX.Element => {
  const { edit, item, parent, selected, onAction, ...other } = props;

  const doAction = (event: React.MouseEvent, action: string) => {
    event.stopPropagation();
    onAction(item, parent, action as SchemaItemAction);
  };

  return (
    <TreeItem
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Typography
            variant="body2"
            sx={{
              fontStyle: item.isRoot ? 'italic' : 'inherit',
              fontWeight: 'inherit',
              color: item.isImmutable ? 'red' : 'initial',
            }}
          >
            {item.isRoot ? 'root' : item.path}
            {item.isRequired ? `*` : ''}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            {selected && (
              <IconButton
                color={'info'}
                aria-label={SchemaItemAction.OpenProperty}
                component="label"
                size={'small'}
                onClick={(e: React.MouseEvent) =>
                  doAction(e, SchemaItemAction.OpenProperty)
                }
                disabled={!edit}
              >
                <Box component={EditTwoToneIcon} color="inherit" />
              </IconButton>
            )}
            {selected && !item.isImmutable && !item.isRequired && (
              <IconButton
                color={'warning'}
                aria-label={SchemaItemAction.DeleteProperty}
                component="label"
                size={'small'}
                onClick={(e: React.MouseEvent) =>
                  doAction(e, SchemaItemAction.DeleteProperty)
                }
                disabled={!edit}
              >
                <Box component={RemoveCircleTwoToneIcon} color="inherit" />
              </IconButton>
            )}
          </Box>

          <Typography variant="caption" color="inherit">
            ({item.isArray ? `${item.type}[]` : item.type})
          </Typography>
        </Box>
      }
      {...other}
    />
  );
};

const JsonSchemaTree = ({
  readOnly = false,
  allowRootModification,
  item,
  edit,
  selectedId,
  onAction,
}: {
  allowRootModification: boolean;
  readOnly?: boolean;
  item: SchemaItem;
  edit?: boolean;
  selectedId?: string;
  onAction: (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: SchemaItemAction
  ) => void;
}): JSX.Element => {
  const renderTree = (item: SchemaItem, parent?: SchemaItem) => {
    const shouldAddButton =
      !readOnly && edit && item.isRoot
        ? true
        : !readOnly && edit && item.type === 'object';

    return (
      <SchemaTreeItem
        key={item.id}
        nodeId={item.id}
        item={{ ...item, ...(readOnly && { isImmutable: true }) }}
        parent={parent}
        edit={edit}
        selected={item.id === selectedId}
        onAction={onAction}
      >
        {shouldAddButton && (
          <AddButtonTreeItem
            key={item.id + '-add'}
            nodeId={item.id + '-add'}
            parent={item}
            edit={edit}
            readOnly={readOnly}
            onAction={onAction}
          />
        )}

        {item.properties !== undefined
          ? Object.values(item.properties).map(child => renderTree(child, item))
          : null}
      </SchemaTreeItem>
    );
  };

  return renderTree(
    item.isRoot ? { ...item, isImmutable: !allowRootModification } : item
  );
};

interface Props {
  allowRootModification?: boolean;
  readOnly?: boolean;
  propertyName?: string;
  items: SchemaItem;
  edit?: boolean;
  onAction: (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: SchemaItemAction
  ) => void;
}

const JsonSchemaTreeView = ({
  allowRootModification = true,
  readOnly = false,
  propertyName,
  items,
  edit,
  onAction,
}: Props): JSX.Element => {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState<string>();

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      id={`tree-view-${propertyName}`}
      sx={{ minHeight: 300, flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}
      multiSelect={false}
      onNodeSelect={(_e, nodeId: string) => setSelectedId(nodeId)}
      onNodeToggle={(_e, expandedNodeIds: string[]) => {
        setExpandedIds(expandedNodeIds);
      }}
      expanded={expandedIds}
    >
      <JsonSchemaTree
        allowRootModification={allowRootModification}
        readOnly={readOnly}
        item={items}
        edit={edit}
        selectedId={selectedId}
        onAction={onAction}
      />
    </TreeView>
  );
};

export default JsonSchemaTreeView;
