import React, { JSX } from 'react';

import {
  IntegrationNodeType,
  RunCondition,
} from '@apus/common-lib/integration-engine/src/interface';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Grid, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import WorkflowNodeIcon from './WorkflowNodeIcon';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '0.9rem', pointerEvents: 'auto' }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

type PartialIntegrationNode = {
  id: string;
  name: string;
  nodeType: IntegrationNodeType;
  internal?: boolean;
  runCondition?: RunCondition;
  disableValidation?: boolean;
};

interface Props {
  disabled?: boolean;
  expanded?: boolean;
  node: PartialIntegrationNode;
  statusIcon?: JSX.Element;
  onExpand?: (nodeId: string) => void;
  onCollapse?: (nodeId: string) => void;
  children?: JSX.Element | JSX.Element[];
}

function isTrigger(node: PartialIntegrationNode) {
  return [
    IntegrationNodeType.WebhookTrigger,
    IntegrationNodeType.PollingTrigger,
    IntegrationNodeType.ApiEndpointTrigger,
  ].includes(node.nodeType);
}

function isConditional(node: PartialIntegrationNode) {
  return node.runCondition !== undefined && node.runCondition.enabled === true;
}

function listNodeConfigurationIcons({
  node,
}: {
  node: PartialIntegrationNode;
}): JSX.Element[] {
  if (node.disableValidation)
    return [<VisibilityOffTwoToneIcon color={'warning'} />];
  return [<VisibilityTwoToneIcon color={'success'} />];
}

const WorkflowNode = ({
  disabled,
  expanded,
  onExpand,
  onCollapse,
  node,
  statusIcon,
  children,
}: Props): JSX.Element => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector
          sx={{ bgcolor: isTrigger(node) ? 'transparent' : 'secondary.main' }}
        />
        <TimelineDot color="primary" variant="outlined">
          <WorkflowNodeIcon node={node} />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Accordion
          expanded={expanded}
          onChange={() => {
            if (!disabled) {
              const shouldExpand = !(expanded ?? false);
              if (shouldExpand && onExpand) onExpand(node.id);
              else if (onCollapse) onCollapse(node.id);
            }
          }}
          sx={{
            borderColor: 'secondary.main',
            border: 1,
            margin: 0,
            padding: 0,
            borderRadius: 2,
          }}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Box flexGrow={1} display={'flex'} alignItems={'center'}>
                  {statusIcon !== undefined && (
                    <Box sx={{ marginRight: 1 }}>{statusIcon}</Box>
                  )}
                  <Typography
                    variant={'body1'}
                    color={disabled ? 'lightgrey' : 'black'}
                    flexGrow={1}
                    display={'flex'}
                  >
                    {isConditional(node) ? `(${node.name})` : node.name}
                  </Typography>
                  <Box marginRight={1} alignItems={'center'}>
                    {listNodeConfigurationIcons({ node }).map(icon => icon)}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
};

export default WorkflowNode;
