import { JSX } from 'react';
import { useForm } from 'react-hook-form';

import {
  ErpTransactionIdentificationRule,
  ErpTransactionIdentificationRuleHandler,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';

import DefineTransactionIdentificationRuleCondition from './condition/DefineTransactionIdentificationRuleCondition';
import RuleBaseForm from './form/RuleBaseForm';

interface Props<T extends ErpTransactionIdentificationRuleHandler> {
  disabled?: boolean;
  rule: ErpTransactionIdentificationRule<T>;
  onChange: (rule: ErpTransactionIdentificationRule<T>) => void;
}

const DefineTransactionIdentificationRule = <
  T extends ErpTransactionIdentificationRuleHandler
>({
  rule,
  disabled,
  onChange,
}: Props<T>): JSX.Element => {
  const form = useForm<ErpTransactionIdentificationRule<T>>();

  return (
    <RuleBaseForm
      rule={rule}
      form={form}
      conditionComponent={DefineTransactionIdentificationRuleCondition}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default DefineTransactionIdentificationRule;
