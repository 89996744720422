import {
  AccessTokenStorage,
  EnvironmentConfiguration,
  Recorder,
  TenantIntegrationClient,
  TriggerNode,
  WorkflowNodeResult,
} from '@apus/common-lib/api/interface/integration-service';
import {
  ApiEndpointTriggerNode,
  ApiResultNode,
  DispatchNode,
  ErrorTriggerNode,
  IntegrationNode,
  IntegrationNodeType,
  OperationNode,
  PollingTriggerNode,
  UUID,
  WebhookTriggerNode,
  WorkflowResultContext,
  WorkflowRunContext,
} from '@apus/common-lib/integration-engine/src/interface';
import { v4 as uuid } from 'uuid';
import { getFinalResult } from '@apus/common-lib/utils/src/data-utils';
import cronParser from 'cron-parser';
import { logRecordFor } from '@apus/common-lib/utils/src/logger';
import { JSONSchema7 } from 'json-schema';
import {
  ApiResultHandler,
  ConnectionResolver,
  EventContext,
  eventContextSchema,
  HandlerResolver,
  IntegrationDispatcher,
  ModuleConfigurations,
  OperationResolver,
  runContextSchema,
} from '@apus/common-lib/integrations/src/interface';
import { nodeHandlerFactory } from '@apus/common-lib/integration-engine/src/handler-factory';
import { TenantSubscriptions } from '@apus/common-lib/api/interface/tenant-service';
import { MappingFunction } from '@apus/common-lib/json-data-mapper/src/interface';
import { BadRequest } from '@apus/common-lib/utils/src/error';
import { NodeList } from '@apus/common-lib/utils/src/collections';

const debug = logRecordFor('integration-engine', 'debug');
const warn = logRecordFor('integration-engine', 'warn');

type RunnableNode = OperationNode | DispatchNode | ApiResultNode;

export class IntegrationEngineBase {
  protected trigger?: TriggerNode;
  protected nodes?: NodeList<TriggerNode, RunnableNode>;

  constructor() {}

  protected addTrigger(node: TriggerNode) {
    if (this.nodes !== undefined) throw new Error('Trigger already defined');
    this.nodes = new NodeList<TriggerNode, RunnableNode>(node);
    this.trigger = node;
    return node;
  }

  protected addNode(node: RunnableNode) {
    if (this.nodes === undefined) throw new Error('Trigger not defined');

    this.nodes.add(node);
    return node;
  }

  protected updateTrigger(node: TriggerNode) {
    if (this.nodes === undefined) throw new Error('Trigger not defined');
    this.nodes.updateFirst(node);
    this.trigger = node;
  }

  protected updateNode(node: RunnableNode) {
    if (this.nodes === undefined) throw new Error('Trigger not defined');
    this.nodes.update(node);
  }

  protected deleteNode(id: string) {
    if (this.nodes === undefined) throw new Error('Trigger not defined');

    const node = this.nodes.get(id);

    if (node === undefined)
      throw new Error(`Cannot delete node - node not found`);

    this.nodes.remove(node);
  }

  getLastNode(): IntegrationNode | undefined {
    if (this.trigger === undefined) return undefined;
    const lastItem = this.nodes?.getLast();
    if (lastItem === undefined) return this.trigger;
    return lastItem;
  }

  isEmpty() {
    return this.nodes?.getFirst() === undefined;
  }

  containsNode(node: IntegrationNode) {
    if (this.nodes === undefined) return undefined;
    if (node.id === undefined || node.id.trim() === '') return false;
    if (this.nodes.getFirst().id === node.id) return true;

    return this.nodes.get(node.id) !== undefined;
  }

  addWebhookTriggerNode(trigger: Omit<WebhookTriggerNode, 'id' | 'nodeType'>) {
    const node = this.addTrigger({
      ...trigger,
      name: 'Trigger',
      nodeType: IntegrationNodeType.WebhookTrigger,
      id: uuid(),
    });
    return [node];
  }

  addPollingTriggerNode(trigger: Omit<PollingTriggerNode, 'id' | 'nodeType'>) {
    const node = this.addTrigger({
      ...trigger,
      nodeType: IntegrationNodeType.PollingTrigger,
      id: uuid(),
    });
    return [node];
  }

  addErrorTriggerNode(trigger: Omit<ErrorTriggerNode, 'id' | 'nodeType'>) {
    const node = this.addTrigger({
      ...trigger,
      name: 'Trigger',
      nodeType: IntegrationNodeType.ErrorTrigger,
      id: uuid(),
    });
    return [node];
  }

  addApiEndpointTriggerNode(
    trigger: Omit<ApiEndpointTriggerNode, 'id' | 'nodeType'>
  ) {
    const node = this.addTrigger({
      ...trigger,
      nodeType: IntegrationNodeType.ApiEndpointTrigger,
      id: uuid(),
    });
    return [node];
  }

  addOperationNode(operation: Omit<OperationNode, 'id' | 'nodeType'>) {
    const node = this.addNode({
      ...operation,
      id: uuid(),
      nodeType: IntegrationNodeType.Operation,
    });

    return [node];
  }

  addDispatchNode(dispatchNode: Omit<DispatchNode, 'id' | 'nodeType'>) {
    const node = this.addNode({
      ...dispatchNode,
      id: uuid(),
      nodeType: IntegrationNodeType.Dispatch,
    });
    return [node];
  }

  addApiResultNode(apiResultNode: Omit<ApiResultNode, 'id' | 'nodeType'>) {
    const node = this.addNode({
      ...apiResultNode,
      id: uuid(),
      nodeType: IntegrationNodeType.ApiResult,
    });
    return [node];
  }

  updateWebhookTriggerNode(trigger: WebhookTriggerNode) {
    this.updateTrigger({
      ...this.trigger,
      ...trigger,
    });
  }

  updateApiEndpointTriggerNode(trigger: ApiEndpointTriggerNode) {
    this.updateTrigger({
      ...this.trigger,
      ...trigger,
    });
  }

  updatePollingTriggerNode(trigger: PollingTriggerNode) {
    this.updateTrigger({
      ...this.trigger,
      ...trigger,
    });
  }

  updateErrorTriggerNode(trigger: ErrorTriggerNode) {
    this.updateTrigger({
      ...this.trigger,
      ...trigger,
    });
  }

  updateOperationNode(node: OperationNode) {
    this.updateNode(node);
  }

  updateDispatchNode(node: DispatchNode) {
    this.updateNode(node);
  }

  updateApiResultNode(node: ApiResultNode) {
    this.updateNode(node);
  }

  deleteOperationNode(node: OperationNode) {
    this.deleteNode(node.id);
  }

  deleteDispatchNode(node: DispatchNode) {
    this.deleteNode(node.id);
  }

  deleteApiResultNode(node: ApiResultNode) {
    this.deleteNode(node.id);
  }

  moveUp(node: RunnableNode) {
    if (node.prev !== this.trigger?.id) this.nodes?.up(node);
  }

  moveDown(node: RunnableNode) {
    if (node.next !== undefined) this.nodes?.down(node);
  }

  isPolling(): boolean {
    return this.trigger?.nodeType === IntegrationNodeType.PollingTrigger;
  }

  nextRuntime(lastRuntime: Date | undefined): Date | undefined {
    if (this.trigger === undefined)
      throw new Error(`Cannot calculate next runtime: no trigger defined`);

    if (this.trigger.nodeType !== IntegrationNodeType.PollingTrigger) {
      warn('Cannot calculate next runtime: wrong trigger type', {
        trigger: this.trigger,
      });
      return undefined;
    }

    debug('Calculating next runtime', {
      schedule: this.trigger.schedule,
      lastRuntime,
    });

    const interval = cronParser.parseExpression(this.trigger.schedule, {
      utc: true,
      currentDate: lastRuntime,
    });

    const next = interval.next().toDate();

    debug('Calculated next runtime', {
      schedule: this.trigger.schedule,
      lastRuntime,
      next: next.toISOString(),
    });

    return next;
  }

  /**
   * Generate the integration context which will be available to the given node
   *
   * This is used to provide accurate data models to operations when defining the integration workflow.
   *
   * @param nodeId id of the given node
   */
  generateIntegrationContextSchema(nodeId?: UUID): JSONSchema7 {
    if (this.trigger === undefined || this.nodes === undefined)
      return runContextSchema;

    return this.nodes.list(nodeId).reduce((res, cur) => {
      return nodeHandlerFactory(cur, res).getFullOutputSchema() ?? res;
    }, runContextSchema);
  }

  /**
   * Traverse full workflow (including trigger)
   */
  traverseFullWorkflow() {
    if (this.nodes === undefined) return [];
    return this.nodes.list();
  }

  /**
   * Traverse workflow (not including trigger)
   */
  traverseWorkflow() {
    const nodes = this.traverseFullWorkflow();
    if (nodes.length <= 1) return [];
    return nodes.slice(1);
  }

  expectedTriggerSchema() {
    if (this.trigger === undefined) return undefined;

    if (this.trigger.nodeType === IntegrationNodeType.PollingTrigger)
      return undefined;

    return nodeHandlerFactory(this.trigger, runContextSchema).getOutputSchema();
  }

  protected async run({
    triggerData,
    operationResolver,
    handlerResolver,
    connectionResolver,
    integrationDispatcher,
    apiResultHandler,
    tenantSubscriptions,
    recorder,
    map,
    eventContext,
    environmentConfiguration,
    accessTokenStorage,
    tenantIntegrationClient,
    results = [],
  }: {
    triggerData: unknown;
    operationResolver: OperationResolver;
    handlerResolver: HandlerResolver;
    connectionResolver: ConnectionResolver;
    integrationDispatcher?: IntegrationDispatcher;
    apiResultHandler: ApiResultHandler;
    tenantSubscriptions?: TenantSubscriptions;
    recorder?: Recorder;
    map: MappingFunction;
    eventContext: EventContext;
    environmentConfiguration: EnvironmentConfiguration;
    accessTokenStorage: AccessTokenStorage;
    tenantIntegrationClient: TenantIntegrationClient;
    results?: WorkflowNodeResult[];
  }): Promise<WorkflowNodeResult[]> {
    debug('run', {
      tenantSubscriptions,
    });
    if (this.nodes === undefined)
      throw new BadRequest('Cannot run workflow - no trigger defined');

    if (this.nodes.list().length <= 1)
      throw new BadRequest('Cannot run workflow - no nodes defined');

    const initialContext: WorkflowResultContext = {
      integrationContext: {
        trigger: triggerData,
        operations: {},
      },
      integrationContextSchema: runContextSchema,
      results,
      status: 'Pending',
    };

    const workflowResultContext = await this.nodes
      .list()
      .reduce<Promise<WorkflowResultContext>>(async (res, cur) => {
        const resultContext: WorkflowResultContext = await res;
        const runContext: WorkflowRunContext = {
          integrationContextSchema: resultContext.integrationContextSchema,
          integrationContext: resultContext.integrationContext,
          eventContext: eventContext,
          eventContextSchema: eventContextSchema,
          results: resultContext.results,
          previousResults: [],
          map,
          operationResolver,
          handlerResolver,
          connectionResolver,
          integrationDispatcher,
          apiResultHandler,
          tenantSubscriptions,
          environmentConfiguration,
          recorder,
          accessTokenStorage,
          tenantIntegrationClient,
        };

        const handler = nodeHandlerFactory(
          cur,
          resultContext.integrationContextSchema
        );

        if (resultContext.status === 'Error') {
          // run through the workflow and let handlers take whatever steps are necessary after error result (most don't
          // do anything)
          await handler.runAfterError(
            runContext,
            getFinalResult(resultContext.results)
          );
          return res;
        }

        return await handler.run(runContext);
      }, Promise.resolve(initialContext));

    return workflowResultContext.results;
  }

  async runWorkflow({
    triggerData,
    operationResolver,
    handlerResolver,
    integrationDispatcher,
    apiResultHandler,
    tenantSubscriptions,
    recorder,
    map,
    eventContext,
    environmentConfiguration,
    accessTokenStorage,
    tenantIntegrationClient,
    connectionResolver,
  }: {
    triggerData?: unknown;
    operationResolver: OperationResolver;
    handlerResolver: HandlerResolver;
    integrationDispatcher?: IntegrationDispatcher;
    apiResultHandler: ApiResultHandler;
    tenantSubscriptions?: TenantSubscriptions;
    recorder?: Recorder;
    map: MappingFunction;
    eventContext: EventContext;
    environmentConfiguration: EnvironmentConfiguration;
    accessTokenStorage: AccessTokenStorage;
    tenantIntegrationClient: TenantIntegrationClient;
    connectionResolver: ConnectionResolver;
  }): Promise<WorkflowNodeResult[]> {
    if (this.trigger === undefined)
      throw new BadRequest('Cannot run webhook trigger - no trigger defined');

    return await this.run({
      triggerData,
      operationResolver,
      handlerResolver,
      connectionResolver,
      integrationDispatcher,
      apiResultHandler,
      tenantSubscriptions,
      recorder,
      map,
      eventContext,
      environmentConfiguration,
      accessTokenStorage,
      tenantIntegrationClient,
      results: [],
    });
  }
}
