import { useMemo } from 'react';

import { BadRequest } from '@apus/common-lib/utils/src/error';

import useAxios from '../hooks/useAxios';
import {
  createManagementService,
  ManagementService,
} from '../api-client/management-service';

const ErrorThrowingManagementService = (
  message: string
): ManagementService => ({
  createIntegrationOperation() {
    throw new BadRequest(message);
  },
  createIntegrationOperations() {
    throw new BadRequest(message);
  },
  getIntegrationOperation() {
    throw new BadRequest(message);
  },
  listIntegrationOperations() {
    throw new BadRequest(message);
  },
  listBaseModules() {
    throw new BadRequest(message);
  },
  listPrototypeOperations() {
    throw new BadRequest(message);
  },
  updateIntegrationOperation() {
    throw new BadRequest(message);
  },
  getErrorWorkflow() {
    throw new BadRequest(message);
  },
  upsertErrorWorkflow() {
    throw new BadRequest(message);
  },
  parseOpenAPIDocument() {
    throw new BadRequest(message);
  },
});

function useManagementService(): ManagementService {
  const axiosInstance = useAxios();

  return useMemo(() => {
    if (axiosInstance === undefined)
      return ErrorThrowingManagementService(
        'Axios instance could not be defined'
      );

    return createManagementService(axiosInstance);
  }, [axiosInstance]);
}

export default useManagementService;
