import React, { JSX, useContext, useState } from 'react';

import {
  ErrorHandlingWorkflowDefinition,
  IntegrationWorkflowDefinition,
} from '@apus/common-lib/api/interface/integration-service';
import { IntegrationNodeType } from '@apus/common-lib/integration-engine/src/interface';
import useApiCallHook from '@apus/common-ui/hooks/useApiCallHook';
import useManagementService from '@apus/common-ui/hooks/useManagementService';
import { Alert, AlertColor, AlertTitle, Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import ShowApiCallStatus from '../../../components/alert/ShowApiCallStatus';
import DefineWorkflow from '../../../components/workflow/timeline/DefineWorkflow';
import { AppContext } from '../../../state/appContext';
import { bodyMaxHeight } from '../../../theme/theme';

interface StatusAlert {
  severity: AlertColor;
  message: string;
}

const ErrorHandlingView = (): JSX.Element => {
  const managementService = useManagementService();
  const [appState] = useContext(AppContext);
  const [updatedWorkflow, setUpdatedWorkflow] = useState<
    ErrorHandlingWorkflowDefinition | undefined
  >();
  const [alert, setAlert] = useState<StatusAlert | undefined>(undefined);
  const [isLoadingDefinition, definition, definitionLoadingError] =
    useApiCallHook<ErrorHandlingWorkflowDefinition | undefined>(
      managementService.getErrorWorkflow,
      []
    );

  const onWorkflowChanged = (updated: IntegrationWorkflowDefinition) => {
    if (updated.trigger.nodeType !== IntegrationNodeType.ErrorTrigger)
      throw new Error(`Wrong trigger type ${updated.trigger.nodeType}`);
    setUpdatedWorkflow(updated as ErrorHandlingWorkflowDefinition);
  };

  const onSave = async () => {
    if (updatedWorkflow !== undefined) {
      await managementService.upsertErrorWorkflow(updatedWorkflow);
      setAlert({ severity: 'success', message: `Integration saved` });
    }
  };

  if (isLoadingDefinition || definitionLoadingError)
    return (
      <ShowApiCallStatus
        infoText={'loading workflow definition'}
        loading={isLoadingDefinition}
        error={definitionLoadingError}
      />
    );

  if (
    appState.integrationModules !== undefined &&
    appState.integrationModules.length === 0
  )
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          No integration modules defined.
        </Alert>
      </Box>
    );

  return (
    <Grid
      container
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={12}>
        {alert !== undefined && (
          <Alert severity={alert.severity}>{alert.message}</Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <DefineWorkflow
          modules={appState.integrationModules}
          integrations={appState.integrations}
          workflow={definition}
          onChange={onWorkflowChanged}
        />
      </Grid>
      <Grid item xs={10} />
      <Grid item>
        <Button disabled={updatedWorkflow === undefined} onClick={onSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorHandlingView;
