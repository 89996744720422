import { MapperRoute } from '../components/dashboard/SideNavItems';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AppsTwoToneIcon from '@mui/icons-material/AppsTwoTone';
import * as React from 'react';

export const monitorRoutes = [
  {
    text: 'Live feed',
    path: '/monitor',
  },
  {
    text: 'Search actions',
    path: '/monitor/search',
  },
];

export const tenantRoutes: MapperRoute[] = [
  {
    text: 'Connections',
    path: '/connections',
    icon: <AppsTwoToneIcon />,
  },
  {
    text: 'View monitor',
    path: '/monitor',
    icon: <MonitorHeartOutlinedIcon />,
    subRoutes: monitorRoutes,
  },
];
