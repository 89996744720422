import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { NetsuiteConfiguration } from '@apus/common-lib/integrations/src/netsuite/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack, Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';
import Collapsible from '../../surface/Collapsible';

const NetsuiteConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
  subtitleProps = { variant: 'h6' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<NetsuiteConfiguration>();
  const [isOauth1Enabled, setIsOauth1Enabled] = useState<boolean>(false);

  useEffect(() => {
    if (moduleConfiguration !== undefined) {
      const configuration =
        moduleConfiguration.configuration as unknown as NetsuiteConfiguration;
      form.reset(configuration);
      setIsOauth1Enabled(configuration?.preferOAuth1 ?? false);
    }
  }, [form, moduleConfiguration, setIsOauth1Enabled]);

  const onSubmit = async (formData: NetsuiteConfiguration) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId: moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Oracle Netsuite -connection
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="numberOfConcurrentRequests"
            form={form}
            label="Number of concurrent requests"
            defaultValue={''}
            helperText={
              'How many requests can be concurrently made to Netsuite'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="accountId"
            form={form}
            label="Netsuite account id"
            defaultValue={''}
            helperText={'The account id will be used to define URL endpoints'}
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientId"
            form={form}
            label="Client id generated by Netsuite"
            defaultValue={''}
            helperText={
              'The value of the client id for the integration (see https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_157771733782.html for more information)'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientSecret"
            form={form}
            label="Client secret generated by Netsuite"
            defaultValue={''}
            helperText={
              'The value of client secret generated during the mapping of the connection (see https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_162686838198.html for more information)'
            }
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Collapsible
          title={'OAuth1 configuration'}
          titleTypographyProps={subtitleProps}
        >
          <Stack spacing={2}>
            <HookFormCheckbox
              name={'preferOAuth1'}
              label={'Use OAuth1 -authorization'}
              form={form}
              onValueChange={value => setIsOauth1Enabled(value ?? false)}
              disabled={!edit}
            />
            <HookFormTextInput
              name="tokenId"
              form={form}
              label="Token id generated by Netsuite"
              disabled={!edit || !isOauth1Enabled}
              defaultValue={''}
              helperText={
                'The value of token id generated during the mapping of the connection (see https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_162686838198.html for more information)'
              }
            />
            <HookFormTextInput
              name="tokenSecret"
              form={form}
              label="Token secret generated by Netsuite"
              disabled={!edit || !isOauth1Enabled}
              defaultValue={''}
              helperText={
                'The value of token secret generated during the mapping of the connection (see https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_162686838198.html for more information)'
              }
            />
          </Stack>
        </Collapsible>
      </Grid>
      <Grid item xs={12}>
        <Collapsible
          title={'OAuth2 configuration'}
          titleTypographyProps={subtitleProps}
        >
          <Stack spacing={2}>
            <HookFormTextInput
              name="certificateId"
              form={form}
              label="Certificate id generated by Netsuite"
              defaultValue={''}
              helperText={
                'The value of Certificate id generated during the mapping of the connection (see https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_162686838198.html for more information)'
              }
              disabled={!edit}
            />
            <HookFormTextInput
              name="privateKey"
              form={form}
              multiline={true}
              minRows={10}
              label="Private key in x509-format (e.g. pem, crt)"
              defaultValue={''}
              helperText={
                'The key will be used to sign access token requests as part of the OAuth2 client credentials flow. See https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_162686838198.html for more information'
              }
              disabled={!edit}
            />
          </Stack>
        </Collapsible>
      </Grid>
      <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
        Save
      </Button>
    </Grid>
  );
};

export default NetsuiteConfigurationForm;
