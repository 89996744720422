import { FieldValues, Resolver } from 'react-hook-form';

import {
  IntegrationOperation,
  IntegrationOperationPrototype,
  WorkflowOperation,
  WorkflowOperationType,
} from '@apus/common-lib/integrations/src/interface';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { isValidBIC, isValidIBAN } from 'ibantools';

export function parseNonEmptyValue(value?: string): string | undefined {
  if (value !== undefined && value.trim() !== '') return value;
  return undefined;
}

export function getResolver<T extends FieldValues>(schema: any): Resolver<T> {
  return ajvResolver<T>(schema, {
    formats: {
      iban: {
        validate: (data: string) => {
          return isValidIBAN(data);
        },
      },
      bic: {
        validate: (data: string) => {
          return isValidBIC(data);
        },
      },
    },
  });
}

export function resolveIntegrationOperation(
  workflowOperation: WorkflowOperation,
  operations: IntegrationOperation[]
): IntegrationOperation | undefined {
  if (
    workflowOperation.operationType === WorkflowOperationType.INSTANCE ||
    workflowOperation.operationType === undefined
  ) {
    return operations.find(
      o =>
        o.moduleId === workflowOperation.moduleId &&
        o.operationId === workflowOperation.operationId
    );
  }
  return undefined;
}

export function resolvePrototypeOrThrow(
  operation: {
    prototype: {
      operationId: string;
      moduleId: string;
    };
  },
  prototypes: IntegrationOperationPrototype[]
) {
  // don't use the prototype defined inside integration operation, but instead refresh the prototype
  const prototype = prototypes.find(
    prototype =>
      prototype.moduleId === operation.prototype.moduleId &&
      prototype.operationId === operation.prototype.operationId
  );

  if (prototype === undefined)
    throw new Error(
      `Could not resolve integration operation prototype with moduleId ${operation.prototype.moduleId} and operationId ${operation.prototype.operationId}`
    );
  return prototype;
}

export function resolvePrototypeForWorkflowOperationOrThrow(
  workflowOperation: WorkflowOperation,
  operations: IntegrationOperation[],
  prototypes: IntegrationOperationPrototype[]
): IntegrationOperationPrototype {
  if (
    workflowOperation.operationType === WorkflowOperationType.INSTANCE ||
    workflowOperation.operationType === undefined
  ) {
    const integrationOperation = resolveIntegrationOperation(
      workflowOperation,
      operations
    );

    if (integrationOperation === undefined)
      throw new Error(
        `Could not resolve integration operation with moduleId ${workflowOperation.moduleId} and operationId ${workflowOperation.operationId}`
      );

    // don't use the prototype defined inside integration operation, but instead refresh the prototype
    return resolvePrototypeOrThrow(integrationOperation, prototypes);
  }
  return resolvePrototypeOrThrow(workflowOperation, prototypes);
}
