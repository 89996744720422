import { SignedInUser } from '@apus/common-lib/api/interface/tenant-service';

import { UserState, initialUserState } from './userContext';

export enum UserActionEnum {
  SetUser = 'setUser',
}

export type UserAction = {
  type: UserActionEnum.SetUser;
  user: SignedInUser | undefined;
};

/**
 * Set the currently signed-in user
 *
 * @param user
 */
export const setUser = (user: SignedInUser | undefined): UserAction => {
  return {
    type: UserActionEnum.SetUser,
    user,
  };
};

export type UserReducerContext = [UserState, React.Dispatch<UserAction>];

export const defaultDispatch: React.Dispatch<UserAction> = () =>
  initialUserState;

export const userContextReducer = (
  state: UserState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case UserActionEnum.SetUser: {
      console.log(`Set user: ${JSON.stringify(action.user)}`);
      return {
        ...state,
        user: action.user,
      };
    }
    default:
      return state;
  }
};
