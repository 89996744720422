import { JSX } from 'react';
import { useForm } from 'react-hook-form';

import {
  ErpEntityIdentificationRule,
  ErpEntityIdentificationRuleHandler,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';

import DefineEntityIdentificationRuleCondition from './condition/DefineEntityIdentificationRuleCondition';
import RuleBaseForm from './form/RuleBaseForm';

interface Props<T extends ErpEntityIdentificationRuleHandler> {
  rule: ErpEntityIdentificationRule<T>;
  onChange: (rule: ErpEntityIdentificationRule<T>) => void;
  disabled?: boolean;
}

const DefineEntityIdentificationRule = <
  T extends ErpEntityIdentificationRuleHandler
>({
  rule,
  disabled,
  onChange,
}: Props<T>): JSX.Element => {
  const form = useForm<ErpEntityIdentificationRule<T>>();

  return (
    <RuleBaseForm
      rule={rule}
      form={form}
      conditionComponent={DefineEntityIdentificationRuleCondition}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default DefineEntityIdentificationRule;
