import { JSX } from 'react';
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';

import { Checkbox, FormControlLabel, CheckboxProps } from '@mui/material';

import { isError } from './util';
import ValidationError from './ValidationError';

type Props<FormType extends FieldValues> = {
  name: string;
  label: string;
  onValueChange?: (value?: boolean) => void;
  form: UseFormReturn<FormType>;
} & Omit<CheckboxProps, 'form' | 'name'>;

const HookFormCheckbox = <FormType extends FieldValues>({
  name,
  label,
  onValueChange,
  form,
  ...otherProps
}: Props<FormType>): JSX.Element => {
  const error = isError(name, form.formState.errors);

  const { defaultValue, ...prunedProps } = otherProps;

  return (
    <Controller
      control={form.control}
      name={name as Path<FormType>}
      defaultValue={
        (defaultValue ?? false) as PathValue<FormType, Path<FormType>>
      }
      key={
        prunedProps.key !== undefined
          ? `${prunedProps.key}-controller`
          : `${name}-controller`
      }
      render={({ field }) => {
        return (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  {...prunedProps}
                  onChange={event => {
                    field.onChange(event);
                    if (onValueChange) onValueChange(event.target.checked);
                  }}
                  checked={
                    field.value === true || prunedProps.defaultChecked === true
                  }
                  key={
                    prunedProps.key !== undefined
                      ? `${prunedProps.key}-control-label-checkbox`
                      : `${name}-control-label-checkbox`
                  }
                />
              }
              key={
                prunedProps.key !== undefined
                  ? `${prunedProps.key}-control-label`
                  : `${name}-control-label`
              }
              label={label}
            />
            {error !== undefined && <ValidationError error={error} />}
          </div>
        );
      }}
    />
  );
};

export default HookFormCheckbox;
