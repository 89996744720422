import { RenderTemplateRequest } from '@apus/common-lib/api/interface/freemarker-service';
import { AxiosInstance } from 'axios';

export interface FreemarkerService {
  render(data: RenderTemplateRequest): Promise<string>;
}

export const createFreemarkerService = (
  client: AxiosInstance
): FreemarkerService => {
  return {
    render(data: RenderTemplateRequest): Promise<string> {
      return client.post<string>(`/templating/render`, data).then(res => {
        return res.data;
      });
    },
  };
};
