import { createContext } from 'react';

import { Tenant } from '@apus/common-lib/api/interface/tenant-service';

import { defaultDispatch, TenantReducerContext } from './tenantReducer';

export interface TenantState {
  tenant?: Tenant;
  tenants: Tenant[];
  lastUpdated: {
    tenants?: Date;
  };
}

export const initialTenantState: TenantState = {
  tenants: [],
  lastUpdated: {},
};

export const TenantContext = createContext<TenantReducerContext>([
  initialTenantState,
  defaultDispatch,
]);
