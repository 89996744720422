import {
  IntegrationDefinition,
  ModuleConfiguration,
} from '@apus/common-lib/api/interface/integration-service';
import { IntegrationModule } from '@apus/common-lib/integrations/src/interface';

import { ActionsSearchResults, AppState, initialAppState } from './appContext';
import { MapperRoute } from '../components/dashboard/SideNavItems';

export enum AppActionEnum {
  SetAppBarRoutes = 'setAppBarRoutes',
  SetIntegrations = 'setIntegrations',
  SetIntegrationModules = 'setIntegrationModules',
  SetIntegrationConnections = 'setIntegrationConnections',
  SetActionsSearchResults = 'setActionsSearchResults',
}

export type AppAction =
  | {
      type: AppActionEnum.SetAppBarRoutes;
      appBarRoutes: MapperRoute[];
    }
  | {
      type: AppActionEnum.SetIntegrations;
      integrations: IntegrationDefinition[];
      updatedAt: Date;
    }
  | {
      type: AppActionEnum.SetIntegrationModules;
      modules: IntegrationModule[];
      updatedAt: Date;
    }
  | {
      type: AppActionEnum.SetIntegrationConnections;
      connections: ModuleConfiguration[];
      updatedAt: Date;
    }
  | {
      type: AppActionEnum.SetActionsSearchResults;
      results: ActionsSearchResults;
    };

export const setAppBarNavItems = (appBarRoutes: MapperRoute[]): AppAction => {
  return {
    type: AppActionEnum.SetAppBarRoutes,
    appBarRoutes,
  };
};

export const setIntegrations = (
  integrations: IntegrationDefinition[],
  updatedAt: Date = new Date()
): AppAction => {
  return {
    type: AppActionEnum.SetIntegrations,
    integrations,
    updatedAt,
  };
};

export const setIntegrationModules = (
  modules: IntegrationModule[],
  updatedAt: Date = new Date()
): AppAction => {
  return {
    type: AppActionEnum.SetIntegrationModules,
    modules,
    updatedAt,
  };
};

export const setIntegrationConnections = (
  connections: ModuleConfiguration[],
  updatedAt: Date = new Date()
): AppAction => {
  return {
    type: AppActionEnum.SetIntegrationConnections,
    connections,
    updatedAt,
  };
};

export const setActionsSearchResults = (
  results: ActionsSearchResults
): AppAction => {
  return {
    type: AppActionEnum.SetActionsSearchResults,
    results,
  };
};

export type AppReducerContext = [AppState, React.Dispatch<AppAction>];

export const defaultDispatch: React.Dispatch<AppAction> = () => initialAppState;

export const appContextReducer = (
  state: AppState,
  action: AppAction
): AppState => {
  switch (action.type) {
    case AppActionEnum.SetAppBarRoutes:
      return {
        ...state,
        appBarRoutes: action.appBarRoutes,
      };
    case AppActionEnum.SetIntegrations: {
      return {
        ...state,
        integrations: [...action.integrations],
        lastUpdated: {
          ...state.lastUpdated,
          integrations: action.updatedAt,
        },
      };
    }
    case AppActionEnum.SetIntegrationConnections: {
      return {
        ...state,
        integrationConnections: [...action.connections],
        lastUpdated: {
          ...state.lastUpdated,
          integrationConnections: action.updatedAt,
        },
      };
    }
    case AppActionEnum.SetIntegrationModules: {
      return {
        ...state,
        integrationModules: [...action.modules],
        lastUpdated: {
          ...state.lastUpdated,
          integrationModules: action.updatedAt,
        },
      };
    }
    default:
      return state;
  }
};
