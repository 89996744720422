import React, {
  JSX,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';

import {
  ExposedApi,
  ExposedApiCommand,
  ExposedApiGroup,
} from '@apus/common-lib/api/interface/exposed-api';
import { IntegrationDefinition } from '@apus/common-lib/api/interface/integration-service';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { getResolver } from '@apus/common-ui/utils/data-utils';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { Box, Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';

import DefineExposedApiCommand from './DefineExposedApiCommand';
import DefineExposedApiGroup from './DefineExposedApiGroup';
import ExposedApiGroupList from './ExposedApiGroupList';
import { AppContext } from '../../state/appContext';
import { setIntegrations } from '../../state/appReducer';
import { bodyMaxHeight } from '../../theme/theme';

type ExposedApiEdit = Partial<Omit<ExposedApi, 'groups'>>;

interface Props {
  api: ExposedApi;
  isNew?: boolean;
}

const DefineExposedApi = ({ api, isNew }: Props): JSX.Element => {
  const [appState, dispatch] = useContext(AppContext);
  const integrationService = useIntegrationService();

  const [needsSaving, setNeedsSaving] = useState<boolean>();
  const [workingApi, setWorkingApi] = useState<ExposedApi | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<
    ExposedApiGroup | undefined
  >(undefined);
  const [selectedCommand, setSelectedCommand] = useState<
    ExposedApiCommand | undefined
  >(undefined);
  const [edit] = useState<boolean>(true);

  const form = useForm<ExposedApiEdit>({
    resolver: getResolver({
      type: 'object',
      properties: {
        apiName: {
          type: 'string',
          minLength: 1,
        },
        apiDescription: {
          type: 'string',
        },
        apiVersion: {
          type: 'string',
        },
      },
      required: ['apiName', 'apiDescription', 'apiVersion'],
    }),
  });

  const listIntegrations = useCallback(
    () => integrationService.listIntegrations(),
    [integrationService]
  );

  useEffect(() => {
    setWorkingApi({ ...api });
    form.setValue('apiName', api.apiName);
    form.setValue('apiDescription', api.apiDescription ?? '');
    form.setValue('apiVersion', api.apiVersion ?? '1.0.0');
    setNeedsSaving(isNew);
  }, [api, form, isNew]);

  useEffect(() => {
    (async () => {
      if (appState.lastUpdated.integrations === undefined) {
        await executeApiCall<IntegrationDefinition[]>({
          callFunction: listIntegrations,
          setResult: integrations => {
            dispatch(setIntegrations(integrations));
          },
        });
      }
    })();
  }, [appState.lastUpdated.integrations, dispatch, listIntegrations]);

  function addGroup() {
    if (workingApi !== undefined) {
      const changed = {
        ...workingApi,
        groups: workingApi.groups.concat([
          {
            groupId: uuid(),
            groupName: '',
            groupDescription: '',
            commands: [],
          },
        ]),
      };
      setWorkingApi(changed);
    }
  }

  function addCommand(groupId: string) {
    if (workingApi !== undefined) {
      const copy = [...workingApi.groups];
      const changedGroup = copy.find(group => group.groupId === groupId);

      if (changedGroup === undefined)
        throw new Error(`Cannot add new command - group ${groupId} not found`);

      changedGroup.commands.push({
        commandId: uuid(),
        commandName: '[New command]',
        commandDescription: '',
      });

      const changed = {
        ...workingApi,
        groups: copy,
      };
      setWorkingApi(changed);
    }
  }
  async function save(data: ExposedApiEdit) {
    if (workingApi !== undefined) {
      await integrationService.storeApi({
        ...workingApi,
        ...data,
      });
    }
  }

  function formChanged() {
    if (workingApi !== undefined) {
      setWorkingApi({
        ...workingApi,
        ...form.getValues(),
      });
    }
  }

  function groupChanged(group: ExposedApiGroup) {
    if (workingApi !== undefined) {
      const index = workingApi.groups.findIndex(
        g => g.groupId === group.groupId
      );
      if (index < 0)
        throw new Error(`Cannot update group - ${group.groupId} not found`);

      const changedGroups = [...workingApi.groups];
      changedGroups.splice(index, 1, group);

      const changed = {
        ...workingApi,
        groups: changedGroups,
      };
      setWorkingApi(changed);
    }
  }

  function groupSelected(groupId: string) {
    if (workingApi !== undefined && selectedGroup?.groupId !== groupId) {
      const group = workingApi.groups.find(g => g.groupId === groupId);
      if (group === undefined)
        throw new Error(`Cannot select group - group ${groupId} not found`);

      setSelectedGroup(group);
    }
  }

  function commandChanged(command: ExposedApiCommand) {
    if (workingApi !== undefined) {
      // find the relevant group first
      const group = workingApi.groups.find(g =>
        g.commands.some(c => c.commandId === command.commandId)
      );

      if (group === undefined)
        throw new Error(
          `Cannot update command - command ${command.commandId} not found in any groups`
        );

      const index = group.commands.findIndex(
        c => c.commandId === command.commandId
      );

      if (index < 0)
        throw new Error(
          `Cannot update command - command ${command.commandId} not found in any groups`
        );

      group.commands[index] = command;

      const changed = {
        ...workingApi,
      };
      setWorkingApi(changed);
    }
  }

  function commandSelected(groupId: string, commandId: string) {
    if (workingApi !== undefined) {
      const group = workingApi.groups.find(g => g.groupId === groupId);
      if (group === undefined)
        throw new Error(`Cannot select command - group ${groupId} not found`);

      const command = group.commands.find(c => c.commandId === commandId);
      if (command === undefined)
        throw new Error(
          `Cannot select command - command ${commandId} not found`
        );

      groupSelected(groupId);
      setSelectedCommand(command);
    }
  }

  if (workingApi === undefined) return <></>;

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={12}>
        <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            aria-label="save"
            component="label"
            size={'small'}
            onClick={form.handleSubmit(save)}
            startIcon={<AddCircleTwoToneIcon color={'success'} />}
          >
            Save
          </Button>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="apiName"
            form={form}
            label="Api name"
            disabled={!edit}
            sx={{
              ...(!edit && { '& fieldset': { border: 'none' } }),
            }}
            onValueChange={formChanged}
          />
          <HookFormTextInput
            name="apiVersion"
            form={form}
            label="Api version"
            disabled={!edit}
            sx={{
              ...(!edit && { '& fieldset': { border: 'none' } }),
            }}
            onValueChange={formChanged}
          />
        </Stack>
      </Grid>
      <Grid item xs={7}>
        <HookFormTextInput
          name="apiDescription"
          form={form}
          label="Api description"
          disabled={!edit}
          fullWidth={true}
          multiline={true}
          rows={4}
          sx={{
            ...(!edit && { '& fieldset': { border: 'none' } }),
          }}
          onValueChange={formChanged}
        />
      </Grid>
      <Grid item xs={12}>
        {!needsSaving && (
          <Grid container>
            <Grid item xs={3}>
              <ExposedApiGroupList
                groups={workingApi.groups}
                onAddGroupClicked={addGroup}
                onAddCommandClicked={addCommand}
                onGroupExpanded={groupId => groupSelected(groupId)}
                onGroupCollapsed={groupId => groupSelected(groupId)}
                onCommandSelected={(groupId, commandId) =>
                  commandSelected(groupId, commandId)
                }
              />
            </Grid>
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={12}>
                  {selectedGroup !== undefined && (
                    <DefineExposedApiGroup
                      group={selectedGroup}
                      onChange={groupChanged}
                    />
                  )}
                </Grid>
                <Grid item xs={12} paddingLeft={2}>
                  {selectedCommand !== undefined &&
                    selectedGroup !== undefined && (
                      <DefineExposedApiCommand
                        apiId={api.apiId}
                        group={selectedGroup}
                        command={selectedCommand}
                        onChange={commandChanged}
                      />
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DefineExposedApi;
