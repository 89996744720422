import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { SupportedSchemaDefinition } from '@apus/common-lib/api/interface/files';
import {
  ConfigurationSchema,
  EmbeddedIntegrationOperation,
  IntegrationOperation,
  IntegrationOperationPrototype,
  SupportedModule,
  SupportedModuleNames,
  WorkflowOperationType,
} from '@apus/common-lib/integrations/src/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import {
  generateConfiguration,
  splitConfigurationSchema,
  toJsonSchemaDefinition,
} from '@apus/common-lib/utils/src/data-utils';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import useTenant from '@apus/common-ui/hooks/useTenant';
import { getResolver } from '@apus/common-ui/utils/data-utils';
import {
  capitalize,
  Drawer,
  FormControl,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { JSONSchema7 } from 'json-schema';

import TestIntegrationOperation from './TestIntegrationOperation';
import ConfigurationSchemaForm from '../input/ConfigurationSchemaForm';
import DefineSchema from '../json/schema/DefineSchema';
import { isEqual } from 'lodash';

interface EmbeddedIntegrationOperationEdit {
  readonly moduleId: string;
  readonly operationId: string;
  title: string;
  description?: string;
  readonly prototype: IntegrationOperationPrototype;
  configuration?: SourceObject;
  runtimeConfiguration?: SourceObject;
  readonly configurationSchema?: ConfigurationSchema;
  inputSchema?: SupportedSchemaDefinition;
  outputSchema?: SupportedSchemaDefinition;
}

/**
 * AJV-compatible validation schema for the form data
 *
 * This is really complicated because ajv has some really annoying design choices
 */
const integrationOperationEditSchema = (
  operation: IntegrationOperationPrototype
): JSONSchema7 => {
  const { configurationSchema } = splitConfigurationSchema(
    operation.configurationSchema
  );

  return {
    type: 'object',
    properties: {
      operationId: {
        type: 'string',
        pattern: '^[a-zA-Z0-9]+$',
        minLength: 4,
        errorMessage: {
          minLength: 'operationId must be at least 4 characters',
          pattern: 'operationId can only contain alpha-numeric characters',
        },
      },
      moduleId: {
        type: 'string',
        minLength: 1,
        errorMessage: {
          minLength: 'moduleId must be at least 1 characters',
        },
      },
      title: {
        type: 'string',
        minLength: 1,
        errorMessage: {
          minLength: 'title must be at least 1 characters',
        },
      },
      description: {
        type: 'string',
        nullable: true,
      },
      configuration: {
        type: 'object',
        ...configurationSchema,
        nullable: configurationSchema === undefined,
      },
    },
    required:
      configurationSchema === undefined
        ? ['operationId', 'title']
        : ['operationId', 'title', 'configuration'],
    additionalProperties: true,
  } as JSONSchema7;
};

interface Props {
  readOnly?: boolean;
  operation?: EmbeddedIntegrationOperation;
  prototype: IntegrationOperationPrototype;
  onChange: (operation: EmbeddedIntegrationOperation) => void;
}

const asIntegrationOperation = (
  data: EmbeddedIntegrationOperationEdit
): IntegrationOperation => {
  return {
    description: data.description ?? '',
    inputSchema: data.inputSchema,
    moduleId: data.moduleId as SupportedModule,
    operationId: data.operationId ?? '',
    outputSchema: data.outputSchema,
    prototype: data.prototype as IntegrationOperationPrototype,
    configuration: data.configuration,
    title: data.title,
  };
};

const DefineEmbeddedIntegrationOperation = ({
  operation,
  readOnly,
  prototype,
  onChange,
}: Props): JSX.Element => {
  const form = useForm<EmbeddedIntegrationOperationEdit>({
    resolver: getResolver(integrationOperationEditSchema(prototype)),
    defaultValues: {
      description: undefined,
      moduleId: undefined,
      operationId: undefined,
      title: undefined,
      inputSchema: undefined,
      outputSchema: undefined,
      prototype: undefined,
      configuration: undefined,
      configurationSchema: undefined,
    },
  });
  const [showTestForm, setShowTestForm] = useState<boolean>(false);
  const [inputSchema, setInputSchema] = useState<
    SupportedSchemaDefinition | undefined
  >();
  const [outputSchema, setOutputSchema] = useState<
    SupportedSchemaDefinition | undefined
  >();
  const tenant = useTenant();

  const [workingOperation, setWorkingOperation] =
    useState<EmbeddedIntegrationOperationEdit>();

  const triggerOnChange = useCallback(() => {
    const changed = form.getValues();
    onChange({
      ...changed,
      operationType: WorkflowOperationType.EMBEDDED,
      inputSchema: changed.inputSchema,
      outputSchema: changed.outputSchema,
    });
  }, []);

  useEffect(() => {
    const { configurationSchema } = splitConfigurationSchema(
      prototype.configurationSchema
    );

    const edit: EmbeddedIntegrationOperationEdit = {
      moduleId: prototype.moduleId,
      operationId: `embedded${capitalize(prototype.operationId)}`,
      prototype: prototype,
      title: operation?.title ?? prototype.title,
      description: operation?.description,
      inputSchema: operation?.inputSchema ?? prototype.inputSchema,
      outputSchema: operation?.outputSchema ?? prototype.outputSchema,
      configuration: generateConfiguration(
        operation?.configuration,
        configurationSchema
      ),
      configurationSchema: configurationSchema,
    };

    form.setValue('moduleId', edit.moduleId);
    form.setValue('operationId', edit.operationId);
    form.setValue('title', edit?.title);
    form.setValue('description', edit?.description);
    form.setValue('prototype', edit.prototype);
    form.setValue('inputSchema', edit.inputSchema);
    form.setValue('outputSchema', edit.outputSchema);
    form.setValue('configuration', edit.configuration);
    form.setValue('configurationSchema', edit.configurationSchema);
    // note: runtimeConfiguration will be defined at the workflow node -handler (i.e. DefineOperationNode or DefinePollingTriggerNode)

    setWorkingOperation(edit);
    setOutputSchema(edit.outputSchema);
    setInputSchema(edit.inputSchema);

    if (!isEqual(workingOperation, edit)) {
      // trigger change event so that all operation controls are initialized
      triggerOnChange();
    }
  }, [
    operation,
    prototype,
    form,
    setWorkingOperation,
    setInputSchema,
    setOutputSchema,
    triggerOnChange,
  ]);

  const onOverrideOutputSchema = (schema: JSONSchema7) => {
    if (schema !== undefined && workingOperation !== undefined) {
      const changed: SupportedSchemaDefinition = {
        ...outputSchema,
        ...toJsonSchemaDefinition(schema),
      };
      setOutputSchema(changed);
      form.setValue('outputSchema', changed);

      triggerOnChange();
    }
  };

  return (
    <Grid container spacing={2}>
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: false,
        }}
        open={showTestForm}
        anchor={'right'}
        onClose={() => setShowTestForm(false)}
        // TODO: instead of using margin, we should bind to another component
        PaperProps={{ sx: { marginTop: '48px' } }}
      >
        {prototype !== undefined && tenant !== undefined && (
          <TestIntegrationOperation
            allowSchemaOverride={!readOnly}
            operation={asIntegrationOperation(form.getValues())}
            tenant={tenant}
            onOverrideOutputSchema={onOverrideOutputSchema}
          />
        )}
      </Drawer>
      <Grid item xs={12}>
        <FormControl fullWidth margin="normal">
          <FormGroup>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Stack spacing={2} direction={'column'}>
                  <HookFormAutocomplete
                    name={'moduleId'}
                    form={form}
                    options={[''].concat(SupportedModuleNames)}
                    disabled={true}
                    label="Operation module"
                    helperText={'Operation is part of this module'}
                    fullWidth={true}
                    onValueChange={triggerOnChange}
                  />
                  <HookFormTextInput
                    name={'operationId'}
                    form={form}
                    disabled={true}
                    label="Operation id"
                    helperText={
                      'Case-sensitive and unique identifier for the operation'
                    }
                    defaultValue={''}
                    fullWidth={true}
                    onValueChange={triggerOnChange}
                  />
                  <HookFormTextInput
                    name={'title'}
                    form={form}
                    disabled={readOnly}
                    label="Name"
                    helperText={'Human-readable name for the operation'}
                    defaultValue={''}
                    fullWidth={true}
                    onValueChange={triggerOnChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={7}>
                <HookFormTextInput
                  name={'description'}
                  form={form}
                  label="Description"
                  helperText={'Operation description'}
                  disabled={readOnly}
                  defaultValue={''}
                  multiline={true}
                  rows={9}
                  fullWidth={true}
                  onValueChange={triggerOnChange}
                />
              </Grid>
            </Grid>
          </FormGroup>
          <FormGroup>
            <Typography variant={'h6'} sx={{ marginTop: 2, marginBottom: 2 }}>
              Data model
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {/* when operation prototype does not require mapping, then input schema is also meaningless */}
                {!prototype.noMapping && (
                  <Controller
                    name={`inputSchema`}
                    control={form.control}
                    render={({ field }) => (
                      <DefineSchema
                        title="Input schema"
                        propertyName={`inputSchema`}
                        mandatorySchema={prototype?.inputSchema ?? undefined}
                        readOnly={readOnly}
                        schema={field.value}
                        onSchemaSave={jsonSchema => {
                          field.onChange(jsonSchema);
                          setInputSchema(jsonSchema);
                          triggerOnChange();
                        }}
                      />
                    )}
                  />
                )}
                {prototype.noMapping && (
                  <Typography>
                    Operation does not require mapping, so input schema is not
                    needed
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`outputSchema`}
                  control={form.control}
                  render={({ field }) => (
                    <DefineSchema
                      allowUndefined={true}
                      propertyName={`outputSchema`}
                      title="Output schema"
                      mandatorySchema={prototype?.outputSchema ?? undefined}
                      readOnly={readOnly}
                      schema={field.value}
                      onSchemaSave={jsonSchema => {
                        field.onChange(jsonSchema);
                        setOutputSchema(jsonSchema);
                        triggerOnChange();
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </FormGroup>
          {workingOperation?.configurationSchema !== undefined && (
            <FormGroup>
              <Typography variant={'h6'} sx={{ marginTop: 2, marginBottom: 2 }}>
                Prototype-based configuration
              </Typography>
              <Stack spacing={2}>
                <ConfigurationSchemaForm
                  readOnly={readOnly}
                  form={form}
                  configurationSchema={workingOperation?.configurationSchema}
                  propertyPath={'configuration'}
                  inputSchema={inputSchema?.content.jsonSchema}
                  outputSchema={outputSchema?.content.jsonSchema}
                  onChange={triggerOnChange}
                />
              </Stack>
            </FormGroup>
          )}
        </FormControl>
      </Grid>
      {!readOnly && (
        <Grid item xs={12}>
          {tenant !== undefined && (
            <Button onClick={() => setShowTestForm(true)}>Test</Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default DefineEmbeddedIntegrationOperation;
