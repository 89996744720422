import { JSX, useState } from 'react';
import * as React from 'react';

import { ExpandMore } from '@mui/icons-material';
import {
  CollapseProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ListItemTextProps } from '@mui/material/ListItemText/ListItemText';

interface Props {
  onExpanded?: () => void;
  onCollapsed?: () => void;
  children?: JSX.Element | JSX.Element[];
  defaultExpanded?: boolean;
  listItemProps?: ListItemProps;
  listItemTextProps?: ListItemTextProps;
  collapseProps?: CollapseProps;
}

const ExpandedIcon = () => <ExpandMore />;
const NotExpandedIcon = () => (
  <ExpandMore sx={{ transform: 'rotate(-90deg)' }} />
);

const CollapsibleListItem = ({
  onExpanded,
  onCollapsed,
  defaultExpanded = false,
  children,
  listItemProps,
  listItemTextProps,
  collapseProps,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  return (
    <div>
      <ListItem {...listItemProps}>
        <ListItemButton
          onClick={() => {
            const isExpanded = !expanded;
            if (isExpanded && onExpanded !== undefined) {
              onExpanded();
            }
            if (!isExpanded && onCollapsed !== undefined) {
              onCollapsed();
            }
            setExpanded(!expanded);
          }}
        >
          <ListItemIcon>
            {expanded ? <ExpandedIcon /> : <NotExpandedIcon />}
          </ListItemIcon>
          <ListItemText {...listItemTextProps} />
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded} timeout="auto" {...collapseProps}>
        {children}
      </Collapse>
    </div>
  );
};

export default CollapsibleListItem;
