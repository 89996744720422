import { createTheme } from '@mui/material/styles';

export const sideNavWidth = 56;
export const appBarHeight = 48;

export const maxHeight = `calc(100vh - ${appBarHeight}px)`;
export const bodyMaxHeight = `calc(100vh - ${appBarHeight * 2}px)`;

export const primaryColor = '#63b996';
export const secondaryColor = '#010130';

const mapperTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryColor,
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ _ownerState, theme }) => ({
          [theme.breakpoints.up('xs')]: {
            minHeight: appBarHeight,
          },
        }),
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: appBarHeight,
    },
  },
});

export default mapperTheme;
