import React, { useCallback, useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { JSONSchema7 } from 'json-schema';

import { FilterCondition } from '@apus/common-lib/integration-engine/src/interface';
import { isEqual } from 'lodash';
import DefineJsonLogicCondition from '../../../input/editor/jsonlogic/DefineJsonLogicCondition';

interface Props {
  iterate: boolean | undefined;
  transformedSchema: JSONSchema7 | undefined;
  onChange: (filter: FilterCondition | undefined) => void;
  filter?: FilterCondition;
}

const DefineFilter = ({
  iterate,
  transformedSchema,
  onChange,
  filter,
}: Props): JSX.Element => {
  const [workingCondition, setWorkingCondition] = useState<FilterCondition>();

  const updateWorkingCondition = useCallback(
    (changed?: FilterCondition) => {
      setWorkingCondition(changed);

      if (!isEqual(filter, changed)) {
        onChange(changed);
      }
    },
    [filter, onChange]
  );

  useEffect(() => {
    updateWorkingCondition(filter);
  }, [filter, updateWorkingCondition]);

  function queryChanged(query: string | undefined) {
    updateWorkingCondition({
      ...workingCondition,
      condition: query,
    });
  }

  function enabledChanged(enabled: boolean) {
    updateWorkingCondition({
      ...filter,
      enabled,
    });
  }

  if (iterate !== true) {
    return <></>;
  }

  return (
    <Grid container>
      <Grid item xs={10}>
        <Typography variant={'body1'}>
          Only items matching this filter are included
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={event => {
                enabledChanged(event.target.checked);
              }}
              checked={filter?.enabled ?? false}
            />
          }
          label={'Condition enabled'}
        />
      </Grid>
      <Grid item xs={12}>
        {workingCondition !== undefined && (
          <DefineJsonLogicCondition
            disabled={filter?.enabled !== true}
            inputSchema={transformedSchema ?? {}}
            condition={workingCondition.condition}
            onChange={queryChanged}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DefineFilter;
