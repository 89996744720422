import React, { JSX, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  SystemglueActionStorageDurationLevel,
  SystemglueAppConfiguration,
  SystemglueMonthlyActionCountLevel,
} from '@apus/common-lib/api/interface/apps/systemglue-integration';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import { SystemglueIntegrationApp } from '@apus/common-lib/api/interface/subscriptions';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack } from '@mui/material';

interface Props {
  pending?: boolean;
  value: SystemglueAppConfiguration;
  edit?: boolean;
  onSave: (
    configuration: SystemglueAppConfiguration,
    subscriptionName?: string
  ) => void;
  onCancel: () => void;
}

export function createEmptySystemglueIntegrationApp(): SystemglueIntegrationApp {
  return {
    name: SystemglueAppName.BASE_INTEGRATION_APP,
    configuration: {
      actionStorageDuration: {
        serviceLevel: SystemglueActionStorageDurationLevel.BASIC,
      },
      monthlyActions: {
        serviceLevel: SystemglueMonthlyActionCountLevel.BASIC,
      },
    },
  };
}

const ConfigureBankingSubscription = ({
  pending,
  value,
  edit = false,
  onSave,
}: Props): JSX.Element => {
  const form = useForm<SystemglueAppConfiguration>();

  const [isCustomActionStorage, setIsCustomActionStorage] =
    useState<boolean>(false);
  const [isCustomMonthlyActions, setIsCustomMonthlyActions] =
    useState<boolean>(false);

  useEffect(() => {
    form.setValue('actionStorageDuration', value.actionStorageDuration);
    form.setValue('monthlyActions', value.monthlyActions);
    setIsCustomActionStorage(
      value.actionStorageDuration.serviceLevel ===
        SystemglueActionStorageDurationLevel.CUSTOM
    );
    setIsCustomMonthlyActions(
      value.monthlyActions.serviceLevel ===
        SystemglueMonthlyActionCountLevel.CUSTOM
    );
  }, [value, form]);

  function onSubmit(configuration: SystemglueAppConfiguration) {
    const subscriptionName = `Systemglue Basic`;
    onSave(configuration, subscriptionName);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Grid container>
            <Grid item xs={4}>
              <HookFormAutocomplete
                name={'actionStorageDuration.serviceLevel'}
                form={form}
                label={'Select action storage -service level'}
                options={Object.values(SystemglueActionStorageDurationLevel)}
                isOptionEqualToValue={(option, value) => option === value}
                disabled={!edit}
                onValueChange={value =>
                  setIsCustomActionStorage(
                    value === SystemglueActionStorageDurationLevel.CUSTOM
                  )
                }
                sx={{ paddingRight: 2 }}
              />
            </Grid>
            <Grid item xs={8}>
              <HookFormTextInput
                name={'actionStorageDuration.customValue'}
                label={'Set custom number of days'}
                form={form}
                type={'number'}
                disabled={!isCustomActionStorage}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <HookFormAutocomplete
                name={'monthlyActions.serviceLevel'}
                label={'Select service level for allowed monthly actions'}
                form={form}
                options={Object.values(SystemglueActionStorageDurationLevel)}
                isOptionEqualToValue={(option, value) => option === value}
                onValueChange={value =>
                  setIsCustomMonthlyActions(
                    value === SystemglueActionStorageDurationLevel.CUSTOM
                  )
                }
                disabled={!edit}
                sx={{ paddingRight: 2 }}
              />
            </Grid>
            <Grid item xs={8}>
              <HookFormTextInput
                name={'monthlyActions.customValue'}
                label={'Set custom number of monthly actions'}
                form={form}
                type={'number'}
                disabled={!isCustomMonthlyActions}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={pending}
          disabled={!edit}
          onClick={form.handleSubmit(onSubmit)}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default ConfigureBankingSubscription;
