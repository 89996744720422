/**
 * Default values and settings for integration-service -related objects
 */
import {
  IntegrationConfiguration,
  IntegrationDefinitionBase,
  RetryStrategy,
} from '../interface/integration-service';

export const defaultIntegrationConfiguration: IntegrationConfiguration = {
  retrying: {
    delay: 30,
    enabled: false,
    retries: 5,
    strategy: RetryStrategy.Fixed,
  },
  debugging: {
    // record and store api calls
    recordApiCalls: true,
  },
  storage: {
    // store actions indefinitely by default
    numberOfDaysToStore: -1,
  },
};

export const defaultIntegrationDefinitionBase: IntegrationDefinitionBase = {
  configuration: defaultIntegrationConfiguration,
  description: '',
  integrationId: '',
  name: '',
};
