import { JSX } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { useAuthenticator } from '@aws-amplify/ui-react';

interface Props {
  requireRole?: string[];
  children: JSX.Element;
}

export function RequireAuth({ children }: Props): JSX.Element {
  const location = useLocation();
  const { route } = useAuthenticator(context => [context.route]);
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
