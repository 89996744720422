import React, { JSX, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  ExposedApiCommand,
  ExposedApiGroup,
} from '@apus/common-lib/api/interface/exposed-api';
import { IntegrationNodeType } from '@apus/common-lib/integration-engine/src/interface';
import { asIntegrationDefinitionReference } from '@apus/common-lib/utils/src/data-utils';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { getResolver } from '@apus/common-ui/utils/data-utils';
import { Grid, Stack } from '@mui/material';
import { isEqual } from 'lodash';

import GenerateApiCommandHook from './GenerateApiCommandHook';
import { AppContext } from '../../state/appContext';
import IntegrationSelector from '../integration/IntegrationSelector';
import CollapsiblePanel from '../surface/CollapsiblePanel';

type ExposedApiCommandEdit = Partial<ExposedApiCommand>;

interface Props {
  apiId: string;
  group: ExposedApiGroup;
  command: ExposedApiCommand;
  onChange: (group: ExposedApiCommand) => void;
}

const DefineExposedApiCommand = ({
  apiId,
  group,
  command,
  onChange,
}: Props): JSX.Element => {
  const [appState] = useContext(AppContext);

  const form = useForm<ExposedApiCommandEdit>({
    resolver: getResolver({
      type: 'object',
      properties: {
        commandName: {
          type: 'string',
          minLength: 1,
        },
        commandDescription: {
          type: 'string',
          minLength: 1,
        },
      },
      required: ['commandName'],
    }),
  });

  useEffect(() => {
    if (!isEqual(command, form.getValues())) {
      form.setValue('commandName', command.commandName);
      form.setValue('commandDescription', command.commandDescription);
      form.setValue('integration', command.integration);
    }
  }, [command, form]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CollapsiblePanel title={command.commandName} defaultExpanded={false}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Stack spacing={2}>
                <HookFormTextInput
                  name="commandName"
                  form={form}
                  label="Api command name"
                  fullWidth
                  onValueChange={value =>
                    onChange({
                      ...command,
                      ...form.getValues(),
                      commandName: value ?? '',
                    })
                  }
                />

                <Controller
                  control={form.control}
                  name={'integration'}
                  render={({ field }) => (
                    <IntegrationSelector
                      name="select-integration"
                      label="Link to integration"
                      onIntegrationSelected={integration => {
                        field.onChange(
                          integration !== undefined
                            ? asIntegrationDefinitionReference(integration)
                            : undefined
                        );
                        onChange({
                          ...command,
                          ...form.getValues(),
                          integration:
                            integration !== undefined
                              ? asIntegrationDefinitionReference(integration)
                              : undefined,
                        });
                      }}
                      displayButton={false}
                      integration={appState.integrations.find(
                        integration =>
                          integration.integrationId ===
                          field.value?.integrationId
                      )}
                      integrations={appState.integrations.filter(integration =>
                        [IntegrationNodeType.ApiEndpointTrigger].includes(
                          integration.workflow.trigger.nodeType
                        )
                      )}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <HookFormTextInput
                name="commandDescription"
                form={form}
                label="Api command description"
                fullWidth
                multiline
                rows={6}
                onValueChange={value =>
                  onChange({
                    ...command,
                    ...form.getValues(),
                    commandDescription: value ?? '',
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CollapsiblePanel title={'Codegen'}>
              <GenerateApiCommandHook
                apiId={apiId}
                groupId={group.groupId}
                command={command}
              />
            </CollapsiblePanel>
          </Grid>
        </CollapsiblePanel>
      </Grid>
    </Grid>
  );
};

export default DefineExposedApiCommand;
