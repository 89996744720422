import { createContext } from 'react';

import {
  IntegrationActionLifecycleNotification,
  IntegrationDefinition,
  ModuleConfiguration,
} from '@apus/common-lib/api/interface/integration-service';
import { IntegrationModule } from '@apus/common-lib/integrations/src/interface';
import { SearchActionParams } from '@apus/common-ui/api-client/integration-service';

import { defaultDispatch, AppReducerContext } from './appReducer';
import { MapperRoute } from '../components/dashboard/SideNavItems';

export interface ActionsSearchResults {
  params: SearchActionParams;
  results: IntegrationActionLifecycleNotification[];
}

export interface AppState {
  appBarRoutes: MapperRoute[];
  integrations: IntegrationDefinition[];
  integrationModules: IntegrationModule[];
  integrationConnections: ModuleConfiguration[];
  searchResults: {
    actions?: ActionsSearchResults;
  };
  lastUpdated: {
    integrations?: Date;
    integrationModules?: Date;
    integrationConnections?: Date;
  };
}

export const initialAppState: AppState = {
  appBarRoutes: [],
  integrations: [],
  integrationModules: [],
  integrationConnections: [],
  searchResults: {},
  lastUpdated: {},
};

export const AppContext = createContext<AppReducerContext>([
  initialAppState,
  defaultDispatch,
]);
