import React, { useEffect, useState, JSX } from 'react';

import { SchemaItem } from '@apus/common-lib/json-data-mapper/src/interface';
import { JsonSchemaManipulationTool } from '@apus/common-lib/json-data-mapper/src/schema-manipulation-tool';
import { Box, Dialog, DialogTitle, Grid } from '@mui/material';
import { JSONSchema7 } from 'json-schema';

import SchemaItemEditor from './item/editor/SchemaItemEditor';
import JsonSchemaTreeView, { SchemaItemAction } from './JsonSchemaTreeView';

interface Props {
  allowRootModification?: boolean;
  readOnly?: boolean;
  propertyName: string;
  mandatorySchema?: JSONSchema7;
  schema?: JSONSchema7;
  edit?: boolean;
  onSchemaChange?: (jsonSchema: JSONSchema7) => void;
  onSchemaItemChange?: (item: SchemaItem) => void;
}

const JsonSchemaEditor = ({
  allowRootModification = true,
  readOnly = false,
  propertyName,
  mandatorySchema,
  schema,
  edit = true,
  onSchemaChange,
  onSchemaItemChange,
}: Props): JSX.Element => {
  const [tool, setTool] = useState<JsonSchemaManipulationTool>(
    new JsonSchemaManipulationTool({
      schema,
      mandatorySchema,
    })
  );
  const [items, setItems] = useState<SchemaItem>();
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SchemaItem | undefined>(
    undefined
  );
  const [selectedParent, setSelectedParent] = useState<SchemaItem | undefined>(
    undefined
  );
  const [action, setAction] = useState<SchemaItemAction | undefined>(undefined);

  useEffect(() => {
    const newTool = new JsonSchemaManipulationTool({
      schema,
      mandatorySchema,
    });
    setTool(newTool);
    setItems(newTool.asItem());
  }, [schema, mandatorySchema]);

  const updateData = () => {
    const updatedItem = tool.asItem();
    const updatedSchema = tool.asSchema();

    setItems(updatedItem);

    if (onSchemaItemChange) onSchemaItemChange(updatedItem);
    if (onSchemaChange) onSchemaChange(updatedSchema);
  };

  const onTreeItemAction = (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: SchemaItemAction
  ) => {
    if (action === SchemaItemAction.DeleteProperty) {
      if (item === undefined)
        throw new Error(`Cannot delete item: item is undefined`);
      tool.deleteItem(item);
      updateData();

      setSelectedItem(undefined);
      setAction(undefined);
    } else {
      setSelectedItem(item);
      setSelectedParent(parent);
      setAction(action);
      setShowEditor(true);
    }
  };

  const onSaveItem = (item: SchemaItem) => {
    if (action === SchemaItemAction.AddProperty) {
      tool.addItem(selectedParent, item);
    }
    if (action === SchemaItemAction.OpenProperty) {
      tool.updateItem(item);
    }
    setShowEditor(false);
    updateData();
  };

  return items !== undefined ? (
    <Box sx={{ border: 0 }}>
      <JsonSchemaTreeView
        readOnly={readOnly}
        allowRootModification={allowRootModification}
        propertyName={propertyName}
        items={items}
        edit={edit}
        onAction={onTreeItemAction}
      />
      <Dialog onClose={() => setShowEditor(false)} open={showEditor}>
        <DialogTitle>{selectedParent?.schemaPath ?? '/'}</DialogTitle>

        <Grid item xs={12} sx={{ padding: 1 }}>
          <SchemaItemEditor
            parentObjectPath={selectedParent?.objectPath}
            parentSchemaPath={selectedParent?.schemaPath}
            item={selectedItem}
            readOnly={readOnly || selectedItem?.isImmutable}
            onSave={onSaveItem}
          />
        </Grid>
      </Dialog>
    </Box>
  ) : (
    <></>
  );
};

export default JsonSchemaEditor;
