import { useContext } from 'react';

import { Tenant } from '@apus/common-lib/api/interface/tenant-service';

import { TenantContext } from '../state/tenantContext';

function useTenant(): Tenant | undefined {
  const [state] = useContext(TenantContext);

  return state.tenant;
}

export default useTenant;
