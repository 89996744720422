import React, { useState, JSX } from 'react';

import { IntegrationActionLifecycleNotification } from '@apus/common-lib/api/interface/integration-service';
import { Drawer, Grid } from '@mui/material';

import ActionSearchToolbar from '../../../components/input/ActionSearchToolbar';
import ActionNotificationList from '../../../components/monitor/ActionNotificationList';
import ActionViewer from '../../../components/monitor/ActionViewer';
import { bodyMaxHeight } from '../../../theme/theme';

const ActionMonitor = (): JSX.Element => {
  const [actions, setActions] = useState<
    IntegrationActionLifecycleNotification[]
  >([]);

  const [action, setAction] = useState<
    IntegrationActionLifecycleNotification | undefined
  >(undefined);

  return (
    <div>
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: false,
        }}
        PaperProps={{
          sx: { minWidth: '90%', padding: 1 },
        }}
        open={action !== undefined}
        anchor={'right'}
        onClose={() => setAction(undefined)}
      >
        <ActionViewer notification={action} />
      </Drawer>
      <Grid
        container
        spacing={2}
        sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
      >
        <Grid item xs={12}>
          <ActionSearchToolbar setActions={setActions} />
        </Grid>
        <Grid item xs={12}>
          <ActionNotificationList
            actions={actions}
            onActionSelected={setAction}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ActionMonitor;
