import { useMemo } from 'react';

import { BadRequest } from '@apus/common-lib/utils/src/error';

import useAxios from '../hooks/useAxios';
import {
  createFreemarkerService,
  FreemarkerService,
} from '../api-client/freemarker-service';

const ErrorThrowingFreemarkerService = (
  message: string
): FreemarkerService => ({
  render() {
    throw new BadRequest(message);
  },
});

function useFreemarkerService(): FreemarkerService {
  const axiosInstance = useAxios();

  return useMemo(() => {
    if (axiosInstance === undefined)
      return ErrorThrowingFreemarkerService(
        'Axios instance could not be defined'
      );

    return createFreemarkerService(axiosInstance);
  }, [axiosInstance]);
}

export default useFreemarkerService;
