import React, { JSX, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ExposedApiGroup } from '@apus/common-lib/api/interface/exposed-api';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { getResolver } from '@apus/common-ui/utils/data-utils';
import { Grid, Stack } from '@mui/material';
import { isEqual } from 'lodash';

import CollapsiblePanel from '../surface/CollapsiblePanel';

type ExposedApiGroupEdit = Partial<Omit<ExposedApiGroup, 'commands'>>;

interface Props {
  group: ExposedApiGroup;
  onChange: (group: ExposedApiGroup) => void;
}

const DefineExposedApiGroup = ({ group, onChange }: Props): JSX.Element => {
  const form = useForm<ExposedApiGroupEdit>({
    resolver: getResolver({
      type: 'object',
      properties: {
        groupName: {
          type: 'string',
          minLength: 1,
        },
        groupDescription: {
          type: 'string',
          minLength: 1,
        },
      },
      required: ['groupName'],
    }),
  });

  useEffect(() => {
    if (!isEqual(group, form.getValues())) {
      form.setValue('groupName', group.groupName);
      form.setValue('groupDescription', group.groupDescription);
    }
  }, [group, form]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CollapsiblePanel title={group.groupName} defaultExpanded={false}>
          <Stack spacing={2}>
            <HookFormTextInput
              name="groupName"
              form={form}
              label="Api group name"
              fullWidth
              onValueChange={value =>
                onChange({ ...group, groupName: value ?? '' })
              }
            />
            <HookFormTextInput
              name="groupDescription"
              form={form}
              label="Api group description"
              fullWidth
              multiline
              rows={6}
              onValueChange={value =>
                onChange({ ...group, groupDescription: value ?? '' })
              }
            />
          </Stack>
        </CollapsiblePanel>
      </Grid>
    </Grid>
  );
};

export default DefineExposedApiGroup;
