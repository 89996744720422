import React from 'react';

import { RequestAuthorizer } from '@apus/common-lib/integrations/src/interface';
import { Autocomplete, TextField } from '@mui/material';

export interface DefineTriggerAuthorizerProps {
  authorizer?: RequestAuthorizer;
  authorizers: RequestAuthorizer[];
  onChange: (authorizer: RequestAuthorizer | undefined) => void;
  disabled?: boolean;
}

/**
 * use empty operation as the initial value for autocomplete so that we don't get those annoying
 * controlled/uncontrolled -state change warnings and errors
 */
const emptyAuthorizer = {
  authorizerId: '',
  description: '',
  moduleId: '',
  name: 'No authorizer',
};

const DefineTriggerAuthorizer = ({
  authorizer,
  authorizers,
  onChange,
  disabled,
}: DefineTriggerAuthorizerProps) => {
  return (
    <Autocomplete
      disablePortal
      disabled={disabled}
      options={[emptyAuthorizer as RequestAuthorizer].concat(authorizers)}
      getOptionLabel={option => {
        return (option as RequestAuthorizer).name;
      }}
      defaultValue={emptyAuthorizer as RequestAuthorizer}
      isOptionEqualToValue={(
        option: RequestAuthorizer,
        value: RequestAuthorizer
      ) =>
        option.authorizerId === value.authorizerId &&
        option.moduleId === value.moduleId
      }
      groupBy={option => option.moduleId}
      value={authorizer ?? (emptyAuthorizer as RequestAuthorizer)}
      renderInput={params => (
        <TextField {...params} label={'Authorize external webhook with'} />
      )}
      onChange={(_, data) => {
        if (onChange !== undefined) onChange(data as RequestAuthorizer);
      }}
    />
  );
};

export default DefineTriggerAuthorizer;
