import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { TenantContext } from '@apus/common-ui/state/tenantContext';
import { setCurrentTenant } from '@apus/common-ui/state/tenantReducer';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SignalWifiStatusbarNullIcon from '@mui/icons-material/SignalWifiStatusbarNull';
import WifiIcon from '@mui/icons-material/Wifi';
import { Box, Grid, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Logo from '../../images/systemglue-white1.png';
import { WebsocketContext } from '../../state/websocketContext';
import useUser from '@apus/common-ui/hooks/useUser';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  drawerOpen?: boolean;
  onOpenDrawer: () => void;
}

export default function MapperAppBar({ drawerOpen }: Props) {
  const theme = useTheme();

  const { signOut } = useAuthenticator(context => [context.signOut]);
  const user = useUser();

  const [tenantState, tenantDispatch] = useContext(TenantContext);
  const [websocketState] = useContext(WebsocketContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path?: string) => {
    setAnchorEl(null);
    if (path !== undefined) navigate(path);
  };

  const logout = () => {
    signOut();
    handleClose();
    navigate('/');
  };

  return (
    <AppBar position="static" open={drawerOpen} color={'secondary'}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Box
              paddingLeft={`calc(${theme.spacing(7)} + 1px)`}
              component="img"
              sx={{
                height: 28,
                marginRight: 2,
              }}
              alt="Systemglue"
              src={Logo}
            />
            {user !== undefined && tenantState.tenant === undefined ? (
              <Typography variant="overline" component="div" color={'orange'}>
                No tenant selected
              </Typography>
            ) : (
              <Typography variant="overline" component="div">
                {tenantState.tenant?.tenantName}
              </Typography>
            )}
          </Grid>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            disabled={websocketState.isOpen || user === undefined}
            onClick={websocketState.openConnection}
            color="inherit"
          >
            {websocketState.isOpen ? (
              <WifiIcon sx={{ color: '#fff' }} />
            ) : (
              <SignalWifiStatusbarNullIcon />
            )}
          </IconButton>
        </Box>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMenuItemClick('login')}>
            Login
          </MenuItem>
        </Menu>

        {user !== undefined && (
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <Typography variant={'subtitle2'}>
                User: {user.userName}
              </Typography>
            </MenuItem>

            <MenuItem
              disabled={tenantState.tenant === undefined}
              onClick={async () => {
                await navigator.clipboard.writeText(
                  String(tenantState.tenant?.tenantId)
                );
              }}
            >
              <Typography variant={'subtitle2'}>
                TenantId: {tenantState.tenant?.tenantId}
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={() => {
                logout();
              }}
            >
              Logout
            </MenuItem>

            <Divider />

            {tenantState.tenants.map(tenant => (
              <MenuItem
                key={tenant.tenantId}
                onClick={() => {
                  tenantDispatch(setCurrentTenant(tenant));
                  handleClose();
                }}
              >
                {tenant.tenantName}
              </MenuItem>
            ))}
          </Menu>
        )}
      </Toolbar>
    </AppBar>
  );
}
