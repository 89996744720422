/**
 * The service provider id for Systemglue Oy
 */
export const SYSTEMGLUE_SERVICE_PROVIDER_ID =
  'ae265b6b-eda7-42d0-afec-d7d83decd218';

/**
 * Name of the Systemglue service provider account
 */
export const SYSTEMGLUE_SERVICE_PROVIDER_NAME = 'Systemglue service provider';

export const SYSTEMGLUE_SERVICE_PROVIDER_BUSINESS_ID = '3365522-2';

export interface ApiError<
  DETAILS_TYPE extends Record<string, unknown> = Record<string, unknown>
> {
  statusCode: number;
  message: string;
  details?: DETAILS_TYPE;
}

export interface HttpApiError<
  DETAILS_TYPE extends Record<string, unknown> = Record<string, unknown>
> extends ApiError<DETAILS_TYPE> {
  method: string;
  url: string;
}

export enum SystemglueAppName {
  BASE_INTEGRATION_APP = 'systemglue-integration-app',
  PAYMENT_INTEGRATION_APP = 'payment-integration-app',
  E_INVOICE_INTEGRATION_APP = 'e-invoice-integration-app',
  BANK_TRANSACTION_INTEGRATION_APP = 'bank-transaction-integration-app',
}
