import React, { JSX } from 'react';

import {
  CustomContentMediaType,
  TypescriptFunction,
  TypescriptFunctionDefinition,
} from '@apus/common-lib/api/interface/files';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import { JSONSchema7 } from 'json-schema';

import {
  SupportedMappingSchemaFileEdit,
  TypescriptFunctionFileEdit,
} from '../../types/stored-files';
import {
  asSchemaDefinition,
  asSchemaDefinitionEdit,
  createSchemaDefinitionEdit,
} from '../../utils/stored-files-helpers';
import DefineTypescriptHandler from '../input/editor/typescript/DefineTypescriptHandler';

interface Props {
  targetSchema: JSONSchema7;
  sourceSchema: JSONSchema7;
  value?: TypescriptFunctionFileEdit;
  onChange: (mappingSchema: TypescriptFunctionFileEdit) => void;
}

/**
 * This is the name of the function expected by the mapper - DON'T CHANGE THIS UNLESS YOU KNOW WHAT TO DO
 */
const functionName = 'map';

const functionCommentTemplate = `/**
 * This function is called when integration context is mapped to operation's input value and must be defined
 */`;

const valueAsTypescriptFunction = (value?: SupportedMappingSchemaFileEdit) => {
  return value?.contentType === CustomContentMediaType.TypescriptFunction
    ? asSchemaDefinition<TypescriptFunctionDefinition>(value).content
    : undefined;
};

const DefineTypescriptMapping = ({
  value,
  sourceSchema,
  targetSchema,
  onChange,
}: Props): JSX.Element => {
  const onFunctionChanged = (changed: TypescriptFunction) => {
    if (value !== undefined) {
      onChange(
        asSchemaDefinitionEdit({
          ...value,
          content: changed,
          contentType: CustomContentMediaType.TypescriptFunction,
        })
      );
    }
    onChange(
      createSchemaDefinitionEdit({
        content: changed as unknown as SourceObject,
        contentType: CustomContentMediaType.TypescriptFunction,
      })
    );
  };

  if (sourceSchema === undefined || targetSchema === undefined) return <></>;

  return (
    <DefineTypescriptHandler
      value={valueAsTypescriptFunction(value)}
      functionName={functionName}
      functionComment={functionCommentTemplate}
      onChange={onFunctionChanged}
      inputSchema={sourceSchema}
      outputSchema={targetSchema}
    />
  );
};

export default DefineTypescriptMapping;
