import React, { useEffect, useState, JSX } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Box } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography/Typography';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

interface Props {
  title: string;
  subtitle?: string;
  disabledSubtitle?: string;
  titleTypographyProps?: TypographyProps;
  secondaryComponent?: React.ReactNode;
  disabled?: boolean;
  defaultExpanded?: boolean;
  unmountOnExit?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const CollapsiblePanel = ({
  title,
  subtitle,
  disabledSubtitle,
  titleTypographyProps = { variant: 'h6' },
  secondaryComponent,
  disabled,
  defaultExpanded = true,
  unmountOnExit = false,
  children,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (disabled) {
      setIsDisabled(true);
      setExpanded(false);
    } else {
      setIsDisabled(false);
      setExpanded(defaultExpanded);
    }
  }, [
    subtitle,
    disabledSubtitle,
    disabled,
    setIsDisabled,
    setExpanded,
    defaultExpanded,
  ]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        if (!isDisabled) setExpanded(!expanded);
      }}
      TransitionProps={{ unmountOnExit }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box display={'flex'} flexGrow={1}>
          <Typography
            {...titleTypographyProps}
            display={'flex'}
            flexGrow={1}
            color={isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
          >
            {title}
          </Typography>
          {secondaryComponent}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CollapsiblePanel;
