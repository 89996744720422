import React, { JSX, useState } from 'react';

import { ApiError } from '@apus/common-lib/api/interface/common';
import {
  AppSubscription,
  SubscriptionStatus,
  SystemglueApp,
} from '@apus/common-lib/api/interface/subscriptions';
import useSubscriptionService from '@apus/common-ui/hooks/useSubscriptionService';
import {
  executeApiCall,
  executePollingApiCall,
} from '@apus/common-ui/utils/api-call';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import ToggleOffTwoToneIcon from '@mui/icons-material/ToggleOffTwoTone';
import ToggleOnTwoToneIcon from '@mui/icons-material/ToggleOnTwoTone';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertColor,
  Box,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';

import ConfigureApp from './ConfigureApp';
import SubscriptionElement from './SubscriptionElement';

//import AutorenewTwoToneIcon from '@mui/icons-material/AutorenewTwoTone';

enum SubscriptionAction {
  SUBSCRIBE = 'Subscribe',
  UNSUBSCRIBE = 'Unsubscribe',
  UPDATE = 'Update',
  DELETE = 'Delete',
}

interface StatusAlert {
  severity: AlertColor;
  message: string;
}

function listAllowedActions(
  subscription: AppSubscription
): SubscriptionAction[] {
  switch (subscription.status) {
    case SubscriptionStatus.CONFIGURATION_FAILED:
    case SubscriptionStatus.CREATED:
      return [
        SubscriptionAction.UPDATE,
        SubscriptionAction.SUBSCRIBE,
        SubscriptionAction.DELETE,
      ];
    case SubscriptionStatus.ACTIVE:
    case SubscriptionStatus.CONFIGURED:
      return [SubscriptionAction.UPDATE, SubscriptionAction.UNSUBSCRIBE];
    case SubscriptionStatus.CANCELLING:
      return [SubscriptionAction.UPDATE];
  }

  return [];
}

interface Props {
  subscription: AppSubscription;
  onChange: (subscription: AppSubscription) => void;
  onDelete: () => void;
}

function ConfigureExistingSubscription({
  subscription,
  onChange,
  onDelete,
}: Props): JSX.Element {
  const subscriptionService = useSubscriptionService();
  const [alert, setAlert] = useState<StatusAlert | undefined>();
  const [action, setAction] = useState<SubscriptionAction | undefined>(
    undefined
  );
  const [apiError, setApiError] = useState<ApiError>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  function onError(message: string) {
    return (error: ApiError | undefined) => {
      setAlert({ message, severity: 'error' });
      setApiError(error);
    };
  }

  function onSuccess(message: string) {
    return (subscription: AppSubscription) => {
      setAlert({ message, severity: 'success' });
      setApiError(undefined);
      onChange(subscription);
    };
  }

  async function onUpdated(app: SystemglueApp, subscriptionName?: string) {
    setAction(SubscriptionAction.UPDATE);
    setApiError(undefined);
    setAlert(undefined);

    await executeApiCall<AppSubscription>({
      callFunction: () =>
        subscriptionService
          .updateSubscription({
            subscriptionId: subscription.id,
            app,
            name: subscriptionName ?? subscription.subscriptionDetails.name,
          })
          .then(result => {
            setAction(undefined);
            return result;
          }),
      setError: onError('Subscription update failed'),
      setPending: setIsLoading,
      setResult: onSuccess('Subscription updated'),
    });
  }

  async function onSubscribed() {
    setIsLocked(true);
    setAction(SubscriptionAction.SUBSCRIBE);
    setApiError(undefined);
    setAlert(undefined);

    // begin the subscription first
    await executeApiCall<AppSubscription>({
      callFunction: () =>
        subscriptionService.beginSubscription({
          subscriptionId: subscription.id,
          app: subscription.app,
        }),
      setError: onError('Initial subscription failed'),
      setPending: setIsLoading,
      setResult: onSuccess('Initial subscription succeeded'),
    });

    // and then start polling for progress
    await executePollingApiCall<AppSubscription>({
      callFunction: () => subscriptionService.getSubscription(subscription.id),
      setError: onError('Subscription handling had an issue'),
      setPending: setIsLoading,
      setResult: onSuccess('Subscription finished'),
      polling: {
        endCondition: result =>
          result.status === SubscriptionStatus.ACTIVE ||
          result.status === SubscriptionStatus.CONFIGURATION_FAILED,
        interval: 1000,
        timeout: 20000,
        onFinish: () => {
          setAction(undefined);
          setIsLocked(false);
        },
      },
    });
  }

  async function onUnsubscribed() {
    setAction(SubscriptionAction.UNSUBSCRIBE);
    setApiError(undefined);
    setAlert(undefined);

    await executeApiCall<AppSubscription>({
      callFunction: () =>
        subscriptionService
          .endSubscription({
            subscriptionId: subscription.id,
          })
          .then(result => {
            setAction(undefined);
            return result;
          }),
      setError: onError('Unsubscribe failed'),
      setPending: setIsLoading,
      setResult: onSuccess('Unsubscribed'),
    });
  }

  async function onDeleted() {
    setAction(SubscriptionAction.DELETE);
    setApiError(undefined);
    setAlert(undefined);

    await executeApiCall({
      callFunction: () =>
        subscriptionService
          .deleteSubscription({
            subscriptionId: subscription.id,
          })
          .then(() => {
            setAction(undefined);
            onDelete();
          }),
      setError: onError('Delete failed'),
      setPending: setIsLoading,
    });
  }

  return (
    <SubscriptionElement subscription={subscription}>
      <Grid container marginBottom={3}>
        <Grid item xs={1}>
          <FormControlLabel
            disabled={
              !listAllowedActions(subscription).includes(
                SubscriptionAction.UPDATE
              )
            }
            control={<Switch onChange={(_, checked) => setEditMode(checked)} />}
            label="Edit"
          />
        </Grid>
        <Grid item xs={11}>
          <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
            <LoadingButton
              loading={action === SubscriptionAction.UNSUBSCRIBE && isLoading}
              onClick={onUnsubscribed}
              disabled={
                isLocked ||
                !listAllowedActions(subscription).includes(
                  SubscriptionAction.UNSUBSCRIBE
                )
              }
              startIcon={<ToggleOffTwoToneIcon />}
            >
              {SubscriptionAction.UNSUBSCRIBE}
            </LoadingButton>

            <LoadingButton
              loading={action === SubscriptionAction.SUBSCRIBE && isLoading}
              onClick={onSubscribed}
              disabled={
                isLocked ||
                !listAllowedActions(subscription).includes(
                  SubscriptionAction.SUBSCRIBE
                )
              }
              startIcon={<ToggleOnTwoToneIcon />}
            >
              {SubscriptionAction.SUBSCRIBE}
            </LoadingButton>

            {subscription.status === SubscriptionStatus.CREATED && (
              <LoadingButton
                loading={action === SubscriptionAction.DELETE && isLoading}
                onClick={onDeleted}
                disabled={
                  isLocked ||
                  !listAllowedActions(subscription).includes(
                    SubscriptionAction.DELETE
                  )
                }
                startIcon={<DeleteTwoToneIcon />}
              >
                {SubscriptionAction.DELETE}
              </LoadingButton>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {alert !== undefined && (
              <Alert severity={alert.severity}>
                {alert.message}
                {apiError !== undefined ? `: ${JSON.stringify(apiError)}` : ''}
              </Alert>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfigureApp
        app={subscription.app}
        edit={editMode}
        onSave={onUpdated}
        onCancel={() => {
          setEditMode(false);
        }}
      />
    </SubscriptionElement>
  );
}

export default ConfigureExistingSubscription;
