import {
  ApiCommandResult,
  ExportIntegrationsRequest,
  IntegrationAction,
  IntegrationActionLifecycleNotification,
  IntegrationControlAction,
  IntegrationDefinition,
  IntegrationOperationTestRequest,
  IntegrationWorkflowTestRequest,
  IntegrationWorkflowTestResult,
  ModuleConfiguration,
  RunIntegrationWorkflowTestsRequest,
  VersionedIntegrations,
  WorkflowNodeResult,
} from '@apus/common-lib/api/interface/integration-service';
import { Tenant } from '@apus/common-lib/api/interface/tenant-service';
import {
  Module,
  IntegrationModule,
  IntegrationOperation,
} from '@apus/common-lib/integrations/src/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import { AxiosInstance } from 'axios';
import {
  AddIntegrationToApiRequest,
  ExposedApi,
} from '@apus/common-lib/api/interface/exposed-api';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';

export interface SearchActionParams {
  actionId?: string;
  from?: Date;
  until?: Date;
  filterByIntegration?: string[];
  filterByStatus?: string[];
}

export interface IntegrationService {
  upsertModuleConfiguration(
    configuration: ModuleConfiguration
  ): Promise<ModuleConfiguration>;

  getModuleConfiguration(
    module: Module
  ): Promise<ModuleConfiguration | undefined>;

  listModuleConfigurations(): Promise<ModuleConfiguration[]>;

  listModules(): Promise<IntegrationModule[]>;

  exportIntegrations(
    req: ExportIntegrationsRequest
  ): Promise<VersionedIntegrations>;

  createAppVersion(app: VersionedIntegrations): Promise<void>;

  updateAppVersion(app: VersionedIntegrations): Promise<void>;

  getDefaultAppVersion(appName: SystemglueAppName): Promise<string | undefined>;

  setAppVersionToDefault(
    appName: SystemglueAppName,
    version: string
  ): Promise<void>;

  listAppVersions(appName: SystemglueAppName): Promise<VersionedIntegrations[]>;

  listOperations(): Promise<IntegrationOperation[]>;

  defineIntegration(
    integrationId: string,
    definition: IntegrationDefinition
  ): Promise<void>;

  deleteIntegrations(integrationIds: string[]): Promise<void>;

  getIntegration(integrationId: string): Promise<IntegrationDefinition>;

  listIntegrations(): Promise<IntegrationDefinition[]>;

  runIntegration(integrationId: string): Promise<void>;

  executeControlAction(
    integrationId: string,
    action: IntegrationControlAction
  ): Promise<IntegrationDefinition>;

  retryActions(integrationId: string, actionIds: string[]): Promise<void>;

  getAction(actionId: string): Promise<IntegrationAction | undefined>;

  editAction(action: IntegrationAction): Promise<void>;

  searchActions(
    searchParams: SearchActionParams
  ): Promise<IntegrationActionLifecycleNotification[]>;

  testOperation(
    request: IntegrationOperationTestRequest
  ): Promise<SourceObject | undefined>;

  testWorkflow(
    request: IntegrationWorkflowTestRequest
  ): Promise<WorkflowNodeResult[]>;

  runWorkflowTests(
    request: RunIntegrationWorkflowTestsRequest
  ): Promise<IntegrationWorkflowTestResult[]>;

  getApi(apiId: string): Promise<ExposedApi>;

  listApis(): Promise<ExposedApi[]>;

  storeApi(api: ExposedApi): Promise<void>;

  addIntegrationToApi(
    apiId: string,
    request: AddIntegrationToApiRequest
  ): Promise<void>;

  executeApiCommand<T = unknown>(
    apiId: string,
    groupId: string,
    commandId: string,
    data: unknown
  ): Promise<ApiCommandResult<T>>;
  getApiCommandResult<T = unknown>(
    apiId: string,
    groupId: string,
    commandId: string,
    resultId: string
  ): Promise<ApiCommandResult<T>>;
}

export const createIntegrationService = (
  tenant: Tenant,
  client: AxiosInstance
): IntegrationService => {
  return {
    upsertModuleConfiguration(
      operation: ModuleConfiguration
    ): Promise<ModuleConfiguration> {
      return client.post<ModuleConfiguration, ModuleConfiguration>(
        `/integrations/${tenant.tenantId}/configurations/`,
        operation
      );
    },
    getModuleConfiguration(
      module: Module
    ): Promise<ModuleConfiguration | undefined> {
      return client
        .get<ModuleConfiguration>(
          `/integrations/${tenant.tenantId}/configurations/${module}`
        )
        .then(({ data }) => data);
    },
    listModuleConfigurations(): Promise<ModuleConfiguration[]> {
      return client
        .get<ModuleConfiguration[]>(
          `/integrations/${tenant.tenantId}/configurations`
        )
        .then(({ data }) => data);
    },
    listModules(): Promise<IntegrationModule[]> {
      return client
        .get<IntegrationModule[]>(
          `/integrations/${tenant.tenantId}/list-modules`
        )
        .then(({ data }) => data);
    },
    listOperations(): Promise<IntegrationOperation[]> {
      return client
        .get<IntegrationModule[]>(
          `/integrations/${tenant.tenantId}/list-modules`
        )
        .then(({ data }) => {
          return Promise.resolve(
            data
              .map((module): IntegrationOperation[] =>
                Object.values(module.operations)
              )
              .flat()
          );
        });
    },
    async exportIntegrations(
      req: ExportIntegrationsRequest
    ): Promise<VersionedIntegrations> {
      const result = await client.post<VersionedIntegrations>(
        `/integrations/${tenant.tenantId}/export`,
        req
      );
      return result.data;
    },
    async createAppVersion(app: VersionedIntegrations): Promise<void> {
      await client.post<VersionedIntegrations>(`/apps/${app.app}`, app);
    },

    async updateAppVersion(app: VersionedIntegrations): Promise<void> {
      await client.put<VersionedIntegrations>(
        `/apps/${app.app}/${app.version}`,
        app
      );
    },

    getDefaultAppVersion(
      appName: SystemglueAppName
    ): Promise<string | undefined> {
      return client
        .get<string | undefined>(`/apps/default/${appName}`)
        .then(({ data }) => data);
    },

    async setAppVersionToDefault(
      appName: SystemglueAppName,
      version: string
    ): Promise<void> {
      await client.post<VersionedIntegrations>(
        `/apps/${appName}/${version}/set-default`
      );
    },

    listAppVersions(
      appName: SystemglueAppName
    ): Promise<VersionedIntegrations[]> {
      return client
        .get<VersionedIntegrations[]>(`/apps/${appName}`)
        .then(({ data }) => data);
    },

    defineIntegration(
      integrationId: string,
      definition: IntegrationDefinition
    ): Promise<void> {
      return client.post(`/integrations/${tenant.tenantId}/define`, definition);
    },
    deleteIntegrations(integrationIds: string[]): Promise<void> {
      return client.post(`/integrations/${tenant.tenantId}/delete`, {
        integrationIds,
      });
    },
    getIntegration(integrationId: string): Promise<IntegrationDefinition> {
      return client
        .get<IntegrationDefinition>(
          `/integrations/${tenant.tenantId}/${integrationId}`
        )
        .then(({ data }) => data);
    },
    listIntegrations(): Promise<IntegrationDefinition[]> {
      return client
        .get<IntegrationDefinition[]>(`/integrations/${tenant.tenantId}`)
        .then(({ data }) => data);
    },
    runIntegration(integrationId: string): Promise<void> {
      return client.post(
        `/integrations/${tenant.tenantId}/run/${integrationId}`
      );
    },
    executeControlAction(
      integrationId: string,
      action: IntegrationControlAction
    ): Promise<IntegrationDefinition> {
      return client
        .post(
          `/integrations/${tenant.tenantId}/${integrationId}/actions/control/execute`,
          action
        )
        .then(({ data }) => data);
    },
    getAction(actionId: string): Promise<IntegrationAction | undefined> {
      return client
        .post<IntegrationAction>(
          `/maintenance/${tenant.tenantId}/actions/get`,
          {
            actionId,
          }
        )
        .then(({ data }) => data);
    },
    async searchActions(
      searchParams: SearchActionParams
    ): Promise<IntegrationActionLifecycleNotification[]> {
      const result = await client
        .post<IntegrationActionLifecycleNotification[]>(
          `/maintenance/${tenant.tenantId}/actions/search`,
          {
            from: searchParams.from?.toISOString(),
            until: searchParams.until?.toISOString(),
            ...searchParams,
          }
        )
        .then(({ data }) => data);
      // sort in descending order
      return result.sort((a, b) => b.effectiveOn.localeCompare(a.effectiveOn));
    },
    editAction(action: IntegrationAction): Promise<void> {
      return client.post(`/maintenance/${tenant.tenantId}/edit-action`, action);
    },
    retryActions(integrationId: string, actionIds: string[]): Promise<void> {
      return client.post(`/maintenance/${tenant.tenantId}/retry-actions`, {
        integrationId,
        actionIds,
      });
    },
    testOperation(
      request: IntegrationOperationTestRequest
    ): Promise<SourceObject | undefined> {
      return client
        .post(`/maintenance/${tenant.tenantId}/test-operation`, request)
        .then(({ data }) => data);
    },
    testWorkflow(
      request: IntegrationWorkflowTestRequest
    ): Promise<WorkflowNodeResult[]> {
      return client
        .post(`/maintenance/${tenant.tenantId}/test-workflow`, request)
        .then(({ data }) => data);
    },
    runWorkflowTests(
      request: RunIntegrationWorkflowTestsRequest
    ): Promise<IntegrationWorkflowTestResult[]> {
      return client
        .post(`/maintenance/${tenant.tenantId}/run-workflow-tests`, request)
        .then(({ data }) => data);
    },
    getApi(apiId: string): Promise<ExposedApi> {
      return client
        .get(`/apis/${tenant.tenantId}/${apiId}`)
        .then(({ data }) => data);
    },
    listApis(): Promise<ExposedApi[]> {
      return client.get(`/apis/${tenant.tenantId}`).then(({ data }) => data);
    },
    storeApi(api: ExposedApi): Promise<void> {
      return client
        .post(`/apis/${tenant.tenantId}/${api.apiId}`, api)
        .then(({ data }) => data);
    },
    addIntegrationToApi(
      apiId: string,
      request: AddIntegrationToApiRequest
    ): Promise<void> {
      return client
        .post(`/apis/${tenant.tenantId}/${apiId}/add-integration`, request)
        .then(({ data }) => data);
    },
    executeApiCommand<T = unknown>(
      apiId: string,
      groupId: string,
      commandId: string,
      data: unknown
    ): Promise<ApiCommandResult<T>> {
      return client
        .post(
          `/apis/${tenant.tenantId}/execute/${apiId}/group/${groupId}/command/${commandId}`,
          data
        )
        .then(({ data }) => data);
    },
    getApiCommandResult<T = unknown>(
      apiId: string,
      groupId: string,
      commandId: string,
      resultId: string
    ): Promise<ApiCommandResult<T>> {
      return client
        .get(
          `/apis/${tenant.tenantId}/execute/${apiId}/group/${groupId}/command/${commandId}/${resultId}`
        )
        .then(({ data }) => data);
    },
  };
};
