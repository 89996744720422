import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { ServiceProviderOsuuspankkiCorporateAPIConfiguration } from '@apus/common-lib/integrations/src/osuuspankki/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack, Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';

const ServiceProviderOsuuspankkiConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<ServiceProviderOsuuspankkiCorporateAPIConfiguration>();

  useEffect(() => {
    if (moduleConfiguration !== undefined) {
      const configuration =
        moduleConfiguration.configuration as unknown as ServiceProviderOsuuspankkiCorporateAPIConfiguration;
      form.reset(configuration);
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (
    formData: ServiceProviderOsuuspankkiCorporateAPIConfiguration
  ) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId: moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container spacing={2}>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Osuuspankki -connection for service provider
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="apiServer"
            form={form}
            label="API server URL"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientId"
            form={form}
            label="Client id provided by OP"
            defaultValue={''}
            helperText={
              'The value of the client id for the integration (see https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_157771733782.html for more information)'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientSecret"
            form={form}
            label="Client secret provided by OP"
            defaultValue={''}
            helperText={
              'The value of client secret generated during the mapping of the connection (see https://docs.oracle.com/en/cloud/saas/netsuite/ns-online-help/section_162686838198.html for more information)'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="mtlsKey"
            form={form}
            label="Mutual TLS private key generated by us"
            defaultValue={''}
            helperText={''}
            multiline={true}
            minRows={10}
            disabled={!edit}
          />
          <HookFormTextInput
            name="mtlsCertificate"
            form={form}
            label="Mutual TLS certificate in X.509-format provided by OP"
            defaultValue={''}
            helperText={''}
            multiline={true}
            minRows={10}
            disabled={!edit}
          />
          <HookFormTextInput
            name="signingKid"
            form={form}
            label="Signing key id provided by OP"
            defaultValue={''}
            helperText={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="signingKey"
            form={form}
            label="Signing key generated by us"
            defaultValue={''}
            helperText={''}
            multiline={true}
            minRows={10}
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item>
        <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default ServiceProviderOsuuspankkiConfigurationForm;
