import { JSX } from 'react';
import {
  Controller,
  UseFormReturn,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { TextField, TextFieldProps } from '@mui/material';

import { isError } from './util';

type Props<FormType extends FieldValues> = {
  name: string;
  form: UseFormReturn<FormType>;
  onValueChange?: (value?: string) => void;
} & TextFieldProps;

const HookFormTextInput = <FormType extends FieldValues>({
  name,
  form,
  onValueChange,
  ...otherProps
}: Props<FormType>): JSX.Element => {
  const error = isError(name, form.formState.errors);

  // defaultValue should be provided via the controller, not the input
  const { defaultValue, type, ...prunedProps } = otherProps;

  const parseValue = (value: string, previous?: string) => {
    switch (type) {
      case 'number': {
        if (Number.isNaN(Number.parseFloat(value))) return previous;

        return Number.isInteger(value)
          ? Number.parseInt(value)
          : Number.parseFloat(value);
      }
      default: {
        return value;
      }
    }
  };

  return (
    <Controller
      control={form.control}
      name={name as Path<FormType>}
      defaultValue={(defaultValue ?? '') as PathValue<FormType, Path<FormType>>}
      key={
        otherProps.key !== undefined
          ? `${otherProps.key}-controller`
          : `${name}-controller`
      }
      render={({ field }) => (
        <TextField
          {...prunedProps}
          {...field}
          onChange={event => {
            field.onChange({
              ...event,
              target: {
                ...event.target,
                // react-hook-form does not automatically handle number values from text inputs
                value: parseValue(event.target.value, field.value),
              },
            });
            if (onValueChange) onValueChange(event.target.value);
          }}
          key={
            prunedProps.key !== undefined
              ? `${prunedProps.key}-text-field`
              : `${name}-text-field`
          }
          error={error !== undefined}
          helperText={
            error !== undefined ? error.message : prunedProps.helperText
          }
        />
      )}
    />
  );
};

export default HookFormTextInput;
