import { useReducer, JSX, Dispatch, useEffect, useRef } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';

import {
  AccessTokenContext,
  initialAccessTokenState,
} from '../state/accessTokenContext';
import {
  AccessTokenAction,
  accessTokenContextReducer,
  accessTokenRefreshed,
} from '../state/accessTokenReducer';

const CHECK_INTERVAL_IN_MS = 60000;

function getJwtToken(user: CognitoUser) {
  const sessionUser = user?.getSignInUserSession();

  if (sessionUser == null) return undefined;

  return sessionUser.getIdToken().getJwtToken();
}

async function refreshToken(
  user: CognitoUser,
  dispatch: Dispatch<AccessTokenAction>
) {
  const sessionUser = user?.getSignInUserSession();

  if (sessionUser == null) {
    return undefined;
  }

  const token = sessionUser.getIdToken();

  const tokenExpirationTime = new Date(token.getExpiration() * 1000);
  const tokenExpiresIn = tokenExpirationTime.valueOf() - new Date().valueOf();

  if (tokenExpiresIn < CHECK_INTERVAL_IN_MS) {
    await user.refreshSession(sessionUser.getRefreshToken(), (err, session) => {
      user.setSignInUserSession(session);
      const jwtToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
      dispatch(accessTokenRefreshed(jwtToken));

      if (err != null) {
        // TODO: decide what else should be done
        console.log(
          `Got error when refreshing access token: ${JSON.stringify(err)}`
        );
        dispatch(accessTokenRefreshed(undefined));
      }
    });
  }
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

const AccessTokenProvider = ({ children }: Props): JSX.Element => {
  const { user } = useAuthenticator(context => [context.user]);

  const [state, dispatch] = useReducer(
    accessTokenContextReducer,
    initialAccessTokenState
  );
  const intervalRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    async function checkRefresh() {
      await refreshToken(user, dispatch);
    }
    if (intervalRef.current === undefined && user !== undefined) {
      dispatch(accessTokenRefreshed(getJwtToken(user)));
    }

    if (intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
    }

    if (user !== undefined)
      intervalRef.current = setInterval(checkRefresh, CHECK_INTERVAL_IN_MS);

    return () => clearInterval(intervalRef.current);
  }, [user]);

  return (
    <AccessTokenContext.Provider value={[state, dispatch]}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export default AccessTokenProvider;
