import { IntegrationWorkflowDefinition } from '../../api/interface/integration-service';
import { InternalServerError } from '../../utils/src/error';
import { logRecordFor } from '../../utils/src/logger';
import { IntegrationNodeType } from './interface';
import { IntegrationEngineBase } from './integration-engine-base';

const debug = logRecordFor('integration-engine', 'debug');

class IntegrationEngine extends IntegrationEngineBase {
  asDefinition(): IntegrationWorkflowDefinition {
    if (this.trigger === undefined)
      throw new Error(
        `Cannot generate error workflow definition: no trigger defined`
      );

    return {
      trigger: this.trigger,
      nodes: this.traverseWorkflow(),
    };
  }

  static parse(definition: IntegrationWorkflowDefinition) {
    debug('parse', { definition });
    const engine = new IntegrationEngine();

    engine.addTrigger(definition.trigger);
    definition.nodes.map(node => {
      if (
        node.nodeType === IntegrationNodeType.Operation ||
        node.nodeType === IntegrationNodeType.Dispatch ||
        node.nodeType === IntegrationNodeType.ApiResult
      )
        engine.addNode(node);
      else
        throw new InternalServerError(
          `Cannot parse definition - wrong node type ${node.nodeType}`
        );
    });

    if (engine.trigger === undefined)
      throw new InternalServerError(
        'IntegrationEngine.parse failed: no trigger defined'
      );

    return engine;
  }

  // TODO: use only this static method
  static isPollingWorkflow(workflow: IntegrationWorkflowDefinition): boolean {
    return workflow.trigger.nodeType === IntegrationNodeType.PollingTrigger;
  }
}

export default IntegrationEngine;
