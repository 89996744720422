import React, { useEffect, useState, JSX } from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography/Typography';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // position expand icon on the left
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

interface Props {
  title: string;
  subtitle?: string;
  disabledSubtitle?: string;
  titleTypographyProps?: TypographyProps;
  subtitleTypographyProps?: TypographyProps;
  disabledSubtitleTypographyProps?: TypographyProps;
  disabled?: boolean;
  defaultExpanded?: boolean;
  unmountOnExit?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const Collapsible = ({
  title,
  subtitle,
  disabledSubtitle,
  titleTypographyProps = { variant: 'h6' },
  subtitleTypographyProps = { variant: 'subtitle2' },
  disabledSubtitleTypographyProps = { variant: 'subtitle2' },
  disabled,
  defaultExpanded = true,
  unmountOnExit = false,
  children,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [descriptionText, setDescriptionText] = useState<string | undefined>(
    undefined
  );
  const [disabledDescriptionText, setDisabledDescriptionText] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (disabled) {
      setIsDisabled(true);
      setExpanded(false);
    } else {
      setIsDisabled(false);
      setExpanded(defaultExpanded);
    }
    setDescriptionText(subtitle);
    setDisabledDescriptionText(disabledSubtitle);
  }, [
    subtitle,
    disabledSubtitle,
    disabled,
    setIsDisabled,
    setExpanded,
    defaultExpanded,
  ]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        if (!isDisabled) setExpanded(!expanded);
      }}
      TransitionProps={{ unmountOnExit }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Box display={'block'}>
          <Typography
            {...titleTypographyProps}
            color={isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
          >
            {title}
          </Typography>
          {descriptionText !== undefined && (
            <Typography
              {...subtitleTypographyProps}
              color={isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
            >
              {descriptionText}
            </Typography>
          )}
          {isDisabled && disabledDescriptionText !== undefined && (
            <Typography {...disabledSubtitleTypographyProps}>
              {disabledDescriptionText}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Collapsible;
