import { useContext, useMemo } from 'react';

import { BadRequest } from '@apus/common-lib/utils/src/error';

import useAxios from '../hooks/useAxios';
import {
  createSubscriptionService,
  SubscriptionService,
} from '../api-client/subscription-service';
import { TenantContext } from '../state/tenantContext';

const ErrorThrowingSubscriptionService = (
  message: string
): SubscriptionService => ({
  createSubscription() {
    throw new BadRequest(message);
  },
  beginSubscription() {
    throw new BadRequest(message);
  },
  endSubscription() {
    throw new BadRequest(message);
  },
  deleteSubscription() {
    throw new BadRequest(message);
  },
  getSubscription() {
    throw new BadRequest(message);
  },
  listSubscriptions() {
    throw new BadRequest(message);
  },
  updateSubscription() {
    throw new BadRequest(message);
  },
  executeAppUpdate() {
    throw new BadRequest(message);
  },
  getAppUpdate() {
    throw new BadRequest(message);
  },
});

function useSubscriptionService(): SubscriptionService {
  const [tenantState] = useContext(TenantContext);
  const axiosInstance = useAxios();

  return useMemo(() => {
    if (tenantState.tenant === undefined) {
      return ErrorThrowingSubscriptionService('Tenant must be selected first');
    }

    if (axiosInstance === undefined)
      return ErrorThrowingSubscriptionService(
        'Axios instance could not be defined'
      );

    return createSubscriptionService(tenantState.tenant, axiosInstance);
  }, [tenantState.tenant, axiosInstance]);
}

export default useSubscriptionService;
