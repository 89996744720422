import {
  IntegrationOperation,
  SupportedModule,
} from '../../integrations/src/interface';
import {
  IntegrationDefinition,
  IntegrationWorkflowDefinition,
} from './integration-service';
import { EventHandlingError } from './events';
import { PaymentIntegrationAppConfiguration } from './apps/payment-integration';
import { SystemglueAppConfiguration } from './apps/systemglue-integration';
import { SystemglueAppName } from './common';
import { BankTransactionIntegrationAppConfiguration } from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import { ExposedApi } from '@apus/common-lib/api/interface/exposed-api';
import { EInvoiceIntegrationAppConfiguration } from '@apus/common-lib/api/interface/apps/e-invoice-integration';

export type AppExposedApi = ExposedApi;

export interface AppIntegration
  extends Pick<
    IntegrationDefinition,
    | 'integrationId'
    | 'name'
    | 'description'
    | 'errorIntegration'
    | 'configuration'
    | 'allowedActions'
    | 'isReadOnly'
    | 'app'
  > {
  /**
   * @deprecated
   */
  step?: string;
  connection?: SupportedModule;
  workflow?: IntegrationWorkflowDefinition;
}

export interface SystemglueAppStructure {
  name: SystemglueAppName;
  integrations: AppIntegration[];
  apis?: AppExposedApi[];
  operations?: IntegrationOperation[];
}

/**
 * @title Internally used status enum for subscriptions
 */
export enum SubscriptionStatus {
  // Initial state
  CREATED = 'Created',
  // Subscription configuration has been triggered
  CONFIGURING = 'Configuring',
  // Configuration done
  CONFIGURED = 'Configured',
  // Configuration failed
  CONFIGURATION_FAILED = 'ConfigurationFailed',
  // Subscription is active
  ACTIVE = 'Active',
  // Subscription cancelled, but still valid
  CANCELLING = 'Cancelling',
  // Subscription has ended
  CANCELLED = 'Cancelled',
  DELETED = 'Deleted',
}

export interface SubscriptionStatusEntry {
  status: SubscriptionStatus;
  created: string;
  error?: EventHandlingError;
}

export interface SubscriptionDetails {
  /**
   * User-definable name for the integration
   */
  name?: string;
  disabled?: boolean;
  startDate: string;
  endDate?: string;
  createdDate: string;
  updatedDate: string;
}

export interface App<CONFIGURATION = unknown> {
  name: SystemglueAppName;
  configuration: CONFIGURATION;
}

export interface SystemglueIntegrationApp
  extends App<SystemglueAppConfiguration> {
  name: SystemglueAppName.BASE_INTEGRATION_APP;
}

export interface EInvoiceIntegrationApp
  extends App<EInvoiceIntegrationAppConfiguration> {
  /**
   * @title Application name
   */
  name: SystemglueAppName.E_INVOICE_INTEGRATION_APP;
}

export interface PaymentIntegrationApp
  extends App<PaymentIntegrationAppConfiguration> {
  /**
   * @title Application name
   */
  name: SystemglueAppName.PAYMENT_INTEGRATION_APP;
}

export interface BankTransactionIntegrationApp
  extends App<BankTransactionIntegrationAppConfiguration> {
  /**
   * @title Application name
   */
  name: SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP;
}

export type SystemglueApp =
  | EInvoiceIntegrationApp
  | SystemglueIntegrationApp
  | PaymentIntegrationApp
  | BankTransactionIntegrationApp;

export interface AppSubscription<APP extends App = SystemglueApp> {
  id: string;
  app: APP;
  status: SubscriptionStatus;
  subscriptionDetails: SubscriptionDetails;
}

export interface CreateSubscriptionRequest {
  app: SystemglueApp;
  name?: string;
}

export interface DeleteSubscriptionRequest {
  subscriptionId: string;
}

export interface BeginSubscriptionRequest {
  subscriptionId: string;
  app: SystemglueApp;
}

export interface UpdateSubscriptionRequest {
  subscriptionId: string;
  app: SystemglueApp;
  name?: string;
}

export interface CancelSubscriptionRequest {
  subscriptionId: string;
}

export type UnknownAppSubscription = AppSubscription<App>;

export type EInvoiceIntegrationAppSubscription =
  AppSubscription<EInvoiceIntegrationApp>;

export type PaymentIntegrationAppSubscription =
  AppSubscription<PaymentIntegrationApp>;

export type SystemglueIntegrationAppSubscription =
  AppSubscription<SystemglueIntegrationApp>;

export type BankTransactionIntegrationAppSubscription =
  AppSubscription<BankTransactionIntegrationApp>;

export interface AppUpdateRequest {
  app: SystemglueAppName;
}

export interface AppUpdateTenantResult {
  tenantId: string;
  subscriptionIds: string[];
  status: 'Pending' | 'Success' | 'Error';
  errorMessage?: string;
}

export interface AppUpdateExecution {
  appUpdateId: string;
  status: 'Started' | 'Finished';
  request: AppUpdateRequest;
  started: string;
  finished?: string;
  tenants: AppUpdateTenantResult[];
}
