import React, { JSX, useEffect, useState } from 'react';

import {
  EmbeddedIntegrationOperation,
  IntegrationOperationPrototype,
} from '@apus/common-lib/integrations/src/interface';
import { resolvePrototypeOrThrow } from '@apus/common-ui/utils/data-utils';

import DefineEmbeddedIntegrationOperation from '../../../operation/DefineEmbeddedIntegrationOperation';
import OperationSelector from '../../../operation/OperationSelector';

interface Props {
  prototypes: IntegrationOperationPrototype[];
  instance?: EmbeddedIntegrationOperation;
  onChange: (operation: EmbeddedIntegrationOperation) => void;
}

const DefineEmbeddedOperation = ({
  instance,
  prototypes,
  onChange,
}: Props): JSX.Element => {
  const [prototype, setPrototype] = useState<
    IntegrationOperationPrototype | undefined
  >();

  useEffect(() => {
    if (instance !== undefined) {
      setPrototype(resolvePrototypeOrThrow(instance, prototypes));
    } else setPrototype(undefined);
  }, [instance, prototypes, setPrototype]);

  if (prototype === undefined)
    return (
      <OperationSelector<IntegrationOperationPrototype>
        name="create-operation"
        label="Create new operation from prototype"
        onOperationSelected={setPrototype}
        operations={prototypes}
      />
    );

  return (
    <DefineEmbeddedIntegrationOperation
      operation={instance}
      prototype={prototype}
      onChange={onChange}
    />
  );
};

export default DefineEmbeddedOperation;
