import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { IntegrationOperationTestRequest } from '@apus/common-lib/api/interface/integration-service';
import { Tenant } from '@apus/common-lib/api/interface/tenant-service';
import {
  ConfigurationSchema,
  IntegrationOperation,
} from '@apus/common-lib/integrations/src/interface';
import {
  SourceObject,
  SupportedFileType,
} from '@apus/common-lib/json-data-mapper/src/interface';
import { jsonFromSchema } from '@apus/common-lib/json-data-mapper/src/json-schema-library';
import { parseSchema } from '@apus/common-lib/json-data-mapper/src/schema-utils';
import {
  generateConfiguration,
  splitConfigurationSchema,
} from '@apus/common-lib/utils/src/data-utils';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import Editor from '@monaco-editor/react';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { JSONSchema7 } from 'json-schema';

import ConfigurationSchemaForm from '../input/ConfigurationSchemaForm';
import DefineJsonHandler from '../input/editor/json/DefineJsonHandler';

interface Props {
  allowSchemaOverride?: boolean;
  operation: IntegrationOperation;
  tenant: Tenant;
  onOverrideOutputSchema?: (schema: JSONSchema7) => void;
}

interface IntegrationOperationTestRequestEdit {
  operation: IntegrationOperation;
  configuration?: SourceObject;
  runtimeConfiguration?: SourceObject;
  inputData: string;
}

function safeParseJson(value?: string) {
  if (value === undefined || value.trim() === '') return undefined;

  try {
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

const TestIntegrationOperation = ({
  allowSchemaOverride = true,
  operation,
  tenant,
  onOverrideOutputSchema,
}: Props): JSX.Element => {
  const integrationService = useIntegrationService();
  const [configurationSchema, setConfigurationSchema] = useState<
    ConfigurationSchema | undefined
  >();
  const [runtimeConfigurationSchema, setRuntimeConfigurationSchema] = useState<
    ConfigurationSchema | undefined
  >();
  const form = useForm<IntegrationOperationTestRequestEdit>({
    defaultValues: {
      inputData: '',
      runtimeConfiguration: undefined,
    },
  });

  const [callPending, setCallPending] = useState<boolean>(false);
  const [callResult, setCallResult] = useState<SourceObject | undefined>();
  const [callError, setCallError] = useState<any>();

  useEffect(() => {
    if (operation !== undefined) {
      const { configurationSchema, runtimeConfigurationSchema } =
        splitConfigurationSchema(operation.prototype.configurationSchema);

      const edit: IntegrationOperationTestRequestEdit = {
        operation,
        inputData:
          operation.inputSchema?.content.jsonSchema !== undefined
            ? JSON.stringify(
                jsonFromSchema(operation.inputSchema.content.jsonSchema, false),
                null,
                2
              )
            : '',
        configuration: generateConfiguration(
          operation.configuration,
          configurationSchema
        ),
      };

      console.log(JSON.stringify(edit, null, 2));
      form.reset(edit);

      setConfigurationSchema(configurationSchema);
      setRuntimeConfigurationSchema(runtimeConfigurationSchema);
    }
  }, [operation, form]);

  const overrideOutputSchemaWithResult = async () => {
    if (callResult !== undefined) {
      const schema = await parseSchema(
        JSON.stringify(callResult),
        SupportedFileType.Json
      );
      if (schema !== undefined && onOverrideOutputSchema !== undefined)
        onOverrideOutputSchema(schema);
    }
  };

  const submit = async (edit: IntegrationOperationTestRequestEdit) => {
    setCallResult(undefined);
    setCallError(undefined);

    const request: IntegrationOperationTestRequest = {
      operation: operation,
      configuration: edit.configuration,
      runtimeConfiguration: edit.runtimeConfiguration,
      inputData: safeParseJson(edit.inputData),
    };

    await executeApiCall<SourceObject | undefined>({
      callFunction: () => integrationService.testOperation(request),
      setError: setCallError,
      setResult: setCallResult,
      setPending: setCallPending,
    });
  };

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Test operation '{operation.moduleId}/{operation.operationId}' using
          prototype '{operation.prototype.moduleId}/
          {operation.prototype.operationId}'
        </Typography>
        <Typography variant="body2">
          Operation will be executed using the connection configuration
          currently defined for "{tenant.tenantName}" so please make sure that
          this will not cause unwanted data changes
        </Typography>
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
      </Grid>
      <Grid item xs={11}>
        <Stack spacing={2}>
          <Stack spacing={2}>
            {configurationSchema !== undefined && (
              <ConfigurationSchemaForm
                form={form}
                configurationSchema={configurationSchema}
                inputSchema={operation.inputSchema}
                propertyPath={'configuration'}
              />
            )}
            {runtimeConfigurationSchema !== undefined && (
              <ConfigurationSchemaForm
                form={form}
                configurationSchema={runtimeConfigurationSchema}
                inputSchema={operation.inputSchema}
                propertyPath={'runtimeConfiguration'}
              />
            )}
          </Stack>
          <Controller
            render={({ field }) => (
              <DefineJsonHandler
                height={'40vh'}
                allowUndefined={false}
                title={'Input data'}
                value={safeParseJson(field.value)}
                onChange={value => {
                  field.onChange(JSON.stringify(value));
                }}
              />
            )}
            name={'inputData'}
            control={form.control}
          />
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack direction={'column-reverse'} sx={{ height: '100%' }}>
          <LoadingButton
            variant={'contained'}
            loading={callPending}
            onClick={form.handleSubmit(submit)}
          >
            Execute
          </LoadingButton>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5">Results</Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={'row-reverse'}>
              {onOverrideOutputSchema !== undefined && (
                <Button
                  onClick={overrideOutputSchemaWithResult}
                  disabled={
                    !allowSchemaOverride ||
                    callError !== undefined ||
                    callResult === undefined
                  }
                >
                  Use as output schema
                </Button>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Editor
              height={600}
              value={
                callError !== undefined
                  ? JSON.stringify(callError, null, 2)
                  : callResult !== undefined
                  ? JSON.stringify(callResult, null, 2)
                  : undefined
              }
              language={'json'}
              options={{
                readOnly: true,
                lineNumbers: 'off',
                minimap: { enabled: false },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TestIntegrationOperation;
