import React, { JSX, useCallback, useEffect, useState } from 'react';

import { RunCondition } from '@apus/common-lib/integration-engine/src/interface';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import { isEqual } from 'lodash';

import DefineJsonLogicCondition from '../../../input/editor/jsonlogic/DefineJsonLogicCondition';

interface Props {
  runContextSchema: JSONSchema7;
  condition?: RunCondition;
  onChange: (condition?: RunCondition) => void;
}

interface WorkingCondition {
  enabled?: boolean;
  condition?: string;
}

const DefineJsonLogicRule = ({
  runContextSchema,
  condition,
  onChange,
}: Props): JSX.Element => {
  const [workingCondition, setWorkingCondition] = useState<WorkingCondition>();

  const updateWorkingCondition = useCallback(
    (changed?: RunCondition) => {
      setWorkingCondition(changed);

      if (!isEqual(condition, changed)) {
        onChange(changed);
      }
    },
    [condition, onChange]
  );

  useEffect(() => {
    updateWorkingCondition(condition);
  }, [condition, updateWorkingCondition]);

  function queryChanged(query: string | undefined) {
    updateWorkingCondition({
      ...workingCondition,
      condition: query,
    });
  }

  function enabledChanged(enabled: boolean) {
    updateWorkingCondition({
      ...condition,
      enabled,
    });
  }

  return (
    <Grid container>
      <Grid item xs={10}>
        <Typography variant={'body1'}>
          The node will be executed only when condition is true.
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={event => {
                enabledChanged(event.target.checked);
              }}
              checked={condition?.enabled ?? false}
            />
          }
          label={'Condition enabled'}
        />
      </Grid>
      <Grid item xs={12}>
        {workingCondition !== undefined && (
          <DefineJsonLogicCondition
            disabled={condition?.enabled !== true}
            inputSchema={runContextSchema}
            condition={workingCondition.condition}
            onChange={queryChanged}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DefineJsonLogicRule;
