import React, { useRef, useState } from 'react';

import { SupportedFileType } from '@apus/common-lib/json-data-mapper/src/interface';
import { Autocomplete, Box, ButtonBase, Stack, TextField } from '@mui/material';

interface Props {
  onChange: (data: string, fileType: SupportedFileType) => void;
}

const SchemaSourceFileInput = ({ onChange }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [fileType, setFileType] = useState<SupportedFileType>(
    SupportedFileType.JsonSchema
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files != null) {
      const files = Array.from(event.target.files);
      const [file] = files;

      onChange(await file.text(), fileType);
    }
  };

  return (
    <Box>
      <Stack spacing={2} direction={'row'}>
        <Autocomplete
          disablePortal
          sx={{ minWidth: 400 }}
          options={Object.values(SupportedFileType)}
          defaultValue={fileType}
          renderInput={params => (
            <TextField
              {...params}
              label="Choose file type to use for constructing schema"
            />
          )}
          onChange={(_, data) => {
            if (data) setFileType(data);
          }}
        />

        <ButtonBase component="label">
          <input
            ref={inputRef}
            type="file"
            accept="application/json"
            onChange={handleChange}
          />
        </ButtonBase>
      </Stack>
    </Box>
  );
};

export default SchemaSourceFileInput;
