import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import { AxiosInstance } from 'axios';
import { OAuthAuthorizationCodeFlowResult } from '@apus/common-lib/api/interface/oauth-service';

export interface OAuthService {
  beginAuthorizationCodeFlow(
    connection: SupportedModule,
    tenantId: string
  ): Promise<string>;

  handleAuthorizationCode(
    connection: SupportedModule,
    code: string,
    state: string
  ): Promise<OAuthAuthorizationCodeFlowResult>;
}

export const createOAuthService = (client: AxiosInstance): OAuthService => {
  return {
    beginAuthorizationCodeFlow(
      connection: SupportedModule,
      tenantId: string
    ): Promise<string> {
      return client
        .get(`/oauth/redirect/${connection}`, {
          params: {
            tenantId,
          },
        })
        .then(({ data }) => data);
    },
    handleAuthorizationCode(
      connection: SupportedModule,
      code: string,
      state: string
    ): Promise<OAuthAuthorizationCodeFlowResult> {
      return client
        .get(`/oauth/authorize/${connection}`, {
          params: {
            code,
            state,
          },
        })
        .then(({ data }) => data);
    },
  };
};
