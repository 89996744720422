import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { JsonSchemaDefinition } from '@apus/common-lib/api/interface/files';
import { failedIntegrationActionSchema } from '@apus/common-lib/api/schemas/integration-service';
import {
  ErrorTriggerNode,
  IntegrationNodeType,
} from '@apus/common-lib/integration-engine/src/interface';
import { toJsonSchemaDefinition } from '@apus/common-lib/utils/src/data-utils';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { Box, FormControl, Stack, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

import { NodeBaseComponent } from './interface';
import NodeFooter from './NodeFooter';
import NodeToolBar from './NodeToolBar';
import DefineSchema from '../../json/schema/DefineSchema';
import Collapsible from '../../surface/Collapsible';

type Props = NodeBaseComponent<ErrorTriggerNode>;

interface NodeForm {
  name: string;
  description?: string;
  disableValidation?: boolean;
}

const DefineWebhookTriggerNode = ({
  expanded,
  node,
  onSave,
  onCancel,
}: Props): JSX.Element => {
  const [needsSaving, setNeedsSaving] = useState<boolean>(false);
  const form = useForm<NodeForm>({
    resolver: ajvResolver<NodeForm>({
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'name must be at least 1 characters long',
          },
          nullable: false,
        },
        description: {
          type: 'string',
          nullable: true,
        },
        disableValidation: {
          type: 'boolean',
          nullable: true,
        },
      },
      required: ['name'],
    }),
    defaultValues: {
      name: '',
      description: undefined,
    },
  });

  const [workingNode, setWorkingNode] = useState<ErrorTriggerNode | undefined>(
    undefined
  );

  useEffect(() => {
    if (node !== undefined) {
      form.setValue('name', node.name);
      form.setValue('description', node.description);
      form.setValue('disableValidation', node.disableValidation);
      setWorkingNode(node);
    } else {
      setWorkingNode({
        id: uuid(),
        description: '',
        nodeType: IntegrationNodeType.ErrorTrigger,
        name: `Error -trigger`,
        triggerSchema: toJsonSchemaDefinition(failedIntegrationActionSchema),
      });
      form.setValue('name', `Webhook -trigger`);
      form.setValue('description', '');
      form.setValue('disableValidation', false);
    }
  }, [node, form]);

  const updateSchema = (schema?: JsonSchemaDefinition) => {
    if (workingNode !== undefined && schema !== undefined) {
      setWorkingNode({
        ...workingNode,
        triggerSchema: schema,
      });
    }
  };

  function updateNeedsSaving(current: ErrorTriggerNode | undefined) {
    if (node !== undefined) {
      setNeedsSaving(!isEqual(node, current));
    } else {
      setNeedsSaving(true);
    }
  }

  function formUpdated() {
    const current =
      workingNode !== undefined
        ? {
            ...workingNode,
            ...form.getValues(),
          }
        : undefined;
    updateNeedsSaving(current);
  }

  const submit = async (data: NodeForm) => {
    if (workingNode !== undefined) {
      onSave({
        ...workingNode,
        name: data.name,
        description: data.description,
        disableValidation: data.disableValidation,
      } as ErrorTriggerNode);
    }
  };

  if (expanded !== true) return <></>;

  return (
    <Box sx={{ minWidth: 1024, padding: 2 }}>
      <NodeToolBar
        save={{
          onClick: form.handleSubmit(submit),
          disabled: !needsSaving,
        }}
        cancel={{
          onClick: onCancel,
        }}
      />
      <FormControl fullWidth margin="normal">
        <Typography variant={'h5'} sx={{ marginBottom: 3 }}>
          Define an error trigger
        </Typography>
        <Collapsible
          title="Describe the workflow node"
          subtitle="Add a name and description for the workflow node"
        >
          <Stack spacing={2}>
            <HookFormTextInput
              name="name"
              form={form}
              label="Workflow operation identifier"
              helperText="Name for trigger"
              onValueChange={formUpdated}
            />
            <HookFormTextInput
              name="description"
              form={form}
              label="Trigger description"
              onValueChange={formUpdated}
            />
            <HookFormCheckbox
              name={'disableValidation'}
              label={'Disable validation'}
              form={form}
              onValueChange={formUpdated}
            />
          </Stack>
        </Collapsible>
        <Collapsible
          title={'Define the error data model'}
          defaultExpanded={false}
        >
          <Stack spacing={2}>
            <DefineSchema
              propertyName={`triggerSchema`}
              title="Define error schema"
              mandatorySchema={toJsonSchemaDefinition(
                failedIntegrationActionSchema
              )}
              schema={workingNode?.triggerSchema}
              onSchemaSave={updateSchema}
            />
          </Stack>
        </Collapsible>
      </FormControl>
      <NodeFooter />
    </Box>
  );
};

export default DefineWebhookTriggerNode;
