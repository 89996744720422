import { useContext, useMemo } from 'react';

import { BadRequest } from '@apus/common-lib/utils/src/error';

import useAxios from '../hooks/useAxios';
import {
  createIntegrationService,
  IntegrationService,
} from '../api-client/integration-service';
import { TenantContext } from '../state/tenantContext';
import { VersionedIntegrations } from '@apus/common-lib/api/interface/integration-service';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';

const ErrorThrowingIntegrationService = (
  message: string
): IntegrationService => ({
  defineIntegration() {
    throw new BadRequest(message);
  },
  getIntegration() {
    throw new BadRequest(message);
  },
  getModuleConfiguration() {
    throw new BadRequest(message);
  },
  listIntegrations() {
    throw new BadRequest(message);
  },
  runIntegration() {
    throw new BadRequest(message);
  },
  listModuleConfigurations() {
    throw new BadRequest(message);
  },
  listModules() {
    throw new BadRequest(message);
  },
  listOperations() {
    throw new BadRequest(message);
  },
  exportIntegrations() {
    throw new BadRequest(message);
  },
  createAppVersion() {
    throw new BadRequest(message);
  },
  deleteIntegrations() {
    throw new BadRequest(message);
  },
  updateAppVersion() {
    throw new BadRequest(message);
  },
  getDefaultAppVersion() {
    throw new BadRequest(message);
  },
  listAppVersions() {
    throw new BadRequest(message);
  },
  setAppVersionToDefault() {
    throw new BadRequest(message);
  },
  getAction() {
    throw new BadRequest(message);
  },
  searchActions() {
    throw new BadRequest(message);
  },
  editAction() {
    throw new BadRequest(message);
  },
  retryActions() {
    throw new BadRequest(message);
  },
  testOperation() {
    throw new BadRequest(message);
  },
  testWorkflow() {
    throw new BadRequest(message);
  },
  runWorkflowTests() {
    throw new BadRequest(message);
  },
  executeControlAction() {
    throw new BadRequest(message);
  },
  upsertModuleConfiguration() {
    throw new BadRequest(message);
  },
  getApi() {
    throw new BadRequest(message);
  },
  listApis() {
    throw new BadRequest(message);
  },
  storeApi() {
    throw new BadRequest(message);
  },
  addIntegrationToApi() {
    throw new BadRequest(message);
  },
  executeApiCommand() {
    throw new BadRequest(message);
  },
  getApiCommandResult() {
    throw new BadRequest(message);
  },
});

function useIntegrationService(): IntegrationService {
  const [tenantState] = useContext(TenantContext);
  const axiosInstance = useAxios();

  return useMemo(() => {
    if (tenantState.tenant === undefined) {
      return ErrorThrowingIntegrationService('Tenant must be selected first');
    }

    if (axiosInstance === undefined)
      return ErrorThrowingIntegrationService(
        'Axios instance could not be defined'
      );

    return createIntegrationService(tenantState.tenant, axiosInstance);
  }, [tenantState.tenant, axiosInstance]);
}

export default useIntegrationService;
