import { IntegrationActionLifecycleNotification } from '@apus/common-lib/api/interface/integration-service';

import { initialWebsocketState, WebsocketState } from './websocketContext';
import { LimitedQueue } from '@apus/common-lib/utils/src/collections';

export enum WebsocketActionEnum {
  ActionLifecycleEventReceived = 'actionLifecycleEventReceived',
  ResetEventQueue = 'resetEventQueue',
  WebsocketOpening = 'websocketOpening',
  WebsocketStatusChanged = 'websocketStatusChanged',
}

export type WebsocketAction =
  | {
      type: WebsocketActionEnum.ResetEventQueue;
    }
  | {
      type: WebsocketActionEnum.ActionLifecycleEventReceived;
      event: IntegrationActionLifecycleNotification;
    }
  | {
      type: WebsocketActionEnum.WebsocketOpening;
    }
  | {
      type: WebsocketActionEnum.WebsocketStatusChanged;
      isOpen: boolean;
    };

export type WebsocketReducerContext = [
  WebsocketState,
  React.Dispatch<WebsocketAction>
];

export const resetEventQueue = (): WebsocketAction => {
  return {
    type: WebsocketActionEnum.ResetEventQueue,
  };
};

export const actionLifecycleEventReceived = (
  event: IntegrationActionLifecycleNotification
): WebsocketAction => {
  return {
    type: WebsocketActionEnum.ActionLifecycleEventReceived,
    event,
  };
};

export const websocketStatusChanged = (isOpen: boolean): WebsocketAction => {
  return {
    type: WebsocketActionEnum.WebsocketStatusChanged,
    isOpen,
  };
};

export const defaultDispatch: React.Dispatch<WebsocketAction> = () =>
  initialWebsocketState;

const actualWebsocketContextReducer = (
  state: WebsocketState,
  action: WebsocketAction
): WebsocketState => {
  switch (action.type) {
    case WebsocketActionEnum.ResetEventQueue: {
      return {
        ...state,
        events: new LimitedQueue<IntegrationActionLifecycleNotification>(),
      };
    }
    case WebsocketActionEnum.ActionLifecycleEventReceived: {
      return {
        ...state,
        events: state.events.add(
          action.event,
          e => e.actionId === action.event.actionId
        ),
      };
    }
    case WebsocketActionEnum.WebsocketOpening: {
      return {
        ...state,
        isOpening: true,
      };
    }
    case WebsocketActionEnum.WebsocketStatusChanged: {
      return {
        ...state,
        isOpen: action.isOpen,
        isOpening: false,
      };
    }
    default:
      return state;
  }
};

export const websocketContextReducer = (
  state: WebsocketState,
  action: WebsocketAction
): WebsocketState => {
  return actualWebsocketContextReducer(state, action);
};
