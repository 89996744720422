import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  SupportedMappingSchemaDefinition,
  SupportedSchemaDefinition,
} from '@apus/common-lib/api/interface/files';
import {
  ApiResultNode,
  IntegrationNodeType,
} from '@apus/common-lib/integration-engine/src/interface';
import { toJsonSchemaDefinition } from '@apus/common-lib/utils/src/data-utils';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { Box, FormControl, Stack, Typography } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

import { NodeBaseComponent } from './interface';
import DefineApiResultMapping from './mapping/DefineApiResultMapping';
import NodeFooter from './NodeFooter';
import NodeToolBar from './NodeToolBar';
import DefineSchema from '../../json/schema/DefineSchema';
import Collapsible from '../../surface/Collapsible';

interface NodeForm {
  name: string;
  description?: string;
  disableValidation?: boolean;
}

interface Props extends NodeBaseComponent<ApiResultNode> {
  integrationContextSchema: JSONSchema7;
  sourceId: string;
}

type PartialApiResultNode = Omit<
  ApiResultNode,
  'mappingSchema' | 'resultSchema'
> & {
  mappingSchema?: SupportedMappingSchemaDefinition;
  resultSchema?: SupportedSchemaDefinition;
};

const DefineApiResultNode = ({
  expanded,
  integrationContextSchema,
  sourceId,
  node,
  onCancel,
  onSave,
  onDelete,
}: Props): JSX.Element => {
  const [needsSaving, setNeedsSaving] = useState<boolean>(false);
  const [workingNode, setWorkingNode] = useState<
    PartialApiResultNode | undefined
  >(undefined);

  const form = useForm<NodeForm>({
    resolver: ajvResolver<NodeForm>({
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'name must be at least 1 characters long',
          },
          nullable: false,
        },
        description: {
          type: 'string',
          nullable: true,
        },
        disableValidation: {
          type: 'boolean',
          nullable: true,
        },
      },
      required: ['name'],
    }),
    defaultValues: {
      name: '',
      description: undefined,
    },
  });

  useEffect(() => {
    if (node !== undefined) {
      form.setValue('name', node.name);
      form.setValue('description', node.description);
      form.setValue('disableValidation', node.disableValidation);

      setWorkingNode(node);
    }
  }, [node, form]);

  useEffect(() => {
    if (node === undefined) {
      setWorkingNode({
        id: uuid(),
        nodeType: IntegrationNodeType.ApiResult,
        name: `API result`,
        // by default, validation should always be on
        disableValidation: false,
        resultSchema: toJsonSchemaDefinition({}),
        prev: sourceId,
      });

      form.setValue('name', `API result`);
    }
  }, [node, setWorkingNode, form, sourceId]);

  const updateMapping = (mappingSchema?: SupportedMappingSchemaDefinition) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        mappingSchema,
      };
      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateSchema = (schema?: SupportedSchemaDefinition) => {
    if (workingNode !== undefined && schema !== undefined) {
      const updated = {
        ...workingNode,
        resultSchema: schema,
      };
      setWorkingNode(updated);
    }
  };

  function updateNeedsSaving(current: PartialApiResultNode | undefined) {
    if (node !== undefined) {
      setNeedsSaving(!isEqual(node, current));
    } else {
      setNeedsSaving(true);
    }
  }

  function formUpdated() {
    const current =
      workingNode !== undefined
        ? {
            ...workingNode,
            ...form.getValues(),
          }
        : undefined;
    updateNeedsSaving(current);
  }

  const deleteNode = () => {
    if (workingNode !== undefined && onDelete !== undefined) {
      onDelete(workingNode as ApiResultNode);
    }
  };

  const submit = async (data: NodeForm) => {
    if (workingNode !== undefined) {
      if (workingNode.mappingSchema === undefined)
        throw new Error(`Cannot save api result node - mappingSchema missing`);

      onSave({
        ...workingNode,
        name: data.name,
        description: data.description,
        disableValidation: data.disableValidation,
      } as ApiResultNode);
    }
  };

  if (expanded !== true) return <></>;

  return (
    <Box sx={{ minWidth: 1024, padding: 2 }}>
      <NodeToolBar
        save={{
          onClick: form.handleSubmit(submit),
          disabled: !needsSaving,
        }}
        cancel={{
          onClick: onCancel,
        }}
        delete={{
          disabled: workingNode?.id === undefined,
          onClick: deleteNode,
        }}
      />
      <FormControl fullWidth margin="normal">
        <Typography variant={'h5'} sx={{ marginBottom: 3 }}>
          Define an API result
        </Typography>
        <Collapsible
          title="Describe the workflow node"
          subtitle="Add a name and description for the workflow node"
          disabled={workingNode === undefined}
        >
          <Stack spacing={2}>
            <HookFormTextInput
              name="name"
              form={form}
              label="Workflow operation identifier"
              helperText="Results from this workflow operation will be provided in workflow context using this identifier. Must be in camel case."
              disabled={workingNode === undefined}
              onValueChange={formUpdated}
            />
            <HookFormTextInput
              name="description"
              form={form}
              label="Workflow operation description"
              disabled={workingNode === undefined}
              onValueChange={formUpdated}
            />
            <HookFormCheckbox
              name={'disableValidation'}
              label={'Disable validation'}
              form={form}
              onValueChange={formUpdated}
            />
          </Stack>
        </Collapsible>
        <Collapsible
          title={'Define the result data model'}
          defaultExpanded={false}
        >
          <Stack spacing={2}>
            <DefineSchema
              propertyName={`resultSchema`}
              title="Define trigger schema"
              schema={workingNode?.resultSchema}
              onSchemaSave={updateSchema}
            />
          </Stack>
        </Collapsible>
        <Collapsible
          title="Define result mapping"
          subtitle="The outcome of this operation will be defined as the end result of the API call"
          disabled={workingNode === undefined}
          defaultExpanded={false}
        >
          <DefineApiResultMapping
            integrationContextSchema={integrationContextSchema}
            node={workingNode as ApiResultNode}
            onChange={updateMapping}
          />
        </Collapsible>
      </FormControl>
      <NodeFooter />
    </Box>
  );
};

export default DefineApiResultNode;
