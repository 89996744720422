import React, { useEffect, useState, JSX } from 'react';
import { UseFormReturn } from 'react-hook-form';

import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack } from '@mui/material';

import { EditableSchemaItem } from './interface';

enum StringFormat {
  dateTime = 'date-time',
  date = 'date',
  time = 'time',
  email = 'email',
  idnEmail = 'idn-email',
  hostname = 'hostname',
  idnHostname = 'idn-hostname',
  ipv4 = 'ipv4',
  ipv6 = 'ipv6',
  uuid = 'uuid',
  uri = 'uri',
  uriReference = 'uri-reference',
  iri = 'iri',
  iriReference = 'iri-reference',
  uriTemplate = 'uri-template',
  jsonPointer = 'json-pointer',
  relativeJsonPointer = 'relative-json-pointer',
  regex = 'regex',
}

interface Props {
  form: UseFormReturn<EditableSchemaItem>;
  readOnly?: boolean;
}

const StringForm = ({ form, readOnly }: Props): JSX.Element => {
  const [isEnum, setIsEnum] = useState<boolean>(false);

  useEffect(() => {
    setIsEnum(form.getValues('useEnum') ?? false);
  }, [form, setIsEnum]);

  return (
    <Stack spacing={2}>
      <HookFormTextInput
        name="default"
        disabled={readOnly}
        form={form}
        label="Default value"
        defaultValue={''}
      />
      <HookFormAutocomplete
        name="format"
        disabled={readOnly}
        form={form}
        label="Format"
        disablePortal
        isOptionEqualToValue={(option, value) => option === value}
        // Add empty string to options to support the initial value
        options={[''].concat(Object.values(StringFormat))}
      />
      <HookFormTextInput
        name="pattern"
        disabled={readOnly}
        form={form}
        label="Pattern"
        defaultValue={''}
      />
      <HookFormCheckbox
        name="useEnum"
        disabled={readOnly}
        form={form}
        label={'Is this an enum?'}
        defaultChecked={false}
        onValueChange={value => setIsEnum(value ?? false)}
      />
      <HookFormTextInput
        name="enum"
        form={form}
        multiline={true}
        minRows={5}
        label="Enum values - strings separated by line break"
        defaultValue={''}
        disabled={readOnly || !isEnum}
      />
    </Stack>
  );
};

export default StringForm;
