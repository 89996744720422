import React, { useEffect, useState, JSX } from 'react';

import {
  AppSubscription,
  SubscriptionStatus,
} from '@apus/common-lib/api/interface/subscriptions';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Box, Grid } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { systemglueAppSubscriptionNames } from './apps/names';

function statusProps(status: SubscriptionStatus): {
  borderColor: string;
  bgcolor: string;
  text: string;
} {
  switch (status) {
    case SubscriptionStatus.CREATED:
      return { borderColor: 'black', bgcolor: 'white', text: 'Tentative' };
    case SubscriptionStatus.CONFIGURING:
      return { borderColor: 'black', bgcolor: '#fff176', text: 'Configuring' };
    case SubscriptionStatus.CONFIGURED:
      return { borderColor: 'black', bgcolor: '#aed581', text: 'Configured' };
    case SubscriptionStatus.CONFIGURATION_FAILED:
      return {
        borderColor: 'black',
        bgcolor: 'red',
        text: 'Configuration failed',
      };
    case SubscriptionStatus.ACTIVE:
      return { borderColor: 'black', bgcolor: '#b2ff59', text: 'Active' };
    case SubscriptionStatus.CANCELLING:
      return { borderColor: 'black', bgcolor: '#ffb74d', text: 'Cancelling' };
    case SubscriptionStatus.CANCELLED:
      return { borderColor: 'black', bgcolor: '#e0e0e0', text: 'Cancelled' };
    default:
      throw new Error(
        `Cannot define status props - unsupported status ${status}`
      );
  }
}

function Status({ status }: { status: SubscriptionStatus }): JSX.Element {
  const { borderColor, bgcolor, text } = statusProps(status);

  return (
    <Box
      width={'100%'}
      maxWidth={'150px'}
      sx={{
        border: 0.5,
        borderColor,
        boxShadow: 1,
        padding: 1,
        borderRadius: 1,
        bgcolor,
      }}
      display="flex"
      alignItems={'center'}
      justifyContent="center"
    >
      <Typography variant={'subtitle2'} textTransform={'uppercase'}>
        {text}
      </Typography>
    </Box>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface Props {
  subscription: AppSubscription;
  disabled?: boolean;
  defaultExpanded?: boolean;
  children?: JSX.Element | JSX.Element[];
}

const SubscriptionElement = ({
  subscription,
  disabled,
  defaultExpanded = false,
  children,
}: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (disabled) {
      setIsDisabled(true);
      setExpanded(false);
    } else {
      setIsDisabled(false);
      setExpanded(defaultExpanded);
    }
  }, [disabled, setIsDisabled, setExpanded, defaultExpanded]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        if (!isDisabled) setExpanded(!expanded);
      }}
      sx={{
        border: 0.5,
        margin: 0.5,
        padding: 2,
        borderRadius: 2,
      }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant={'h6'}
              color={isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
            >
              {systemglueAppSubscriptionNames[subscription.app.name]}
            </Typography>
            <Typography
              variant={'subtitle2'}
              color={isDisabled ? 'rgba(0, 0, 0, 0.38)' : 'inherit'}
            >
              {subscription.subscriptionDetails.name}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <Status status={subscription.status} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SubscriptionElement;
