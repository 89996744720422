export enum SystemglueMonthlyActionCountLevel {
  BASIC = 'Basic',
  CUSTOM = 'Custom',
}

export enum SystemglueActionStorageDurationLevel {
  BASIC = 'Basic',
  CUSTOM = 'Custom',
}

export interface SystemglueAppConfiguration {
  monthlyActions: {
    serviceLevel: SystemglueMonthlyActionCountLevel;
    customValue?: number;
  };
  actionStorageDuration: {
    serviceLevel: SystemglueActionStorageDurationLevel;
    customValue?: number;
  };
}
