import { JSX } from 'react';
import { Routes, Route } from 'react-router-dom';

import AppManagementView from './tools/AppManagementView';
import BankingFileTemplatesManagementView from './tools/BankingFileTemplatesManagementView';
import ErrorHandlingView from './tools/ErrorHandlingView';
import OperationImportView from './tools/OperationImportView';
import OperationManagementView from './tools/OperationManagementView';
import useSetAppBarRoutes from '../../hooks/useSetAppBarRoutes';
import { toolsRoutes } from '../../routes/service-provider-routes';

const ToolsView = (): JSX.Element => {
  useSetAppBarRoutes(toolsRoutes);

  return (
    <Routes>
      <Route
        path="/banking-template-management"
        element={<BankingFileTemplatesManagementView />}
      />
      <Route path="/error-handling" element={<ErrorHandlingView />} />
      <Route
        path="/operation-management"
        element={<OperationManagementView />}
      />
      <Route path="/app-management/*" element={<AppManagementView />} />
      <Route path="/operation-import" element={<OperationImportView />} />
    </Routes>
  );
};

export default ToolsView;
