import * as React from 'react';
import { useEffect } from 'react';

import { IntegrationOperation } from '@apus/common-lib/integrations/src/interface';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { cloneDeep } from 'lodash';

function not(
  a: readonly IntegrationOperationWrapper[],
  b: readonly IntegrationOperationWrapper[]
) {
  return a.filter(
    value => b.find(o => o.operationId === value.operationId) === undefined
  );
}

function intersection(
  a: readonly IntegrationOperationWrapper[],
  b: readonly IntegrationOperationWrapper[]
) {
  return a.filter(
    value => b.find(o => o.operationId === value.operationId) !== undefined
  );
}

function union(
  a: readonly IntegrationOperationWrapper[],
  b: readonly IntegrationOperationWrapper[]
) {
  return [...a, ...not(b, a)];
}

interface Props {
  operations: IntegrationOperation[];
  onSelected: (operations: IntegrationOperation[]) => void;
}

interface IntegrationOperationWrapper extends IntegrationOperation {
  displayProperties: {
    isOpenAPI: boolean;
    titlePrefix?: string;
    title: string;
    description: string;
  };
}

function wrap(operation: IntegrationOperation): IntegrationOperationWrapper {
  if (
    operation.configuration?.hasOwnProperty('path') &&
    operation.configuration?.hasOwnProperty('operation')
  ) {
    return {
      ...operation,
      displayProperties: {
        isOpenAPI: true,
        titlePrefix: `${operation.operationId}:`,
        title: `${(
          operation.configuration?.['operation'] as string
        ).toUpperCase()} ${operation.configuration?.['path']}`,
        description: `${operation.description}`,
      },
    };
  }

  return {
    ...operation,
    displayProperties: {
      isOpenAPI: false,
      title: `${operation.operationId ?? operation.title}`,
      description: `${operation.description}`,
    },
  };
}

function unwrap(wrapped: IntegrationOperationWrapper): IntegrationOperation {
  const operation = cloneDeep(wrapped);

  delete (operation as Partial<IntegrationOperationWrapper>).displayProperties;

  return operation;
}

function OperationItemText({
  value,
  labelId,
}: {
  value: IntegrationOperationWrapper;
  labelId: string;
}): JSX.Element {
  if (value.displayProperties.isOpenAPI) {
    return (
      <ListItemText
        id={labelId}
        primary={`${value.displayProperties.titlePrefix} ${value.displayProperties.title}`}
        secondary={`${value.displayProperties.description}`}
      />
    );
  }

  return (
    <ListItemText
      id={labelId}
      primary={`${value.displayProperties.title}`}
      secondary={`${value.displayProperties.description}`}
    />
  );
}

const OperationTransferList = ({
  operations,
  onSelected,
}: Props): JSX.Element => {
  const [checked, setChecked] = React.useState<
    readonly IntegrationOperationWrapper[]
  >([]);
  const [left, setLeft] = React.useState<
    readonly IntegrationOperationWrapper[]
  >([]);
  const [right, setRight] = React.useState<
    readonly IntegrationOperationWrapper[]
  >([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLeft(operations.map(wrap));
    setRight([]);
    setChecked([]);
  }, [operations, setLeft, setRight, setChecked]);

  const handleToggle = (value: IntegrationOperationWrapper) => () => {
    const currentIndex = checked.findIndex(
      o => o.operationId === value.operationId
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly IntegrationOperationWrapper[]) =>
    intersection(checked, items).length;

  const handleToggleAll =
    (items: readonly IntegrationOperationWrapper[]) => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };

  const handleCheckedRight = () => {
    const selected = right.concat(leftChecked);
    setRight(selected);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));

    onSelected(selected.map(o => unwrap(o)));
  };

  const handleCheckedLeft = () => {
    const selected = not(right, rightChecked);

    setLeft(left.concat(rightChecked));
    setRight(selected);
    setChecked(not(checked, rightChecked));

    onSelected(selected.map(o => unwrap(o)));
  };

  const customList = (
    title: React.ReactNode,
    items: readonly IntegrationOperationWrapper[]
  ) => {
    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                'aria-label': 'all items selected',
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List
          sx={{
            width: '100%',
            height: 400,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
          {[...items]
            .sort((a, b) =>
              (
                a.displayProperties.titlePrefix ?? a.displayProperties.title
              ).localeCompare(
                b.displayProperties.titlePrefix ?? b.displayProperties.title
              )
            )
            .map((value: IntegrationOperationWrapper, index) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem
                  key={index}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.some(
                        o => o.operationId === value.operationId
                      )}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </ListItemIcon>
                  <OperationItemText value={value} labelId={labelId} />
                </ListItem>
              );
            })}
        </List>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        {customList('Selected', left)}
      </Grid>
      <Grid item xs={'auto'}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList('Selected', right)}
      </Grid>
    </Grid>
  );
};

export default OperationTransferList;
