import { JSX } from 'react';

import {
  BankTransactionHandlingRule,
  BankTransactionHandlingRuleHandler,
  ErpEntityIdentificationRule,
  ErpEntityIdentificationRuleHandler,
  ErpTransactionIdentificationRule,
  ErpTransactionIdentificationRuleHandler,
  Rule,
  RuleGroup,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import { Box } from '@mui/material';

import DefineBankTransactionHandlingRule from './DefineBankTransactionHandlingRule';
import DefineEntityIdentificationRule from './DefineEntityIdentificationRule';
import DefineTransactionIdentificationRule from './DefineTransactionIdentificationRule';
import { BankTransactionRuleConfigurationData } from './interface';

type SupportedRule = Rule<RuleGroup, any>;

interface Props<T extends SupportedRule> {
  rule: T;
  configurationData?: BankTransactionRuleConfigurationData;
  onChange: (rule: T) => void;
  disabled?: boolean;
}

function RuleHandler<T extends SupportedRule>({
  disabled,
  rule,
  configurationData,
  onChange,
}: Pick<
  Props<T>,
  'rule' | 'onChange' | 'disabled' | 'configurationData'
>): JSX.Element {
  switch (rule.ruleGroup) {
    case RuleGroup.ERP_ENTITY_IDENTIFICATION_RULE:
      return (
        <DefineEntityIdentificationRule
          rule={
            rule as ErpEntityIdentificationRule<ErpEntityIdentificationRuleHandler>
          }
          onChange={
            onChange as (
              rule: ErpEntityIdentificationRule<ErpEntityIdentificationRuleHandler>
            ) => void
          }
          disabled={disabled}
        />
      );
    case RuleGroup.ERP_TRANSACTION_IDENTIFICATION_RULE:
      return (
        <DefineTransactionIdentificationRule
          rule={
            rule as ErpTransactionIdentificationRule<ErpTransactionIdentificationRuleHandler>
          }
          onChange={
            onChange as (
              rule: ErpTransactionIdentificationRule<ErpTransactionIdentificationRuleHandler>
            ) => void
          }
          disabled={disabled}
        />
      );
    case RuleGroup.BANK_TRANSACTION_HANDLING_RULE:
      return (
        <DefineBankTransactionHandlingRule
          rule={
            rule as unknown as BankTransactionHandlingRule<BankTransactionHandlingRuleHandler>
          }
          onChange={
            onChange as unknown as (
              rule: BankTransactionHandlingRule<BankTransactionHandlingRuleHandler>
            ) => void
          }
          disabled={disabled}
          configurationData={configurationData}
        />
      );
  }
}

const DefineRule = <T extends SupportedRule>({
  disabled,
  rule,
  configurationData,
  onChange,
}: Props<T>): JSX.Element => {
  return (
    <Box marginTop={2}>
      <RuleHandler
        key={rule.ruleId}
        rule={rule}
        configurationData={configurationData}
        onChange={onChange}
        disabled={disabled}
      ></RuleHandler>
    </Box>
  );
};

export default DefineRule;
