import { JSX, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ExposedApi } from '@apus/common-lib/api/interface/exposed-api';
import useApiCall from '@apus/common-ui/hooks/useApiCallHook';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';

import DefineExposedApi from '../../../components/apis/DefineExposedApi';
import useSetAppBarRoutes from '../../../hooks/useSetAppBarRoutes';
import { apiRoutes } from '../../../routes/service-provider-routes';

const ApiView = (): JSX.Element => {
  useSetAppBarRoutes(apiRoutes);

  const integrationService = useIntegrationService();
  const { apiId } = useParams();

  const getApi = useCallback(() => {
    if (apiId !== undefined) return integrationService.getApi(apiId);
    return Promise.resolve(undefined);
  }, [integrationService, apiId]);

  const [, api] = useApiCall<ExposedApi | undefined>(getApi, [getApi]);

  if (api === undefined) return <></>;

  return <DefineExposedApi api={api} />;
};

export default ApiView;
