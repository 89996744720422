import { SystemglueAppName } from '@apus/common-lib/api/interface/common';

export const systemglueAppNames: Record<SystemglueAppName, string> = {
  [SystemglueAppName.E_INVOICE_INTEGRATION_APP]: 'E-invoices',
  [SystemglueAppName.PAYMENT_INTEGRATION_APP]: 'Bank payments',
  [SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP]: 'Bank transactions',
  [SystemglueAppName.BASE_INTEGRATION_APP]: 'Platform',
};

export const systemglueAppSubscriptionNames: Record<SystemglueAppName, string> =
  {
    [SystemglueAppName.E_INVOICE_INTEGRATION_APP]:
      'E-invoice integration -subscription',
    [SystemglueAppName.PAYMENT_INTEGRATION_APP]:
      'Bank payment integration -subscription',
    [SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP]:
      'Bank transaction integration -subscription',
    [SystemglueAppName.BASE_INTEGRATION_APP]: 'Systemglue -subscription',
  };
