import React, { JSX } from 'react';

import SystemglueBaseEditor from '../base/SystemglueBaseEditor';

interface Props {
  allowUndefined?: boolean;
  readOnly?: boolean;
  title?: string;
  helperText?: string;
  value?: Record<string, unknown>;
  onChange: (value: Record<string, unknown> | undefined) => void;
  height?: string;
}

const DefineJsonHandler = ({
  allowUndefined = false,
  readOnly = false,
  title,
  helperText,
  value,
  onChange,
  height = '60vh',
}: Props): JSX.Element => {
  return (
    <SystemglueBaseEditor
      allowUndefined={allowUndefined}
      title={title}
      helperText={helperText}
      value={JSON.stringify(value, null, 2)}
      height={height}
      language="json"
      onValueChange={content => {
        if (content !== undefined) {
          try {
            onChange(JSON.parse(content));
          } catch (e) {}
        }
      }}
      options={{
        readOnly: readOnly,
        minimap: { enabled: false },
        lineNumbers: 'off',
      }}
    />
  );
};

export default DefineJsonHandler;
