import { createContext } from 'react';

import { AccessTokenReducerContext } from './accessTokenReducer';

export interface AccessTokenState {
  accessToken: string | undefined;
}

export const initialAccessTokenState: AccessTokenState = {
  accessToken: undefined,
};

export const AccessTokenContext = createContext<AccessTokenReducerContext>([
  initialAccessTokenState,
  () => initialAccessTokenState,
]);
