import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { SchemaChrootConfiguration } from '@apus/common-lib/integration-engine/src/interface';
import { resolveSchemaAndPointerFromObjectPath } from '@apus/common-lib/json-data-mapper/src/schema-utils';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import { JSONSchema7 } from 'json-schema';

import ChangeSchemaRootTreeView from '../../../json/schema/ChangeSchemaRootTreeView';

interface Props {
  schema?: JSONSchema7;
  chroot: SchemaChrootConfiguration | undefined;
  onChange?: (configuration: SchemaChrootConfiguration | undefined) => void;
}

const DefineChroot = ({ schema, chroot, onChange }: Props): JSX.Element => {
  // TODO: I'm not sure that need the form at all...
  const form = useForm<SchemaChrootConfiguration>({
    defaultValues: { enabled: false, schemaPath: undefined },
  });
  const [enabled, setEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (schema === undefined) form.reset();

    if (chroot !== undefined) {
      form.setValue('enabled', chroot.enabled);
      form.setValue('schemaPath', chroot.schemaPath);
    } else {
      form.reset();
    }
  }, [schema, chroot, form]);

  if (schema === undefined) return <></>;

  return (
    <>
      <HookFormCheckbox
        name={'enabled'}
        label={'Enable root modification'}
        form={form}
        value={chroot?.enabled}
        onValueChange={value => {
          setEnabled(value ?? false);
          if (onChange !== undefined)
            onChange({
              ...form.getValues(),
              enabled: value ?? false,
            });
        }}
      />
      <Controller
        name={'schemaPath'}
        control={form.control}
        render={({ field }) => (
          <ChangeSchemaRootTreeView
            schema={schema}
            value={field.value}
            onAction={item => {
              if (item !== undefined) {
                const [schemaPath] = resolveSchemaAndPointerFromObjectPath(
                  schema,
                  item.objectPath
                );
                field.onChange(schemaPath);

                if (onChange !== undefined)
                  onChange({
                    ...form.getValues(),
                    schemaPath: schemaPath,
                  });
              } else {
                field.onChange(undefined);
                if (onChange !== undefined)
                  onChange({
                    ...form.getValues(),
                    schemaPath: undefined,
                  });
              }
            }}
            edit={enabled}
          />
        )}
      />
    </>
  );
};

export default DefineChroot;
