import { useContext, useEffect } from 'react';

import { MapperRoute } from '../components/dashboard/SideNavItems';
import { AppContext } from '../state/appContext';
import { setAppBarNavItems } from '../state/appReducer';

interface AppBarRoutes {
  routes: MapperRoute[];
}

function useSetAppBarRoutes(routes: MapperRoute[]): AppBarRoutes {
  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    dispatch(setAppBarNavItems(routes));
    return () => {
      dispatch(setAppBarNavItems([]));
    };
  }, [routes, dispatch]);

  return { routes: state.appBarRoutes };
}

export default useSetAppBarRoutes;
