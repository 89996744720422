import * as React from 'react';
import { JSX, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface MapperRoute {
  text: string;
  path: string;
  icon?: React.ReactNode;
  subRoutes?: MapperRoute[];
}

interface Props {
  open?: boolean;
  routes: MapperRoute[];
}

// TODO: activate route highlighting?
export default function SideNavItems({ open, routes }: Props): JSX.Element {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentSubRoutes, setCurrentSubRoutes] = useState<
    undefined | MapperRoute[]
  >(undefined);

  const handleMouseOverMenuItem = (
    event: React.MouseEvent<HTMLElement>,
    path: string
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentSubRoutes(routes.find(r => r.path === path)?.subRoutes);
  };

  const handleSubMenuClose = () => {
    setAnchorEl(null);
    setCurrentSubRoutes(undefined);
  };

  const handleMenuItemClick = (path?: string) => {
    setAnchorEl(null);
    setCurrentSubRoutes(undefined);
    if (path !== undefined) navigate(path);
  };

  return (
    <div>
      <List sx={{ pointerEvents: 'auto' }}>
        <Menu
          id={`sub-menu`}
          open={
            anchorEl !== null &&
            currentSubRoutes !== undefined &&
            currentSubRoutes.length > 0
          }
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          MenuListProps={{
            onMouseLeave: handleSubMenuClose,
          }}
          variant={'menu'}
          onClose={handleSubMenuClose}
          sx={{
            zIndex: 101,
          }}
        >
          {currentSubRoutes !== undefined &&
            currentSubRoutes.map(route => (
              <MenuItem
                key={route.path}
                onClick={() => handleMenuItemClick(route.path)}
              >
                {route.text}
              </MenuItem>
            ))}
        </Menu>

        {routes.map(route => (
          <NavLink
            to={route.path}
            key={route.text}
            onClick={handleSubMenuClose}
          >
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                dense
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onMouseOver={event =>
                  handleMouseOverMenuItem(event, route.path)
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {route.icon}
                </ListItemIcon>
                <ListItemText
                  primary={route.text}
                  primaryTypographyProps={{ fontWeight: 'bold' }}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );
}
