import React, { JSX, useCallback, useEffect, useState } from 'react';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import {
  ApiError,
  SystemglueAppName,
} from '@apus/common-lib/api/interface/common';
import { VersionedIntegrations } from '@apus/common-lib/api/interface/integration-service';
import ShowApiCallStatus from '../alert/ShowApiCallStatus';
import { Box, Grid, Typography } from '@mui/material';
import { bodyMaxHeight } from '../../theme/theme';
import AppVersionList from './AppVersionList';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import AppVersionEditor from './AppVersionEditor';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { systemglueAppNames } from '../subscriptions/apps/names';

interface Props {
  appName: SystemglueAppName;
}

const AppVersionManagementTool = ({ appName }: Props): JSX.Element => {
  const integrationService = useIntegrationService();
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [defaultAppVersion, setDefaultAppVersion] = useState<
    string | undefined
  >(undefined);
  const [appVersion, setAppVersion] = useState<
    VersionedIntegrations | undefined
  >(undefined);
  const [appVersionList, setAppVersionList] = useState<VersionedIntegrations[]>(
    []
  );
  const [isLoadingAppVersionList, setIsLoadingAppVersionList] =
    useState<boolean>(false);
  const [errorLoadingAppVersionList, setErrorLoadingAppVersionList] = useState<
    ApiError | undefined
  >(undefined);

  const listAppVersions = useCallback(async () => {
    await executeApiCall({
      callFunction: () => integrationService.listAppVersions(appName),
      setResult: setAppVersionList,
      setPending: setIsLoadingAppVersionList,
      setError: setErrorLoadingAppVersionList,
    });
  }, [
    appName,
    integrationService,
    setAppVersionList,
    setIsLoadingAppVersionList,
    setErrorLoadingAppVersionList,
  ]);

  const getDefaultAppVersion = useCallback(async () => {
    await executeApiCall({
      callFunction: () => integrationService.getDefaultAppVersion(appName),
      setResult: setDefaultAppVersion,
    });
  }, [appName, integrationService, setDefaultAppVersion]);

  useEffect(() => {
    (async () => {
      await getDefaultAppVersion();
      await listAppVersions();
    })();
  }, [appName, listAppVersions, getDefaultAppVersion]);

  const onSelect = (template: VersionedIntegrations | undefined) => {
    setAppVersion(template);
  };

  const onEdit = (template: VersionedIntegrations | undefined) => {
    setAppVersion(template);
    setEditMode(true);
  };

  const onAdd = () => {
    setAppVersion(undefined);
    setEditMode(true);
  };

  const onSave = async () => {
    setEditMode(false);
    await listAppVersions();
    await getDefaultAppVersion();
  };
  const onCancel = () => {
    setEditMode(false);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={12}>
        <Box flexGrow={1} flexDirection={'row'} display={'flex'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            aria-label="Back"
            component="label"
            size={'small'}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Typography fontWeight={'bold'} variant={'h5'} marginLeft={5}>
            Configure {systemglueAppNames[appName]}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ShowApiCallStatus
          infoText={'loading app versions'}
          loading={isLoadingAppVersionList}
          error={errorLoadingAppVersionList}
        />
      </Grid>
      <Grid item xs={2}>
        <AppVersionList
          defaultAppVersion={defaultAppVersion}
          appVersions={appVersionList}
          onSelect={onSelect}
          onEdit={onEdit}
          onAdd={onAdd}
        />
      </Grid>
      <Grid item xs={10}>
        {appVersion !== undefined && (
          <AppVersionEditor
            defaultAppVersion={defaultAppVersion}
            editMode={editMode}
            appVersion={appVersion}
            onSave={onSave}
            onCancel={onCancel}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AppVersionManagementTool;
