import React, {
  JSX,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IntegrationDefinition } from '@apus/common-lib/api/interface/integration-service';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import DefineIntegration from '../../../components/workflow/timeline/DefineIntegration';
import { AppContext } from '../../../state/appContext';
import { setIntegrations } from '../../../state/appReducer';
import { bodyMaxHeight } from '../../../theme/theme';

const ApiCommandView = (): JSX.Element => {
  const [integration, setIntegration] = useState<IntegrationDefinition>();
  const [appState, dispatch] = useContext(AppContext);

  const navigate = useNavigate();
  const integrationService = useIntegrationService();
  const { integrationId } = useParams();

  const getIntegration = useCallback(() => {
    if (integrationId !== undefined)
      return integrationService.getIntegration(integrationId);
    return Promise.resolve(undefined);
  }, [integrationService, integrationId]);

  useEffect(() => {
    const call = async () => {
      await executeApiCall({
        callFunction: getIntegration,
        setResult: setIntegration,
      });
    };

    call();
  }, [getIntegration]);

  async function onSave() {
    dispatch(setIntegrations(await integrationService.listIntegrations()));
  }

  if (appState.integrationModules === undefined)
    return (
      <Box>
        <Typography variant={'subtitle1'}>Loading modules...</Typography>
        <CircularProgress />
      </Box>
    );

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={1}>
        <Box flexGrow={1} flexDirection={'row'} display={'flex'}>
          <Button
            variant={'contained'}
            color={'secondary'}
            aria-label="Back"
            component="label"
            size={'small'}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
      </Grid>
      <Grid item xs={11}></Grid>
      <Grid item xs={12}>
        <DefineIntegration
          modules={appState.integrationModules}
          integrations={appState.integrations}
          integration={integration}
          onSave={onSave}
        />
      </Grid>
    </Grid>
  );
};

export default ApiCommandView;
