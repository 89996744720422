import React, { JSX, useRef, useState } from 'react';

import Editor, { EditorProps } from '@monaco-editor/react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { Resizable } from 're-resizable';

const resizableStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 1px #ddd',
  background: '#fffffff',
} as const;

interface CustomProps {
  allowUndefined?: boolean;
  readOnly?: boolean;
  title?: string;
  helperText?: string;
  onValueChange: (value: string | undefined) => void;
  minHeight?: number;
  children?: JSX.Element | JSX.Element[] | boolean;
  sidePanelWidthPercentage?: number;
}

type Props = CustomProps & EditorProps;

const SystemglueBaseEditor = ({
  allowUndefined,
  value,
  title,
  readOnly,
  helperText,
  onValueChange,
  minHeight = 300,
  sidePanelWidthPercentage = 30,
  children,
  ...editorProps
}: Props): JSX.Element => {
  const cache = useRef<string | undefined>(undefined);
  const [isUndefined, setIsUndefined] = useState<boolean>(false);

  function toggleUndefined(checked: boolean) {
    setIsUndefined(checked);

    if (checked) {
      onValueChange(cache.current);
    } else {
      onValueChange(undefined);
    }
  }

  function contentUpdated(content: string | undefined) {
    cache.current = content;
    onValueChange(content);
  }

  if (value === undefined) return <></>;

  return (
    <Grid container>
      <Grid item xs={10} display={'flex'} alignItems={'center'}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={2}>
        {allowUndefined && (
          <FormControlLabel
            control={
              <Checkbox
                size={'small'}
                disabled={readOnly}
                checked={isUndefined}
                onChange={event => {
                  toggleUndefined(event.target.checked);
                }}
              />
            }
            label="Undefined"
          />
        )}
      </Grid>
      {helperText !== undefined && (
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <Typography variant={'subtitle2'}>{helperText}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <Resizable
            style={resizableStyle}
            minHeight={minHeight}
            maxWidth={'100%'}
            defaultSize={{
              width: `${
                children !== undefined ? 100 - sidePanelWidthPercentage : 99
              }%`,
              height: minHeight,
            }}
          >
            <Editor
              value={value}
              onChange={contentUpdated}
              {...editorProps}
              height={'100%'}
            />
          </Resizable>
          <Box
            style={{
              ...resizableStyle,
              width: '100%',
              minWidth: '1px',
            }}
          >
            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SystemglueBaseEditor;
