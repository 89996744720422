import { JSX } from 'react';
import { ModuleConfigurationAction } from '@apus/common-lib/api/interface/integration-service';
import { FormGroup } from '@mui/material';
import { useForm } from 'react-hook-form';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';

function actionLabel(action: ModuleConfigurationAction): string {
  if (action === 'tenant-authorization') return 'Require tenant authorization';
  return 'Unsupported action';
}

const ConfigureModuleActions = ({
  actions,
  onChange,
}: {
  actions?: ModuleConfigurationAction[];
  onChange: (actions: ModuleConfigurationAction[]) => void;
}): JSX.Element => {
  const form = useForm<Record<ModuleConfigurationAction, boolean>>();
  if (actions === undefined || actions.length === 0) return <></>;

  function formChanged() {
    const values = form.getValues();
    const changed = Object.keys(values)
      .filter(action => values[action as ModuleConfigurationAction])
      .map(v => v as ModuleConfigurationAction);

    onChange(changed);
  }

  return (
    <FormGroup>
      {actions.map(action => {
        return (
          <HookFormCheckbox
            name={action}
            label={actionLabel(action)}
            form={form}
            onValueChange={formChanged}
          />
        );
      })}
    </FormGroup>
  );
};

export default ConfigureModuleActions;
