import { JSX } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { ConfigurationValueSchema } from '@apus/common-lib/integrations/src/interface';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormNumberRangeInput from '@apus/common-ui/components/hook-form/HookFormSlider';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { SliderValueLabelProps, Tooltip } from '@mui/material';

interface Props<FormType extends FieldValues> {
  disabled?: boolean;
  name: string;
  schema: ConfigurationValueSchema;
  form: UseFormReturn<FormType>;
  key?: string;
  // TODO: maybe add the parameter - for now this is needed only to notify parents of change
  onChange?: () => void;
}

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

function InputFromSchema<FormType extends FieldValues>({
  disabled = false,
  name,
  schema,
  form,
  onChange,
}: Props<FormType>): JSX.Element {
  switch (schema.type) {
    case 'string': {
      if (schema.enum !== undefined) {
        return (
          <HookFormAutocomplete
            name={name}
            label={schema.title}
            options={schema.enum}
            form={form}
            disabled={disabled}
            onValueChange={() => {
              if (onChange !== undefined) onChange();
            }}
          />
        );
      }
      if (schema.maxLength !== undefined && schema.maxLength > 256) {
        return (
          <HookFormTextInput
            name={name}
            multiline={true}
            minRows={15}
            label={schema.title}
            defaultValue={schema.default ?? ''}
            disabled={disabled || schema.const !== undefined}
            helperText={schema.description}
            form={form}
            onValueChange={() => {
              if (onChange !== undefined) onChange();
            }}
          />
        );
      }
      return (
        <HookFormTextInput
          name={name}
          label={schema.title}
          defaultValue={schema.default ?? ''}
          disabled={disabled || schema.const !== undefined}
          helperText={schema.description}
          form={form}
          onValueChange={() => {
            if (onChange !== undefined) onChange();
          }}
        />
      );
    }
    case 'integer': {
      if (schema.maximum !== undefined) {
        return (
          <HookFormNumberRangeInput
            name={name}
            label={schema.title}
            valueLabelDisplay="auto"
            components={{
              ValueLabel: ValueLabelComponent,
            }}
            min={schema.minimum ?? 0}
            max={schema.maximum}
            aria-label="custom thumb label"
            defaultValue={(schema.default as number) ?? 0}
            disabled={disabled || schema.const !== undefined}
            form={form}
            onValueChange={() => {
              if (onChange !== undefined) onChange();
            }}
          />
        );
      }
      return (
        <HookFormTextInput
          name={name}
          label={schema.title}
          defaultValue={schema.default ?? ''}
          disabled={disabled || schema.const !== undefined}
          helperText={schema.description}
          form={form}
          type={'number'}
          onValueChange={() => {
            if (onChange !== undefined) onChange();
          }}
        />
      );
    }
    case 'boolean': {
      return (
        <HookFormCheckbox
          name={name}
          defaultChecked={schema.default === true}
          label={schema.title}
          disabled={disabled || schema.const !== undefined}
          form={form}
          onValueChange={() => {
            if (onChange !== undefined) onChange();
          }}
        />
      );
    }
    default:
      return <></>;
  }
}

export default InputFromSchema;
