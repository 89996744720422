import { MouseEventHandler, useEffect, useState } from 'react';

import { IntegrationDefinitionBase } from '@apus/common-lib/api/interface/integration-service';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';

interface Props<T extends IntegrationDefinitionBase> {
  name: string;
  label: string;
  integrations: T[];
  onIntegrationSelected?: (integration: T | undefined) => void;
  integration?: T;
  disabled?: boolean;
  displayButton?: boolean;
}

/**
 * use empty operation as the initial value for autocomplete so that we don't get those annoying
 * controlled/uncontrolled -state change warnings and errors
 */
const emptyOperation = {
  description: '',
  integrationId: '',
  name: '',
};

const IntegrationSelector = <T extends IntegrationDefinitionBase>({
  onIntegrationSelected,
  name,
  label,
  integrations,
  integration,
  disabled,
  displayButton = true,
  ...props
}: Props<T>): JSX.Element => {
  const [selectedIntegration, setSelectedIntegration] = useState<T | undefined>(
    integration
  );

  const propagateIntegration = (integration?: T) => {
    if (disabled) throw new Error('Integration selector is disabled');
    if (integration !== undefined && onIntegrationSelected !== undefined)
      onIntegrationSelected(integration);
  };

  const onSelect: MouseEventHandler<Element> = e => {
    e.preventDefault();
    if (
      selectedIntegration !== undefined &&
      onIntegrationSelected !== undefined
    ) {
      onIntegrationSelected(selectedIntegration);
    }
    try {
    } catch (err) {}
  };

  useEffect(() => {
    setSelectedIntegration(integration);
  }, [integration]);

  if (integrations === undefined || integrations.length === 0)
    return <Grid container />;

  return (
    <Grid container>
      <Grid item xs={displayButton ? 10 : 12}>
        <Autocomplete
          disablePortal
          id={`combo-box-${name}`}
          options={[emptyOperation as T].concat(integrations)}
          getOptionLabel={option => {
            return option.name;
          }}
          value={selectedIntegration ?? (emptyOperation as T)}
          isOptionEqualToValue={(option: T, value: T) =>
            option?.integrationId === value?.integrationId
          }
          sx={{ width: '100%' }}
          renderInput={params => <TextField {...params} label={label} />}
          onChange={(event: any, value: T | null) => {
            const selected =
              value != null && value?.integrationId !== '' ? value : undefined;

            setSelectedIntegration(selected);

            if (!displayButton && selected != null)
              propagateIntegration(selected);
          }}
          {...props}
        />
      </Grid>
      <Grid item xs={displayButton ? 2 : 0}>
        {displayButton && (
          <IconButton
            color="success"
            aria-label="Select integration"
            component="label"
            onClick={onSelect}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default IntegrationSelector;
