import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from '../components/auth/RequireAuth';
import { RequireRole } from '../components/auth/RequireRole';
import ToolsView from './service-provider/ToolsView';
import { RequireTenant } from '../components/auth/RequireTenant';
import IntegrationsView from './service-provider/IntegrationsView';
import ApisLanding from './service-provider/ApisLanding';
import ActionsMonitorView from './service-provider/actions/ActionsMonitorView';
import TenantsView from './service-provider/tenants/TenantsView';
import TenantCreateView from './service-provider/tenants/TenantCreateView';
import TenantView from './service-provider/tenants/TenantView';
import * as React from 'react';
import ConnectionsView from './service-provider/ConnectionsView';

function ServiceProviderAppView() {
  return (
    <Routes>
      <Route
        path="/tools/*"
        element={
          <RequireAuth>
            <RequireRole requireRoles={['platform-admin-user']}>
              <ToolsView />
            </RequireRole>
          </RequireAuth>
        }
      />
      <Route
        path="/integrations/*"
        element={
          <RequireAuth>
            <RequireTenant>
              <IntegrationsView />
            </RequireTenant>
          </RequireAuth>
        }
      />
      <Route
        path="/connections/*"
        element={
          <RequireAuth>
            <RequireTenant>
              <ConnectionsView />
            </RequireTenant>
          </RequireAuth>
        }
      />
      <Route
        path="/apis/*"
        element={
          <RequireAuth>
            <RequireTenant>
              <ApisLanding />
            </RequireTenant>
          </RequireAuth>
        }
      />
      <Route
        path="/monitor/*"
        element={
          <RequireAuth>
            <RequireTenant>
              <ActionsMonitorView />
            </RequireTenant>
          </RequireAuth>
        }
      />
      <Route
        path="/tenants"
        element={
          <RequireAuth>
            <RequireRole requireRoles={['platform-admin-user']}>
              <TenantsView />
            </RequireRole>
          </RequireAuth>
        }
      />
      <Route
        path="/tenants/create"
        element={
          <RequireAuth>
            <RequireRole requireRoles={['platform-admin-user']}>
              <TenantCreateView />
            </RequireRole>
          </RequireAuth>
        }
      />
      <Route
        path="/tenants/:tenantId"
        element={
          <RequireAuth>
            <RequireRole requireRoles={['platform-admin-user']}>
              <TenantView />
            </RequireRole>
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default ServiceProviderAppView;
