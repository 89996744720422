import React, { JSX } from 'react';
import { UseFormReturn } from 'react-hook-form';

import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';

import { EditableSchemaItem } from './interface';

interface Props {
  form: UseFormReturn<EditableSchemaItem>;
  readOnly?: boolean;
}

const BooleanForm = ({ form, readOnly }: Props): JSX.Element => {
  return (
    <HookFormAutocomplete
      name="default"
      disabled={readOnly}
      label="Default value"
      form={form}
      disablePortal
      isOptionEqualToValue={(option, value) => option === value}
      options={['True', 'False']}
    />
  );
};

export default BooleanForm;
