import React, { JSX, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  bankConnections,
  erpConnections,
} from '@apus/common-lib/api/interface/apps/e-invoice-integration';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import { EInvoiceIntegrationApp } from '@apus/common-lib/api/interface/subscriptions';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';

import AppRequirementConfiguration from '../AppRequirementConfiguration';
import { EInvoiceIntegrationAppConfiguration } from '@apus/common-lib/api/interface/apps/e-invoice-integration';

interface Props {
  pending?: boolean;
  value: EInvoiceIntegrationAppConfiguration;
  edit?: boolean;
  onSave: (
    configuration: EInvoiceIntegrationAppConfiguration,
    subscriptionName?: string
  ) => void;
  onCancel: () => void;
}

export function createEmptyEInvoiceIntegrationApp(): EInvoiceIntegrationApp {
  return {
    name: SystemglueAppName.E_INVOICE_INTEGRATION_APP,
    configuration: {
      connections: {
        erp: 'netsuite',
        bank: 'pagero',
      },
      subsidiary: {
        subsidiaryId: '',
        subsidiaryName: '',
      },
    },
  };
}

const ConfigureBankingSubscription = ({
  pending,
  value,
  edit = false,
  onSave,
}: Props): JSX.Element => {
  const form = useForm<EInvoiceIntegrationAppConfiguration>();

  useEffect(() => {
    form.setValue('connections', value.connections);
    form.setValue('subsidiary', value.subsidiary);
  }, [value, form]);

  function onSubmit(configuration: EInvoiceIntegrationAppConfiguration) {
    const subscriptionName = `${configuration.subsidiary.subsidiaryId} - ${configuration.subsidiary.subsidiaryName}`;
    onSave(configuration, subscriptionName);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bolder'}>Required connections:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          render={({ field }) => (
            <AppRequirementConfiguration
              title="ERP"
              value={field.value}
              edit={edit}
              options={erpConnections}
              onChange={field.onChange}
            />
          )}
          name={'connections.erp'}
        />
        <Controller
          control={form.control}
          render={({ field }) => (
            <AppRequirementConfiguration
              title="Bank"
              value={field.value}
              edit={edit}
              options={bankConnections}
              onChange={field.onChange}
            />
          )}
          name={'connections.bank'}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="subsidiary.subsidiaryId"
            form={form}
            label="Subsidiary id"
            disabled={!edit}
          />
          <HookFormTextInput
            name="subsidiary.subsidiaryName"
            form={form}
            label="Subsidiary name"
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={pending}
          disabled={!edit}
          onClick={form.handleSubmit(onSubmit)}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default ConfigureBankingSubscription;
