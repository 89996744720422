import React, { useContext, useState, JSX } from 'react';

import { IntegrationActionLifecycleNotification } from '@apus/common-lib/api/interface/integration-service';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Drawer, Grid } from '@mui/material';

import ActionNotificationList from '../../../components/monitor/ActionNotificationList';
import ActionViewer from '../../../components/monitor/ActionViewer';
import { WebsocketContext } from '../../../state/websocketContext';
import { resetEventQueue } from '../../../state/websocketReducer';
import { bodyMaxHeight } from '../../../theme/theme';

const ActionsLiveView = (): JSX.Element => {
  const [state, dispatch] = useContext(WebsocketContext);

  const [action, setAction] = useState<
    IntegrationActionLifecycleNotification | undefined
  >(undefined);

  return (
    <div>
      <Drawer
        variant="temporary"
        ModalProps={{
          keepMounted: false,
        }}
        PaperProps={{
          sx: { marginTop: '48px', minWidth: '90%', padding: 1 },
        }}
        open={action !== undefined}
        anchor={'right'}
        onClose={() => setAction(undefined)}
      >
        <ActionViewer notification={action} />
      </Drawer>
      <Grid
        container
        spacing={2}
        sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
      >
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
            <Button
              startIcon={<ClearIcon />}
              onClick={() => dispatch(resetEventQueue())}
            >
              Clear
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ActionNotificationList
            actions={state.events.getItems()}
            onActionSelected={setAction}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ActionsLiveView;
