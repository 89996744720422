import React from 'react';

import { SupportedMappingSchemaDefinition } from '@apus/common-lib/api/interface/files';
import { nodeHandlerFactory } from '@apus/common-lib/integration-engine/src/handler-factory';
import {
  OperationNode,
  PollingTriggerNode,
} from '@apus/common-lib/integration-engine/src/interface';
import { JSONSchema7 } from 'json-schema';

import { SupportedMappingSchemaFileEdit } from '../../../../types/stored-files';
import DefineMapping from '../../../mapping/DefineMapping';

interface Props {
  integrationContextSchema: JSONSchema7;
  node?: OperationNode | PollingTriggerNode;
  targetSchema: JSONSchema7;
  onChange: (mapping?: SupportedMappingSchemaDefinition) => void;
}

const DefineOperationMapping = ({
  integrationContextSchema,
  node,
  targetSchema,
  onChange,
}: Props): JSX.Element => {
  if (node === undefined || node.operation === undefined) return <></>;

  return (
    <DefineMapping
      // TODO: check if the edit-format is needed
      value={node.operation.mappingSchema as SupportedMappingSchemaFileEdit}
      sourceSchema={
        nodeHandlerFactory(
          node,
          integrationContextSchema
        ).getFullTransformedInputSchema() ?? {}
      }
      targetSchema={targetSchema}
      onChange={mappingSchema => {
        onChange(mappingSchema as SupportedMappingSchemaDefinition);
      }}
    />
  );
};

export default DefineOperationMapping;
