import { BankingTransaction } from './events';

export type BankingFileType = 'xml' | 'json' | 'csv' | 'other';

/**
 * @title Banking file template
 */
export interface BankingFileTemplate {
  /**
   * @title Template identifier
   * @description This is a unique identifier which cannot be modified after creation. Use this in Netsuite to refer to a given template. Can contain only lower-case letters, underscores and hyphens. Min length is 5 characters
   * @pattern /^[a-z-_]+$/gm
   * @minLength 5
   */
  id: string;
  /**
   * @title Template description
   * @description (Optional) description for the template
   */
  description?: string;
  /**
   * @title Template file type
   * @description Used to define formatting in editor. Default 'xml'.
   */
  type: BankingFileType;
  /**
   * @title Template as a string
   */
  template: string;
}

/**
 * @title Payment status record used in Netsuite
 */
export enum NetsuitePaymentStatus {
  SENT_TO_BANK_PROCESSING = '1',
  PAYMENT_IN_TRANSIT = '2',
  PAYMENT_COMPLETED = '3',
  ERROR = '4',
  NEW_OR_UNPROCESSED = '5',
  WAITING_FOR_DUE_DATE = '6',
}

/**
 * @title Internally used status enum for payments being processed
 */
export enum SystemGluePaymentStatus {
  // Received from Netsuite
  RECEIVED = 'Received',
  // Sent to freemarker-service for conversion
  SENT_FOR_CONVERSION = 'SentForConversion',
  // Conversion in freemarker-service failed for some reason
  CONVERSION_FAILED = 'ConversionFailed',
  // Conversion successfully done and payment file stored
  CONVERTED = 'Converted',
  // Conversion successfully done and payment file stored
  READY_FOR_BANK = 'ReadyForBank',
  // Payment file has been sent to bank / payment gateway
  SENT_TO_BANK = 'SentToBank',
  // Payment integration failed
  BANK_PAYMENT_FAILED = 'BankPaymentFailed',
  // Bank payment was made and is being processed
  BANK_PAYMENT_PROCESSING = 'BankPaymentInProcessing',
  // 'Bank payment was successfully processed'
  BANK_PAYMENT_PROCESSED = 'BankPaymentProcessed',
}

/**
 * @title Internally used status enum for payments being processed
 */
export enum SystemGlueTransactionStatus {
  // Received from Netsuite
  RECEIVED = 'Received',
}

/**
 * @title Physical address details
 * @description Used to identify transaction parties in bank payment files
 */
export interface Address {
  /**
   * @title Address as a single string
   */
  line?: string;
  /**
   * @title First street address line
   */
  streetAddress1: string;
  /**
   * @title (Optional) Second street address line
   */
  streetAddress2?: string;
  /**
   * @title City
   */
  city: string;
  /**
   * @title Postal code
   */
  postalCode: string;
  /**
   * @title (Optional) state code
   */
  stateCode?: string;
  /**
   * @title Country code in ISO 3166-1 alpha-2 -format
   */
  countryCode: string;
}

/**
 * @title Subsidiary i.e. the debtor
 * @description This is the party making the payment
 */
export interface Subsidiary {
  /**
   * @title Subsidiary's legal name
   * @description This is "subsidiary.legalName"
   */
  name: string;
  /**
   * @title Subsidiary's physical address
   */
  address: Address;
}

/**
 * @title Vendor i.e. the creditor
 * @description This can be any entity receiving a payment after rendering a service or selling a product to subsidiary
 */
export interface Vendor {
  /**
   * @title Vendor internal id
   * @description This is "vendor.entityId"
   */
  internalId: string;
  /**
   * @title Vendor's name
   * @description This is "vendor.companyName"
   */
  name: string;
  /**
   * @title Vendor's bank account to which the payment will be made
   */
  bank: {
    /**
     * @title Vendor's bank account number in IBAN -format
     * @description This is "customrecord_rhi_bank_details.custrecord_rhi_bank_iban"
     */
    iban: string;
    /**
     * @title Vendor's bank identification code in BIC -format
     * @description This is "customrecord_rhi_bank_details.custrecord_rhi_bank_bic"
     */
    bic: string;
  };
  /**
   * @title Vendor's physical address
   */
  address: Address;
}

/**
 * @title Single payment information
 * @description This describes a single payment made for a transaction
 */
export interface Payment {
  /**
   * @title Payment record internal id
   * @description This is "customrecord_rhi_bank_payments.id"
   */
  internalId: string;
  /**
   * @title The transaction for which the payment is made
   * @description This is "customrecord_rhi_bank_payments.id"
   */
  transactionId: string;
  /**
   * @title Payment amount
   * @description This is "customrecord_rhi_bank_payments.custrecord_rhi_payment_amount"
   */
  amount: number;
  /**
   * @title Currency to use in payment
   * @description This is "customrecord_rhi_bank_payments.custrecord_rhi_payment_currency"
   */
  currencyCode: string;
  /**
   * @title Due date for the payment in ISO 8601 -format
   * @description This is "customrecord_rhi_bank_payments.custrecord_rhi_payment_date"
   */
  dueDate: string;
  /**
   * @title Reference string for the payment
   * @description This is "customrecord_rhi_bank_payments.custrecord_rhi_payment_reference"
   */
  reference: string;
  /**
   * @title SystemGlue -template to use for transforming the payment into bank payment file
   * @description This is "customrecord_rhi_bank_payments.custrecord_rhi_payment_format"
   */
  templateId?: string;
  /**
   * @title The bank account from which the payment will be made
   * @description
   */
  bank: {
    /**
     * @title Subsidiary's bank account number in IBAN -format
     * @description This is "customrecord_rhi_company_bank_details.custrecord_rhi_company_bank_iban"
     */
    iban: string;
    /**
     * @title Subsidiary's bank identification code in BIC -format
     * @description This is "customrecord_rhi_company_bank_details.custrecord_rhi_company_bank_bic"
     */
    bic: string;
  };
}

/**
 * @title Bank payment record
 * @description This record defines a single payment made for a transaction
 */
export interface NetsuiteBankPaymentRecord {
  subsidiary: Subsidiary;
  vendor: Vendor;
  payment: Payment;

  // TODO: log details and matching payment
}

export interface ActionInfo {
  tenantId: string;
  integrationId: string;
  actionId?: string;
}

export interface StatusEntry<STATUS_TYPE> {
  recordStatus: STATUS_TYPE;
  created: string;
  action?: ActionInfo;
  error?: unknown;
}

export enum BankingFileId {
  CONVERTED = 'Converted',
}

export interface BankingFile {
  id: BankingFileId;
  created: string;
  /**
   * @title Template file type
   * @description Used to define formatting in editor. Default 'xml'.
   */
  type: BankingFileType;
  /**
   * @title File data
   */
  data: string;
}

/**
 * @title Payment frame used by SystemGlue
 * @description Contains the actual payment data received from Netsuite and extra status information used in SystemGlue
 */
export interface SystemGluePayment {
  /**
   * @title Creation date in ISO format
   */
  created: string;
  /**
   * @title Last modification date in ISO format
   */
  modified: string;
  /**
   * @title Current status
   */
  paymentStatus: SystemGluePaymentStatus;
  /**
   * @title Status history
   */
  statusHistory?: StatusEntry<SystemGluePaymentStatus>[];
  /**
   * @title Action identifier
   * @description Identifies the action from which this payment originates
   */
  actionId?: string;
  /**
   * @title Tenant identifier
   */
  tenantId: string;
  /**
   * @title The banking app subscription identifier
   */
  subscriptionId: string;
  /**
   * @title Original Netsuite payment record
   */
  record: NetsuiteBankPaymentRecord;
  bankingFiles?: BankingFile[];
}

/**
 * @title Transaction frame used by SystemGlue
 * @description Contains the actual transaction data received from bank and extra status information used in SystemGlue
 */
export interface SystemGlueTransaction {
  /**
   * @title Creation date in ISO format
   */
  created: string;
  /**
   * @title Last modification date in ISO format
   */
  modified: string;
  /**
   * @title Current status
   */
  transactionStatus: SystemGlueTransactionStatus;
  /**
   * @title Status history
   */
  statusHistory?: StatusEntry<SystemGlueTransactionStatus>[];
  /**
   * @title Action identifier
   * @description Identifies the action from which this payment originates
   */
  actionId?: string;
  /**
   * @title Tenant identifier
   */
  tenantId: string;
  /**
   * @title The banking app subscription identifier
   */
  subscriptionId: string;
  /**
   * @title The transaction record
   */
  record: BankingTransaction;
}
