import React, { JSX } from 'react';
import { Controller, FieldValues, Path, UseFormReturn } from 'react-hook-form';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props<FormType extends FieldValues, T> = {
  name: string;
  key?: string;
  form: UseFormReturn<FormType>;
  options: T[];
  pickValue: (value: T) => string;
  pickLabel: (value: T) => string;
  onValueChange?: (values: T[]) => void;
  width?: number;
  label?: string;
};

const HookFormDateTimePicker = <FormType extends FieldValues, T>({
  name,
  form,
  key,
  options,
  pickValue,
  pickLabel,
  width = 300,
  label = 'Select',
}: Props<FormType, T>): JSX.Element => {
  function resolveLabel(value: string) {
    const match = options.find(o => pickValue(o) === value);
    if (match === undefined)
      throw new Error(
        `Could not match selected value "${value}" to options ${JSON.stringify(
          options
        )}`
      );

    return pickLabel(match);
  }

  return (
    <Controller
      control={form.control}
      name={name as Path<FormType>}
      key={key !== undefined ? `${key}-controller` : `${name}-controller`}
      render={({ field }) => (
        <div>
          <FormControl sx={{ width }}>
            <InputLabel>{label}</InputLabel>
            <Select<string[]>
              multiple
              value={field.value ?? []}
              onChange={event => {
                const selected = Array.isArray(event.target.value)
                  ? event.target.value
                  : event.target.value.split(',');

                field.onChange(selected);
              }}
              input={<OutlinedInput label={label} />}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map(value => (
                    <Chip key={value} label={resolveLabel(value)} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {options.map(option => (
                <MenuItem key={pickValue(option)} value={pickValue(option)}>
                  {pickLabel(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    />
  );
};

export default HookFormDateTimePicker;
