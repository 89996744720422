import { Box, CssBaseline, Grid, ThemeProvider } from '@mui/material';
import MapperAppBar from '../components/dashboard/MapperAppBar';
import SideNav from '../components/dashboard/SideNav';
import * as React from 'react';
import { JSX, useState } from 'react';
import { MapperRoute } from '../components/dashboard/SideNavItems';
import mapperTheme, { maxHeight, sideNavWidth } from '../theme/theme';

interface Props {
  routes: MapperRoute[];
  children?: JSX.Element | JSX.Element[];
}

function Dashboard({ routes, children }: Props) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <ThemeProvider theme={mapperTheme}>
      <Box>
        <CssBaseline />
        <MapperAppBar
          drawerOpen={drawerOpen}
          onOpenDrawer={() => setDrawerOpen(true)}
        />
        <Grid container sx={{ height: maxHeight, width: '100vw' }}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <SideNav
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              routes={routes}
              width={sideNavWidth}
            />
            <Box
              id="main"
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                height: '100%',
                width: `calc(100vw - ${sideNavWidth + 1}px)`,
              }}
            >
              {children}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
