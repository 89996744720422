import { JSX } from 'react';
import { FieldError } from 'react-hook-form';

interface Props {
  error: FieldError;
}

const ValidationError = ({ error }: Props): JSX.Element => {
  return <div>{error.message}</div>;
};

export default ValidationError;
