import { useMemo } from 'react';

import { BadRequest } from '@apus/common-lib/utils/src/error';

import useAxios from '../hooks/useAxios';
import {
  BankingService,
  createBankingService,
} from '../api-client/banking-service';

const ErrorThrowingTenantService = (message: string): BankingService => ({
  createTemplate() {
    throw new BadRequest(message);
  },
  getTemplate() {
    throw new BadRequest(message);
  },
  listTemplates() {
    throw new BadRequest(message);
  },
  updateTemplate() {
    throw new BadRequest(message);
  },
});

function useBankingService(): BankingService {
  const axiosInstance = useAxios();

  return useMemo(() => {
    if (axiosInstance === undefined)
      return ErrorThrowingTenantService('Axios instance could not be defined');

    return createBankingService(axiosInstance);
  }, [axiosInstance]);
}

export default useBankingService;
