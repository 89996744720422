import React, { useState } from 'react';

import { VersionedIntegrations } from '@apus/common-lib/api/interface/integration-service';

import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

interface Props {
  defaultAppVersion?: string;
  appVersions?: VersionedIntegrations[];
  edit?: boolean;
  onSelect: (test: VersionedIntegrations | undefined) => void;
  onEdit: (test: VersionedIntegrations | undefined) => void;
  onAdd: () => void;
  onDelete?: (test: VersionedIntegrations) => void;
}

const VersionedIntegrationsList = ({
  defaultAppVersion,
  appVersions,
  edit,
  onSelect,
  onEdit,
  onAdd,
  onDelete,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

  const deleteTemplate = () => {
    if (onDelete === undefined) throw new Error('Delete is not supported yet');
    if (appVersions === undefined || appVersions.length === 0)
      throw new Error('Cannot delete - no appVersion defined');
    if (selectedIndex === undefined || appVersions[selectedIndex] === undefined)
      throw new Error('Cannot delete - no appVersion selected');

    onDelete(appVersions[selectedIndex]);
  };

  const selectTemplate = (index: number) => {
    setSelectedIndex(index);
    onSelect(
      index !== undefined &&
        appVersions !== undefined &&
        appVersions.length > index
        ? appVersions[index]
        : undefined
    );
  };

  const editTemplate = () => {
    if (selectedIndex !== undefined) {
      onEdit(
        appVersions !== undefined && appVersions.length > selectedIndex
          ? appVersions[selectedIndex]
          : undefined
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'}>App versions</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
          <IconButton
            disabled={
              edit ||
              onDelete === undefined ||
              appVersions === undefined ||
              selectedIndex === undefined ||
              appVersions[selectedIndex] === undefined
            }
            onClick={deleteTemplate}
          >
            <DeleteTwoToneIcon color={'error'} />
          </IconButton>
          <IconButton
            disabled={edit || selectedIndex === undefined}
            onClick={editTemplate}
          >
            <EditTwoToneIcon color={'info'} />
          </IconButton>
          <IconButton disabled={edit} onClick={onAdd}>
            <AddBoxTwoToneIcon color={'success'} />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <List>
          {appVersions?.length === 0 && (
            <ListItem key={-1} disableGutters={true}>
              <ListItemText
                primaryTypographyProps={{
                  noWrap: true,
                }}
                primary={'No versions defined'}
                secondaryTypographyProps={{
                  noWrap: true,
                }}
                secondary={''}
              ></ListItemText>
            </ListItem>
          )}
          {appVersions?.map((appVersion, index) => (
            <ListItem key={index} disableGutters={true}>
              <ListItemButton
                selected={edit}
                onClick={() => selectTemplate(index)}
                disableGutters={true}
              >
                <ListItemText
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                  primary={
                    appVersion.version === defaultAppVersion
                      ? `* ${appVersion.version}`
                      : appVersion.version
                  }
                  secondaryTypographyProps={{
                    noWrap: true,
                  }}
                  secondary={appVersion.description}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default VersionedIntegrationsList;
