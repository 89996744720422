import { useEffect, useState } from 'react';

import { ApiError } from '@apus/common-lib/api/interface/common';

import { executeApiCall } from '../utils/api-call';

function useApiCall<T>(
  callFunction: () => Promise<T>,
  depends: Array<any> = []
): [boolean, T | undefined, any] {
  const [pending, setPending] = useState<boolean>(false);
  const [result, setResult] = useState<T | undefined>();
  const [error, setError] = useState<ApiError | undefined>();

  useEffect(
    () => {
      (async () => {
        await executeApiCall<T | undefined>({
          callFunction,
          setPending,
          setResult,
          setError,
        });
      })();
    },
    // Disable check so that linter doesn't complain about the spread operator
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callFunction, ...depends]
  );

  return [pending, result, error];
}

export default useApiCall;
