/**
 * This file provides required type declarations (as string) for the utility types usable in runtime typescript
 * operations.
 *
 * TODO: The module declaration should be generated automatically with tsc and imported as raw file for monaco-editor
 */

import subscriptionTypes from '../src/generated/subscriptions';

export const moduleName = 'systemglue';

export const moduleExports = [
  'IDate',
  'IBoolean',
  'INumber',
  'IUtil',
  'ISubscription',
];

export const moduleContent = `
${subscriptionTypes}

declare enum WeekDay {
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
};

declare type SupportedDate = string | number | Date | IDate;

declare class IDate {
  constructor(d: SupportedDate = new Date());
  addDays(days: number): IDate;
  addMilliseconds(millis: number): IDate;
  format(format: string): string;
  getWeek(weekStartsOn: WeekDay = WeekDay.Monday): number;
  getISOWeeksInYear(): number;
  startOfWeek(weekStartsOn: WeekDay = WeekDay.Monday): Date;
  endOfWeek(weekStartsOn: WeekDay = WeekDay.Monday): Date;
  toTimeZone(timeZone: string): IDate;
  toDate(): Date;
  static from(d: SupportedDate = new Date(), format?: string): IDate;
}

declare type SupportedBoolean = string | boolean | number;

declare class IBoolean {
  constructor(v: SupportedBoolean = false);
  toBoolean(): boolean;
  static from(v: SupportedBoolean): IBoolean;
}

declare type SupportedNumber = string | number | INumber;

declare class INumber {
  constructor(d: SupportedNumber);
  plus(d: SupportedNumber): INumber;
  minus(d: SupportedNumber): INumber;
  times(d: SupportedNumber): INumber;
  toNumber(): number;
  toCurrency(): string;
  static from(d: SupportedNumber): INumber;
}

declare class IUtil {
  static groupBy<T>(
    collection: Array<T>,
    iteratee?: (value: T) => string
  ): Record<string, Array<T>>;
  
  static isEmpty(value: unknown): boolean;

  static uuid(): string;
  
  static encodeText(value: string): number[];
}

declare class ISubscription {
  constructor(subscription: UnknownAppSubscription);
  asPaymentIntegrationAppSubscription(): PaymentIntegrationAppSubscription;
  asBankTransactionIntegrationAppSubscription(): BankTransactionIntegrationAppSubscription;
  asSystemglueAppSubscription(): SystemglueIntegrationAppSubscription;
}

`;

export const moduleDeclaration = `
${moduleContent}

declare module '${moduleName}' {
  export { ${moduleExports.join(', ')} };
}
`;
