import React, { useEffect, useState, JSX } from 'react';

import { SchemaItem } from '@apus/common-lib/json-data-mapper/src/interface';
import { JsonSchemaManipulationTool } from '@apus/common-lib/json-data-mapper/src/schema-manipulation-tool';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ContentCutTwoToneIcon from '@mui/icons-material/ContentCutTwoTone';
import StartTwoToneIcon from '@mui/icons-material/StartTwoTone';
import { TreeView } from '@mui/lab';
import TreeItem, { TreeItemProps } from '@mui/lab/TreeItem';
import { Box, IconButton, SvgIconProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { JSONSchema7 } from 'json-schema';

import { SchemaActionButton } from './JsonSchemaViewTreeItem';

export enum ChangeSchemaRootItemAction {
  SelectRoot = 'Select as new root',
}

type JsonSchemaTreeItemProps = TreeItemProps & {
  labelIcon?: React.ElementType<SvgIconProps>;
  labelStyle?: SxProps<Theme>;
  edit?: boolean;
  item: SchemaItem;
  parent?: SchemaItem;
  onAction: (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: ChangeSchemaRootItemAction
  ) => void;
  selected: boolean;
  highlighted: boolean;
};

const SchemaTreeItem = (props: JsonSchemaTreeItemProps): JSX.Element => {
  const { edit, item, parent, selected, highlighted, onAction, ...other } =
    props;

  const propertyActions: SchemaActionButton[] = [
    {
      color: 'warning',
      actionName: ChangeSchemaRootItemAction.SelectRoot,
      actionLabel: ChangeSchemaRootItemAction.SelectRoot,
      icon: ContentCutTwoToneIcon,
      enabled: !!edit,
    },
  ];

  const doAction = (event: React.MouseEvent, action: string) => {
    event.stopPropagation();
    onAction(item, parent, action as ChangeSchemaRootItemAction);
  };

  return (
    <TreeItem
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          {highlighted && (
            <Box component={StartTwoToneIcon} color="green" sx={{ mr: 1 }} />
          )}
          <Typography
            variant="body2"
            sx={{
              fontStyle: item.isRoot ? 'italic' : 'inherit',
              fontWeight: highlighted ? 'bold' : 'inherit',
              color: item.isImmutable ? 'red' : 'initial',
            }}
          >
            {item.isRoot ? 'root' : item.path}
            {item.isRequired ? `*` : ''}
          </Typography>
          <Box sx={{ flexGrow: 1 }}>
            {selected && !item.isImmutable && !item.isRoot && edit
              ? propertyActions.map((button, idx) => {
                  return (
                    <IconButton
                      key={idx}
                      color={
                        button.color !== undefined ? button.color : undefined
                      }
                      aria-label={button.actionLabel}
                      component="label"
                      size={'small'}
                      onClick={(e: React.MouseEvent) =>
                        doAction(e, button.actionName)
                      }
                      disabled={!button.enabled}
                    >
                      <Box component={button.icon} color="inherit" />
                    </IconButton>
                  );
                })
              : null}
          </Box>

          <Typography variant="caption" color="inherit">
            ({item.isArray ? `${item.type}[]` : item.type})
          </Typography>
        </Box>
      }
      {...other}
    />
  );
};

const JsonSchemaTree = ({
  item,
  edit,
  selectedId,
  highlightedPath,
  onAction,
}: {
  item: SchemaItem;
  edit?: boolean;
  selectedId?: string;
  highlightedPath?: string;
  onAction: (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: ChangeSchemaRootItemAction
  ) => void;
}): JSX.Element => {
  const renderTree = (item: SchemaItem, parent?: SchemaItem) => {
    return (
      <SchemaTreeItem
        key={item.id}
        nodeId={item.id}
        item={item}
        parent={parent}
        edit={edit}
        highlighted={item.schemaPath === highlightedPath}
        selected={item.id === selectedId}
        onAction={onAction}
      >
        {item.properties !== undefined
          ? Object.values(item.properties).map(child => renderTree(child, item))
          : null}
      </SchemaTreeItem>
    );
  };

  return renderTree(item);
};

interface Props {
  schema: JSONSchema7;
  edit?: boolean;
  value?: string;
  onAction: (
    item: SchemaItem | undefined,
    parent: SchemaItem | undefined,
    action: ChangeSchemaRootItemAction
  ) => void;
}

const ChangeSchemaRootTreeView = ({
  schema,
  edit,
  value,
  onAction,
}: Props): JSX.Element => {
  const [items, setItems] = useState<SchemaItem>();
  const [selectedId, setSelectedId] = useState<string>();

  useEffect(() => {
    const tool = new JsonSchemaManipulationTool({ schema });
    setItems(tool.asItem());
  }, [schema]);

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      sx={{ minHeight: 300, flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}
      multiSelect={false}
      onNodeSelect={(_e, nodeId: string) => setSelectedId(nodeId)}
    >
      {items !== undefined && (
        <JsonSchemaTree
          item={items}
          edit={edit}
          highlightedPath={value}
          selectedId={selectedId}
          onAction={onAction}
        />
      )}
    </TreeView>
  );
};

export default ChangeSchemaRootTreeView;
