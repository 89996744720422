export const bankTransaction = {
  type: 'object',
  properties: {
    account: {
      type: 'object',
      properties: {
        iban: {
          type: 'string',
          title: 'Transaction bank account number',
          description: 'Bank account number in IBAN format',
        },
        bic: {
          type: 'string',
          title: 'Transaction bank identifier',
          description: 'Bank identifier in BIC format',
        },
      },
      required: ['iban', 'bic'],
      title: 'The bank account',
      description: 'The bank account where this transaction happened',
    },
    entityName: {
      type: 'string',
      title: 'Transaction customer',
      description:
        'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
    },
    debtorName: {
      type: 'string',
      title: 'The debtor name defined by the bank',
      description: 'This is the original debtor name from the transaction data',
    },
    creditorName: {
      type: 'string',
      title: 'The creditor name defined by the bank',
      description:
        'This is the original creditor name from the transaction data',
    },
    message: {
      type: 'string',
      title: 'Transaction message',
      description:
        'This is the message that the payer gave for this transaction',
    },
    reference: {
      type: 'string',
      title: 'Transaction national reference',
      description: 'This is the original reference from the transaction data',
    },
    rfReference: {
      type: 'string',
      title: 'Transaction RF reference',
      description: 'This is the original rfReference from the transaction data',
    },
    paymentISODate: {
      type: 'string',
      title: 'Transaction date',
      description:
        'This is the original payment date from the transaction data converted into ISO format',
    },
    currencyCode: {
      type: 'string',
      title: 'Transaction currency',
      description:
        'This is the original currency code as an ISO currency code from the transaction data',
    },
    amount: {
      type: 'number',
      title: 'Transaction amount',
      description:
        'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
    },
    transactionType: {
      type: 'string',
      enum: ['debit', 'credit'],
      title: 'Transaction type',
      description:
        'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
    },
  },
  required: [
    'account',
    'entityName',
    'debtorName',
    'creditorName',
    'paymentISODate',
    'currencyCode',
    'amount',
    'transactionType',
  ],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const entityIdentificationRuleInput = {
  type: 'object',
  properties: {
    bankTransaction: {
      type: 'object',
      properties: {
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        message: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the message that the payer gave for this transaction',
        },
        reference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        rfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'amount',
        'transactionType',
      ],
    },
    erpEntity: {
      type: 'object',
      properties: {
        entityName: {
          type: 'string',
          title: 'ERP entity name',
          description: 'Name of the entity as defined in ERP',
        },
        entityId: {
          type: 'string',
          title: 'ERP entity internal id',
          description: 'The internal id for the entity in ERP',
        },
        entityType: {
          type: 'string',
          enum: ['vendor', 'customer'],
          title: 'ERP entity type',
          description: 'The type of the entity',
        },
        invoiceGroupingEnabled: {
          type: 'boolean',
          title: 'ERP entity uses invoice grouping',
          description: 'Does entity have invoice grouping enabled?',
        },
      },
      required: ['entityName', 'entityId', 'entityType'],
    },
  },
  required: ['bankTransaction'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const transactionIdentificationRuleInput = {
  type: 'object',
  properties: {
    bankTransaction: {
      type: 'object',
      properties: {
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        message: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the message that the payer gave for this transaction',
        },
        reference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        rfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'amount',
        'transactionType',
      ],
    },
    erpEntity: {
      type: 'object',
      properties: {
        entityName: {
          type: 'string',
          title: 'ERP entity name',
          description: 'Name of the entity as defined in ERP',
        },
        entityId: {
          type: 'string',
          title: 'ERP entity internal id',
          description: 'The internal id for the entity in ERP',
        },
        entityType: {
          type: 'string',
          enum: ['vendor', 'customer'],
          title: 'ERP entity type',
          description: 'The type of the entity',
        },
        invoiceGroupingEnabled: {
          type: 'boolean',
          title: 'ERP entity uses invoice grouping',
          description: 'Does entity have invoice grouping enabled?',
        },
      },
      required: ['entityName', 'entityId', 'entityType'],
    },
    erpTransaction: {
      type: 'object',
      properties: {
        transactionType: {
          type: 'string',
          enum: ['vendor-bill', 'customer-invoice'],
          title: 'ERP transaction type',
          description: 'The type of the transaction in ERP',
        },
        entityId: {
          type: 'string',
          title: 'ERP transaction entity internal id',
          description: 'The internal id for the transaction owner in ERP',
        },
        reference: {
          type: 'string',
          title: 'ERP transaction reference',
          description:
            'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
        },
        number: {
          type: 'string',
          title: 'ERP transaction number',
          description:
            'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
        },
        createdISODate: {
          type: 'string',
          title: 'ERP transaction creation date',
          description: 'Transaction creation date in ISO format',
        },
        dueISODate: {
          type: 'string',
          title: 'ERP transaction due date',
          description: 'Transaction due date in ISO format',
        },
        internalId: {
          type: 'string',
          title: 'ERP transaction internal id',
          description: 'The internal id for the transaction in ERP',
        },
        amountTotal: {
          type: 'number',
          title: 'ERP transaction total original amount',
          description: 'The total amount for the transaction',
        },
        amountRemaining: {
          type: 'number',
          title: 'ERP transaction amount remaining',
          description: 'The amount currently open for the transaction',
        },
        currencyCode: {
          type: 'string',
          title: 'ERP transaction currency',
          description: 'The currency used in transaction in ISO format',
        },
      },
      required: [
        'transactionType',
        'entityId',
        'createdISODate',
        'dueISODate',
        'internalId',
        'amountTotal',
        'amountRemaining',
        'currencyCode',
      ],
    },
  },
  required: ['bankTransaction', 'erpTransaction'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveTransactionDataInput = {
  type: 'object',
  properties: {
    subscriptionId: { type: 'string' },
    erpEntities: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          entityName: {
            type: 'string',
            title: 'ERP entity name',
            description: 'Name of the entity as defined in ERP',
          },
          entityId: {
            type: 'string',
            title: 'ERP entity internal id',
            description: 'The internal id for the entity in ERP',
          },
          entityType: {
            type: 'string',
            enum: ['vendor', 'customer'],
            title: 'ERP entity type',
            description: 'The type of the entity',
          },
          invoiceGroupingEnabled: {
            type: 'boolean',
            title: 'ERP entity uses invoice grouping',
            description: 'Does entity have invoice grouping enabled?',
          },
        },
        required: ['entityName', 'entityId', 'entityType'],
      },
    },
    bankTransaction: {
      type: 'object',
      properties: {
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        message: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the message that the payer gave for this transaction',
        },
        reference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        rfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'amount',
        'transactionType',
      ],
    },
    erpTransactions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          transactionType: {
            type: 'string',
            enum: ['vendor-bill', 'customer-invoice'],
            title: 'ERP transaction type',
            description: 'The type of the transaction in ERP',
          },
          entityId: {
            type: 'string',
            title: 'ERP transaction entity internal id',
            description: 'The internal id for the transaction owner in ERP',
          },
          reference: {
            type: 'string',
            title: 'ERP transaction reference',
            description:
              'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
          },
          number: {
            type: 'string',
            title: 'ERP transaction number',
            description:
              'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
          },
          createdISODate: {
            type: 'string',
            title: 'ERP transaction creation date',
            description: 'Transaction creation date in ISO format',
          },
          dueISODate: {
            type: 'string',
            title: 'ERP transaction due date',
            description: 'Transaction due date in ISO format',
          },
          internalId: {
            type: 'string',
            title: 'ERP transaction internal id',
            description: 'The internal id for the transaction in ERP',
          },
          amountTotal: {
            type: 'number',
            title: 'ERP transaction total original amount',
            description: 'The total amount for the transaction',
          },
          amountRemaining: {
            type: 'number',
            title: 'ERP transaction amount remaining',
            description: 'The amount currently open for the transaction',
          },
          currencyCode: {
            type: 'string',
            title: 'ERP transaction currency',
            description: 'The currency used in transaction in ISO format',
          },
        },
        required: [
          'transactionType',
          'entityId',
          'createdISODate',
          'dueISODate',
          'internalId',
          'amountTotal',
          'amountRemaining',
          'currencyCode',
        ],
      },
    },
  },
  required: [
    'subscriptionId',
    'erpEntities',
    'bankTransaction',
    'erpTransactions',
  ],
  title: 'Resolve transaction data -input data model',
  description:
    'This model is processed with identification rules resulting in resolved entity and transactions',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveTransactionDataOutput = {
  type: 'object',
  properties: {
    subscriptionId: { type: 'string' },
    bankTransaction: {
      type: 'object',
      properties: {
        account: {
          type: 'object',
          properties: {
            iban: {
              type: 'string',
              title: 'Transaction bank account number',
              description: 'Bank account number in IBAN format',
            },
            bic: {
              type: 'string',
              title: 'Transaction bank identifier',
              description: 'Bank identifier in BIC format',
            },
          },
          required: ['iban', 'bic'],
          title: 'The bank account',
          description: 'The bank account where this transaction happened',
        },
        entityName: {
          type: 'string',
          title: 'Transaction customer',
          description:
            'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
        },
        debtorName: {
          type: 'string',
          title: 'The debtor name defined by the bank',
          description:
            'This is the original debtor name from the transaction data',
        },
        creditorName: {
          type: 'string',
          title: 'The creditor name defined by the bank',
          description:
            'This is the original creditor name from the transaction data',
        },
        message: {
          type: 'string',
          title: 'Transaction message',
          description:
            'This is the message that the payer gave for this transaction',
        },
        reference: {
          type: 'string',
          title: 'Transaction national reference',
          description:
            'This is the original reference from the transaction data',
        },
        rfReference: {
          type: 'string',
          title: 'Transaction RF reference',
          description:
            'This is the original rfReference from the transaction data',
        },
        paymentISODate: {
          type: 'string',
          title: 'Transaction date',
          description:
            'This is the original payment date from the transaction data converted into ISO format',
        },
        currencyCode: {
          type: 'string',
          title: 'Transaction currency',
          description:
            'This is the original currency code as an ISO currency code from the transaction data',
        },
        amount: {
          type: 'number',
          title: 'Transaction amount',
          description:
            'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
        },
        transactionType: {
          type: 'string',
          enum: ['debit', 'credit'],
          title: 'Transaction type',
          description:
            'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
        },
      },
      required: [
        'account',
        'entityName',
        'debtorName',
        'creditorName',
        'paymentISODate',
        'currencyCode',
        'amount',
        'transactionType',
      ],
      title: 'The bank transaction',
      description: 'The bank transaction',
    },
    resolvedErpEntities: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          erpEntity: {
            type: 'object',
            properties: {
              entityName: {
                type: 'string',
                title: 'ERP entity name',
                description: 'Name of the entity as defined in ERP',
              },
              entityId: {
                type: 'string',
                title: 'ERP entity internal id',
                description: 'The internal id for the entity in ERP',
              },
              entityType: {
                type: 'string',
                enum: ['vendor', 'customer'],
                title: 'ERP entity type',
                description: 'The type of the entity',
              },
              invoiceGroupingEnabled: {
                type: 'boolean',
                title: 'ERP entity uses invoice grouping',
                description: 'Does entity have invoice grouping enabled?',
              },
            },
            required: ['entityName', 'entityId', 'entityType'],
          },
          matchedRule: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                description: 'Human-readable name for the rule',
              },
              description: {
                type: 'string',
                description: 'Human-readable description for the rule',
              },
              ruleId: {
                type: 'string',
                description: 'Internal id (UUID) for the rule',
              },
              condition: {
                type: 'string',
                description: 'Configure Json Logic rule as the condition',
              },
              isDefault: {
                type: 'boolean',
                description:
                  'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
              },
              ruleGroup: {
                type: 'string',
                const: 'erp-entity-identification-rule',
                description: 'Rule type',
              },
              configuration: { description: 'Rule configuration' },
              handler: {
                type: 'string',
                enum: [
                  'identify-entity-automatically',
                  'identify-entity-by-exception',
                ],
              },
              disableAutomatedHandling: { type: 'boolean' },
            },
            required: ['condition', 'handler', 'name', 'ruleGroup', 'ruleId'],
          },
        },
        required: ['erpEntity'],
      },
      title: 'ERP entities identified',
      description: 'ERP entities identified',
    },
    resolvedErpTransactions: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          erpTransaction: {
            type: 'object',
            properties: {
              transactionType: {
                type: 'string',
                enum: ['vendor-bill', 'customer-invoice'],
                title: 'ERP transaction type',
                description: 'The type of the transaction in ERP',
              },
              entityId: {
                type: 'string',
                title: 'ERP transaction entity internal id',
                description: 'The internal id for the transaction owner in ERP',
              },
              reference: {
                type: 'string',
                title: 'ERP transaction reference',
                description:
                  'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
              },
              number: {
                type: 'string',
                title: 'ERP transaction number',
                description:
                  'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
              },
              createdISODate: {
                type: 'string',
                title: 'ERP transaction creation date',
                description: 'Transaction creation date in ISO format',
              },
              dueISODate: {
                type: 'string',
                title: 'ERP transaction due date',
                description: 'Transaction due date in ISO format',
              },
              internalId: {
                type: 'string',
                title: 'ERP transaction internal id',
                description: 'The internal id for the transaction in ERP',
              },
              amountTotal: {
                type: 'number',
                title: 'ERP transaction total original amount',
                description: 'The total amount for the transaction',
              },
              amountRemaining: {
                type: 'number',
                title: 'ERP transaction amount remaining',
                description: 'The amount currently open for the transaction',
              },
              currencyCode: {
                type: 'string',
                title: 'ERP transaction currency',
                description: 'The currency used in transaction in ISO format',
              },
            },
            required: [
              'transactionType',
              'entityId',
              'createdISODate',
              'dueISODate',
              'internalId',
              'amountTotal',
              'amountRemaining',
              'currencyCode',
            ],
          },
          matchedRule: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                description: 'Human-readable name for the rule',
              },
              description: {
                type: 'string',
                description: 'Human-readable description for the rule',
              },
              ruleId: {
                type: 'string',
                description: 'Internal id (UUID) for the rule',
              },
              condition: {
                type: 'string',
                description: 'Configure Json Logic rule as the condition',
              },
              isDefault: {
                type: 'boolean',
                description:
                  'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
              },
              ruleGroup: {
                type: 'string',
                const: 'erp-transaction-identification-rule',
                description: 'Rule type',
              },
              configuration: { description: 'Rule configuration' },
              handler: {
                type: 'string',
                const: 'identify-transaction-automatically',
              },
              disableAutomatedHandling: { type: 'boolean' },
            },
            required: ['condition', 'handler', 'name', 'ruleGroup', 'ruleId'],
          },
        },
        required: ['erpTransaction'],
      },
      title: 'ERP transactions identified',
      description: 'ERP transactions identified',
    },
    resolvedNumberOfEntities: {
      type: 'number',
      title: 'Number of ERP entities identified',
      description: 'Number of ERP entities identified',
    },
    resolvedNumberOfTransactions: {
      type: 'number',
      title: 'Number of ERP transactions identified',
      description: 'Number of ERP transactions identified',
    },
    resolvedTotalTransactionSum: {
      type: 'number',
      title: 'Remaining amount in ERP transaction(s)',
      description: 'Remaining amount in ERP transaction(s)',
    },
    resolvedTotalDifference: {
      type: 'number',
      title: 'Difference between ERP remaining amount and transaction sum',
      description: 'Difference between remaining amount and transaction sum',
    },
    roundingTolerance: {
      type: 'number',
      title: 'Configured rounding tolerance',
      description: 'Configured rounding tolerance',
    },
  },
  required: [
    'subscriptionId',
    'bankTransaction',
    'resolvedErpTransactions',
    'resolvedNumberOfEntities',
    'resolvedNumberOfTransactions',
    'resolvedTotalTransactionSum',
    'resolvedTotalDifference',
    'roundingTolerance',
  ],
  title: 'Resolve transaction data -output data model',
  description: 'This model is the result of processed identification rules',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveTransactionHandlingOutput = {
  type: 'object',
  properties: {
    transactionData: {
      type: 'object',
      properties: {
        subscriptionId: { type: 'string' },
        bankTransaction: {
          type: 'object',
          properties: {
            account: {
              type: 'object',
              properties: {
                iban: {
                  type: 'string',
                  title: 'Transaction bank account number',
                  description: 'Bank account number in IBAN format',
                },
                bic: {
                  type: 'string',
                  title: 'Transaction bank identifier',
                  description: 'Bank identifier in BIC format',
                },
              },
              required: ['iban', 'bic'],
              title: 'The bank account',
              description: 'The bank account where this transaction happened',
            },
            entityName: {
              type: 'string',
              title: 'Transaction customer',
              description:
                'The name of the external customer decided by Systemglue - this is either the debtor or the creditor depending on transaction type',
            },
            debtorName: {
              type: 'string',
              title: 'The debtor name defined by the bank',
              description:
                'This is the original debtor name from the transaction data',
            },
            creditorName: {
              type: 'string',
              title: 'The creditor name defined by the bank',
              description:
                'This is the original creditor name from the transaction data',
            },
            message: {
              type: 'string',
              title: 'Transaction message',
              description:
                'This is the message that the payer gave for this transaction',
            },
            reference: {
              type: 'string',
              title: 'Transaction national reference',
              description:
                'This is the original reference from the transaction data',
            },
            rfReference: {
              type: 'string',
              title: 'Transaction RF reference',
              description:
                'This is the original rfReference from the transaction data',
            },
            paymentISODate: {
              type: 'string',
              title: 'Transaction date',
              description:
                'This is the original payment date from the transaction data converted into ISO format',
            },
            currencyCode: {
              type: 'string',
              title: 'Transaction currency',
              description:
                'This is the original currency code as an ISO currency code from the transaction data',
            },
            amount: {
              type: 'number',
              title: 'Transaction amount',
              description:
                'Amount transferred in the transaction. Debit transactions are marked with a minus sign and credit transactions are positive',
            },
            transactionType: {
              type: 'string',
              enum: ['debit', 'credit'],
              title: 'Transaction type',
              description:
                'In debit transactions the Systemglue customer has made a payment to the resolved entity, and in credit transactions Systemglue customer receives a payment',
            },
          },
          required: [
            'account',
            'entityName',
            'debtorName',
            'creditorName',
            'paymentISODate',
            'currencyCode',
            'amount',
            'transactionType',
          ],
          title: 'The bank transaction',
          description: 'The bank transaction',
        },
        resolvedErpEntities: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              erpEntity: {
                type: 'object',
                properties: {
                  entityName: {
                    type: 'string',
                    title: 'ERP entity name',
                    description: 'Name of the entity as defined in ERP',
                  },
                  entityId: {
                    type: 'string',
                    title: 'ERP entity internal id',
                    description: 'The internal id for the entity in ERP',
                  },
                  entityType: {
                    type: 'string',
                    enum: ['vendor', 'customer'],
                    title: 'ERP entity type',
                    description: 'The type of the entity',
                  },
                  invoiceGroupingEnabled: {
                    type: 'boolean',
                    title: 'ERP entity uses invoice grouping',
                    description: 'Does entity have invoice grouping enabled?',
                  },
                },
                required: ['entityName', 'entityId', 'entityType'],
              },
              matchedRule: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    description: 'Human-readable name for the rule',
                  },
                  description: {
                    type: 'string',
                    description: 'Human-readable description for the rule',
                  },
                  ruleId: {
                    type: 'string',
                    description: 'Internal id (UUID) for the rule',
                  },
                  condition: {
                    type: 'string',
                    description: 'Configure Json Logic rule as the condition',
                  },
                  isDefault: {
                    type: 'boolean',
                    description:
                      'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                  },
                  ruleGroup: {
                    type: 'string',
                    const: 'erp-entity-identification-rule',
                    description: 'Rule type',
                  },
                  configuration: { description: 'Rule configuration' },
                  handler: {
                    type: 'string',
                    enum: [
                      'identify-entity-automatically',
                      'identify-entity-by-exception',
                    ],
                  },
                  disableAutomatedHandling: { type: 'boolean' },
                },
                required: [
                  'condition',
                  'handler',
                  'name',
                  'ruleGroup',
                  'ruleId',
                ],
              },
            },
            required: ['erpEntity'],
          },
          title: 'ERP entities identified',
          description: 'ERP entities identified',
        },
        resolvedErpTransactions: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              erpTransaction: {
                type: 'object',
                properties: {
                  transactionType: {
                    type: 'string',
                    enum: ['vendor-bill', 'customer-invoice'],
                    title: 'ERP transaction type',
                    description: 'The type of the transaction in ERP',
                  },
                  entityId: {
                    type: 'string',
                    title: 'ERP transaction entity internal id',
                    description:
                      'The internal id for the transaction owner in ERP',
                  },
                  reference: {
                    type: 'string',
                    title: 'ERP transaction reference',
                    description:
                      'The attribute in which references are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved reference is the one found first when querying transaction data.',
                  },
                  number: {
                    type: 'string',
                    title: 'ERP transaction number',
                    description:
                      'The attribute in which transaction numbers are stored in ERP can vary depending on the transaction type. Supported attribute names are defined in priority order in the application configuration and resolved number is the one found first when querying transaction data.',
                  },
                  createdISODate: {
                    type: 'string',
                    title: 'ERP transaction creation date',
                    description: 'Transaction creation date in ISO format',
                  },
                  dueISODate: {
                    type: 'string',
                    title: 'ERP transaction due date',
                    description: 'Transaction due date in ISO format',
                  },
                  internalId: {
                    type: 'string',
                    title: 'ERP transaction internal id',
                    description: 'The internal id for the transaction in ERP',
                  },
                  amountTotal: {
                    type: 'number',
                    title: 'ERP transaction total original amount',
                    description: 'The total amount for the transaction',
                  },
                  amountRemaining: {
                    type: 'number',
                    title: 'ERP transaction amount remaining',
                    description:
                      'The amount currently open for the transaction',
                  },
                  currencyCode: {
                    type: 'string',
                    title: 'ERP transaction currency',
                    description:
                      'The currency used in transaction in ISO format',
                  },
                },
                required: [
                  'transactionType',
                  'entityId',
                  'createdISODate',
                  'dueISODate',
                  'internalId',
                  'amountTotal',
                  'amountRemaining',
                  'currencyCode',
                ],
              },
              matchedRule: {
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    description: 'Human-readable name for the rule',
                  },
                  description: {
                    type: 'string',
                    description: 'Human-readable description for the rule',
                  },
                  ruleId: {
                    type: 'string',
                    description: 'Internal id (UUID) for the rule',
                  },
                  condition: {
                    type: 'string',
                    description: 'Configure Json Logic rule as the condition',
                  },
                  isDefault: {
                    type: 'boolean',
                    description:
                      'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                  },
                  ruleGroup: {
                    type: 'string',
                    const: 'erp-transaction-identification-rule',
                    description: 'Rule type',
                  },
                  configuration: { description: 'Rule configuration' },
                  handler: {
                    type: 'string',
                    const: 'identify-transaction-automatically',
                  },
                  disableAutomatedHandling: { type: 'boolean' },
                },
                required: [
                  'condition',
                  'handler',
                  'name',
                  'ruleGroup',
                  'ruleId',
                ],
              },
            },
            required: ['erpTransaction'],
          },
          title: 'ERP transactions identified',
          description: 'ERP transactions identified',
        },
        resolvedNumberOfEntities: {
          type: 'number',
          title: 'Number of ERP entities identified',
          description: 'Number of ERP entities identified',
        },
        resolvedNumberOfTransactions: {
          type: 'number',
          title: 'Number of ERP transactions identified',
          description: 'Number of ERP transactions identified',
        },
        resolvedTotalTransactionSum: {
          type: 'number',
          title: 'Remaining amount in ERP transaction(s)',
          description: 'Remaining amount in ERP transaction(s)',
        },
        resolvedTotalDifference: {
          type: 'number',
          title: 'Difference between ERP remaining amount and transaction sum',
          description:
            'Difference between remaining amount and transaction sum',
        },
        roundingTolerance: {
          type: 'number',
          title: 'Configured rounding tolerance',
          description: 'Configured rounding tolerance',
        },
      },
      required: [
        'subscriptionId',
        'bankTransaction',
        'resolvedErpTransactions',
        'resolvedNumberOfEntities',
        'resolvedNumberOfTransactions',
        'resolvedTotalTransactionSum',
        'resolvedTotalDifference',
        'roundingTolerance',
      ],
      title: 'Resolve transaction data -output data model',
      description: 'This model is the result of processed identification rules',
    },
    resolvedHandlers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          matchedRule: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                description: 'Human-readable name for the rule',
              },
              description: {
                type: 'string',
                description: 'Human-readable description for the rule',
              },
              ruleId: {
                type: 'string',
                description: 'Internal id (UUID) for the rule',
              },
              condition: {
                type: 'string',
                description: 'Configure Json Logic rule as the condition',
              },
              isDefault: {
                type: 'boolean',
                description:
                  'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
              },
              ruleGroup: {
                type: 'string',
                const: 'bank-transaction-handling-rule',
                description: 'Rule type',
              },
              configuration: { description: 'Rule configuration' },
              handler: {
                type: 'string',
                enum: [
                  'generate-customer-payments-per-identified-transaction',
                  'generate-customer-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                  'generate-vendor-payments-per-identified-transaction',
                  'generate-vendor-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                  'generate-consolidated-customer-payments-per-identified-transaction',
                ],
              },
              disableAutomatedHandling: { type: 'boolean' },
            },
            required: ['condition', 'handler', 'name', 'ruleGroup', 'ruleId'],
          },
          actions: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                actionType: {
                  type: 'string',
                  enum: [
                    'create-vendor-payment',
                    'create-customer-payment',
                    'create-consolidated-customer-payment',
                    'create-journal-entry',
                  ],
                },
                transactionInternalId: {
                  type: 'string',
                  description:
                    'Reference to the matched transaction\n\nThis can be a VendorBill, Invoice, etc. depending on the action',
                },
                accountInternalId: {
                  type: 'string',
                  description:
                    'The account to which a transaction will be made\n\nUsed only for journal entries',
                },
                amount: {
                  type: 'number',
                  description: 'Amount of the new transaction',
                },
              },
              required: ['actionType', 'amount'],
            },
          },
        },
        required: ['actions'],
      },
    },
    resolvedNumberOfHandlers: { type: 'number' },
  },
  required: ['transactionData', 'resolvedHandlers', 'resolvedNumberOfHandlers'],
  title: 'Resolve transaction data -output data model',
  description: 'This model is the result of processed identification rules',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankTransactionSubscriptionInput = {
  type: 'object',
  properties: {
    account: { type: 'string', title: 'Account number in IBAN -format' },
  },
  required: ['account'],
  title: 'Resolve bank transaction subscription input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankTransactionSubscriptionOutput = {
  type: 'object',
  properties: {
    subscription: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        app: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              const: 'bank-transaction-integration-app',
              title: 'Application name',
            },
            configuration: {
              type: 'object',
              properties: {
                connections: {
                  type: 'object',
                  properties: {
                    erp: {
                      type: 'string',
                      const: 'netsuite',
                      title: 'Used ERP',
                    },
                    bank: {
                      type: 'string',
                      const: 'osuuspankki',
                      title: 'Used bank',
                    },
                  },
                  required: ['erp', 'bank'],
                  title: 'System connections',
                },
                account: {
                  type: 'object',
                  properties: {
                    iban: {
                      type: 'string',
                      title: "Subsidiary's bank account number in IBAN -format",
                      description:
                        'This is used to match incoming payments to configurations',
                    },
                    bic: {
                      type: 'string',
                      title:
                        "Subsidiary's bank identification code in BIC -format",
                    },
                  },
                  required: ['iban', 'bic'],
                  title: 'Bank account details',
                },
                transactions: {
                  type: 'object',
                  properties: {
                    referenceFieldName: { type: 'string' },
                    referenceFieldName2: { type: 'string' },
                    referenceFieldName3: { type: 'string' },
                    referenceFieldName4: { type: 'string' },
                    referenceFieldName5: { type: 'string' },
                    numberFieldName: { type: 'string' },
                    numberFieldName2: { type: 'string' },
                    numberFieldName3: { type: 'string' },
                    numberFieldName4: { type: 'string' },
                    numberFieldName5: { type: 'string' },
                  },
                  required: ['referenceFieldName', 'numberFieldName'],
                },
                general: {
                  type: 'object',
                  properties: {
                    dateFormat: { type: 'string' },
                    roundingTolerance: { type: 'number' },
                  },
                  required: ['dateFormat'],
                },
                rules: {
                  type: 'object',
                  properties: {
                    erpEntityIdentification: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: {
                            type: 'string',
                            description: 'Human-readable name for the rule',
                          },
                          description: {
                            type: 'string',
                            description:
                              'Human-readable description for the rule',
                          },
                          ruleId: {
                            type: 'string',
                            description: 'Internal id (UUID) for the rule',
                          },
                          condition: {
                            type: 'string',
                            description:
                              'Configure Json Logic rule as the condition',
                          },
                          isDefault: {
                            type: 'boolean',
                            description:
                              'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                          },
                          ruleGroup: {
                            type: 'string',
                            const: 'erp-entity-identification-rule',
                            description: 'Rule type',
                          },
                          configuration: { description: 'Rule configuration' },
                          handler: {
                            type: 'string',
                            enum: [
                              'identify-entity-automatically',
                              'identify-entity-by-exception',
                            ],
                          },
                          disableAutomatedHandling: { type: 'boolean' },
                        },
                        required: [
                          'condition',
                          'handler',
                          'name',
                          'ruleGroup',
                          'ruleId',
                        ],
                      },
                    },
                    erpTransactionIdentification: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: {
                            type: 'string',
                            description: 'Human-readable name for the rule',
                          },
                          description: {
                            type: 'string',
                            description:
                              'Human-readable description for the rule',
                          },
                          ruleId: {
                            type: 'string',
                            description: 'Internal id (UUID) for the rule',
                          },
                          condition: {
                            type: 'string',
                            description:
                              'Configure Json Logic rule as the condition',
                          },
                          isDefault: {
                            type: 'boolean',
                            description:
                              'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                          },
                          ruleGroup: {
                            type: 'string',
                            const: 'erp-transaction-identification-rule',
                            description: 'Rule type',
                          },
                          configuration: { description: 'Rule configuration' },
                          handler: {
                            type: 'string',
                            const: 'identify-transaction-automatically',
                          },
                          disableAutomatedHandling: { type: 'boolean' },
                        },
                        required: [
                          'condition',
                          'handler',
                          'name',
                          'ruleGroup',
                          'ruleId',
                        ],
                      },
                    },
                    bankTransactionHandling: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          name: {
                            type: 'string',
                            description: 'Human-readable name for the rule',
                          },
                          description: {
                            type: 'string',
                            description:
                              'Human-readable description for the rule',
                          },
                          ruleId: {
                            type: 'string',
                            description: 'Internal id (UUID) for the rule',
                          },
                          condition: {
                            type: 'string',
                            description:
                              'Configure Json Logic rule as the condition',
                          },
                          isDefault: {
                            type: 'boolean',
                            description:
                              'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                          },
                          ruleGroup: {
                            type: 'string',
                            const: 'bank-transaction-handling-rule',
                            description: 'Rule type',
                          },
                          configuration: { description: 'Rule configuration' },
                          handler: {
                            type: 'string',
                            enum: [
                              'generate-customer-payments-per-identified-transaction',
                              'generate-customer-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                              'generate-vendor-payments-per-identified-transaction',
                              'generate-vendor-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                              'generate-consolidated-customer-payments-per-identified-transaction',
                            ],
                          },
                          disableAutomatedHandling: { type: 'boolean' },
                        },
                        required: [
                          'condition',
                          'handler',
                          'name',
                          'ruleGroup',
                          'ruleId',
                        ],
                      },
                    },
                  },
                  required: [
                    'erpEntityIdentification',
                    'erpTransactionIdentification',
                    'bankTransactionHandling',
                  ],
                },
              },
              required: ['connections', 'account', 'transactions', 'general'],
            },
          },
          required: ['configuration', 'name'],
        },
        status: {
          type: 'string',
          enum: [
            'Created',
            'Configuring',
            'Configured',
            'ConfigurationFailed',
            'Active',
            'Cancelling',
            'Cancelled',
            'Deleted',
          ],
          title: 'Internally used status enum for subscriptions',
        },
        subscriptionDetails: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'User-definable name for the integration',
            },
            disabled: { type: 'boolean' },
            startDate: { type: 'string' },
            endDate: { type: 'string' },
            createdDate: { type: 'string' },
            updatedDate: { type: 'string' },
          },
          required: ['startDate', 'createdDate', 'updatedDate'],
        },
      },
      required: ['id', 'app', 'status', 'subscriptionDetails'],
      title:
        'Enabled subscription matching the given account - undefined if not found',
    },
  },
  title: 'Resolve bank transaction subscriptions input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankPaymentSubscriptionInput = {
  type: 'object',
  properties: {
    account: { type: 'string', title: 'Account number in IBAN -format' },
  },
  required: ['account'],
  title: 'Resolve bank payment subscription input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const resolveBankPaymentSubscriptionOutput = {
  type: 'object',
  properties: {
    subscription: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        app: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              const: 'payment-integration-app',
              title: 'Application name',
            },
            configuration: {
              type: 'object',
              properties: {
                connections: {
                  type: 'object',
                  properties: {
                    erp: {
                      type: 'string',
                      const: 'netsuite',
                      title: 'Used ERP',
                    },
                    bank: {
                      type: 'string',
                      const: 'osuuspankki',
                      title: 'Used bank',
                    },
                  },
                  required: ['erp', 'bank'],
                  title: 'System connections',
                },
                subsidiary: {
                  type: 'object',
                  properties: {
                    subsidiaryId: {
                      type: 'string',
                      title: 'Identifier for the subsidiary',
                    },
                  },
                  required: ['subsidiaryId'],
                  title: 'ERP subsidiary details',
                },
                bank: {
                  type: 'object',
                  properties: {
                    name: { type: 'string', title: 'Bank name' },
                    addressLine1: {
                      type: 'string',
                      title: 'Street address line 1',
                    },
                    addressLine2: {
                      type: 'string',
                      title: 'Street address line 2',
                    },
                    countryCode: {
                      type: 'string',
                      title: 'Country code in ISO-3166 2-letters',
                    },
                  },
                  required: ['name', 'addressLine1', 'countryCode'],
                  title: 'Bank details',
                },
                account: {
                  type: 'object',
                  properties: {
                    iban: {
                      type: 'string',
                      title: "Subsidiary's bank account number in IBAN -format",
                      description:
                        'This is used to match incoming payments to configurations',
                    },
                    bic: {
                      type: 'string',
                      title:
                        "Subsidiary's bank identification code in BIC -format",
                    },
                    routingNumber: {
                      type: 'string',
                      title: 'The unique identification id for the bank',
                    },
                    organisationIdentification: {
                      type: 'string',
                      title: 'The unique service number issued by the bank',
                    },
                  },
                  required: ['iban', 'bic'],
                  title: 'Bank account details',
                },
              },
              required: ['connections', 'subsidiary', 'bank', 'account'],
            },
          },
          required: ['configuration', 'name'],
        },
        status: {
          type: 'string',
          enum: [
            'Created',
            'Configuring',
            'Configured',
            'ConfigurationFailed',
            'Active',
            'Cancelling',
            'Cancelled',
            'Deleted',
          ],
          title: 'Internally used status enum for subscriptions',
        },
        subscriptionDetails: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description: 'User-definable name for the integration',
            },
            disabled: { type: 'boolean' },
            startDate: { type: 'string' },
            endDate: { type: 'string' },
            createdDate: { type: 'string' },
            updatedDate: { type: 'string' },
          },
          required: ['startDate', 'createdDate', 'updatedDate'],
        },
      },
      required: ['id', 'app', 'status', 'subscriptionDetails'],
      title:
        'Enabled subscription matching the given account - undefined if not found',
    },
  },
  title: 'Resolve bank payment subscriptions input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const createBankPaymentInput = {
  type: 'object',
  properties: {
    payment: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          title:
            'Payment id - will be provided as the instruction id in bank systems',
        },
        endToEndId: { type: 'string', title: 'Identifier in ERP' },
        creditor: {
          type: 'object',
          properties: {
            name: { type: 'string', title: 'Creditor name' },
            iban: {
              type: 'string',
              title: 'Creditor bank account in IBAN format',
            },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'countryCode'],
              title: 'Creditor address',
            },
          },
          required: ['name', 'iban', 'address'],
          title: 'Payment creditor i.e. vendor in ERP',
        },
        debtor: {
          type: 'object',
          properties: {
            name: { type: 'string', title: 'Debtor name' },
            iban: {
              type: 'string',
              title: 'Debtor bank account in IBAN format',
            },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'countryCode'],
              title: 'Debtor address',
            },
          },
          required: ['name', 'iban', 'address'],
          title: 'Payment debtor i.e. subsidiary in ERP',
        },
        currencyCode: { type: 'string', title: 'Payment currency' },
        amount: { type: 'number', title: 'Payment amount' },
        reference: { type: 'string', title: 'Payment reference' },
        message: { type: 'string', title: 'Payment message' },
      },
      required: [
        'id',
        'endToEndId',
        'creditor',
        'debtor',
        'currencyCode',
        'amount',
      ],
      title: 'Bank payment',
    },
  },
  required: ['payment'],
  title: 'Create bank payment input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const createBankPaymentOutput = {
  type: 'object',
  properties: {
    status: {
      type: 'string',
      enum: ['Processing', 'Processed', 'Rejected'],
      title: 'Payment status',
    },
  },
  required: ['status'],
  title: 'Get bank payment status input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const getBankPaymentStatusInput = {
  type: 'object',
  properties: {
    id: { type: 'string', title: 'Payment id' },
    iban: { type: 'string', title: 'Account in IBAN format' },
  },
  required: ['id', 'iban'],
  title: 'Create bank payment status input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const getBankPaymentStatusOutput = {
  type: 'object',
  properties: {
    status: {
      type: 'string',
      enum: ['Processing', 'Processed', 'Rejected'],
      title: 'Payment status',
    },
  },
  required: ['status'],
  title: 'Get bank payment status input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const sendEInvoiceInput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        payerEdiNumber: { type: 'string' },
        referenceNumber: { type: 'string' },
        invoiceNumber: { type: 'string' },
        buyerReferenceNumber: { type: 'string' },
        date: { type: 'string' },
        dueDate: { type: 'string' },
        currencyCode: { type: 'string' },
        customer: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Customer id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Customer name' },
            businessId: { type: 'string', title: 'Customer business id' },
            eInvoiceId: { type: 'string', title: 'Customer e-invoice id' },
            vatNumber: { type: 'string', title: 'Customer vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Customer address',
            },
          },
          required: ['id', 'routingIdType', 'name', 'vatNumber', 'address'],
          title: 'Customer',
        },
        subsidiary: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Subsidiary id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Subsidiary name' },
            businessId: { type: 'string', title: 'Subsidiary business id' },
            eInvoiceId: { type: 'string', title: 'Subsidiary e-invoice id' },
            vatNumber: { type: 'string', title: 'Subsidiary vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Subsidiary address',
            },
            bankAccount: {
              type: 'object',
              properties: {
                bic: { type: 'string', title: 'BIC' },
                iban: { type: 'string', title: 'IBAN' },
              },
              required: ['bic', 'iban'],
              title: 'Used bank account',
            },
          },
          required: [
            'id',
            'routingIdType',
            'name',
            'vatNumber',
            'address',
            'bankAccount',
          ],
          title: 'Subsidiary',
        },
        lineItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              lineNumber: { type: 'number' },
              netAmount: { type: 'number' },
              taxAmount: { type: 'number' },
              taxPercentage: { type: 'number' },
              taxCategoryCode: { type: 'string' },
              item: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  quantity: { type: 'number' },
                  description: { type: 'string' },
                  netPrice: { type: 'number' },
                },
                required: ['name', 'quantity', 'netPrice'],
              },
            },
            required: [
              'lineNumber',
              'netAmount',
              'taxAmount',
              'taxPercentage',
              'taxCategoryCode',
              'item',
            ],
            title: 'Subsidiary',
          },
        },
        attachments: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: { type: 'string' },
              name: { type: 'string' },
              content: { type: 'array', items: { type: 'number' } },
            },
            required: ['id', 'name', 'content'],
          },
        },
      },
      required: [
        'id',
        'referenceNumber',
        'date',
        'dueDate',
        'currencyCode',
        'customer',
        'subsidiary',
        'lineItems',
        'attachments',
      ],
      title: 'Invoice',
    },
  },
  required: ['invoice'],
  title: 'Send e-invoice input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const sendEInvoiceOutput = {
  type: 'object',
  properties: {
    operatorId: { type: 'string' },
    senderReference: { type: 'string' },
  },
  required: ['operatorId'],
  title: 'Send e-invoice output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const eInvoiceOperationConfiguration = {
  type: 'object',
  properties: {
    sendMode: {
      type: 'string',
      enum: ['Production', 'Test', 'Certification'],
      title: "Send mode - default is 'Certification'",
    },
  },
  title: 'E-invoice operation configuration',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const queryFailedEInvoicesOutput = {
  type: 'object',
  properties: {
    invoices: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          operatorId: { type: 'string' },
          operatorFileId: { type: 'string' },
          error: {
            type: 'object',
            properties: {
              description: { type: 'string' },
              documentErrors: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    title: { type: 'string' },
                    description: { type: 'string' },
                    errorValue: { type: 'string' },
                    errorReference: { type: 'string' },
                  },
                  required: [
                    'title',
                    'description',
                    'errorValue',
                    'errorReference',
                  ],
                },
              },
            },
            required: ['description', 'documentErrors'],
          },
        },
        required: ['operatorId', 'operatorFileId', 'error'],
      },
    },
  },
  required: ['invoices'],
  title: 'Query failed e-invoices output data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const handleFailedEInvoiceInput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        operatorId: { type: 'string' },
        operatorFileId: { type: 'string' },
        error: {
          type: 'object',
          properties: {
            description: { type: 'string' },
            documentErrors: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  title: { type: 'string' },
                  description: { type: 'string' },
                  errorValue: { type: 'string' },
                  errorReference: { type: 'string' },
                },
                required: [
                  'title',
                  'description',
                  'errorValue',
                  'errorReference',
                ],
              },
            },
          },
          required: ['description', 'documentErrors'],
        },
      },
      required: ['operatorId', 'operatorFileId', 'error'],
      title: 'Invoice',
    },
  },
  required: ['invoice'],
  title: 'Handle failed e-invoice input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const handleFailedEInvoiceOutput = {
  type: 'object',
  properties: {
    invoice: {
      type: 'object',
      properties: {
        operatorId: { type: 'string' },
        operatorFileId: { type: 'string' },
        error: {
          type: 'object',
          properties: {
            description: { type: 'string' },
            documentErrors: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  title: { type: 'string' },
                  description: { type: 'string' },
                  errorValue: { type: 'string' },
                  errorReference: { type: 'string' },
                },
                required: [
                  'title',
                  'description',
                  'errorValue',
                  'errorReference',
                ],
              },
            },
          },
          required: ['description', 'documentErrors'],
        },
      },
      required: ['operatorId', 'operatorFileId', 'error'],
      title: 'Failed invoice',
    },
    originalInvoice: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        payerEdiNumber: { type: 'string' },
        referenceNumber: { type: 'string' },
        invoiceNumber: { type: 'string' },
        buyerReferenceNumber: { type: 'string' },
        date: { type: 'string' },
        dueDate: { type: 'string' },
        currencyCode: { type: 'string' },
        customer: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Customer id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Customer name' },
            businessId: { type: 'string', title: 'Customer business id' },
            eInvoiceId: { type: 'string', title: 'Customer e-invoice id' },
            vatNumber: { type: 'string', title: 'Customer vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Customer address',
            },
          },
          required: ['id', 'routingIdType', 'name', 'vatNumber', 'address'],
          title: 'Customer',
        },
        subsidiary: {
          type: 'object',
          properties: {
            id: { type: 'string', title: 'Subsidiary id' },
            routingIdType: {
              type: 'string',
              enum: ['businessId', 'vatNumber', 'eInvoiceId'],
              title: 'Routing id to use',
            },
            name: { type: 'string', title: 'Subsidiary name' },
            businessId: { type: 'string', title: 'Subsidiary business id' },
            eInvoiceId: { type: 'string', title: 'Subsidiary e-invoice id' },
            vatNumber: { type: 'string', title: 'Subsidiary vat number' },
            address: {
              type: 'object',
              properties: {
                streetAddress1: { type: 'string', title: 'Street address' },
                streetAddress2: { type: 'string', title: 'Street address 2' },
                city: { type: 'string', title: 'City' },
                postalCode: { type: 'string', title: 'Postal code' },
                countryCode: { type: 'string', title: 'Country code' },
              },
              required: ['streetAddress1', 'city', 'postalCode', 'countryCode'],
              title: 'Subsidiary address',
            },
            bankAccount: {
              type: 'object',
              properties: {
                bic: { type: 'string', title: 'BIC' },
                iban: { type: 'string', title: 'IBAN' },
              },
              required: ['bic', 'iban'],
              title: 'Used bank account',
            },
          },
          required: [
            'id',
            'routingIdType',
            'name',
            'vatNumber',
            'address',
            'bankAccount',
          ],
          title: 'Subsidiary',
        },
        lineItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              lineNumber: { type: 'number' },
              netAmount: { type: 'number' },
              taxAmount: { type: 'number' },
              taxPercentage: { type: 'number' },
              taxCategoryCode: { type: 'string' },
              item: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  quantity: { type: 'number' },
                  description: { type: 'string' },
                  netPrice: { type: 'number' },
                },
                required: ['name', 'quantity', 'netPrice'],
              },
            },
            required: [
              'lineNumber',
              'netAmount',
              'taxAmount',
              'taxPercentage',
              'taxCategoryCode',
              'item',
            ],
            title: 'Subsidiary',
          },
        },
      },
      required: [
        'id',
        'referenceNumber',
        'date',
        'dueDate',
        'currencyCode',
        'customer',
        'subsidiary',
        'lineItems',
      ],
      title: 'Original invoice data',
    },
  },
  required: ['invoice', 'originalInvoice'],
  title: 'Handle failed e-invoice input data model',
  $schema: 'http://json-schema.org/draft-07/schema#',
};
