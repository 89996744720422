import { useEffect, useState } from 'react';

import { ApiError } from '@apus/common-lib/api/interface/common';

import { executeApiCommand } from '../utils/api-call';
import {
  ApiCommandResult,
  TentativeApiCommandResult,
} from '@apus/common-lib/api/interface/integration-service';
import useIntegrationService from './useIntegrationService';
import useTenant from '../hooks/useTenant';

export interface ExposedApiCommandResultProps<Input> {
  apiId: string;
  group: string;
  path: string;
  input: Input | undefined;
}

function useExposedApiCommandResult<Input = undefined, Output = undefined>(
  props: ExposedApiCommandResultProps<Input>,
  depends: Array<any> = []
): [boolean, ApiCommandResult<Output> | undefined, ApiError | undefined] {
  const integrationService = useIntegrationService();
  const tenant = useTenant();
  const [pending, setPending] = useState<boolean>(false);
  const [result, setResult] = useState<TentativeApiCommandResult | undefined>();
  const [error, setError] = useState<ApiError | undefined>();

  const { apiId, group, path, input } = props;

  useEffect(
    () => {
      (async () => {
        if (tenant !== undefined) {
          await executeApiCommand<ApiCommandResult<Output>>({
            commandFunction: () =>
              integrationService.executeApiCommand<Output>(
                apiId,
                group,
                path,
                input
              ),
            resultFunction: (resultId: string) =>
              integrationService.getApiCommandResult(
                apiId,
                group,
                path,
                resultId
              ),
            setResult,
            setError,
            setPending,
          });
        }
      })();
    },
    // Disable check so that linter doesn't complain about the spread operator
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tenant,
      integrationService,
      apiId,
      group,
      path,
      input,
      executeApiCommand,
      setResult,
      setError,
      setPending,
      ...depends,
    ]
  );

  return [pending, result, error];
}

export default useExposedApiCommandResult;
