import {
  CustomContentMediaType,
  SupportedMappingSchemaDefinition,
  SupportedSchemaDefinition,
  TypescriptFunction,
} from '@apus/common-lib/api/interface/files';
import {
  SchemaMappingRule,
  SourceObject,
} from '@apus/common-lib/json-data-mapper/src/interface';
import { JSONSchema7 } from 'json-schema';
import { v4 as uuid } from 'uuid';

import {
  SupportedMappingSchemaFileEdit,
  SupportedTriggerSchemaFileEdit,
} from '../types/stored-files';

export function createSchemaDefinitionEdit<
  CONTENT,
  CONTENT_TYPE = CustomContentMediaType
>({
  name = '',
  description = '',
  content,
  contentType,
}: {
  name?: string;
  description?: string;
  content: CONTENT;
  contentType: CONTENT_TYPE;
}) {
  return {
    fileId: uuid(),
    name,
    description,
    contentType,
    content,
  };
}

export function asSchemaDefinitionEdit<
  T extends SupportedTriggerSchemaFileEdit | SupportedMappingSchemaFileEdit
>(
  definition: SupportedMappingSchemaDefinition | SupportedSchemaDefinition,
  makeACopy = false
): T {
  const base = {
    fileId: makeACopy ? uuid() : definition.fileId,
    name: makeACopy ? `Copy of "${definition.name}"` : definition.name,
    description: definition.description,
  };

  switch (definition.contentType) {
    case CustomContentMediaType.JsonSchema:
      return {
        ...base,
        contentType: CustomContentMediaType.JsonSchema,
        content: {
          jsonSchema: definition.content.jsonSchema as SourceObject,
        },
      } as T;
    case CustomContentMediaType.SchemaMappingRule:
      return {
        ...base,
        contentType: CustomContentMediaType.SchemaMappingRule,
        content: definition.content as SourceObject,
      } as T;
    case CustomContentMediaType.TypescriptFunction:
      return {
        ...base,
        contentType: CustomContentMediaType.TypescriptFunction,
        content: definition.content as unknown as SourceObject,
      } as T;
  }
}

export function asSchemaDefinition<
  T extends SupportedMappingSchemaDefinition | SupportedSchemaDefinition
>(edit: SupportedTriggerSchemaFileEdit | SupportedMappingSchemaFileEdit): T {
  const base = {
    fileId: edit.fileId,
    name: edit.name,
    description: edit.description,
  };

  switch (edit.contentType) {
    case CustomContentMediaType.JsonSchema:
      return {
        ...base,
        contentType: CustomContentMediaType.JsonSchema,
        content: {
          jsonSchema: edit.content.jsonSchema as JSONSchema7,
        },
      } as T;
    case CustomContentMediaType.SchemaMappingRule:
      return {
        ...base,
        contentType: CustomContentMediaType.SchemaMappingRule,
        content: edit.content as SchemaMappingRule,
      } as T;
    case CustomContentMediaType.TypescriptFunction:
      return {
        ...base,
        contentType: CustomContentMediaType.TypescriptFunction,
        content: edit.content as unknown as TypescriptFunction,
      } as T;
  }

  throw new Error(
    `Cannot map to schema definition: unsupported contentType ${edit.contentType}`
  );
}

export function asSchemaDefinitionOrUndefined<
  T extends SupportedMappingSchemaDefinition | SupportedSchemaDefinition
>(
  edit?: SupportedTriggerSchemaFileEdit | SupportedMappingSchemaFileEdit
): T | undefined {
  if (edit === undefined) return undefined;

  return asSchemaDefinition<T>(edit);
}
