import React, { JSX } from 'react';

import { Grid } from '@mui/material';

import OperationImportTool from '../../../components/operation/OperationImportTool';
import { bodyMaxHeight } from '../../../theme/theme';

const OperationImportView = (): JSX.Element => {
  return (
    <Grid
      container
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={12}>
        <OperationImportTool />
      </Grid>
    </Grid>
  );
};

export default OperationImportView;
