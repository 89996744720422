import { SchemaPath } from './interface';
import { JSONSchema7 } from 'json-schema';
import JsonSchemaDereferencer from '@json-schema-tools/dereferencer';
import { eachSchema, schemaFromJson } from './json-schema-library';
import { SupportedFileType } from './interface';

export function resolveSchema(schema: JSONSchema7, pointer: string) {
  const resolve = (
    schema: JSONSchema7,
    pointer: string,
    pointerStack: string[],
    path: string[]
  ): JSONSchema7 | undefined => {
    if (schema === undefined) return undefined;

    if (pointerStack.length === 0) return schema;

    const propName = pointerStack.shift();

    // @ts-ignore
    const next = schema[propName] as JSONSchema7;

    if (next?.type !== undefined) {
      if (
        !(schema.type === 'object' && propName === 'properties') &&
        !(schema.type === 'array' && propName === 'items')
      ) {
        path.push(propName as string);
      }
    }

    return resolve(next, pointer, pointerStack, path);
  };

  return resolve(
    schema,
    pointer,
    pointer.split('/').filter(s => s.trim() !== ''),
    []
  );
}

export function resolveObjectPath(schema: JSONSchema7, pointer: string) {
  const resolve = (
    schema: JSONSchema7,
    pointer: string,
    pointerStack: string[],
    path: string[]
  ): string | undefined => {
    if (schema === undefined) return undefined;

    if (pointerStack.length === 0) return path.join('.');

    const propName = pointerStack.shift();

    // @ts-ignore
    const next = schema[propName] as JSONSchema7;

    if (next?.type !== undefined) {
      if (
        !(schema.type === 'object' && propName === 'properties') &&
        !(schema.type === 'array' && propName === 'items')
      ) {
        path.push(propName as string);
      }
    }

    return resolve(next, pointer, pointerStack, path);
  };

  return resolve(
    schema,
    pointer,
    pointer.split('/').filter(s => s.trim() !== ''),
    []
  );
}

export function resolveSchemaAndPointerFromObjectPath(
  schema: JSONSchema7,
  path: string
) {
  const resolve = (
    schema: JSONSchema7,
    path: string,
    pathStack: string[],
    pointer: string[]
  ): [string | undefined, JSONSchema7 | undefined] => {
    if (schema === undefined) return [undefined, undefined];

    if (pathStack.length === 0) return [`/${pointer.join('/')}`, schema];

    const propName = pathStack.shift();

    if (schema.type === 'object') {
      pointer.push('properties');
      // @ts-ignore
      const next = schema.properties[propName] as JSONSchema7;
      if (next?.type !== undefined) pointer.push(propName as string);
      return resolve(next, path, pathStack, pointer);
    } else if (schema.type === 'array') {
      pointer.push('items');
      pointer.push('properties');
      // @ts-ignore
      const next = schema.items.properties[propName] as JSONSchema7;
      if (next?.type !== undefined) pointer.push(propName as string);
      return resolve(next, path, pathStack, pointer);
    } else {
      return [undefined, undefined];
    }
  };

  return resolve(
    schema,
    path,
    path.split('.').filter(s => s.trim() !== ''),
    []
  );
}

export function buildPathMap(
  schema: JSONSchema7
): Record<SchemaPath, JSONSchema7> {
  const result: Record<SchemaPath, JSONSchema7> = {};

  eachSchema(schema, (subSchema, pointer) => {
    result[pointer] = subSchema;
  });

  return result;
}

export function combinePaths(path: string, ...paths: string[]) {
  if (paths.length === 0) return path;
  return `${path}/${paths.join('/')}`.replace(/[\/][\/]+/g, '/');
}

export async function dereferenceSchema(schema: string | JSONSchema7) {
  if (schema === undefined) return undefined;
  if (typeof schema === 'string') {
    return new JsonSchemaDereferencer(JSON.parse(schema)).resolve();
  }
  return new JsonSchemaDereferencer(schema).resolve();
}

export async function parseSchema(data: string, fileType: SupportedFileType) {
  switch (fileType) {
    case SupportedFileType.JsonSchema: {
      const schema = JSON.parse(data) as JSONSchema7;
      return await dereferenceSchema(schema);
    }
    case SupportedFileType.Json: {
      return Promise.resolve(schemaFromJson(data));
    }
    default:
      throw new Error(
        `Cannot parse data to schema: unknown file type ${fileType}`
      );
  }
}
