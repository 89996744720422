import React from 'react';

import {
  ActionType,
  IntegrationActionLifecycleNotification,
} from '@apus/common-lib/api/interface/integration-service';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import NotificationsPausedTwoToneIcon from '@mui/icons-material/NotificationsPausedTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import {
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  SvgIconProps,
} from '@mui/material';
import { format } from 'date-fns';

interface ActionItemProps {
  inProgress?: boolean;
  action: IntegrationActionLifecycleNotification;
  onActionSelected: (action: IntegrationActionLifecycleNotification) => void;
  icon: React.ElementType<SvgIconProps>;
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

const ActionItem = ({
  inProgress = false,
  action,
  onActionSelected,
  icon,
  color = 'success',
}: ActionItemProps) => {
  const actionName = () => {
    if (action.actionType === ActionType.checkWorkflow) {
      return `Check workflow "${action.integration?.name}"`;
    }
    if (action.actionType === ActionType.runWorkflow) {
      return `Run event in workflow "${action.integration?.name}"`;
    }
    return 'Unknown';
  };

  return (
    <ListItem key={action.actionId + '-list-item'} disablePadding={true}>
      <ListItemIcon>
        {inProgress ? (
          <CircularProgress size={20} />
        ) : (
          <SvgIcon width={20} height={20} component={icon} color={color} />
        )}
      </ListItemIcon>
      <ListItemButton onClick={() => onActionSelected(action)}>
        <ListItemText
          primaryTypographyProps={{
            variant: 'subtitle2',
          }}
          primary={`(${format(
            new Date(action.effectiveOn),
            'dd.MM.yyyy HH:mm:ss.SSS X'
          )})`}
        ></ListItemText>
        <ListItemText
          sx={{ marginLeft: 1, flexGrow: 0 }}
          primaryTypographyProps={{
            variant: 'subtitle2',
            fontWeight: 'bold',
          }}
          primary={`${actionName()}`}
        />
      </ListItemButton>
    </ListItem>
  );
};

interface Props {
  action: IntegrationActionLifecycleNotification;
  onActionSelected: (action: IntegrationActionLifecycleNotification) => void;
}

const ActionNotification = ({
  action,
  onActionSelected,
}: Props): JSX.Element => {
  if (action === undefined) return <></>;

  switch (action.status) {
    case 'Processed':
    case 'Initiated':
      return (
        <ActionItem
          action={action}
          onActionSelected={onActionSelected}
          inProgress={action.status === 'Initiated'}
          icon={CheckCircleTwoToneIcon}
        />
      );
    case 'Omitted':
      return (
        <ActionItem
          action={action}
          onActionSelected={onActionSelected}
          icon={NotificationsPausedTwoToneIcon}
          color={'disabled'}
        />
      );
    case 'Edited':
      return (
        <ActionItem
          action={action}
          onActionSelected={onActionSelected}
          icon={EditTwoToneIcon}
          color={'primary'}
        />
      );
    case 'Retried':
      return (
        <ActionItem
          action={action}
          onActionSelected={onActionSelected}
          icon={ReplayTwoToneIcon}
          color={'primary'}
        />
      );
    case 'Failed':
      return (
        <ActionItem
          action={action}
          onActionSelected={onActionSelected}
          icon={ErrorTwoToneIcon}
          color={'error'}
        />
      );
  }

  return <></>;
};

export default ActionNotification;
