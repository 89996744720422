import {
  EmbeddedIntegrationOperation,
  IntegrationDispatcher,
  IntegrationDispatcherArgs,
  IntegrationOperation,
  IntegrationOperationInstance,
  RunContext,
  SupportedModule,
  WorkflowOperation,
  WorkflowOperationType,
} from '../../integrations/src/interface';
import { BadRequest, InternalServerError } from '../../utils/src/error';
import { SourceObject } from '../../json-data-mapper/src/interface';
import { JSONSchema7 } from 'json-schema';
import { logRecordFor } from '../../utils/src/logger';
import {
  CustomContentMediaType,
  SupportedMappingSchemaDefinition,
  SupportedSchemaDefinition,
} from '../../api/interface/files';
import {
  DispatchNode,
  FilterCondition,
  InputTransformations,
  IntegrationNode,
  IntegrationNodeType,
  NodeResult,
  OperationNode,
  SchemaChrootConfiguration,
  WorkflowRunContext,
} from './interface';
import { JsonSchemaManipulationTool } from '../../json-data-mapper/src/schema-manipulation-tool';
import {
  IntegrationEventStatus,
  WorkflowNodeResult,
} from '../../api/interface/integration-service';
import objectPath from 'object-path';
import { camelCase, cloneDeep, isEmpty, omit } from 'lodash';
import {
  aggregateConfiguration,
  buildJsonLogicRunner,
  toJsonSchemaDefinition,
} from '../../utils/src/data-utils';

const debug = logRecordFor('integration-functions', 'debug');
const errorLog = logRecordFor('integration-functions', 'error');

export function asNodeIdentifier(name: string): string {
  return camelCase(name);
}

export function isTrigger(node: IntegrationNode) {
  return [
    IntegrationNodeType.ErrorTrigger,
    IntegrationNodeType.WebhookTrigger,
    IntegrationNodeType.PollingTrigger,
    IntegrationNodeType.ApiEndpointTrigger,
  ].includes(node.nodeType);
}

export function resolveHandler(
  ctx: WorkflowRunContext,
  operation: EmbeddedIntegrationOperation | IntegrationOperation,
  nodeId: string
) {
  const handler =
    operation !== undefined
      ? ctx.handlerResolver({
          prototype: operation.prototype,
          nodeId: nodeId,
        })
      : undefined;

  if (handler === undefined) {
    errorLog(
      `Cannot handle operation ${operation.operationId}: handler not found`,
      {
        // todo: configuration resolving
        //configuration: cur.configuration,
        id: operation.operationId,
      }
    );
    // Don't throw - instead set error so that we can throw an ActionHandlingError
    throw new InternalServerError(
      `Cannot handle operation ${operation.operationId}: handler not found`,
      {
        // todo: configuration resolving
        //configuration: cur.configuration,
        id: operation.operationId,
      }
    );
  }
  return handler;
}

export function resolveOperation(
  ctx: WorkflowRunContext,
  instance: IntegrationOperationInstance
) {
  const operation = ctx.operationResolver({ ...instance });

  debug(`using operation`, { operation });

  if (operation === undefined) {
    errorLog(
      `Cannot handle operation ${instance.operationId}: operation not found`,
      {
        // todo: configuration resolving
        //configuration: cur.configuration,
        id: instance.operationId,
      }
    );
    // TODO: Don't throw - instead set error so that we can throw an ActionHandlingError
    throw new InternalServerError(
      `Cannot handle operation ${instance.operationId}: operation not found`,
      {
        // todo: configuration resolving
        //configuration: cur.configuration,
        id: instance.operationId,
      }
    );
  }

  return operation;
}

function resolveIntegrationDispatcher(
  ctx: WorkflowRunContext
): IntegrationDispatcher {
  if (ctx.integrationDispatcher === undefined)
    throw new BadRequest(
      `Cannot handle dispatch: integrationDispatcher not defined`
    );

  return ctx.integrationDispatcher;
}

export function mapDataToOperationInput(
  ctx: WorkflowRunContext,
  input: unknown,
  sourceSchema: SupportedSchemaDefinition,
  targetSchema: SupportedSchemaDefinition | undefined,
  mappingDefinition: SupportedMappingSchemaDefinition | undefined
) {
  if (
    sourceSchema.contentType !== CustomContentMediaType.JsonSchema &&
    targetSchema?.contentType !== CustomContentMediaType.JsonSchema
  ) {
    throw new InternalServerError(
      `Cannot map operation data - unsupported content type (source: ${sourceSchema.contentType}, target: ${targetSchema?.contentType}`
    );
  }

  if (targetSchema !== undefined && mappingDefinition?.content !== undefined) {
    debug('runOperation - map data', {
      data: ctx.integrationContext,
      input,
      inputContext: ctx.integrationContextSchema,
      rule: mappingDefinition.content,
      inputSchema: sourceSchema,
      outputSchema: targetSchema,
    });

    // map the inputContext to the input data required by the operation
    return ctx.map({
      data: input as unknown as SourceObject,
      mappingDefinition: mappingDefinition,
      inputSchema: sourceSchema,
      outputSchema: targetSchema,
      eventContext: ctx.eventContext,
    });
  } else {
    debug('runOperation - no data mapping needed');
  }

  return input as unknown;
}

export function resolveModuleConfiguration(
  ctx: WorkflowRunContext,
  instance: WorkflowOperation
) {
  return ctx.tenantModuleConfigurations !== undefined
    ? ctx.tenantModuleConfigurations[instance.moduleId as SupportedModule]
    : undefined;
}

export function resolveServiceProviderModuleConfiguration(
  ctx: WorkflowRunContext,
  instance: WorkflowOperation
) {
  return ctx.serviceProviderModuleConfigurations !== undefined
    ? ctx.serviceProviderModuleConfigurations[
        instance.moduleId as SupportedModule
      ]
    : undefined;
}

export function makeOperationRunner(
  ctx: WorkflowRunContext,
  instance: WorkflowOperation,
  nodeId: string,
  integrationContextSchema: JSONSchema7
) {
  const operation =
    instance.operationType === WorkflowOperationType.EMBEDDED
      ? instance
      : resolveOperation(ctx, instance);
  const handler = resolveHandler(ctx, operation, nodeId);
  const moduleConfiguration = resolveModuleConfiguration(ctx, instance);
  const serviceProviderModuleConfiguration =
    resolveServiceProviderModuleConfiguration(ctx, instance);

  return async function runOperation(inputData: unknown): Promise<NodeResult> {
    const started = new Date();

    if (ctx.recorder !== undefined) ctx.recorder.start();

    let status: IntegrationEventStatus = 'Started';
    let error: Error | undefined = undefined;

    let operationInput: unknown | undefined;
    let operationResult;

    try {
      operationInput = mapDataToOperationInput(
        ctx,
        inputData,
        toJsonSchemaDefinition(integrationContextSchema),
        instance.inputSchema ?? operation?.inputSchema,
        instance.mappingSchema
      );

      debug('Handling operation', {
        operationInput,
        moduleConfiguration,
        serviceProviderModuleConfiguration,
        tenantSubscriptions: ctx.tenantSubscriptions,
        eventContext: ctx.eventContext,
      });

      const prototypeConfiguration =
        instance.operationType === WorkflowOperationType.EMBEDDED
          ? instance.configuration
          : operation.configuration;
      const runtimeConfiguration = isEmpty(instance.runtimeConfiguration)
        ? undefined
        : instance.runtimeConfiguration;
      const mergedConfiguration = aggregateConfiguration(
        prototypeConfiguration,
        runtimeConfiguration
      );

      operationResult = await handler({
        parameters: {
          connectionResolver: ctx.connectionResolver,
          // TODO: we should only provide the connectionResolver
          tenantModuleConfiguration:
            ctx.connectionResolver.resolveTenantConfiguration(
              instance.moduleId as SupportedModule
            ),
          // TODO: we should only provide the connectionResolver
          serviceProviderModuleConfiguration:
            ctx.connectionResolver.resolveProviderConfiguration(
              instance.moduleId as SupportedModule
            ),
          prototypeConfiguration: mergedConfiguration,
          operationId: instance.operationId,
          inputSchema: instance.inputSchema ?? operation?.inputSchema,
          outputSchema: instance.outputSchema ?? operation?.outputSchema,
          environmentConfiguration: ctx.environmentConfiguration,
          eventContext: ctx.eventContext,
          tenantSubscriptions: ctx.tenantSubscriptions,
          accessTokenStorage: ctx.accessTokenStorage,
          tenantIntegrationClient: ctx.tenantIntegrationClient,
        },
        data: operationInput,
      });
      status = 'Finished';
      debug('Handled operation', { operationResult, status });
    } catch (e) {
      status = 'Error';
      error = e as Error;
    }

    ctx.recorder?.stop();

    return {
      started,
      output: operationResult,
      input: operationInput,
      status,
      error,
      // make sure that recorder is stopped always
      callRecord:
        ctx.recorder !== undefined ? ctx.recorder.getCalls() : undefined,
    };
  };
}

export function makeDispatchRunner(
  ctx: WorkflowRunContext,
  nodeId: string,
  integrationId: string,
  integrationContextSchema: JSONSchema7,
  triggerSchema: SupportedSchemaDefinition,
  mappingSchemaDefinition: SupportedMappingSchemaDefinition,
  isSynchronous?: boolean
) {
  const integrationDispatcher = resolveIntegrationDispatcher(ctx);

  return async function runDispatch(inputData: unknown): Promise<NodeResult> {
    // make sure that no recorder is running
    ctx.recorder?.stop();

    const started = new Date();

    let status: IntegrationEventStatus = 'Started';
    let error: Error | undefined = undefined;

    let triggerData: unknown | undefined;

    let dispatcherData: IntegrationDispatcherArgs | undefined;

    try {
      triggerData = mapDataToOperationInput(
        ctx,
        inputData,
        toJsonSchemaDefinition(integrationContextSchema),
        triggerSchema,
        mappingSchemaDefinition
      );

      debug('Dispatching to webhook', { triggerData });

      dispatcherData = {
        integrationId: integrationId,
        data: triggerData,
        synchronous: isSynchronous,
      };

      await integrationDispatcher(dispatcherData);
      status = 'Finished';
      debug('Handled operation', { status });
    } catch (e) {
      status = 'Error';
      error = e as Error;
    }

    return {
      started,
      output: dispatcherData,
      input: triggerData,
      status,
      error,
      // no recording of internal aws calls
      callRecord: undefined,
    };
  };
}

export function makeApiResultRunner(
  ctx: WorkflowRunContext,
  nodeId: string,
  integrationContextSchema: JSONSchema7,
  resultSchema: SupportedSchemaDefinition,
  mappingSchemaDefinition: SupportedMappingSchemaDefinition
) {
  return async function generateResult(
    inputData: unknown
  ): Promise<NodeResult> {
    // make sure that no recorder is running
    ctx.recorder?.stop();

    const started = new Date();

    let status: IntegrationEventStatus = 'Started';
    let error: Error | undefined = undefined;

    let resultData: unknown | undefined;

    try {
      resultData = mapDataToOperationInput(
        ctx,
        inputData,
        toJsonSchemaDefinition(integrationContextSchema),
        resultSchema,
        mappingSchemaDefinition
      );

      debug('Generating result data', { resultData });
      await ctx.apiResultHandler({
        result: {
          resultStatus: 'finished',
          result: resultData,
        },
        eventContext: ctx.eventContext,
      });

      status = 'Finished';
      debug('Handled operation', { status });
    } catch (e) {
      status = 'Error';
      error = e as Error;
    }

    return {
      started,
      output: resultData,
      input: inputData,
      status,
      error,
      // no recording of internal calls
      callRecord: undefined,
    };
  };
}

function splitArraySchema(schema: JSONSchema7): JSONSchema7 {
  if (schema.type !== 'array' || schema.items === undefined) return schema;

  return schema.items as JSONSchema7;
}

export function maybeChrootSchema(
  schema: JSONSchema7,
  chroot?: SchemaChrootConfiguration
): JSONSchema7 {
  if (chroot?.enabled && chroot.schemaPath !== undefined) {
    const schemaItem = new JsonSchemaManipulationTool({
      schema,
    }).getItemBySchemaPath(chroot?.schemaPath);

    if (schemaItem === undefined) {
      throw new InternalServerError(
        `Cannot apply modifications to trigger schema - cannot resolve schema path`,
        { schema, chroot }
      );
    }

    return JsonSchemaManipulationTool.from(schemaItem).asSchema();
  }

  return schema;
}

export function maybeUseArrayItemSchema(schema: JSONSchema7, iterate = false) {
  return iterate ? splitArraySchema(schema) : schema;
}

export function getPreviousResults(
  workflowNodeId: string,
  results: WorkflowNodeResult[],
  failOnMultiple = false
): WorkflowNodeResult[] {
  const previous = results
    .filter(result => result.workflowNodeId === workflowNodeId)
    // only include finished results
    .filter(result => result.status === 'Finished')
    // set the copied flag to make it easier to discern these from new results
    .map(result => ({ ...result, copied: true }));

  if (failOnMultiple && previous.length > 1)
    throw new InternalServerError(
      `Multiple previous results found when it is not allowed`,
      { workflowNodeId }
    );

  debug(`Using previous results`, { previous });
  return previous;
}

export function makeInputDataFilter(
  filter: FilterCondition | undefined,
  schema: JSONSchema7
) {
  if (filter === undefined || !filter.enabled || filter.condition === undefined)
    return () => true;

  const logic = buildJsonLogicRunner(filter.condition, schema);

  return (item: any): boolean => {
    const result = logic(item);
    debug(`filtering input data`, { item, result });
    return result;
  };
}

export function makeConditionChecker<T extends OperationNode | DispatchNode>(
  node: T,
  runContextSchema: JSONSchema7
) {
  debug(`creating node condition check`, { runCondition: node.runCondition });
  if (node.runCondition?.enabled !== true) return () => true;

  if (node.runCondition?.condition === undefined)
    throw new InternalServerError(
      `Node condition is enabled, but condition string is not defined`,
      { node }
    );

  const logic = buildJsonLogicRunner(
    node.runCondition.condition,
    runContextSchema
  );

  return function (runContext: RunContext) {
    debug(`checking node condition`);
    return logic(runContext);
  };
}

export function filterPreviousResults(
  transformedInputData: unknown,
  previousResults: WorkflowNodeResult[]
): unknown | undefined {
  debug(`filterPreviousResults`, { transformedInputData, previousResults });
  if (previousResults.length === 0) return transformedInputData;

  if (Array.isArray(transformedInputData)) {
    if (transformedInputData.length < previousResults.length)
      throw new InternalServerError(
        `Cannot filter previous results from input data - there are more previous results than input data`
      );
    // TODO: maybe we need three options: 1) don't filter, 2) filter by index and 3) filter by matching?
    // for now simply filter by index
    return transformedInputData.slice(previousResults.length);
  } else {
    if (previousResults.length > 1)
      throw new InternalServerError(
        `Cannot filter previous results from input data - data is not an array, yet previous results is`
      );
    return undefined;
  }
}

export function transformToInputData(
  inputContext: unknown | undefined,
  inputTransformations: InputTransformations | undefined,
  schema: JSONSchema7
): unknown | undefined {
  if (
    !inputTransformations?.chroot?.enabled ||
    inputTransformations?.chroot?.schemaPath === undefined
  ) {
    return inputContext;
  }

  if (inputContext === undefined) return inputContext;

  const schemaItem = new JsonSchemaManipulationTool({
    schema,
  }).getItemBySchemaPath(inputTransformations.chroot.schemaPath);

  debug('chrootIntegrationContext - schema item', { schemaItem, schema });

  // TODO: add a type guard
  return objectPath.get(inputContext as object, schemaItem?.objectPath ?? '');
}

export function applyInputTransformations(
  schema: JSONSchema7 | undefined,
  inputTransformations: InputTransformations | undefined
): JSONSchema7 | undefined {
  if (schema === undefined) return undefined;

  // apply chroot
  schema = maybeChrootSchema(schema, inputTransformations?.chroot);

  // apply iteration
  schema = maybeUseArrayItemSchema(schema, inputTransformations?.iterate);

  return schema;
}

export function undoInputTransformations(
  schema: JSONSchema7 | undefined,
  inputTransformations: InputTransformations | undefined
): JSONSchema7 | undefined {
  if (schema === undefined) return undefined;

  if (inputTransformations?.iterate) {
    return {
      type: 'array',
      items: schema,
    };
  }

  return schema;
}

export function moveProperty<T extends object>(
  o: T,
  from: string,
  to: string
): T {
  if (!objectPath.has(o, from)) return o;

  const copy = omit(cloneDeep(o), from);
  const value = objectPath.get(o, from);
  objectPath.set(copy, to, value);

  return copy as T;
}
