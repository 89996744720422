import { JSX, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { TenantContext } from '@apus/common-ui/state/tenantContext';

interface Props {
  children: JSX.Element;
}

export function RequireTenant({ children }: Props): JSX.Element {
  const [tenantState] = useContext(TenantContext);

  if (tenantState.tenant === undefined) {
    return <Navigate to="/" />;
  }

  return children;
}
