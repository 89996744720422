import { v4 as uuid } from 'uuid';

import {
  BankTransactionHandlingRuleHandler,
  ErpEntityIdentificationRuleHandler,
  ErpTransactionIdentificationRuleHandler,
  RuleGroup,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import {
  IdentifyErpEntityAutomaticallyRule,
  IdentifyErpTransactionAutomaticallyRule,
  IdentifyErpEntityByExceptionRule,
  GenerateCustomerPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference,
  GenerateVendorPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference,
  GenerateCustomerPaymentPerIdentifiedTransaction,
  GenerateVendorPaymentPerIdentifiedTransaction,
  GenerateConsolidatedCustomerPaymentsPerIdentifiedTransaction,
} from '@apus/common-lib/integrations/src/banking/transactions/rules-interface';

export const defaultErpEntityIdentificationRule: IdentifyErpEntityAutomaticallyRule =
  {
    name: 'Identify ERP entity by name',
    description:
      'Matches by comparing (case insensitively) an ERP entity name to the customer name in bank transaction',
    ruleGroup: RuleGroup.ERP_ENTITY_IDENTIFICATION_RULE,
    ruleId: '6434899a-cde9-4d89-9798-76037b18d1e5',
    handler: ErpEntityIdentificationRuleHandler.IDENTIFY_ENTITY_AUTOMATICALLY,
    condition: JSON.stringify({
      '==': [
        { var: '/properties/bankTransaction/properties/entityName' },
        { var: '/properties/erpEntity/properties/entityName' },
      ],
    }),
    isDefault: true,
  };

export function makeErpEntityIdentificationExceptionRule(
  entityName: string,
  entityId: string
): IdentifyErpEntityByExceptionRule {
  return {
    name: 'Identify ERP entity by custom mapping',
    description:
      'Allows users to map a customer name in a bank transaction to a given ERP entity id',
    ruleGroup: RuleGroup.ERP_ENTITY_IDENTIFICATION_RULE,
    ruleId: uuid(),
    handler: ErpEntityIdentificationRuleHandler.IDENTIFY_ENTITY_BY_EXCEPTION,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/entityName' },
            entityName,
          ],
        },
        {
          '==': [
            { var: '/properties/erpEntity/properties/entityId' },
            entityId,
          ],
        },
      ],
    }),
    isDefault: false,
  };
}

export const erpTransactionIdentifiedByReferenceRule: IdentifyErpTransactionAutomaticallyRule =
  {
    name: 'Identify ERP transaction by reference',
    description:
      'Matches by comparing (case insensitively) an ERP transaction reference to either of the bank transaction references',
    ruleGroup: RuleGroup.ERP_TRANSACTION_IDENTIFICATION_RULE,
    ruleId: 'f5fd0318-3d1c-4cf2-8b7c-d9088639800d',
    handler:
      ErpTransactionIdentificationRuleHandler.IDENTIFY_TRANSACTION_AUTOMATICALLY,
    condition: JSON.stringify({
      or: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/reference' },
            { var: '/properties/erpTransaction/properties/reference' },
          ],
        },
        {
          '==': [
            { var: '/properties/bankTransaction/properties/rfReference' },
            { var: '/properties/erpTransaction/properties/reference' },
          ],
        },
      ],
    }),
    isDefault: true,
  };

export const erpTransactionIdentifiedByInvoiceNumberRule: IdentifyErpTransactionAutomaticallyRule =
  {
    name: 'Identify ERP transaction by number',
    description:
      'Matches by trying to find (case insensitively) an ERP transaction number from the message in bank transaction',
    ruleGroup: RuleGroup.ERP_TRANSACTION_IDENTIFICATION_RULE,
    ruleId: '7135f005-4f5d-4178-9aa8-3fbe2f8cb296',
    handler:
      ErpTransactionIdentificationRuleHandler.IDENTIFY_TRANSACTION_AUTOMATICALLY,
    condition: JSON.stringify({
      and: [
        {
          in: [
            { var: '/properties/bankTransaction/properties/message' },
            { var: '/properties/erpTransaction/properties/number' },
          ],
        },
        {
          '==': [
            { var: '/properties/erpEntity/properties/entityId' },
            { var: '/properties/erpTransaction/properties/entityId' },
          ],
        },
      ],
    }),
    isDefault: true,
  };

export const erpTransactionIdentifiedByAmountRule: IdentifyErpTransactionAutomaticallyRule =
  {
    name: 'Identify ERP transaction by original amount',
    description:
      'Matches by comparing the original total amount in an ERP transaction to the amount in bank transaction',
    ruleGroup: RuleGroup.ERP_TRANSACTION_IDENTIFICATION_RULE,
    ruleId: '597fbce5-d4f9-49d3-9634-d8777739d775',
    handler:
      ErpTransactionIdentificationRuleHandler.IDENTIFY_TRANSACTION_AUTOMATICALLY,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/erpTransaction/properties/amountTotal' },
            { var: '/properties/bankTransaction/properties/amount' },
          ],
        },
        {
          '==': [
            {
              var: '/properties/erpEntity/properties/entityId',
            },
            { var: '/properties/erpTransaction/properties/entityId' },
          ],
        },
      ],
    }),
    isDefault: true,
  };

export const singleCustomerInvoiceIdentifiedUnambiguouslyRule: GenerateCustomerPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference =
  {
    name: 'Bank transaction matches a single customer invoice exactly',
    description:
      'Bank transaction matches the remaining amount on the identified ERP transaction exactly or within configured rounding error tolerance.\n\nA customer payment will be created for the remaining amount and if rounding tolerance is not zero, a journal entry will be made for the difference.',
    ruleId: '6da7c0e6-bdb4-4255-98bd-dc04f97eb319',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'credit',
          ],
        },
        { '==': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '<=': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const singleVendorBillIdentifiedUnambiguously: GenerateVendorPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference =
  {
    name: 'Bank transaction matches a single vendor bill exactly',
    description:
      'Bank transaction matches the remaining amount on the identified ERP transaction exactly or within configured rounding error tolerance.\n\nA customer payment will be created for the remaining amount and if rounding tolerance is not zero, a journal entry will be made for the difference.',
    ruleId: '70e4a8b7-ad45-41fb-8aad-6b1ffe6465c2',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'debit',
          ],
        },
        { '==': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        {
          '<=': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_VENDOR_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const multipleCustomerInvoicesIdentifiedUnambiguously: GenerateCustomerPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference =
  {
    name: 'Bank transaction matches multiple customer invoices exactly',
    description:
      'Bank transaction matches the remaining amount on the identified ERP transactions exactly or within configured rounding error tolerance.\n\nA customer payment will be created for the remaining amount per each identified transaction and if rounding tolerance is not zero, a journal entry will be made for the remaining difference.',
    ruleId: '738912fc-16a3-4462-a031-250adaef31f4',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'credit',
          ],
        },
        { '>': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        {
          '<=': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const multipleVendorBillsIdentifiedUnambiguously: GenerateVendorPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference =
  {
    name: 'Bank transaction matches multiple vendor bills exactly',
    description:
      'Bank transaction matches the remaining amount on the identified ERP transactions exactly or within configured rounding error tolerance.\n\nA customer payment will be created for the remaining amount per each identified transaction and if rounding tolerance is not zero, a journal entry will be made for the remaining difference.',
    ruleId: '22d8db76-4874-44cb-9d52-62677b7456bf',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'debit',
          ],
        },
        { '>': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        {
          '<=': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_VENDOR_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const singleCustomerInvoiceIdentifiedUnderpaid: GenerateCustomerPaymentPerIdentifiedTransaction =
  {
    name: 'Bank transaction matches a single customer invoice but the amount is less than remaining amount',
    description:
      'Bank transaction is less than the remaining amount on the identified ERP transaction.\n\nA customer payment will be made for the transferred amount and invoice will remain open.',
    ruleId: '477ad3e4-f55a-461c-a1ab-d9775e84f06d',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'credit',
          ],
        },
        { '==': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        {
          '<': [
            { var: '/properties/bankTransaction/properties/amount' },
            { var: '/properties/resolvedTotalTransactionSum' },
          ],
        },
        {
          '>': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const singleVendorBillIdentifiedUnderpaid: GenerateVendorPaymentPerIdentifiedTransaction =
  {
    name: 'Bank transaction matches a single vendor bill but the amount is less than remaining amount',
    description:
      'Bank transaction is less than the remaining amount on the identified ERP transaction.\n\nA vendor payment will be made for the transferred amount and bill will remain open.',
    ruleId: '73c1b175-0d9a-4d0d-9389-12b4a44b24d8',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'debit',
          ],
        },
        { '==': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        {
          '<': [
            { var: '/properties/bankTransaction/properties/amount' },
            { var: '/properties/resolvedTotalTransactionSum' },
          ],
        },
        {
          '>': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_VENDOR_PAYMENT_PER_IDENTIFIED_TRANSACTION,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const singleCustomerInvoiceIdentifiedOverpaid: GenerateCustomerPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference =
  {
    name: 'Bank transaction matches a single customer invoice but the amount is more than remaining amount',
    description:
      'Bank transaction is more than the remaining amount on the identified ERP transaction.\n\nA customer payment will be made for the remaining amount and a journal entry will be made for the extra amount.',
    ruleId: 'fd4a250a-4ab9-4b9f-9da3-ec06ab245fa0',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'credit',
          ],
        },
        { '==': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        {
          '>': [
            { var: '/properties/bankTransaction/properties/amount' },
            { var: '/properties/resolvedTotalTransactionSum' },
          ],
        },
        {
          '>': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const singleVendorBillIdentifiedOverpaid: GenerateVendorPaymentPerIdentifiedTransactionAndAJournalEntryOfDifference =
  {
    name: 'Bank transaction matches a single vendor bill but the amount is more than remaining amount',
    description:
      'Bank transaction is more than the remaining amount on the identified ERP transaction.\n\nA vendor payment will be made for the remaining amount and a journal entry will be made for the extra amount.',
    ruleId: 'afff232e-384d-46e0-8471-71ea0afedbb6',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'debit',
          ],
        },
        { '==': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        {
          '>': [
            { var: '/properties/bankTransaction/properties/amount' },
            { var: '/properties/resolvedTotalTransactionSum' },
          ],
        },
        {
          '>': [
            { var: '/properties/resolvedTotalDifference' },
            { var: '/properties/roundingTolerance' },
          ],
        },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATE_VENDOR_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const multipleConsolidatedCustomerInvoicesIdentifiedUnambiguously: GenerateConsolidatedCustomerPaymentsPerIdentifiedTransaction =
  {
    name: 'Bank transaction matches a multiple customer invoice with invoice grouping enabled',
    description: '?',
    ruleId: '82915147-33a0-469f-aafe-1008a905bcb3',
    ruleGroup: RuleGroup.BANK_TRANSACTION_HANDLING_RULE,
    condition: JSON.stringify({
      and: [
        {
          '==': [
            { var: '/properties/bankTransaction/properties/transactionType' },
            'credit',
          ],
        },
        { '>': [{ var: '/properties/resolvedNumberOfTransactions' }, 1] },
        {
          '>': [{ var: '/properties/resolvedTotalTransactionSum' }, 0],
        },
        // {
        //   '==': [
        //     { var: '/properties/erpEntity/properties/invoiceGroupingEnabled' },
        //     true,
        //   ],
        // },
      ],
    }),
    handler:
      BankTransactionHandlingRuleHandler.GENERATED_CONSOLIDATED_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION,
    isDefault: true,
    disableAutomatedHandling: false,
  };

export const defaultErpEntityIdentificationRules = [
  defaultErpEntityIdentificationRule,
];

export const defaultErpTransactionIdentificationRules = [
  erpTransactionIdentifiedByReferenceRule,
  erpTransactionIdentifiedByInvoiceNumberRule,
  erpTransactionIdentifiedByAmountRule,
];

export const defaultBankTransactionHandlingRules = [
  singleCustomerInvoiceIdentifiedUnambiguouslyRule,
  singleVendorBillIdentifiedUnambiguously,
  multipleCustomerInvoicesIdentifiedUnambiguously,
  multipleVendorBillsIdentifiedUnambiguously,
  singleCustomerInvoiceIdentifiedUnderpaid,
  singleVendorBillIdentifiedUnderpaid,
  singleCustomerInvoiceIdentifiedOverpaid,
  singleVendorBillIdentifiedOverpaid,
  multipleConsolidatedCustomerInvoicesIdentifiedUnambiguously,
];
