import React, { JSX } from 'react';

import {
  Rule,
  RuleGroup,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';
import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

import DefineRule from './DefineRule';
import { BankTransactionRuleConfigurationData } from './interface';
import CollapsibleListItem from '../../../../../surface/CollapsibleListItem';

interface Functionality<T extends RuleGroup> {
  onClick: (rule: Rule<T, any>) => void;
  disabled?: boolean;
}

interface ToolbarProps<T extends RuleGroup> {
  moveUp?: Functionality<T>;
  moveDown?: Functionality<T>;
}

interface Props<T extends RuleGroup> {
  rule: Rule<T, any>;
  configurationData?: BankTransactionRuleConfigurationData;
  onChange: (rule: Rule<T, any>) => void;
  edit?: boolean;
  toolbar?: ToolbarProps<T>;
}

const RuleListItem = <T extends RuleGroup>({
  rule,
  configurationData,
  onChange,
  edit,
  toolbar,
}: Props<T>): JSX.Element => {
  return (
    <CollapsibleListItem
      listItemTextProps={{
        primaryTypographyProps: {
          variant: 'subtitle2',
        },
        primary: `${rule.name}`,
      }}
      collapseProps={{
        sx: { pl: 4, pr: 6 },
        unmountOnExit: true,
      }}
      listItemProps={{
        disablePadding: true,
        secondaryAction: (
          <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
            <Button
              variant={'text'}
              startIcon={
                <ArrowDownwardTwoToneIcon sx={{ fontSize: '1.0rem' }} />
              }
              disabled={toolbar?.moveDown?.disabled ?? true}
              onClick={() => toolbar?.moveDown?.onClick(rule)}
            ></Button>

            <Button
              variant={'text'}
              startIcon={<ArrowUpwardTwoToneIcon sx={{ fontSize: '1.0rem' }} />}
              disabled={toolbar?.moveUp?.disabled ?? true}
              onClick={() => toolbar?.moveUp?.onClick(rule)}
            ></Button>
          </Box>
        ),
      }}
    >
      <DefineRule
        rule={rule}
        configurationData={configurationData}
        onChange={onChange}
        disabled={!edit}
      />
    </CollapsibleListItem>
  );
};

export default RuleListItem;
