import { createContext } from 'react';

import { SignedInUser } from '@apus/common-lib/api/interface/tenant-service';

import { defaultDispatch, UserReducerContext } from './userReducer';

export interface UserState {
  user?: SignedInUser;
}

export const initialUserState: UserState = {};

export const UserContext = createContext<UserReducerContext>([
  initialUserState,
  defaultDispatch,
]);
