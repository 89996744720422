import * as React from 'react';
import { JSX } from 'react';
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { ChipTypeMap } from '@mui/material/Chip';

import { isEqual, isEmpty } from 'lodash';
import { isError } from './util';

export type HookFormAutocompleteProps<
  FormType extends FieldValues,
  T,
  ValueType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
> = {
  name: string;
  label: string;
  helperText?: string;
  key?: string;
  pickValue?: (value: T) => ValueType;
  onValueChange?: (value: T) => void;
  form: UseFormReturn<FormType>;
} & Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'renderInput'
>;

const HookFormAutocomplete = <FormType extends FieldValues, T, ValueType>({
  name,
  label,
  helperText,
  key,
  pickValue,
  onValueChange,
  form,
  ...otherProps
}: HookFormAutocompleteProps<
  FormType,
  T,
  ValueType,
  false,
  false,
  false,
  'div'
>): JSX.Element => {
  const error = isError(name, form.formState.errors);

  const { defaultValue, options, ...prunedProps } = otherProps;

  function setValue(fieldValue: any) {
    return pickValue !== undefined
      ? options.find(o => {
          return isEqual(pickValue(o), fieldValue);
        })
      : fieldValue;
  }

  return (
    <Controller
      control={form.control}
      name={name as Path<FormType>}
      defaultValue={(defaultValue ?? '') as PathValue<FormType, Path<FormType>>}
      key={key !== undefined ? `${key}-controller` : `${name}-controller`}
      render={({ field }) => (
        <Autocomplete
          {...field}
          {...prunedProps}
          value={
            isEmpty(setValue(field.value))
              ? defaultValue
              : setValue(field.value)
          }
          options={options}
          key={
            key !== undefined ? `${key}-autocomplete` : `${name}-autocomplete`
          }
          renderInput={params => (
            <TextField
              {...params}
              key={
                key !== undefined
                  ? `${key}-autocomplete-text-field`
                  : `${name}-autocomplete-text-field`
              }
              label={label}
              error={error !== undefined}
              helperText={error !== undefined ? error.message : helperText}
            />
          )}
          onChange={(_, data) => {
            if (Array.isArray(data))
              throw new Error(`Multi-selection not allowed`);

            const value = data as T;

            field.onChange(pickValue !== undefined ? pickValue(value) : value);

            if (onValueChange) onValueChange(value);
          }}
        />
      )}
    />
  );
};

export default HookFormAutocomplete;
