import { ErrorHandlingWorkflowDefinition } from '@apus/common-lib/api/interface/integration-service';
import { ParseOpenAPIRequest } from '@apus/common-lib/api/interface/management-service';
import {
  IntegrationOperation,
  IntegrationModuleBase,
  IntegrationOperationPrototype,
} from '@apus/common-lib/integrations/src/interface';
import { AxiosInstance } from 'axios';

import { return404AsUndefind } from './util';

export interface ManagementService {
  createIntegrationOperation(operation: IntegrationOperation): Promise<void>;

  createIntegrationOperations(
    operations: IntegrationOperation[]
  ): Promise<void>;

  updateIntegrationOperation(operation: IntegrationOperation): Promise<void>;

  getIntegrationOperation(
    moduleId: string,
    operationId: string
  ): Promise<IntegrationOperation>;

  listIntegrationOperations(): Promise<IntegrationOperation[]>;

  listBaseModules(): Promise<IntegrationModuleBase[]>;

  listPrototypeOperations(): Promise<IntegrationOperationPrototype[]>;

  upsertErrorWorkflow(
    definition: ErrorHandlingWorkflowDefinition
  ): Promise<ErrorHandlingWorkflowDefinition>;

  getErrorWorkflow(): Promise<ErrorHandlingWorkflowDefinition | undefined>;

  parseOpenAPIDocument(
    request: ParseOpenAPIRequest
  ): Promise<IntegrationOperation[]>;
}

export const createManagementService = (
  client: AxiosInstance
): ManagementService => {
  return {
    createIntegrationOperation(operation: IntegrationOperation): Promise<void> {
      return client.post(
        `/management/modules/${encodeURIComponent(
          operation.moduleId
        )}/operations/`,
        operation
      );
    },
    createIntegrationOperations(
      operations: IntegrationOperation[]
    ): Promise<void> {
      return client.post(`/management/operations-batch/`, operations);
    },
    updateIntegrationOperation(operation: IntegrationOperation): Promise<void> {
      return client.put(
        `/management/modules/${encodeURIComponent(
          operation.moduleId
        )}/operations/${encodeURIComponent(operation.operationId)}`,
        operation
      );
    },
    getIntegrationOperation(
      moduleId: string,
      operationId: string
    ): Promise<IntegrationOperation> {
      return client
        .get<IntegrationOperation>(
          `/management/modules/${encodeURIComponent(
            moduleId
          )}/operations/${encodeURIComponent(operationId)}`
        )
        .then(({ data }) => data);
    },
    listIntegrationOperations(): Promise<IntegrationOperation[]> {
      return client
        .get<IntegrationOperation[]>(`/management/operations`)
        .then(({ data }) => data);
    },
    listBaseModules(): Promise<IntegrationModuleBase[]> {
      return client
        .get<IntegrationModuleBase[]>(`/management/base-modules`)
        .then(({ data }) => data);
    },
    listPrototypeOperations(): Promise<IntegrationOperationPrototype[]> {
      return client
        .get<IntegrationModuleBase[]>(`/management/base-modules`)
        .then(({ data }) => {
          return Promise.resolve(
            data
              .map((module): IntegrationOperationPrototype[] =>
                Object.values(module.operationPrototypes)
              )
              .flat()
          );
        });
    },
    upsertErrorWorkflow(
      definition: ErrorHandlingWorkflowDefinition
    ): Promise<ErrorHandlingWorkflowDefinition> {
      return client.post(`/management/error-workflow`, definition);
    },

    getErrorWorkflow(): Promise<ErrorHandlingWorkflowDefinition | undefined> {
      return client
        .get<ErrorHandlingWorkflowDefinition>(`/management/error-workflow`)
        .then(({ data }) => data)
        .catch(return404AsUndefind);
    },
    parseOpenAPIDocument(
      request: ParseOpenAPIRequest
    ): Promise<IntegrationOperation[]> {
      return client
        .post(`/management/parse-openapi-document`, request)
        .then(({ data }) => data);
    },
  };
};
