import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack, Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';
import { ServiceProviderPageroConfiguration } from '@apus/common-lib/integrations/src/pagero/interface';

const ServiceProviderPageroConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<ServiceProviderPageroConfiguration>();

  useEffect(() => {
    if (moduleConfiguration !== undefined) {
      const configuration =
        moduleConfiguration.configuration as unknown as ServiceProviderPageroConfiguration;
      form.reset(configuration);
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (formData: ServiceProviderPageroConfiguration) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId: moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container spacing={2}>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Pagero -connection for service provider
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="tokenServerEndpoint"
            form={form}
            label="Token server endpoint"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="authorizationServerEndpoint"
            form={form}
            label="Authorization server endpoint"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientId"
            form={form}
            label="Client id provided by Pagero"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientSecret"
            form={form}
            label="Client secret provided by Pagero"
            defaultValue={''}
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item>
        <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default ServiceProviderPageroConfigurationForm;
