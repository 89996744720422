import * as React from 'react';
import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';
import { VersionedIntegrations } from '@apus/common-lib/api/interface/integration-service';
import AppVersion from '../app/AppVersion';
import { Grid } from '@mui/material';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import PushPinTwoToneIcon from '@mui/icons-material/PushPinTwoTone';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import { ApiError } from '@apus/common-lib/api/interface/common';
import useSubscriptionService from '@apus/common-ui/hooks/useSubscriptionService';
import ShowApiCallStatus from '../alert/ShowApiCallStatus';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';

interface Props {
  editMode: boolean;
  defaultAppVersion?: string;
  appVersion: VersionedIntegrations;
  onSave: (appVersion: VersionedIntegrations) => void;
  onCancel: () => void;
}

export default function AppVersionEditor({
  editMode,
  defaultAppVersion,
  appVersion,
  onSave,
  onCancel,
}: Props) {
  const subscriptionService = useSubscriptionService();
  const integrationService = useIntegrationService();

  const [app, setApp] = useState<VersionedIntegrations>(appVersion);
  const [isUpdatingTenants, setIsUpdatingTenants] = useState<boolean>(false);
  const [errorUpdatingTenants, setErrorUpdatingTenants] = useState<ApiError>();

  const setAsDefaultAppVersion = useCallback(
    () =>
      executeApiCall({
        callFunction: () =>
          integrationService
            .setAppVersionToDefault(appVersion.app, appVersion.version)
            .then(() => onSave(appVersion)),
        setPending: setIsUpdatingTenants,
        setError: setErrorUpdatingTenants,
      }),
    [subscriptionService]
  );

  const updateTenants = useCallback(
    () =>
      executeApiCall({
        callFunction: () =>
          subscriptionService.executeAppUpdate({
            app: appVersion.app,
          }),
        setPending: setIsUpdatingTenants,
        setError: setErrorUpdatingTenants,
      }),
    [subscriptionService]
  );

  async function save() {
    onSave(app);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <ShowApiCallStatus
          infoText={'updating tenants'}
          loading={isUpdatingTenants}
          error={errorUpdatingTenants}
        />
      </Grid>
      <Grid item xs={12}>
        <ShowApiCallStatus
          infoText={'updating tenants'}
          loading={isUpdatingTenants}
          error={errorUpdatingTenants}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'end'}
      >
        <Button
          variant={'text'}
          startIcon={<PushPinTwoToneIcon color={'info'} />}
          onClick={() => setAsDefaultAppVersion()}
          disabled={defaultAppVersion === appVersion.version}
        >
          Make default
        </Button>
        <Button
          variant={'text'}
          startIcon={<RefreshTwoToneIcon color={'info'} />}
          onClick={() => updateTenants()}
          disabled={defaultAppVersion !== appVersion.version}
        >
          Update tenants
        </Button>
        <Button
          variant={'text'}
          startIcon={<SaveTwoToneIcon color={'success'} />}
          onClick={save}
          disabled={!editMode}
        >
          Save
        </Button>
        <Button
          variant={'text'}
          startIcon={<CancelTwoToneIcon color={'error'} />}
          onClick={onCancel}
          disabled={!editMode}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12}>
        <AppVersion
          edit={true}
          data={app}
          onChange={data => {
            setApp({
              ...app,
              ...data,
            });
          }}
        />
      </Grid>
    </Grid>
  );
}
