import React, {
  useEffect,
  useState,
  JSX,
  useCallback,
  useContext,
} from 'react';

import {
  IntegrationOperation,
  IntegrationOperationPrototype,
} from '@apus/common-lib/integrations/src/interface';
import useIntegrationServiceIfTenantIsDefined from '@apus/common-ui/hooks/useIntegrationServiceIfTenantIsDefined';
import useManagementService from '@apus/common-ui/hooks/useManagementService';
import { Grid, Typography } from '@mui/material';

import SimpleAlert from '../../../components/alert/SimpleAlert';
import DefineIntegrationOperation, {
  OperationEditMode,
} from '../../../components/operation/DefineIntegrationOperation';
import OperationSelector from '../../../components/operation/OperationSelector';
import { AppContext } from '../../../state/appContext';
import { setIntegrationModules } from '../../../state/appReducer';
import { bodyMaxHeight } from '../../../theme/theme';

const OperationManagementView = (): JSX.Element => {
  const integrationService = useIntegrationServiceIfTenantIsDefined();
  const [, appDispatch] = useContext(AppContext);

  const [mode, setMode] = useState<OperationEditMode>('modify');
  const [operationSaveResult, setOperationSaveResult] =
    useState<IntegrationOperation>();

  const [operations, setOperations] = useState<IntegrationOperation[]>();

  const [prototypeOperations, setPrototypeOperations] =
    useState<IntegrationOperationPrototype[]>();

  const [operation, setOperation] = useState<IntegrationOperation | undefined>(
    undefined
  );

  const managementService = useManagementService();

  const refreshOperations = useCallback(async () => {
    setOperations(await managementService.listIntegrationOperations());
    setPrototypeOperations(await managementService.listPrototypeOperations());
  }, [managementService]);

  const saveOperation = async (operation: IntegrationOperation) => {
    const existingOperation = await managementService
      .getIntegrationOperation(operation.moduleId, operation.operationId)
      .catch(() => undefined);

    if (existingOperation === undefined) {
      await managementService.createIntegrationOperation(operation);
      setOperationSaveResult(operation);
    } else {
      await managementService.updateIntegrationOperation(operation);
      setOperationSaveResult(operation);
    }
    await refreshOperations();

    if (integrationService !== undefined) {
      // if tenant has been selected, update tenant's app context as well
      appDispatch(
        setIntegrationModules(await integrationService.listModules())
      );
    }
  };

  const existingOperationSelected = (value: IntegrationOperation) => {
    setMode('modify');
    setOperation(value);
  };

  const operationPrototypeSelected = (value: IntegrationOperationPrototype) => {
    setMode('create');
    setOperation({
      description: '',
      moduleId: value.moduleId,
      operationId: '',
      title: '',
      prototype: value,
    });
  };

  useEffect(() => {
    if (operations === undefined || prototypeOperations === undefined) {
      (async () => {
        await refreshOperations();
      })();
    }
  }, [operations, prototypeOperations, refreshOperations]);

  const onSave = async (operation: IntegrationOperation) => {
    await saveOperation(operation);
  };

  const onCancel = () => {
    console.log(`Cancelled`);
  };

  return (
    <Grid
      container
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <>
        <Grid item xs={12}>
          {operationSaveResult !== undefined && (
            <SimpleAlert
              infoText={`Operation "${operationSaveResult.moduleId}/${operationSaveResult.operationId}" saved`}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {operations !== undefined && (
            <OperationSelector<IntegrationOperation>
              name="modify-operation"
              label="Modify existing operation"
              onOperationSelected={existingOperationSelected}
              operations={operations}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {prototypeOperations !== undefined && (
            <OperationSelector<IntegrationOperationPrototype>
              name="create-operation"
              label="Create new operation from prototype"
              onOperationSelected={operationPrototypeSelected}
              operations={prototypeOperations}
            />
          )}
        </Grid>
        {operation !== undefined && (
          <Grid container>
            <Grid item xs={12}>
              {mode === 'modify' ? (
                <Typography
                  variant={'h5'}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                >
                  Modify operation '{operation.moduleId}/{operation.operationId}
                  ' using prototype '{operation.prototype.moduleId}/
                  {operation.prototype.operationId}'
                </Typography>
              ) : (
                <Typography
                  variant={'h5'}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                >
                  Define a new operation using prototype '
                  {operation.prototype.moduleId}/
                  {operation.prototype.operationId}'
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <DefineIntegrationOperation
                mode={mode}
                operation={operation}
                prototypes={prototypeOperations ?? []}
                onSave={onSave}
                onCancel={onCancel}
              />
            </Grid>
          </Grid>
        )}
      </>
    </Grid>
  );
};

export default OperationManagementView;
