import React, { useContext, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  IntegrationDefinitionBase,
  RetryStrategy,
} from '@apus/common-lib/api/interface/integration-service';
import { IntegrationNodeType } from '@apus/common-lib/integration-engine/src/interface';
import { asIntegrationDefinitionReference } from '@apus/common-lib/utils/src/data-utils';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Grid, Stack, Typography } from '@mui/material';
import { isEqual, pick, isEmpty } from 'lodash';

import { AppContext } from '../../../state/appContext';
import IntegrationSelector from '../IntegrationSelector';

interface Props {
  form: UseFormReturn<IntegrationDefinitionBase>;
  isNew?: boolean;
  integration: IntegrationDefinitionBase;
  onChange?: () => void;
}

const IntegrationConfigurationForm = ({
  form,
  isNew,
  integration,
  onChange,
}: Props): JSX.Element => {
  const [appState] = useContext(AppContext);

  useEffect(() => {
    const current = form.getValues();
    const incoming = pick(
      integration,
      'name',
      'description',
      'errorIntegration',
      'configuration'
    );
    const integrationIsEmpty =
      isEmpty(current.name) &&
      isEmpty(current.description) &&
      isEmpty(current.configuration) &&
      isEmpty(current.errorIntegration);

    if (isEqual(incoming, current)) return;

    if (integration.integrationId) form.reset(integration);

    if (isNew && integrationIsEmpty) {
      form.reset();
    } else {
      form.setValue('name', integration.name);
      form.setValue('description', integration.description);
      form.setValue('errorIntegration', integration.errorIntegration);
      form.setValue('configuration', integration.configuration);
    }
  }, [form, integration, isNew]);

  function valueChanged() {
    if (onChange !== undefined) onChange();
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack spacing={2}>
          <HookFormTextInput
            name={'name'}
            label={'Name'}
            form={form}
            onValueChange={onChange}
          />
          <HookFormTextInput
            name={'description'}
            label={'Description'}
            form={form}
            multiline={true}
            rows={11}
            onValueChange={onChange}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={4} display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Error handler
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Controller
              control={form.control}
              name={'errorIntegration'}
              render={({ field }) => (
                <IntegrationSelector
                  name="select-error-workflow"
                  label="Use error workflow"
                  onIntegrationSelected={integration => {
                    field.onChange(
                      integration !== undefined
                        ? asIntegrationDefinitionReference(integration)
                        : undefined
                    );
                    valueChanged();
                  }}
                  displayButton={false}
                  integration={appState.integrations.find(
                    integration =>
                      integration.integrationId === field.value?.integrationId
                  )}
                  integrations={appState.integrations.filter(integration =>
                    [IntegrationNodeType.ErrorTrigger].includes(
                      integration.workflow.trigger.nodeType
                    )
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Debugging options
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <HookFormCheckbox
              name={'configuration.debugging.recordApiCalls'}
              label={'Record all api calls in operation results'}
              form={form}
              onValueChange={onChange}
            />
          </Grid>
          <Grid item xs={4} display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Automatic retrying options
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <HookFormCheckbox
              name={'configuration.retrying.enabled'}
              label={'Enable automatic retrying'}
              form={form}
              onValueChange={onChange}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HookFormAutocomplete
                  name={'configuration.retrying.strategy'}
                  label={'Choose retry strategy'}
                  form={form}
                  disablePortal
                  options={Object.values(RetryStrategy)}
                  disabled={!form.watch('configuration.retrying.enabled')}
                  fullWidth={true}
                  onValueChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction={'row'}>
                  <HookFormTextInput
                    name={'configuration.retrying.delay'}
                    label={'Delay in minutes'}
                    type={'number'}
                    form={form}
                    disabled={!form.watch('configuration.retrying.enabled')}
                    onValueChange={onChange}
                  />
                  <HookFormTextInput
                    name={'configuration.retrying.retries'}
                    label={'Number of retries'}
                    type={'number'}
                    form={form}
                    disabled={!form.watch('configuration.retrying.enabled')}
                    onValueChange={onChange}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} display={'flex'} alignItems={'center'}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Storage options
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <HookFormTextInput
              name={'configuration.storage.numberOfDaysToStore'}
              label={
                'Number of days to preserve integration events - use "-1" for indefinite time'
              }
              type={'number'}
              form={form}
              fullWidth={true}
              onValueChange={onChange}
            />
            <HookFormCheckbox
              name={'configuration.storage.storeContentOnlyInDynamodb'}
              label={'Store content in DynamoDb only'}
              form={form}
              onValueChange={onChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrationConfigurationForm;
