import React, { JSX } from 'react';

import ArrowDownwardTwoToneIcon from '@mui/icons-material/ArrowDownwardTwoTone';
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import RestartAltTwoToneIcon from '@mui/icons-material/RestartAltTwoTone';
import { Box, ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';

type Functionality = Pick<ButtonProps, 'disabled' | 'onClick'>;

interface Props {
  delete?: Functionality;
  cancel?: Functionality;
  save?: Functionality;
  update?: Functionality;
  resetOperation?: Functionality;
  moveUp?: Functionality;
  moveDown?: Functionality;
}

const NodeToolBar = (props: Props): JSX.Element => {
  return (
    <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
      {props.delete !== undefined && (
        <Button
          variant={'text'}
          startIcon={<DeleteTwoToneIcon color={'error'} />}
          disabled={props.delete.disabled}
          onClick={props.delete.onClick}
        >
          Delete
        </Button>
      )}

      {props.cancel !== undefined && (
        <Button
          variant={'text'}
          startIcon={<CancelPresentationTwoToneIcon color={'warning'} />}
          disabled={props.cancel.disabled}
          onClick={props.cancel.onClick}
        >
          Cancel
        </Button>
      )}

      {props.save !== undefined && (
        <Button
          variant={'text'}
          startIcon={<CheckCircleTwoToneIcon color={'success'} />}
          disabled={props.save.disabled}
          onClick={props.save.onClick}
        >
          Save
        </Button>
      )}

      {props.update !== undefined && (
        <Button
          variant={'text'}
          startIcon={<RefreshTwoToneIcon color={'info'} />}
          disabled={props.update.disabled}
          onClick={props.update.onClick}
        >
          Update
        </Button>
      )}

      {props.resetOperation !== undefined && (
        <Button
          startIcon={<RestartAltTwoToneIcon color={'error'} />}
          variant={'text'}
          disabled={props.resetOperation.disabled}
          onClick={props.resetOperation.onClick}
        >
          Reset
        </Button>
      )}

      {props.moveDown !== undefined && (
        <Button
          variant={'text'}
          startIcon={<ArrowDownwardTwoToneIcon sx={{ fontSize: '1.0rem' }} />}
          disabled={props.moveDown.disabled}
          onClick={props.moveDown.onClick}
        >
          Down
        </Button>
      )}

      {props.moveUp !== undefined && (
        <Button
          variant={'text'}
          startIcon={<ArrowUpwardTwoToneIcon sx={{ fontSize: '1.0rem' }} />}
          disabled={props.moveUp.disabled}
          onClick={props.moveUp.onClick}
        >
          Up
        </Button>
      )}
    </Box>
  );
};

export default NodeToolBar;
