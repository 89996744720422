import React, { useEffect, useState, JSX } from 'react';
import { useForm } from 'react-hook-form';

import { SchemaItem } from '@apus/common-lib/json-data-mapper/src/interface';
import { JsonSchemaManipulationTool } from '@apus/common-lib/json-data-mapper/src/schema-manipulation-tool';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import ContentPasteGoTwoToneIcon from '@mui/icons-material/ContentPasteGoTwoTone';
import { Box, Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { isEmpty, omit } from 'lodash';
import { v4 as uuid } from 'uuid';

import BooleanForm from './form/BooleanForm';
import { EditableSchemaItem } from './form/interface';
import NumberForm from './form/NumberForm';
import StringForm from './form/StringForm';
import { parseJsonSchemaFromString } from '@apus/common-lib/utils/src/json-utils';

interface Props {
  parentSchemaPath?: string;
  parentObjectPath?: string;
  readOnly?: boolean;
  item?: SchemaItem;
  onSave: (item: SchemaItem) => void;
}

const SchemaItemEditor = ({
  parentObjectPath,
  parentSchemaPath,
  readOnly,
  item,
  onSave,
}: Props): JSX.Element => {
  const [type, setType] = useState<string>('string');
  const form = useForm<EditableSchemaItem>({
    defaultValues: {
      path: '',
      type: 'string',
      isRoot: false,
      isArray: false,
      isRequired: false,
      useEnum: false,
      useConstant: false,
    },
  });

  useEffect(() => {
    if (item !== undefined) {
      setType(item.type);
      form.reset({
        ...item,
        enum: item.enum !== undefined ? item.enum.join('\n') : undefined,
        useEnum: item.enum !== undefined,
      });
    }
  }, [form, item, setType]);

  function allowPaste() {
    if (
      item !== undefined &&
      item.type === 'object' &&
      isEmpty(item.properties)
    )
      return true;

    return readOnly !== true;
  }

  async function parseFromClipboard() {
    const clipboard = await navigator.clipboard.readText();
    if (clipboard !== undefined && clipboard.trim() !== '') {
      const pasted = await parseJsonSchemaFromString(clipboard);
      const tool = new JsonSchemaManipulationTool({ schema: pasted });
      const schemaItem = {
        ...omit(tool.asItem(), [
          'id',
          'path',
          'schemaPath',
          'relativeSchemaPath',
          'objectPath',
          'relativeObjectPath',
        ]),
      };
      form.reset({
        ...schemaItem,
        path: item?.path ?? '',
        enum:
          schemaItem.enum !== undefined
            ? schemaItem.enum.join('\n')
            : undefined,
        useEnum: schemaItem.enum !== undefined,
        isRoot: false,
      });
    }
  }

  const parse = (value: string | number | boolean | undefined) => {
    switch (typeof value) {
      case 'boolean':
        return value as boolean;
      case 'string':
        return value.trim() === '' ? undefined : value;
      case 'bigint':
      case 'number':
        return value as number;
      default:
        return undefined;
    }
  };

  const submit = (data: EditableSchemaItem) => {
    const savedItem: SchemaItem =
      item !== undefined
        ? {
            ...item,
            ...data,
            enum: data.useEnum ? data.enum?.split('\n') : undefined,
          }
        : {
            ...data,
            default: parse(data.default),
            pattern: data.pattern?.trim() !== '' ? data.pattern : undefined,
            enum: data.useEnum ? data.enum?.split('\n') : undefined,
            id: uuid(),
            schemaPath: `${
              parentSchemaPath !== undefined ? parentSchemaPath : ''
            }/properties/${data.path}`,
            relativeSchemaPath: `/properties/${data.path}`,
            objectPath: `${
              parentObjectPath !== undefined ? parentObjectPath + '.' : ''
            }${data.path}`,
            relativeObjectPath: `${data.path}`,
            properties: [],
          };

    onSave(savedItem);
    form.reset();
  };

  return (
    <Grid>
      <Stack spacing={2}>
        <Box flexGrow={1} flexDirection={'row-reverse'} display={'flex'}>
          <Button
            variant={'outlined'}
            disabled={!allowPaste()}
            onClick={parseFromClipboard}
            startIcon={<ContentPasteGoTwoToneIcon color={'warning'} />}
          >
            Paste
          </Button>
        </Box>
        <Stack spacing={2} direction="row">
          <HookFormTextInput
            name={'path'}
            form={form}
            label={'Name'}
            disabled={readOnly || item?.isRoot}
            sx={{ minWidth: 400 }}
          />
          <HookFormCheckbox
            name={'isArray'}
            label={'Is this an array?'}
            form={form}
            disabled={readOnly}
            defaultChecked={false}
          />
        </Stack>
        <HookFormAutocomplete
          name={'type'}
          label={'Type'}
          form={form}
          disablePortal
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ minWidth: 200 }}
          options={['object', 'string', 'boolean', 'number', 'integer']}
          disabled={readOnly}
          onValueChange={value => {
            if (value != null && !Array.isArray(value)) {
              setType(value);
            }
          }}
        />
        <HookFormCheckbox
          name={'isRequired'}
          label={'Is this mandatory?'}
          disabled={readOnly || item?.isRoot}
          form={form}
          defaultChecked={false}
        />
        {type === 'string' && <StringForm form={form} readOnly={readOnly} />}
        {type === 'boolean' && <BooleanForm form={form} readOnly={readOnly} />}
        {(type === 'number' || type === 'integer') && (
          <NumberForm form={form} readOnly={readOnly} />
        )}
      </Stack>
      <Button disabled={!allowPaste()} onClick={form.handleSubmit(submit)}>
        Save
      </Button>
    </Grid>
  );
};

export default SchemaItemEditor;
