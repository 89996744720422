import React, { useEffect, useState } from 'react';

import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { JSONSchema7 } from 'json-schema';

import JsonSchemaEditor from '../../../json/schema/JsonSchemaEditor';

interface Props {
  iterate: boolean | undefined;
  schema: JSONSchema7 | undefined;
  transformedSchema: JSONSchema7 | undefined;
  onChange?: (iterate: boolean | undefined) => void;
}

const DefineIterate = ({
  iterate,
  schema,
  transformedSchema,
  onChange,
}: Props): JSX.Element => {
  const [value, setValue] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (schema === undefined || schema.type !== 'array') {
      setValue(false);
      setDisabled(true);
    } else {
      setValue(iterate ?? false);
      setDisabled(false);
    }
  }, [iterate, schema, setValue]);

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            onChange={event => {
              if (onChange) onChange(event.target.checked);
            }}
            checked={value}
            disabled={disabled}
          />
        }
        label={'Iterate data?'}
      />

      {transformedSchema !== undefined && (
        <JsonSchemaEditor
          propertyName={'triggerSchema'}
          schema={transformedSchema}
          edit={false}
        />
      )}
    </Box>
  );
};

export default DefineIterate;
