import React, { useEffect, useRef, useState } from 'react';
import useOAuthService from '@apus/common-ui/hooks/useOAuthService';
import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import { OAuthAuthorizationCodeFlowResult } from '@apus/common-lib/api/interface/oauth-service';
import { executePollingApiCall } from '@apus/common-ui/utils/api-call';
import { isEqual } from 'lodash';
import { LoadingButton } from '@mui/lab';

interface Props {
  connection: SupportedModule;
  tenantId: string;
}

const createPopup = ({
  url,
  title,
  height = 500,
  width = 500,
}: {
  url: string;
  title: string;
  width?: number;
  height?: number;
}) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  return window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  );
};

const OAuthAuthorizationCodeHandler = ({ connection, tenantId }: Props) => {
  const [searchParams, setSearchParams] = useState<URLSearchParams | undefined>(
    undefined
  );
  const [isAuthorizing, setIsAuthorizing] = useState<boolean>(false);

  const [authResult, setAuthResult] =
    useState<OAuthAuthorizationCodeFlowResult>();
  const [authError, setAuthError] = useState<string | undefined>();
  const oauthService = useOAuthService();

  const [externalWindow, setExternalWindow] = useState<Window | null>();
  const intervalRef = useRef<number>();

  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const onAuthorizeClicked = async () => {
    const redirectUri = await oauthService.beginAuthorizationCodeFlow(
      connection,
      tenantId
    );

    setIsAuthorizing(true);
    setExternalWindow(
      createPopup({
        url: redirectUri,
        title: `Authorize ${connection}`,
        width: 500,
        height: 500,
      })
    );
  };

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const params = new URL(currentUrl).searchParams;
          const code = params.get('code');
          const error = params.get('error');
          if (!code && !error) {
            return;
          }
          if (externalWindow) externalWindow.close();
          clearTimer();
          setSearchParams(params);
        } catch (error) {
        } finally {
          if (!externalWindow || externalWindow.closed) {
            setIsAuthorizing(false);
            clearTimer();
          }
        }
      }, 700);
    }
    return () => {
      if (externalWindow) externalWindow.close();
    };
  }, [externalWindow, setSearchParams, oauthService]);

  useEffect(() => {
    (async () => {
      if (searchParams !== undefined) {
        setAuthResult(undefined);
        setAuthError(undefined);
        const state = searchParams.get('state');
        const code = searchParams.get('code');
        const error = searchParams.get('error');

        if (state != null) {
          if (code != null) {
            await executePollingApiCall<OAuthAuthorizationCodeFlowResult>({
              callFunction: () =>
                oauthService.handleAuthorizationCode(connection, code, state),
              setError: err => {
                setAuthError(
                  `Authorization code was received successfully, but it could not be handled: ${err?.message}`
                );
              },
              setResult: result => {
                setAuthResult(result);
              },
              setPending: setIsAuthorizing,
              polling: {
                endCondition: result => {
                  if (result.status === 'ERROR') return true;
                  return isEqual(result.status, 'FINISHED');
                },
                interval: 1000,
                timeout: 20000,
              },
            });
          }
          if (error != null) {
            setAuthError(`Authorization failed: ${error}`);
          }
        }
      }
    })();
  }, [searchParams, oauthService, connection, setAuthResult, setAuthError]);

  return (
    <Grid container spacing={2} display={'flex'} alignItems={'center'}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {!isEqual(authResult?.status, 'FINISHED') && (
        <>
          <Grid item xs={8}>
            <Typography variant={'subtitle2'} align={'left'}>
              Connection needs authorization by an end-user. Click button to
              begin the process.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <LoadingButton
              variant={'contained'}
              component="label"
              size={'small'}
              onClick={onAuthorizeClicked}
              color={'warning'}
              loading={isAuthorizing}
            >
              Authorize
            </LoadingButton>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {authError !== undefined && (
          <Alert severity={'error'}>{authError}</Alert>
        )}
        {isEqual(authResult?.status, 'FINISHED') && (
          <Alert severity={'success'}>Connection established!</Alert>
        )}
      </Grid>
    </Grid>
  );
};

export default OAuthAuthorizationCodeHandler;
