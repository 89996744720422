import React, { JSX, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  bankConnections,
  erpConnections,
  PaymentIntegrationAppConfiguration,
} from '@apus/common-lib/api/interface/apps/payment-integration';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import { PaymentIntegrationApp } from '@apus/common-lib/api/interface/subscriptions';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';

import AppRequirementConfiguration from '../AppRequirementConfiguration';

interface Props {
  pending?: boolean;
  value: PaymentIntegrationAppConfiguration;
  edit?: boolean;
  onSave: (
    configuration: PaymentIntegrationAppConfiguration,
    subscriptionName?: string
  ) => void;
  onCancel: () => void;
}

export function createEmptyPaymentIntegrationApp(): PaymentIntegrationApp {
  return {
    name: SystemglueAppName.PAYMENT_INTEGRATION_APP,
    configuration: {
      connections: {
        erp: 'netsuite',
        bank: 'osuuspankki',
      },
      subsidiary: {
        subsidiaryId: '',
      },
      bank: {
        name: '',
        addressLine1: '',
        countryCode: '',
      },
      account: {
        iban: '',
        bic: '',
      },
    },
  };
}

const ConfigureBankingSubscription = ({
  pending,
  value,
  edit = false,
  onSave,
}: Props): JSX.Element => {
  const form = useForm<PaymentIntegrationAppConfiguration>();

  useEffect(() => {
    form.setValue('connections', value.connections);
    form.setValue('subsidiary', value.subsidiary);
    form.setValue('bank', value.bank);
    form.setValue('account', value.account);
  }, [value, form]);

  function onSubmit(configuration: PaymentIntegrationAppConfiguration) {
    const subscriptionName = `${configuration.bank.name} - ${configuration.account.iban}`;
    onSave(configuration, subscriptionName);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bolder'}>Required connections:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={form.control}
          render={({ field }) => (
            <AppRequirementConfiguration
              title="ERP"
              value={field.value}
              edit={edit}
              options={erpConnections}
              onChange={field.onChange}
            />
          )}
          name={'connections.erp'}
        />
        <Controller
          control={form.control}
          render={({ field }) => (
            <AppRequirementConfiguration
              title="Bank"
              value={field.value}
              edit={edit}
              options={bankConnections}
              onChange={field.onChange}
            />
          )}
          name={'connections.bank'}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="subsidiary.subsidiaryId"
            form={form}
            label="Subsidiary id"
            disabled={!edit}
          />
          <HookFormTextInput
            name="bank.name"
            form={form}
            label="Bank name"
            disabled={!edit}
          />
          <HookFormTextInput
            name="bank.addressLine1"
            form={form}
            label="Bank street address 1"
            disabled={!edit}
          />
          <HookFormTextInput
            name="bank.addressLine2"
            form={form}
            label="Bank street address 2"
            disabled={!edit}
          />
          <HookFormTextInput
            name="bank.countryCode"
            form={form}
            label="Bank country in 2-letter ISO-3166 code"
            disabled={!edit}
          />
          <HookFormTextInput
            name="account.iban"
            form={form}
            label="IBAN"
            disabled={!edit}
          />
          <HookFormTextInput
            name="account.bic"
            form={form}
            label="BIC"
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={pending}
          disabled={!edit}
          onClick={form.handleSubmit(onSubmit)}
        >
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default ConfigureBankingSubscription;
