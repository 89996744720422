import { useContext, useLayoutEffect, useRef, JSX, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useApiCall from '@apus/common-ui/hooks/useApiCallHook';
import useTenantService from '@apus/common-ui/hooks/useTenantService';
import { TenantContext } from '@apus/common-ui/state/tenantContext';
import { setTenants } from '@apus/common-ui/state/tenantReducer';
import { Alert, AlertTitle, Box, CircularProgress, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { bodyMaxHeight } from '../../theme/theme';

interface RowTenant {
  id: string;
  tenantName: string;
}

const RenderActionsCell = (props: GridRenderCellParams<String>) => {
  const { hasFocus, value } = props;
  const navigate = useNavigate();
  const buttonElement = useRef<HTMLButtonElement | null>(null);
  const rippleRef = useRef<TouchRippleActions | null>(null);

  useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current?.querySelector('input');
      input?.focus();
    } else if (rippleRef.current) {
      rippleRef.current.stop({} as any);
    }
  }, [hasFocus]);

  const onEdit = async (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`${value}`);
  };

  return (
    <Button
      component="button"
      ref={buttonElement}
      touchRippleRef={rippleRef}
      variant="contained"
      size="small"
      style={{ marginLeft: 16 }}
      // Remove button from tab sequence when cell does not have focus
      tabIndex={hasFocus ? 0 : -1}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === ' ') {
          // Prevent key navigation when focus is on button
          event.stopPropagation();
        }
      }}
      onClick={onEdit}
    >
      Open
    </Button>
  );
};

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Actions',
    sortable: false,
    renderCell: RenderActionsCell,
    width: 120,
  },
  { field: 'tenantName', headerName: 'Tenant name', width: 300 },
];

const TenantList = (): JSX.Element => {
  const [tenantState, tenantDispatch] = useContext(TenantContext);
  const tenantService = useTenantService();

  const listTenants = useCallback(() => {
    return tenantService.listTenants().then(tenants => {
      tenantDispatch(setTenants(tenants));
      return tenants;
    });
  }, [tenantService, tenantDispatch]);

  const [isLoading, tenants, error] = useApiCall(listTenants, [listTenants]);

  return (
    <Box
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      {tenantState.tenant === undefined && (
        <Alert severity="warning">
          Before you can define workflows, you need to select a tenant
        </Alert>
      )}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {error !== undefined && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Something went wrong while querying for tenants
            </Alert>
          )}
          <Grid container>
            <Box sx={{ height: '100%', width: '100%' }}>
              <DataGrid
                columns={columns}
                rows={(tenants ?? []).map(
                  (tenant): RowTenant => ({
                    id: tenant.tenantId,
                    tenantName: tenant.tenantName,
                  })
                )}
              />
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default TenantList;
