import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SalesforceConfiguration } from '@apus/common-lib/integrations/src/salesforce/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';

const SalesforceConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<SalesforceConfiguration>();

  useEffect(() => {
    form.reset({
      domain: '',
      version: '',
      clientId: '',
      clientSecret: '',
    });

    if (moduleConfiguration !== undefined) {
      form.reset(
        moduleConfiguration.configuration as unknown as SalesforceConfiguration
      );
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (formData: SalesforceConfiguration) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Salesforce -connection
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="domain"
            form={form}
            label="Salesforce domain name"
            defaultValue={''}
            helperText={'This will be used for generating the correct url'}
            disabled={!edit}
          />
          <HookFormTextInput
            name="version"
            form={form}
            label="Salesforce REST API version"
            defaultValue={''}
            helperText={'This will be the default version to use'}
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientId"
            form={form}
            label="Client id"
            defaultValue={''}
            helperText={
              'This is also called the Consumer key - this is provided by the Salesforce'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientSecret"
            form={form}
            label="Client secret"
            defaultValue={''}
            helperText={
              'This is also called the Consumer secret - this is provided by the Salesforce'
            }
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
        Save
      </Button>
    </Grid>
  );
};

export default SalesforceConfigurationForm;
