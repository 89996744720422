import { JSX } from 'react';
import * as React from 'react';

import { ExposedApiGroup } from '@apus/common-lib/api/interface/exposed-api';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import CollapsibleListItem from '../surface/CollapsibleListItem';

interface Props {
  groups: ExposedApiGroup[];
  onGroupExpanded?: (groupId: string) => void;
  onGroupCollapsed?: (groupId: string) => void;
  onCommandSelected?: (groupId: string, commandId: string) => void;
  onAddGroupClicked?: () => void;
  onAddCommandClicked?: (groupId: string) => void;
}

const ExposedApiGroupList = ({
  groups,
  onGroupExpanded,
  onGroupCollapsed,
  onAddGroupClicked,
  onAddCommandClicked,
  onCommandSelected,
}: Props): JSX.Element => {
  return (
    <List>
      <ListItem key={'add-group'}>
        <ListItemButton
          onClick={() => {
            if (onAddGroupClicked !== undefined) onAddGroupClicked();
          }}
        >
          <ListItemText
            primaryTypographyProps={{
              variant: 'subtitle2',
              textTransform: 'uppercase',
            }}
            primary={'Add new group'}
          />
        </ListItemButton>
      </ListItem>
      {groups.map(group => {
        return (
          <CollapsibleListItem
            onExpanded={() => {
              if (onGroupExpanded !== undefined) onGroupExpanded(group.groupId);
            }}
            onCollapsed={() => {
              if (onGroupCollapsed !== undefined)
                onGroupCollapsed(group.groupId);
            }}
            key={`group-${group.groupId}`}
            listItemTextProps={{
              primaryTypographyProps: {
                variant: 'subtitle2',
                textTransform: 'uppercase',
              },
              primary: `${group.groupName}`,
            }}
            collapseProps={{
              sx: { pl: 4, pr: 6 },
            }}
          >
            <List>
              <ListItem key={'add-command'}>
                <ListItemButton
                  onClick={() => {
                    if (onAddCommandClicked !== undefined)
                      onAddCommandClicked(group.groupId);
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'subtitle2',
                    }}
                    primary={'Add new command'}
                  />
                </ListItemButton>
              </ListItem>
              {group.commands.map((command, idx) => {
                return (
                  <ListItem key={`${group.groupId}-${idx}`}>
                    <ListItemButton
                      onClick={() => {
                        if (onCommandSelected !== undefined) {
                          onCommandSelected(group.groupId, command.commandId);
                        }
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{ variant: 'subtitle2' }}
                        primary={command.commandName}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </CollapsibleListItem>
        );
      })}
    </List>
  );
};

export default ExposedApiGroupList;
