import React, { JSX } from 'react';

import {
  CustomContentMediaType,
  SupportedSchemaDefinition,
} from '@apus/common-lib/api/interface/files';
import { JSONSchema7 } from 'json-schema';

import DefineJsonSchema from './DefineJsonSchema';

export interface DefineSchemaProps {
  allowRootModification?: boolean;
  allowUndefined?: boolean;
  readOnly?: boolean;
  title?: string;
  propertyName: string;
  schemaType?: CustomContentMediaType;
  mandatorySchema?: SupportedSchemaDefinition;
  schema?: SupportedSchemaDefinition;
  defaultSchemaTitle?: string;
  defaultSchema?: JSONSchema7;
  onSchemaSave: (schema: SupportedSchemaDefinition | undefined) => void;
}

const DefineSchema = ({
  allowRootModification = true,
  allowUndefined = false,
  readOnly = false,
  title,
  propertyName,
  schemaType = CustomContentMediaType.JsonSchema,
  mandatorySchema,
  schema,
  defaultSchemaTitle,
  defaultSchema,
  onSchemaSave,
}: DefineSchemaProps): JSX.Element => {
  if (schemaType === CustomContentMediaType.JsonSchema)
    return (
      <DefineJsonSchema
        allowRootModification={allowRootModification}
        allowUndefined={allowUndefined}
        readOnly={readOnly}
        title={title}
        mandatorySchema={mandatorySchema}
        schema={schema}
        defaultSchemaTitle={defaultSchemaTitle}
        defaultSchema={defaultSchema}
        propertyName={propertyName}
        onSchemaSave={onSchemaSave}
      />
    );

  throw new Error('Unsupported schema type ' + schemaType);
};

export default DefineSchema;
