import {
  IntegrationEventStatus,
  WorkflowNodeResult,
} from '../../api/interface/integration-service';
import { IntegrationNodeType, NodeResult } from './interface';
import { isEmpty } from 'lodash';

export class WorkflowResultHandler {
  private readonly workflowNodeId: string;
  private readonly nodeType: IntegrationNodeType;
  private readonly operationId?: string;
  private readonly errorMessage: string;
  private readonly results: NodeResult[];

  constructor({
    workflowNodeId,
    nodeType,
    operationId,
    errorMessage,
  }: Pick<WorkflowNodeResult, 'workflowNodeId' | 'nodeType' | 'operationId'> & {
    errorMessage: string;
  }) {
    this.workflowNodeId = workflowNodeId;
    this.nodeType = nodeType;
    this.operationId = operationId;
    this.errorMessage = errorMessage;
    this.results = [];
  }

  add(result: NodeResult) {
    this.results.push(result);
  }

  getStatus(): IntegrationEventStatus {
    if (this.results.length === 0) return 'Finished';
    // TODO: Maybe we need a setting to deny empty results, but for now let's just make them a success
    //throw new InternalServerError(`Cannot return status - no results found`);
    if (this.results.length === 1) return this.results[0].status;
    return this.results[this.results.length - 1].status;
  }

  getResults(): WorkflowNodeResult[] {
    return this.results.map(result => {
      return {
        workflowNodeId: this.workflowNodeId,
        nodeType: this.nodeType,
        operationId: this.operationId,
        started: result.started.toISOString(),
        ended: new Date().toISOString(),
        status: result.status,
        input: isEmpty(result.input) ? undefined : result.input,
        output: isEmpty(result.output) ? undefined : result.output,
        ...(result.status === 'Error' && {
          error: {
            message: result.error?.message ?? this.errorMessage,
            error: result.error,
          },
        }),
        callRecord: result.callRecord,
      };
    });
  }
}
