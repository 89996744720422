import { useCallback, useContext, useEffect, useReducer, useRef } from 'react';

import { IntegrationActionLifecycleNotification } from '@apus/common-lib/api/interface/integration-service';
import { AccessTokenContext } from '@apus/common-ui/state/accessTokenContext';
import { Websocket, WebsocketBuilder } from 'websocket-ts';

import {
  initialWebsocketState,
  WebsocketContext,
} from '../../state/websocketContext';
import {
  actionLifecycleEventReceived,
  websocketContextReducer,
  websocketStatusChanged,
} from '../../state/websocketReducer';
import useTenant from '@apus/common-ui/hooks/useTenant';

enum WebsocketReadyState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

type State<Result> = {
  result?: Result;
  error?: 'error' | 'messageerror';
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

const WebsocketWorker = ({ children }: Props): JSX.Element => {
  const socket = useRef<Websocket | null>(null);

  const [accessTokenState] = useContext(AccessTokenContext);
  const tenant = useTenant();

  const tryToInitializeWebsocket = useCallback(
    (current: Websocket | null, currentAccessToken: string | undefined) => {
      if (currentAccessToken !== undefined) {
        if (current != null) {
          try {
            current?.close();
          } catch (e) {
            console.log(
              'Websocket connection could not be closed: ' + JSON.stringify(e)
            );
          }
        }

        console.log(
          `initializing websocket ${process.env.REACT_APP_WEBSOCKET_API_ENDPOINT} `
        );
        return (
          new WebsocketBuilder(
            `${process.env.REACT_APP_WEBSOCKET_API_ENDPOINT}?access_token=${currentAccessToken}`
          )
            // don't use backoff since it leads to endless loop of retrying
            //.withBackoff(new LinearBackoff(0, 1000, 8000))
            .onMessage((_instance, event) => {
              if (event.data !== undefined) {
                const jsonData =
                  typeof event.data === 'string'
                    ? JSON.parse(event.data)
                    : event.data;

                const data = Array.isArray(jsonData)
                  ? (jsonData as IntegrationActionLifecycleNotification[])
                  : [jsonData as IntegrationActionLifecycleNotification];

                data
                  .filter(result => {
                    return result.tenantId === tenant?.tenantId;
                  })
                  .forEach(result => setStateSafe({ result }));
              }
            })
            .onOpen(instance => {
              websocketDispatch(
                websocketStatusChanged(
                  instance.underlyingWebsocket?.readyState ===
                    WebsocketReadyState.OPEN
                )
              );
            })
            .onClose(instance => {
              websocketDispatch(
                websocketStatusChanged(
                  instance.underlyingWebsocket?.readyState ===
                    WebsocketReadyState.OPEN
                )
              );
            })
            .onError((instance, _ev) => {
              websocketDispatch(
                websocketStatusChanged(
                  instance.underlyingWebsocket?.readyState ===
                    WebsocketReadyState.OPEN
                )
              );
            })
            .build()
        );
      }
      return null;
    },
    [tenant]
  );

  // a callback for child components to open the websocket connection if needed
  // provided via the state
  const openConnection = useCallback(() => {
    console.log('trying to initialize websocket connection');
    socket.current = tryToInitializeWebsocket(
      socket.current,
      accessTokenState.accessToken
    );
  }, [accessTokenState.accessToken, tryToInitializeWebsocket]);

  const [websocketState, websocketDispatch] = useReducer(
    websocketContextReducer,
    initialWebsocketState
  );

  const setStateSafe = (
    nextState: State<IntegrationActionLifecycleNotification>
  ) => {
    if (nextState.error !== undefined) {
      console.log(`There was an error: '${nextState.error}' in worker thread`);
    }
    if (nextState.result !== undefined) {
      websocketDispatch(actionLifecycleEventReceived(nextState.result));
    }
  };

  useEffect(() => {
    if (accessTokenState.accessToken !== undefined) {
      socket.current = tryToInitializeWebsocket(
        socket.current,
        accessTokenState.accessToken
      );
    } else {
      socket.current = null;
    }
  }, [accessTokenState.accessToken, tryToInitializeWebsocket]);

  return (
    <WebsocketContext.Provider
      value={[
        {
          ...websocketState,
          openConnection,
        },
        websocketDispatch,
      ]}
    >
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketWorker;
