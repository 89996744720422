import React, { JSX, useEffect } from 'react';
import { VersionedIntegrations } from '@apus/common-lib/api/interface/integration-service';
import { useForm } from 'react-hook-form';
import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import { Grid, Stack } from '@mui/material';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import { systemglueAppNames } from '../subscriptions/apps/names';

interface Props {
  edit: boolean;
  data: VersionedIntegrations;
  onChange: (data: VersionedIntegrations) => void;
}

type PartialAppVersion = Pick<
  VersionedIntegrations,
  'app' | 'isStable' | 'version' | 'description'
>;

const AppVersion = ({ edit, data, onChange }: Props): JSX.Element => {
  const form = useForm<PartialAppVersion>({
    defaultValues: {
      app: SystemglueAppName.BASE_INTEGRATION_APP,
      isStable: false,
      version: '',
      description: '',
    },
  });

  useEffect(() => {
    form.setValue('app', data.app);
    form.setValue('version', data.version);
    form.setValue('isStable', data.isStable);
  }, [data, form]);

  function formUpdated() {
    const current = {
      ...data,
      ...form.getValues(),
    };
    onChange(current);
  }

  return (
    <Grid container spacing={2} padding={1}>
      <Grid item xs={5}>
        <Stack spacing={2}>
          <HookFormAutocomplete
            name={'app'}
            label={'Application'}
            form={form}
            options={Object.keys(systemglueAppNames).map(
              o => o as SystemglueAppName
            )}
            getOptionLabel={o => systemglueAppNames[o]}
            disabled={!edit}
          />
          <HookFormTextInput
            name="version"
            form={form}
            label="Version identifier"
            onValueChange={formUpdated}
            disabled={!edit}
          />
          <HookFormCheckbox
            name={'isStable'}
            form={form}
            label={'Is stable version'}
            onValueChange={formUpdated}
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item xs={7}>
        <HookFormTextInput
          name="description"
          form={form}
          label="Version description"
          fullWidth={true}
          multiline={true}
          rows={9}
          onValueChange={formUpdated}
        />
      </Grid>
    </Grid>
  );
};

export default AppVersion;
