import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SupportedMappingSchemaDefinition } from '@apus/common-lib/api/interface/files';
import { IntegrationDefinition } from '@apus/common-lib/api/interface/integration-service';
import {
  DispatchNode,
  InputTransformations,
  IntegrationNodeType,
  RunCondition,
} from '@apus/common-lib/integration-engine/src/interface';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { Box, Grid, Stack } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

import DefineInputTransformations from './inputTransformations/DefineInputTransformations';
import { NodeBaseComponent } from './interface';
import DefineDispatchMapping from './mapping/DefineDispatchMapping';
import NodeFooter from './NodeFooter';
import NodeToolBar from './NodeToolBar';
import DefineJsonLogicRule from './rule/DefineJsonLogicRule';
import IntegrationSelector from '../../integration/IntegrationSelector';
import Collapsible from '../../surface/Collapsible';

interface NodeForm {
  name: string;
  description?: string;
  disableValidation?: boolean;
  isSynchronous?: boolean;
}

interface Props extends NodeBaseComponent<DispatchNode> {
  integrationContextSchema: JSONSchema7;
  sourceId: string;
  integrations: IntegrationDefinition[];
}

type PartialDispatchNode = Omit<DispatchNode, 'mappingSchema'> & {
  mappingSchema?: SupportedMappingSchemaDefinition;
};

const DefineDispatchNode = ({
  expanded,
  integrationContextSchema,
  sourceId,
  node,
  integrations,
  onCancel,
  onSave,
  onDelete,
  onMoveUp,
  onMoveDown,
}: Props): JSX.Element => {
  const [needsSaving, setNeedsSaving] = useState<boolean>(false);
  const [integration, setIntegration] = useState<IntegrationDefinition>();
  const [workingNode, setWorkingNode] = useState<
    PartialDispatchNode | undefined
  >(undefined);

  const form = useForm<NodeForm>({
    resolver: ajvResolver<NodeForm>({
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'name must be at least 1 characters long',
          },
          nullable: false,
        },
        description: {
          type: 'string',
          nullable: true,
        },
        disableValidation: {
          type: 'boolean',
          nullable: true,
        },
        isSynchronous: {
          type: 'boolean',
          nullable: true,
        },
      },
      required: ['name'],
    }),
    defaultValues: {
      name: '',
      description: undefined,
    },
  });

  useEffect(() => {
    if (node !== undefined) {
      form.setValue('name', node.name);
      form.setValue('description', node.description);
      form.setValue('disableValidation', node.disableValidation);
      form.setValue('isSynchronous', node.isSynchronous);

      setWorkingNode(node);
      setIntegration(
        integrations.find(i => i.integrationId === node.integrationId)
      );
    }
  }, [node, form, integrations]);

  useEffect(() => {
    if (integration !== undefined && node === undefined) {
      if (
        integration.workflow.trigger.nodeType !==
        IntegrationNodeType.WebhookTrigger
      )
        throw new Error(
          `Cannot define dispatch - wrong trigger type ${integration.workflow.trigger.nodeType}`
        );

      setWorkingNode({
        id: uuid(),
        nodeType: IntegrationNodeType.Dispatch,
        name: `Dispatch to "${integration.name}"`,
        // by default, validation should always be on
        disableValidation: false,
        // by default, dispatches are asynchronous
        isSynchronous: false,
        integrationId: integration.integrationId,
        triggerSchema: {
          ...integration.workflow.trigger.triggerSchema,
          fileId: '',
          name: '',
        },
        prev: sourceId,
      });

      form.setValue('name', `Dispatch to "${integration.name}"`);
    }
  }, [node, integration, setWorkingNode, form, sourceId]);

  const selectIntegration = (edit: IntegrationDefinition) => {
    if (edit.workflow.trigger.nodeType !== IntegrationNodeType.WebhookTrigger)
      throw new Error('Wrong trigger type: ' + edit.workflow.trigger.nodeType);

    setIntegration(edit);
  };

  const updateMapping = (mappingSchema?: SupportedMappingSchemaDefinition) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        mappingSchema,
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateInputTransformations = (
    inputTransformations?: InputTransformations
  ) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        inputTransformations,
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateOperationSchemas = () => {
    if (
      integration === undefined ||
      integration.workflow.trigger.nodeType !==
        IntegrationNodeType.WebhookTrigger
    )
      throw new Error('Cannot update schema - integration is undefined');

    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        triggerSchema: {
          ...integration.workflow.trigger.triggerSchema,
          fileId: '',
          name: '',
        },
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateRunCondition = (runCondition?: RunCondition) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        runCondition,
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  function updateNeedsSaving(current: PartialDispatchNode | undefined) {
    if (node !== undefined) {
      setNeedsSaving(!isEqual(node, current));
    } else {
      setNeedsSaving(true);
    }
  }

  function formUpdated() {
    const current =
      workingNode !== undefined
        ? {
            ...workingNode,
            ...form.getValues(),
          }
        : undefined;
    updateNeedsSaving(current);
  }

  const deleteNode = () => {
    if (workingNode !== undefined && onDelete !== undefined) {
      onDelete(workingNode as DispatchNode);
    }
  };

  const moveUp = () => {
    if (workingNode !== undefined && onMoveUp !== undefined) {
      onMoveUp(workingNode as DispatchNode);
    }
  };

  const moveDown = () => {
    if (workingNode !== undefined && onMoveDown !== undefined) {
      onMoveDown(workingNode as DispatchNode);
    }
  };

  const submit = async (data: NodeForm) => {
    if (workingNode !== undefined) {
      if (workingNode.mappingSchema === undefined)
        throw new Error(`Cannot save dispatch node - mappingSchema missing`);

      onSave({
        ...workingNode,
        name: data.name,
        description: data.description,
        disableValidation: data.disableValidation,
        isSynchronous: data.isSynchronous,
      } as DispatchNode);
    }
  };

  if (expanded !== true) return <></>;

  return (
    <Box sx={{ minWidth: 1024, padding: 2 }}>
      <NodeToolBar
        save={{
          onClick: form.handleSubmit(submit),
          disabled: !needsSaving,
        }}
        cancel={{
          onClick: onCancel,
        }}
        update={{
          disabled: workingNode?.id === undefined,
          onClick: updateOperationSchemas,
        }}
        delete={{
          disabled: workingNode?.id === undefined,
          onClick: deleteNode,
        }}
        moveDown={{
          disabled: workingNode?.next === undefined || onMoveDown === undefined,
          onClick: moveDown,
        }}
        moveUp={{
          disabled: workingNode?.prev === undefined || onMoveUp === undefined,
          onClick: moveUp,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Stack spacing={2}>
            <HookFormTextInput
              name="name"
              form={form}
              label="Workflow operation identifier"
              helperText="Results from this workflow operation will be provided in workflow context using this identifier. Must be in camel case."
              disabled={workingNode === undefined}
              onValueChange={formUpdated}
            />
            <IntegrationSelector
              name="select-integration"
              label="Select target integration"
              onIntegrationSelected={integration => {
                if (integration !== undefined) selectIntegration(integration);
              }}
              integration={integration}
              integrations={integrations.filter(
                integration =>
                  integration.workflow.trigger.nodeType ===
                  IntegrationNodeType.WebhookTrigger
              )}
            />
            <HookFormCheckbox
              name={'isSynchronous'}
              label={'Synchronous execution'}
              form={form}
              onValueChange={formUpdated}
            />
            <HookFormCheckbox
              name={'disableValidation'}
              label={'Disable validation'}
              form={form}
              onValueChange={formUpdated}
            />
          </Stack>
        </Grid>
        <Grid item xs={7}>
          <HookFormTextInput
            name="description"
            form={form}
            label="Workflow operation description"
            disabled={workingNode === undefined}
            multiline={true}
            rows={9}
            fullWidth={true}
            onValueChange={formUpdated}
          />
        </Grid>
      </Grid>

      <Collapsible title={'Define a run condition'} defaultExpanded={false}>
        <DefineJsonLogicRule
          runContextSchema={integrationContextSchema}
          condition={workingNode?.runCondition}
          onChange={updateRunCondition}
        />
      </Collapsible>
      <Collapsible
        title="Define input modifications"
        subtitle="Dispatch operation is always provided with the current integration context. With input modifications, this can be transformed. The transformed input schema will then be used for mapping."
        disabled={workingNode === undefined}
        defaultExpanded={false}
      >
        <DefineInputTransformations
          integrationContextSchema={integrationContextSchema}
          node={workingNode as DispatchNode}
          onChange={updateInputTransformations}
        />
        <DefineDispatchMapping
          integrationContextSchema={integrationContextSchema}
          node={workingNode as DispatchNode}
          onChange={updateMapping}
        />
      </Collapsible>
      <NodeFooter />
    </Box>
  );
};

export default DefineDispatchNode;
