import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  IntegrationOperationPrototype,
  SupportedModule,
  SupportedModuleNames,
} from '@apus/common-lib/integrations/src/interface';
import useManagementService from '@apus/common-ui/hooks/useManagementService';
import {
  Autocomplete,
  Button,
  ButtonBase,
  Grid,
  Stack,
  TextField,
} from '@mui/material';

import OperationSelector from './OperationSelector';

export enum SupportedFileType {
  'OpenAPI' = 'OpenAPI -specification',
  'SystemGlue' = 'SystemGlue -operations',
}

interface Props {
  onChange: (
    data: string,
    fileType: SupportedFileType,
    options?: {
      module?: SupportedModule;
      prototype?: IntegrationOperationPrototype;
    }
  ) => void;
}

const OperationSourceFileInput = ({ onChange }: Props): JSX.Element => {
  const managementService = useManagementService();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [fileContent, setFileContent] = useState<string | undefined>(undefined);
  const [fileType, setFileType] = useState<SupportedFileType>(
    SupportedFileType.OpenAPI
  );
  const [prototypeOperations, setPrototypeOperations] =
    useState<IntegrationOperationPrototype[]>();

  const [module, setModule] = useState<SupportedModule | undefined | null>(
    undefined
  );
  const [prototype, setPrototype] = useState<
    IntegrationOperationPrototype | undefined
  >(undefined);

  const refreshOperations = useCallback(async () => {
    setPrototypeOperations(await managementService.listPrototypeOperations());
  }, [managementService]);

  useEffect(() => {
    (async () => {
      await refreshOperations();
    })();
  }, [refreshOperations]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files != null) {
      const files = Array.from(event.target.files);
      const [file] = files;

      setFileContent(await file.text());
    }
  };

  const onImportClicked = () => {
    if (fileContent !== undefined) {
      onChange(fileContent, fileType, {
        module: module ?? undefined,
        prototype,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={11}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack spacing={2} direction={'row'}>
              <Autocomplete
                disablePortal
                sx={{ minWidth: 400 }}
                options={Object.values(SupportedFileType)}
                defaultValue={fileType}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Choose file type to use for importing operations"
                  />
                )}
                onChange={(_, data) => {
                  if (data) setFileType(data);
                }}
              />

              <ButtonBase component="label">
                <input
                  ref={inputRef}
                  type="file"
                  accept="application/json"
                  onChange={handleFileChange}
                />
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction={'row'}>
              <Autocomplete
                disablePortal
                sx={{ minWidth: 400 }}
                options={SupportedModuleNames}
                disabled={
                  fileType !== SupportedFileType.OpenAPI ||
                  fileContent === undefined
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select module"
                    helperText="Operations will be included as part of the selected module"
                  />
                )}
                onChange={(_, data) => {
                  setModule(data);
                }}
              />
              <OperationSelector<IntegrationOperationPrototype>
                disabled={
                  fileType !== SupportedFileType.OpenAPI ||
                  fileContent === undefined
                }
                name="select-prototype-operation"
                label="Use prototype for imported operations"
                onOperationSelected={operation => {
                  setPrototype(operation);
                }}
                operations={prototypeOperations ?? []}
                operation={prototype}
                displayButton={false}
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Button onClick={onImportClicked}>Parse</Button>
      </Grid>
    </Grid>
  );
};

export default OperationSourceFileInput;
