import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';
import { AirtableConfiguration } from '@apus/common-lib/integrations/src/airtable/interface';

const AWSConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<AirtableConfiguration>();

  useEffect(() => {
    form.reset({
      personalAccessToken: '',
    });

    if (moduleConfiguration !== undefined) {
      form.reset(
        moduleConfiguration.configuration as unknown as AirtableConfiguration
      );
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (formData: AirtableConfiguration) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Airtable -connection
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="personalAccessToken"
            form={form}
            label="Personal access token"
            defaultValue={''}
            helperText={'This will be used for authenticating requests'}
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
        Save
      </Button>
    </Grid>
  );
};

export default AWSConfigurationForm;
