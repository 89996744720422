import { JSX, useEffect, useReducer } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { initialUserState, UserContext } from '../state/userContext';
import { setUser, userContextReducer } from '../state/userReducer';
import { SignedInUser } from '@apus/common-lib/api/interface/tenant-service';
import { SystemglueUser } from '@apus/common-lib/utils/src/user-util';

function parseSignedInUser(cognitoUser: CognitoUser): SignedInUser | undefined {
  const sessionUser = cognitoUser?.getSignInUserSession();

  if (sessionUser == null) return undefined;

  const tokenClaims = sessionUser.getIdToken()?.payload; //.['email'];

  if (tokenClaims === undefined) return undefined;

  const roles: string[] = tokenClaims['cognito:groups'];

  const user = new SystemglueUser({
    userId: tokenClaims['sub'],
    userName: tokenClaims['email'],
    roles,
    tenantId: tokenClaims['custom:tenant_id'],
  });

  return user;
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

const UserContextProvider = ({ children }: Props): JSX.Element => {
  const { user } = useAuthenticator(context => [context.user]);

  const [state, dispatch] = useReducer(userContextReducer, initialUserState);

  useEffect(() => {
    if (user == null) {
      dispatch(setUser(undefined));
    } else {
      dispatch(setUser(parseSignedInUser(user)));
    }
  }, [user, dispatch]);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
