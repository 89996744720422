import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SupportedMappingSchemaDefinition } from '@apus/common-lib/api/interface/files';
import {
  IntegrationNodeType,
  PollingTriggerNode,
} from '@apus/common-lib/integration-engine/src/interface';
import {
  ConfigurationSchema,
  IntegrationOperation,
  IntegrationOperationPrototype,
  WorkflowOperation,
  WorkflowOperationType,
} from '@apus/common-lib/integrations/src/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import { splitConfigurationSchema } from '@apus/common-lib/utils/src/data-utils';
import HookFormCheckbox from '@apus/common-ui/components/hook-form/HookFormCheckbox';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { resolvePrototypeForWorkflowOperationOrThrow } from '@apus/common-ui/utils/data-utils';
import { ajvResolver } from '@hookform/resolvers/ajv/dist/ajv';
import { Box, Grid, Stack } from '@mui/material';
import { JSONSchema7 } from 'json-schema';
import { isEqual } from 'lodash';
import { v4 as uuid } from 'uuid';

import DefineRuntimeConfiguration from './configuration/DefineRuntimeConfiguration';
import { NodeBaseComponent } from './interface';
import DefineOperationMapping from './mapping/DefineOperationMapping';
import NodeFooter from './NodeFooter';
import NodeToolBar from './NodeToolBar';
import DefineWorkflowOperation from './operation/DefineWorkflowOperation';
import DefineCronSchedule from './schedule/DefineCronSchedule';
import Collapsible from '../../surface/Collapsible';

interface NodeForm {
  name: string;
  description?: string;
  disableValidation?: boolean;
}

interface Props extends NodeBaseComponent<PollingTriggerNode> {
  integrationContextSchema: JSONSchema7;
  operations: IntegrationOperation[];
  prototypes: IntegrationOperationPrototype[];
}

type WorkingPollingTriggerNode = Omit<PollingTriggerNode, 'operation'> & {
  operation?: WorkflowOperation;
};

const DefinePollingTriggerNode = ({
  expanded,
  integrationContextSchema,
  operations,
  prototypes,
  node,
  onCancel,
  onSave,
}: Props): JSX.Element => {
  const [needsSaving, setNeedsSaving] = useState<boolean>(false);
  const [workingNode, setWorkingNode] = useState<
    WorkingPollingTriggerNode | undefined
  >(undefined);

  const [allowedOperations, setAllowedOperations] = useState<
    IntegrationOperation[]
  >([]);

  const [runtimeConfigurationSchema, setRuntimeConfigurationSchema] = useState<
    ConfigurationSchema | undefined
  >();

  const form = useForm<NodeForm>({
    resolver: ajvResolver<NodeForm>({
      type: 'object',
      properties: {
        name: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'name must be at least 1 characters long',
          },
          nullable: false,
        },
        description: {
          type: 'string',
          nullable: true,
        },
        disableValidation: {
          type: 'boolean',
          nullable: true,
        },
      },
      required: ['name'],
    }),
    defaultValues: {
      name: '',
      description: undefined,
    },
  });

  const setRuntimeConfigurationSchemaAndOperation = useCallback(
    (workflowOperation: WorkflowOperation) => {
      const prototype = resolvePrototypeForWorkflowOperationOrThrow(
        workflowOperation,
        operations,
        prototypes
      );

      const { runtimeConfigurationSchema } = splitConfigurationSchema(
        prototype.configurationSchema
      );
      setRuntimeConfigurationSchema(runtimeConfigurationSchema);
    },
    [operations, prototypes, setRuntimeConfigurationSchema]
  );

  useEffect(() => {
    setAllowedOperations(operations.filter(o => o.outputSchema !== undefined));
  }, [operations]);

  useEffect(() => {
    if (node !== undefined) {
      form.setValue('name', node.name);
      form.setValue('description', node.description);
      form.setValue('disableValidation', node.disableValidation);

      setWorkingNode(node);
      setRuntimeConfigurationSchemaAndOperation(node.operation);
    }
  }, [node, form, setWorkingNode, setRuntimeConfigurationSchemaAndOperation]);

  useEffect(() => {
    if (node === undefined) {
      setWorkingNode({
        id: uuid(),
        description: '',
        nodeType: IntegrationNodeType.PollingTrigger,
        name: `Polling trigger`,
        schedule: '0 0 0 * * * ',
        disableValidation: false,
      });
    }
  }, [node, setWorkingNode]);

  const updateOperation = (operation?: WorkflowOperation) => {
    if (workingNode !== undefined) {
      if (operation !== undefined) {
        const updated = {
          ...workingNode,
          operation: {
            ...workingNode.operation,
            ...operation,
          },
        };

        setWorkingNode(updated);
        setRuntimeConfigurationSchemaAndOperation(operation);
        updateNeedsSaving(updated);
      } else {
        const updated = {
          ...workingNode,
          operation: undefined,
        };

        setWorkingNode(updated);
        updateNeedsSaving(updated);
      }
    }
  };

  const updateMapping = (mappingSchema?: SupportedMappingSchemaDefinition) => {
    if (workingNode !== undefined && workingNode.operation !== undefined) {
      const updated = {
        ...workingNode,
        operation: {
          ...workingNode.operation,
          mappingSchema,
        },
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
      console.log(
        `Update mapping: ${JSON.stringify(
          updated.operation.mappingSchema,
          null,
          2
        )}`
      );
    }
  };

  const updateSchedule = (schedule: string | undefined) => {
    if (workingNode !== undefined) {
      const updated = {
        ...workingNode,
        ...(schedule !== undefined && { schedule }),
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  const updateRuntimeConfiguration = (runtimeConfiguration?: SourceObject) => {
    if (workingNode !== undefined && workingNode.operation !== undefined) {
      const updated = {
        ...workingNode,
        operation: {
          ...workingNode.operation,
          runtimeConfiguration,
        },
      };

      setWorkingNode(updated);
      updateNeedsSaving(updated);
    }
  };

  function updateNeedsSaving(current: WorkingPollingTriggerNode | undefined) {
    if (node !== undefined) {
      setNeedsSaving(!isEqual(node, current));
    } else {
      setNeedsSaving(true);
    }
  }

  function formUpdated() {
    const current =
      workingNode !== undefined
        ? {
            ...workingNode,
            ...form.getValues(),
          }
        : undefined;
    updateNeedsSaving(current);
  }

  const submit = async (data: NodeForm) => {
    if (workingNode !== undefined && workingNode.operation !== undefined) {
      console.log(
        `submit: ${JSON.stringify({
          ...workingNode,
          operation: workingNode.operation,
          name: data.name,
          description: data.description,
          disableValidation: data.disableValidation,
        })}`
      );
      onSave({
        ...workingNode,
        operation: workingNode.operation,
        name: data.name,
        description: data.description,
        disableValidation: data.disableValidation,
      });
    }
  };

  if (expanded !== true) return <></>;

  return (
    <Box sx={{ minWidth: 1024, padding: 2 }}>
      <NodeToolBar
        save={{
          onClick: form.handleSubmit(submit),
          disabled: !needsSaving,
        }}
        cancel={{
          onClick: onCancel,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Stack spacing={2}>
            <HookFormTextInput
              name="name"
              form={form}
              label="Workflow operation identifier"
              helperText="Results from this workflow operation will be provided in workflow context using this identifier. Must be in camel case."
              disabled={workingNode?.operation === undefined}
              fullWidth={true}
              onValueChange={formUpdated}
            />
            <DefineCronSchedule
              schedule={workingNode?.schedule}
              onChange={updateSchedule}
            />
            <HookFormCheckbox
              name={'disableValidation'}
              label={'Disable validation'}
              form={form}
              onValueChange={formUpdated}
            />
          </Stack>
        </Grid>
        <Grid item xs={7}>
          <HookFormTextInput
            name="description"
            form={form}
            label="Workflow operation description"
            disabled={workingNode?.operation === undefined}
            multiline={true}
            rows={9}
            fullWidth={true}
            onValueChange={formUpdated}
          />
        </Grid>
      </Grid>
      <Collapsible
        title={'Integration operation'}
        subtitle={
          'Define the actual integration that will used when polling. This must be an operation that has explicit output data model.'
        }
        defaultExpanded={false}
      >
        <DefineWorkflowOperation
          operation={workingNode?.operation}
          operations={allowedOperations}
          prototypes={prototypes}
          onChange={updateOperation}
        />
      </Collapsible>
      {workingNode !== undefined && (
        <>
          <Collapsible
            title="Define mapping"
            subtitle="Operations have their own interfaces. Define how the input data is to be mapped to the input schema expected by the operation."
            disabledSubtitle={
              workingNode.operation?.inputSchema === undefined
                ? 'Operation does not define an input schema'
                : 'Operation does not require mapping'
            }
            disabled={
              workingNode.operation?.inputSchema === undefined ||
              (workingNode.operation.operationType ===
                WorkflowOperationType.EMBEDDED &&
                workingNode.operation?.prototype.noMapping)
            }
            defaultExpanded={false}
          >
            <DefineOperationMapping
              integrationContextSchema={integrationContextSchema}
              node={
                workingNode?.operation !== undefined
                  ? (workingNode as PollingTriggerNode)
                  : undefined
              }
              targetSchema={
                workingNode.operation?.inputSchema?.content.jsonSchema ?? {}
              }
              onChange={updateMapping}
            />
          </Collapsible>
          <Collapsible
            title="Configure operation"
            subtitle="Define the runtime configuration required by the integration operation"
            disabled={runtimeConfigurationSchema === undefined}
            defaultExpanded={false}
          >
            <DefineRuntimeConfiguration
              integrationContextSchema={integrationContextSchema}
              configurationSchema={runtimeConfigurationSchema}
              node={
                workingNode?.operation !== undefined
                  ? (workingNode as PollingTriggerNode)
                  : undefined
              }
              onChange={updateRuntimeConfiguration}
            />
          </Collapsible>
        </>
      )}
      <NodeFooter />
    </Box>
  );
};

export default DefinePollingTriggerNode;
