import React, { JSX } from 'react';

import { IntegrationNodeType } from '@apus/common-lib/integration-engine/src/interface';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import NatIcon from '@mui/icons-material/Nat';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SettingsEthernetTwoToneIcon from '@mui/icons-material/SettingsEthernetTwoTone';

const WorkflowNodeIcon = ({
  node,
}: {
  node: {
    nodeType: IntegrationNodeType;
  };
}): JSX.Element => {
  switch (node.nodeType) {
    case IntegrationNodeType.WebhookTrigger:
      return (
        <FileDownloadTwoToneIcon
          color="success"
          sx={{
            transform: 'rotate(-90deg)',
          }}
        />
      );
    case IntegrationNodeType.PollingTrigger:
      return <ScheduleIcon color="warning" />;
    case IntegrationNodeType.ApiEndpointTrigger:
      return (
        <NatIcon
          sx={{
            color: 'blue',
          }}
        />
      );
    case IntegrationNodeType.ErrorTrigger:
      return <ErrorOutlineTwoToneIcon />;
    case IntegrationNodeType.Operation:
      return <SettingsEthernetTwoToneIcon />;
    case IntegrationNodeType.Dispatch:
      return <LogoutTwoToneIcon color="error" />;
    case IntegrationNodeType.ApiResult:
      return <SettingsEthernetTwoToneIcon />;
  }
};

export default WorkflowNodeIcon;
