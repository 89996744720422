import React, { JSX, useEffect } from 'react';

import { JournalEntryConfiguration } from '@apus/common-lib/integrations/src/banking/transactions/rules-interface';
import DisplayApiCommandResult from '@apus/common-ui/components/api-commands/DisplayApiCommandResult';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import { Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { Output as NetsuiteListAccounts } from '../../../../../../apis/hooks/apps/banking/transactions/useNetsuiteListAccounts';
import { ConfigurationComponent } from '../interface';

const defaultValues: NetsuiteListAccounts = [
  {
    accountName: '',
    accountInternalId: '',
    accountNumber: '',
    accountType: '',
  },
];

const JournalEntryConfigurationForm = ({
  configuration,
  form,
  disabled,
  onChange,
  configurationData,
}: ConfigurationComponent): JSX.Element => {
  useEffect(() => {
    if (configuration === undefined) {
      form.setValue('configuration', undefined);
    } else {
      if (typeof configuration !== 'object')
        throw new Error(
          `Cannot define journal entry configuration - wrong data type ${typeof configuration}`
        );
      const journalEntryConfiguration =
        configuration as JournalEntryConfiguration;

      form.setValue('configuration', journalEntryConfiguration);
    }
  }, [configuration, form]);

  if (configurationData?.erpAccounts === undefined)
    return (
      <Typography>Cannot show accounts - configuration data missing</Typography>
    );

  function accountChanged(accountId: string) {
    onChange({
      accountId,
    });
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>Define the journal entry configuration</Typography>
      </Grid>
      <Grid item xs={12}>
        <DisplayApiCommandResult
          command={configurationData.erpAccounts}
          loadingText={'Loading Netsuite accounts'}
          component={
            <HookFormAutocomplete
              label={'Select ERP account'}
              name={'configuration.accountId'}
              form={form}
              disabled={disabled}
              defaultValue={defaultValues[0]}
              pickValue={o => o.accountInternalId}
              isOptionEqualToValue={(option, value) =>
                option.accountInternalId === value.accountInternalId
              }
              getOptionLabel={account =>
                isEmpty(account.accountInternalId)
                  ? defaultValues[0].accountInternalId
                  : `${account.accountNumber ?? ''} ${account.accountName} [${
                      account.accountType
                    }]`
              }
              loadingText={'Loading accounts...'}
              loading={configurationData.erpAccounts.pending}
              options={defaultValues.concat(
                configurationData.erpAccounts.result
              )}
              onValueChange={value => {
                accountChanged(value.accountInternalId);
              }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default JournalEntryConfigurationForm;
