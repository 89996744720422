import {
  GetCommandInput,
  TransactWriteCommandInput,
} from '@aws-sdk/lib-dynamodb';
import { UpdateItemInput } from '@aws-sdk/client-dynamodb';
import { HttpApiError } from '../../api/interface/common';

export class TypedError extends Error {
  public body?: Record<string, unknown>;
  public httpCode?: number;

  public constructor(
    m: string,
    body?: Record<string, unknown>,
    httpCode?: number
  ) {
    super(m);
    this.httpCode = httpCode;
    this.body = body;
    this.name = 'TypedError';
    Object.setPrototypeOf(this, TypedError.prototype);
  }
}

export class InternalServerError extends TypedError {
  public constructor(m: string, body?: Record<string, unknown>) {
    super(m, body, 500);
    this.name = 'InternalServerError';
    Object.setPrototypeOf(this, InternalServerError.prototype);
  }
}

export class ValidationError extends TypedError {
  public constructor(m: string, body?: Record<string, unknown>) {
    super(m, body, 400);
    this.name = 'ValidationError';
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export class BadRequest extends TypedError {
  public constructor(m: string, body?: Record<string, unknown>) {
    super(m, body, 400);
    this.name = 'BadRequest';
    Object.setPrototypeOf(this, BadRequest.prototype);
  }
}

export class NotFound extends TypedError {
  public constructor(m: string, body?: Record<string, unknown>) {
    super(m, body, 404);
    this.name = 'NotFound';
    Object.setPrototypeOf(this, NotFound.prototype);
  }
}

export class Conflict extends TypedError {
  public constructor(m: string, body?: Record<string, unknown>) {
    super(m, body, 409);
    this.name = 'Conflict';
    Object.setPrototypeOf(this, Conflict.prototype);
  }
}

export class Unauthorized extends TypedError {
  public constructor(m: string, body?: Record<string, unknown>) {
    super(m, body, 401);
    this.name = 'Unauthorized';
    Object.setPrototypeOf(this, Unauthorized.prototype);
  }
}

export class MappingError extends TypedError {
  constructor(m: string, body?: Record<string, unknown>) {
    super(m, body);
    this.name = 'MappingError';
    Object.setPrototypeOf(this, MappingError.prototype);
  }
}

export class ExternalServiceAuthenticationError extends TypedError {
  constructor(m: string, body?: Record<string, unknown>) {
    super(m, body);
    this.name = 'ExternalServiceAuthenticationError';
    Object.setPrototypeOf(this, ExternalServiceAuthenticationError.prototype);
  }
}

export class RetryingTransactionWriteError extends Error {
  public input: TransactWriteCommandInput;
  public err: any;

  public constructor(m: string, input: TransactWriteCommandInput, err: any) {
    super(m);
    this.input = input;
    this.err = err;
    this.name = 'RetryingTransactionWriteError';
    Object.setPrototypeOf(this, RetryingTransactionWriteError.prototype);
  }
}

export class RetryingUpdateItemError extends Error {
  public input: UpdateItemInput;
  public err: any;

  public constructor(m: string, input: UpdateItemInput, err: any) {
    super(m);
    this.input = input;
    this.err = err;
    this.name = 'RetryingUpdateItemError';
    Object.setPrototypeOf(this, RetryingUpdateItemError.prototype);
  }
}

export class RetryingGetItemError extends Error {
  public input: GetCommandInput;
  public err: any;

  public constructor(m: string, input: GetCommandInput, err: any) {
    super(m);
    this.input = input;
    this.err = err;
    this.name = 'RetryingGetItemError';
    Object.setPrototypeOf(this, RetryingGetItemError.prototype);
  }
}

export class WorkflowNodeAssertionError extends TypedError {
  public constructor(m: string) {
    super(m);
    this.name = 'WorkflowNodeAssertionError';
    Object.setPrototypeOf(this, WorkflowNodeAssertionError.prototype);
  }
}

export class ExternalApiCallError extends TypedError {
  public url: string;
  public method: string;

  public constructor(err: HttpApiError) {
    super(err.message, err.details, err.statusCode);
    this.url = err.url;
    this.method = err.method;
  }
}

export class XmlParsingError extends TypedError {
  public constructor(m: string, body?: Record<string, unknown>) {
    super(m, body, 500);
    this.name = 'XmlParsingError';
    Object.setPrototypeOf(this, InternalServerError.prototype);
  }
}
