import { LambdaLog, LogMessage } from 'lambda-log';

const log = new LambdaLog({
  debug: JSON.parse(process.env.DEBUG ?? 'false'),
  dev: JSON.parse(process.env.DEBUG ?? 'false'),
});

export { LogMessage };

export type LogRecord = Record<string, unknown> | any;

export type LogRecordFn = (
  message: string,
  logRecord?: LogRecord
) => LogMessage;

function logMsg(name: string, message: string): string {
  return `${name}: ${message}`;
}

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

const loggerByLevel: Record<LogLevel, LogRecordFn> = {
  debug: log.debug,
  info: log.info,
  warn: log.warn,
  error: log.error,
};

export function logRecordFor(name: string, logLevel: LogLevel): LogRecordFn {
  const log = loggerByLevel[logLevel];

  return (message: string, logRecord?: LogRecord) => {
    return log(logMsg(name, message), logRecord);
  };
}

export default log;
