import React, { JSX, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import useTenantService from '@apus/common-ui/hooks/useTenantService';
import { TenantContext } from '@apus/common-ui/state/tenantContext';
import { setTenants } from '@apus/common-ui/state/tenantReducer';
import { getResolver } from '@apus/common-ui/utils/data-utils';
import { Box, Button, Stack } from '@mui/material';
import { v4 as uuid } from 'uuid';

import ConfigureSubscriptions from '../subscriptions/ConfigureSubscriptions';
import Collapsible from '../surface/Collapsible';

interface TenantEdit {
  tenantId: string;
  tenantName?: string;
  businessId?: string;
  isServiceProvider?: boolean;
}

interface Props {
  editMode?: boolean;
  tenant?: TenantEdit;
}

const TenantEditFormView = ({
  editMode = false,
  tenant,
}: Props): JSX.Element => {
  const [, dispatch] = useContext(TenantContext);

  const form = useForm<TenantEdit>({
    resolver: getResolver<TenantEdit>({
      type: 'object',
      properties: {
        tenantId: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'tenant id must be at least 1 character long',
          },
        },
        tenantName: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'tenant name must be at least 1 character long',
          },
        },
        businessId: {
          type: 'string',
          minLength: 1,
          errorMessage: {
            minLength: 'tenant name must be at least 1 character long',
          },
        },
      },
      required: ['tenantId', 'tenantName'],
    }),
    defaultValues: {
      tenantId: '',
      tenantName: '',
    },
  });
  const [isNewTenant, setIsNewTenant] = useState<boolean>(true);

  const tenantService = useTenantService();
  const navigate = useNavigate();

  useEffect(() => {
    if (tenant !== undefined) {
      form.setValue('tenantId', tenant.tenantId);
      form.setValue('tenantName', tenant.tenantName);
      form.setValue('businessId', tenant.businessId);
      setIsNewTenant(false);
    } else {
      form.setValue('tenantId', uuid());
      form.setValue('tenantName', '');
      setIsNewTenant(true);
    }
  }, [tenant, form]);

  const onCancel = () => {
    navigate('/tenants');
  };

  const onSubmit = async (saved: TenantEdit) => {
    if (tenant === undefined) {
      if (saved.tenantId === undefined || saved.tenantId.trim() === '')
        throw new Error(`Cannot create tenant - id is undefined`);

      await tenantService
        .createTenant({
          tenantId: saved.tenantId,
          tenantName: saved.tenantName ?? '',
          businessId: saved.businessId,
        })
        .then(async () =>
          dispatch(setTenants(await tenantService.listTenants()))
        );
      navigate(`/tenants/${saved.tenantId}`);
    } else {
      await tenantService
        .updateTenant({
          ...tenant,
          tenantName: saved.tenantName ?? '',
          businessId: saved.businessId,
        })
        .then(async () =>
          dispatch(setTenants(await tenantService.listTenants()))
        );
      navigate(`/tenants/${tenant.tenantId}`);
    }
  };

  return (
    <>
      <Collapsible title={'Tenant details'}>
        <Stack spacing={2}>
          <HookFormTextInput
            name={'tenantName'}
            form={form}
            label="Name"
            disabled={!editMode}
          />
          <HookFormTextInput
            name={'businessId'}
            form={form}
            label="Business id"
            disabled={!editMode}
          />
        </Stack>
        <>
          {editMode && (
            <Box width={'100%'} marginTop={1}>
              <Stack spacing={2} direction={'row-reverse'}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  onClick={form.handleSubmit(onSubmit)}
                  disabled={tenant?.isServiceProvider === true}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          )}
        </>
      </Collapsible>
      {!editMode &&
        !isNewTenant &&
        tenant !== undefined &&
        tenant.isServiceProvider !== true && (
          <Collapsible title={'Subscriptions'}>
            <ConfigureSubscriptions tenantId={tenant.tenantId} />
          </Collapsible>
        )}
    </>
  );
};

export default TenantEditFormView;
