import React from 'react';

import { IntegrationEventStatus } from '@apus/common-lib/api/interface/integration-service';
import DoneIcon from '@mui/icons-material/Done';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { SvgIcon, SvgIconProps } from '@mui/material';

type Props = { status?: IntegrationEventStatus } & SvgIconProps;

export const getWorkflowNodeStatusIcon = (
  status?: IntegrationEventStatus
): {
  color:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  icon: React.ElementType<SvgIconProps>;
} => {
  switch (status) {
    case 'Error':
      return { color: 'error', icon: PriorityHighIcon };
    case 'Finished':
      return { color: 'success', icon: DoneIcon };
    case 'Omitted':
      return { color: 'disabled', icon: DoneIcon };
  }

  return { color: 'disabled', icon: HourglassDisabledIcon };
};

const WorkflowNodeStatusIcon = ({ status, ...props }: Props) => {
  const { color, icon } = getWorkflowNodeStatusIcon(status);

  return <SvgIcon component={icon} color={color} {...props} />;
};

export default WorkflowNodeStatusIcon;
