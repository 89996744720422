import React, { JSX, useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import HookFormAutocomplete from '@apus/common-ui/components/hook-form/HookFormAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Box, capitalize, Grid, Typography } from '@mui/material';

import { AppContext } from '../../state/appContext';
import ModuleConfigurator from '../connection/ModuleConfigurator';
import CollapsiblePanel from '../surface/CollapsiblePanel';

interface Props {
  title: string;
  options: SupportedModule[];
  value?: SupportedModule;
  edit?: boolean;
  onChange: (module?: SupportedModule) => void;
}

function RequirementStatus({ enabled }: { enabled?: boolean }): JSX.Element {
  if (enabled === undefined)
    return <IndeterminateCheckBoxIcon color={'info'} />;
  if (!enabled) return <ClearIcon color={'error'} />;
  if (enabled) return <CheckIcon color={'success'} />;
  return <></>;
}

const AppRequirementConfiguration = ({
  title,
  value,
  options,
  edit,
  onChange,
}: Props) => {
  const form = useForm<{ connection: string | undefined }>();
  const [appState] = useContext(AppContext);
  const [selected, setSelected] = useState<ModuleConfiguration | undefined>();

  useEffect(() => {
    if (value === undefined) {
      setSelected(undefined);
      form.setValue('connection', undefined);
    } else {
      setSelected(
        appState.integrationConnections.find(c => c.moduleId === value)
      );
      form.setValue('connection', value);
    }
  }, [form, appState.integrationConnections, value, setSelected]);

  return (
    <CollapsiblePanel
      title={capitalize(title)}
      titleTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
      defaultExpanded={selected === undefined}
      secondaryComponent={<RequirementStatus enabled={selected?.enabled} />}
    >
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography>Select {title} -connection</Typography>
        </Grid>
        <Grid item xs={7}>
          <HookFormAutocomplete
            name={'connection'}
            label={title}
            form={form}
            options={options}
            isOptionEqualToValue={(option, value) => option === value}
            disabled={!edit}
            sx={{ width: '100%' }}
            onValueChange={value => onChange(value)}
          />
        </Grid>
        <Grid item xs={12}>
          {value !== undefined && (
            <Box>
              <ModuleConfigurator
                moduleName={value}
                hideTitle={true}
                subtitleProps={{ variant: 'body1', fontWeight: 'bold' }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </CollapsiblePanel>
  );
};

export default AppRequirementConfiguration;
