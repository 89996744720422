import { useContext, useState, JSX, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Tenant } from '@apus/common-lib/api/interface/tenant-service';
import useApiCall from '@apus/common-ui/hooks/useApiCallHook';
import useTenantService from '@apus/common-ui/hooks/useTenantService';
import { TenantContext } from '@apus/common-ui/state/tenantContext';
import { setCurrentTenant } from '@apus/common-ui/state/tenantReducer';
import { Box, Grid, ToggleButton, Typography } from '@mui/material';

import TenantEditFormView from '../../../components/tenants/TenantEditFormView';
import { bodyMaxHeight } from '../../../theme/theme';

const TenantsView = (): JSX.Element => {
  const [tenantState, tenantDispatch] = useContext(TenantContext);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { tenantId } = useParams();
  const tenantService = useTenantService();

  const getTenant = useCallback(() => {
    if (tenantId !== undefined) return tenantService.getTenant(tenantId);
    return Promise.resolve(undefined);
  }, [tenantService, tenantId]);

  const [isLoading, tenant, error] = useApiCall<Tenant | undefined>(getTenant, [
    getTenant,
  ]);

  useEffect(() => {
    if (
      tenant !== undefined &&
      tenantState.tenant?.tenantId !== tenant.tenantId
    ) {
      tenantDispatch(setCurrentTenant(tenant));
    }
  }, [tenant, tenantState.tenant?.tenantId, tenantDispatch]);

  if (tenantId === undefined) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="subtitle1">
              Missing tenantId - nothing to do
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (error !== undefined) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Typography variant="subtitle1">
              Failed to load tenant with id '{tenantId}'
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      <Grid item xs={10}>
        <Typography variant="h6">Tenant</Typography>
      </Grid>
      <Grid item xs={2}>
        <ToggleButton
          value="check"
          selected={editMode}
          disabled={tenant?.isServiceProvider === true}
          onChange={() => {
            setEditMode(!editMode);
          }}
        >
          Edit
        </ToggleButton>{' '}
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Typography variant="subtitle1">Loading tenant...</Typography>
        ) : (
          <TenantEditFormView tenant={tenant} editMode={editMode} />
        )}
      </Grid>
    </Grid>
  );
};

export default TenantsView;
