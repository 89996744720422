import { JSX } from 'react';
import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormReturn,
} from 'react-hook-form';

import { Slider, SliderProps, Typography } from '@mui/material';

import { isError } from './util';
import ValidationError from './ValidationError';

type Props<FormType extends FieldValues> = {
  name: string;
  label: string;
  form: UseFormReturn<FormType>;
  onValueChange?: (value?: number | number[]) => void;
} & SliderProps;

const HookFormSlider = <FormType extends FieldValues>({
  name,
  label,
  form,
  onValueChange,
  ...otherProps
}: Props<FormType>): JSX.Element => {
  const error = isError(name, form.formState.errors);

  const { defaultValue, ...prunedProps } = otherProps;

  return (
    <Controller
      control={form.control}
      name={name as Path<FormType>}
      defaultValue={(defaultValue ?? '') as PathValue<FormType, Path<FormType>>}
      key={
        prunedProps.key !== undefined
          ? `${prunedProps.key}-controller`
          : `${name}-controller`
      }
      render={({ field }) => (
        <div>
          <Typography
            gutterBottom
            key={
              prunedProps.key !== undefined
                ? `${prunedProps.key}-typography`
                : `${name}-typography`
            }
          >
            {label}
          </Typography>
          <Slider
            {...field}
            {...prunedProps}
            key={
              otherProps.key !== undefined
                ? `${prunedProps.key}-slider`
                : `${name}-slider`
            }
            onChange={(event, value) => {
              field.onChange({
                ...event,
                target: {
                  ...event.target,
                  value: value,
                },
              });
              if (onValueChange) onValueChange(value);
            }}
          />
          {error !== undefined && <ValidationError error={error} />}
        </div>
      )}
    />
  );
};

export default HookFormSlider;
