import { JSONSchema7 } from 'json-schema';
import { generate } from './generator';
import { parse } from './parser';
import { format } from './formatter';
import { dereferenceSchema } from '../../json-data-mapper/src/schema-utils';
import { link } from './linker';
import { normalize } from './normalizer';
import { cloneDeep } from 'lodash';
import { optimize } from './optimizer';

export interface Options {
  /**
   * Default value for additionalProperties, when it is not explicitly set.
   */
  additionalProperties: boolean;
  /**
   * Declare external schemas referenced via `$ref`?
   */
  declareExternallyReferenced: boolean;
  /**
   * Generate unknown type instead of any
   */
  unknownAny: boolean;
  /**
   * Prepend enums with [`const`](https://www.typescriptlang.org/docs/handbook/enums.html#computed-and-constant-members)?
   */
  enableConstEnums: boolean;
  /**
   * Ignore maxItems and minItems for `array` types, preventing tuples being generated.
   */
  ignoreMinAndMaxItems: boolean;
  /**
   * Maximum number of unioned tuples to emit when representing bounded-size array types,
   * before falling back to emitting unbounded arrays. Increase this to improve precision
   * of emitted types, decrease it to improve performance, or set it to `-1` to ignore
   * `minItems` and `maxItems`.
   */
  maxItems: number;
  /**
   * Append all index signatures with `| undefined` so that they are strictly typed.
   *
   * This is required to be compatible with `strictNullChecks`.
   */
  strictIndexSignatures: boolean;
  /**
   * Generate code for `definitions` that aren't referenced by the schema?
   */
  unreachableDefinitions: boolean;

  useDeclareInsteadOfExport?: boolean;
}

export const DEFAULT_OPTIONS: Options = {
  declareExternallyReferenced: true,
  unknownAny: true,
  enableConstEnums: true,
  strictIndexSignatures: true,
  unreachableDefinitions: false,
  additionalProperties: false,
  ignoreMinAndMaxItems: false,
  maxItems: 20,
  useDeclareInsteadOfExport: false,
};

export async function compile(
  schema: JSONSchema7,
  typeName: string,
  options = DEFAULT_OPTIONS
) {
  const copy = cloneDeep(schema);

  const deReferencedSchema = await dereferenceSchema(copy);

  const linked = link(deReferencedSchema);

  const normalized = normalize(linked, options);

  // TODO: for some reason JSONSchema7 is incompatible (cannot be indexed with string) with JSONSchema7 - fix this later
  const parsed = parse(normalized, options, typeName);

  const optimized = optimize(parsed, options);

  const generated = generate(optimized, options);

  return format(generated, options);
}
