import Ajv, { Options } from 'ajv';
import addFormats from 'ajv-formats';
import { removeNulls } from './data-utils';
import { InternalServerError, ValidationError } from './error';
import { JSONSchema7 } from 'json-schema';
import { SourceObject } from '../../json-data-mapper/src/interface';

const defaults: Options = {
  allErrors: true,
  useDefaults: true,
  $data: true, // required for ajv-keywords
  strictSchema: 'log', // we need to disable this for now so that unknown keywords don't cause issues. TODO: find a workaround
  strictNumbers: true,
  strictTypes: true,
  strictTuples: true,
  strictRequired: true,
};

const ajv = addFormats(new Ajv(defaults));

export function validate(json: string | unknown, schema: JSONSchema7) {
  if (schema === undefined)
    throw new InternalServerError(`Cannot validate data - no schema defined!`);
  const input = typeof json === 'string' ? JSON.parse(json) : json;

  // remove all null values from the data and schema
  const fixedSchema = removeNulls(schema as SourceObject);
  const fixedInput = removeNulls(input);

  // TODO: add option to trigger to force an exception when data is undefined
  if (input === undefined || input === null) return;

  const validateFn = ajv.compile(fixedSchema);
  const res = validateFn(fixedInput);

  if (!res) {
    throw new ValidationError('Validation failed - see details for errors', {
      errors: validateFn.errors,
      input: fixedInput ?? 'undefined',
      schema: fixedSchema,
    });
  }
}

export default ajv;
