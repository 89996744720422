import {
  ActionType,
  FailedIntegrationAction,
  HandlerError,
  IntegrationAction,
  IntegrationActionLifecycleNotification,
  IntegrationDefinition,
  IntegrationDefinitionReference,
  IntegrationEvent,
  WorkflowNodeError,
  WorkflowNodeResult,
} from '../../api/interface/integration-service';
import { FullQueueAction } from '../../aws-action-queue/src/interface-public';
import { SourceObject } from '../../json-data-mapper/src/interface';
import {
  ConfigurationSchema,
  IntegrationModule,
  IntegrationOperation,
  IntegrationOperationPrototype,
  RequestAuthorizer,
} from '../../integrations/src/interface';
import { InternalServerError } from './error';
import { isEmpty, isMatch as lodashIsMatch, merge, pick } from 'lodash';
import { parseFromSNSEvent, parseFromSQSRecord } from './aws-util';
import { SNSMessage, SQSEvent } from 'aws-lambda';
import * as dateFns from 'date-fns';
import { logRecordFor } from './logger';
import { JSONSchema7 } from 'json-schema';
import {
  CustomContentMediaType,
  JsonSchemaDefinition,
} from '../../api/interface/files';
import { resolveObjectPath } from '@apus/common-lib/json-data-mapper/src/schema-utils';
import { LogicEngine } from 'json-logic-engine';
import { AxiosError } from 'axios';

const errorLog = logRecordFor('data-utils', 'error');
const debugLog = logRecordFor('data-utils', 'debug');

export function range(min: number, max: number) {
  return [...Array(max + 1).keys()].slice(min);
}

export function tryToGetFinalResult(
  workflowResults: WorkflowNodeResult[]
): WorkflowNodeResult | undefined {
  if (workflowResults.length === 0) return undefined;
  return workflowResults[workflowResults.length - 1];
}

export function getFinalResult(workflowResults: WorkflowNodeResult[]) {
  if (workflowResults.length === 0)
    throw new InternalServerError(
      'Workflow yielded no results - this should not happen'
    );

  return workflowResults[workflowResults.length - 1];
}

export function getErrorResult(action: FullQueueAction<IntegrationEvent>) {
  if (action.content.output === undefined) return undefined;

  return getFinalResult(action.content.output.workflowResults).error;
}

export function asQueueId({
  tenantId,
  integrationId,
}: {
  tenantId: string;
  integrationId: string;
}): string {
  return `${tenantId}#${integrationId}`;
}

export function fromQueueId(queueId: string): {
  tenantId: string;
  integrationId: string;
} {
  const [tenantId, integrationId] = queueId.split('#');

  if (tenantId === undefined) {
    errorLog(`Cannot parse queueId "${queueId}" - tenantId not found`);
    throw new InternalServerError(
      `Cannot parse queueId "${queueId}" - tenantId not found`
    );
  }

  if (integrationId === undefined) {
    errorLog(`Cannot parse queueId "${queueId}" - integrationId not found`);
    throw new InternalServerError(
      `Cannot parse queueId "${queueId}" - integrationId not found`
    );
  }

  return { tenantId, integrationId };
}

export function sanitizeAction(action: any) {
  return shrinkLargeArrays(action);
}

export function asIntegrationAction(
  original: FullQueueAction<IntegrationEvent>
): IntegrationAction {
  const action = sanitizeAction(original);

  return {
    actionId: action.actionId,
    actionType: action.actionType as ActionType,
    effectiveOn: action.effectiveOn,
    queueId: action.queueId,
    createdFromActionId: action.createdFromActionId,
    retriedFromActionId: action.retriedFromActionId,
    retriedInActionId: action.retriedInActionId,
    retryCount: action.retryCount,
    status: action.status,
    content: action.content,
    tenantId: action.content.tenantId,
    tenantName: action.content.eventContext?.tenantName,
    correlationId: action.correlationId,
    integration: {
      integrationId: action.content.integration.integrationId,
      name: action.content.integration.name,
      triggerType: action.content.integration.triggerType,
    },
  };
}

export function asIntegrationActionLifecycleNotification(
  action: FullQueueAction<IntegrationEvent>
): IntegrationActionLifecycleNotification {
  const error = getErrorResult(action);

  return {
    actionId: action.actionId,
    actionType: action.actionType as ActionType,
    effectiveOn: action.effectiveOn,
    queueId: action.queueId,
    createdFromActionId: action.createdFromActionId,
    retriedFromActionId: action.retriedFromActionId,
    retriedInActionId: action.retriedInActionId,
    correlationId: action.correlationId,
    retryCount: action.retryCount,
    status: action.status,
    integration: {
      integrationId: action.content.integration.integrationId,
      name: action.content.integration.name,
      triggerType: action.content.integration.triggerType,
    },
    tenantId: action.content.tenantId,
    tenantName: action.content.eventContext?.tenantName,
    ...(error !== undefined && {
      error: {
        message: error.message,
        nonRetryable: error.nonRetryable,
        error: error.error,
      },
    }),
  };
}

export function sanitizeAxiosError(
  message: string,
  error: AxiosError | undefined
): HandlerError {
  if (error === undefined)
    return {
      message,
      name: 'AxiosError',
    };

  try {
    return {
      body: {
        code: error.code,
        status: error.status,
        config: {
          params: error.config?.params,
          method: error.config?.method,
          url: error.config?.url,
          data: error.config?.data,
        },
      },
      message,
      name: 'AxiosError',
      statusCode: String(error.status),
    };
  } catch (e) {
    errorLog('Could not sanitize AxiosError', { e, axiosError: error });
    return {
      ...error,
    };
  }
}

export function sanitizeError(
  errorResult: WorkflowNodeError | undefined
): WorkflowNodeError {
  if (errorResult === undefined)
    return {
      message: 'Action contained no error result - this should not happen',
    };

  if (errorResult?.error?.name === 'AxiosError')
    return {
      ...errorResult,
      error: sanitizeAxiosError(
        errorResult.message,
        errorResult.error as unknown as AxiosError
      ),
    };

  return {
    ...errorResult,
  };
}

export function asFailedIntegrationAction(
  action: FullQueueAction<IntegrationEvent>
): FailedIntegrationAction {
  const errorResult = getErrorResult(action);

  return {
    actionId: action.actionId,
    actionType: action.actionType as ActionType,
    effectiveOn: action.effectiveOn,
    queueId: action.queueId,
    retriedFromActionId: action.retriedFromActionId,
    retriedInActionId: action.retriedInActionId,
    retryCount: action.retryCount,
    correlationId: action.correlationId,
    status: action.status,
    integration: {
      integrationId: action.content.integration.integrationId,
      name: action.content.integration.name,
      triggerType: action.content.integration.triggerType,
    },
    tenantId: action.content.tenantId,
    tenantName: action.content.eventContext?.tenantName,
    content: action.content,
    error: sanitizeError(errorResult),
  };
}

export function isMatch(
  input: unknown | undefined,
  source: unknown | undefined
): boolean {
  if (input === undefined && source === undefined) return true;

  if (
    (input === undefined && source !== undefined) ||
    (input !== undefined && source === undefined)
  )
    return false;

  if (
    (Array.isArray(input) && !Array.isArray(source)) ||
    (!Array.isArray(input) && Array.isArray(source))
  )
    return false;

  if (Array.isArray(input) && Array.isArray(source)) {
    return input.reduce(
      (res: boolean, currentInput: SourceObject, idx: number) => {
        if (!res) return res;
        const currentSource = source[idx];
        return lodashIsMatch(currentInput, currentSource);
      },
      true
    );
  }

  if (input === undefined || input === null) return false;
  if (source === undefined || source === null) return false;

  return lodashIsMatch(input, source);
}

export function minimiseArrays(obj: SourceObject): SourceObject {
  return obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? [
        // return only one item to make schema creation easier - choose the one with most keys
        minimiseArrays(
          obj
            .sort((a, b) => Object.keys(b).length - Object.keys(a).length)
            .shift()
        ),
      ]
    : Object.keys(obj).reduce(
        (acc, x) =>
          Object.assign(acc, {
            [x]: minimiseArrays(obj[x] as SourceObject),
          }),
        {}
      );
}

export function shrinkLargeArrays(obj: any): any {
  return obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.length > 1000
      ? []
      : obj.map(item => shrinkLargeArrays(item))
    : Object.keys(obj).reduce(
        (acc, x) =>
          Object.assign(acc, {
            [x]: shrinkLargeArrays(obj[x]),
          }),
        {}
      );
}

export function removeEmpties(obj: SourceObject): SourceObject {
  return obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.filter(item => item != null).map(item => removeEmpties(item))
    : Object.keys(obj)
        .filter(k => {
          if (obj[k] === undefined) return false;
          if (obj[k] === null) return false;
          if (typeof obj[k] === 'object')
            return Object.keys(obj[k] as object).length > 0;
          if (typeof obj[k] === 'string')
            return (obj[k] as string).trim() !== '';
          return true;
        })
        .reduce(
          (acc, x) =>
            Object.assign(acc, {
              [x]: removeEmpties(obj[x] as SourceObject),
            }),
          {}
        );
}

export function removeNulls(obj: SourceObject): SourceObject {
  return obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.filter(item => item != null).map(item => removeNulls(item))
    : Object.keys(obj)
        .filter(k => {
          return obj[k] !== null;
        })
        .reduce(
          (acc, x) =>
            Object.assign(acc, {
              [x]: removeNulls(obj[x] as SourceObject),
            }),
          {}
        );
}

export function removeUndefinedAndNulls(obj: any): any {
  return obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.filter(item => item != null).map(item => removeNulls(item))
    : Object.keys(obj)
        .filter(k => {
          return obj[k] != null;
        })
        .reduce(
          (acc, x) =>
            Object.assign(acc, {
              [x]: removeNulls(obj[x]),
            }),
          {}
        );
}

export function toLowerCase(obj: any): any {
  return obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.filter(item => item != null).map(item => toLowerCase(item))
    : Object.keys(obj).reduce((acc, x) => {
        const value = obj[x];
        if (typeof value === 'string') {
          return Object.assign(acc, {
            [x]: value.toLowerCase(),
          });
        }
        return Object.assign(acc, {
          [x]: toLowerCase(obj[x]),
        });
      }, {});
}

export function toUpperCase(obj: any): any {
  return obj !== Object(obj)
    ? obj
    : Array.isArray(obj)
    ? obj.filter(item => item != null).map(item => toUpperCase(item))
    : Object.keys(obj).reduce((acc, x) => {
        const value = obj[x];
        if (typeof value === 'string') {
          return Object.assign(acc, {
            [x]: value.toUpperCase(),
          });
        }
        return Object.assign(acc, {
          [x]: toUpperCase(obj[x]),
        });
      }, {});
}

export const asOperations = (
  modules: IntegrationModule[] | undefined
): IntegrationOperation[] => {
  if (modules === undefined) return [];

  return modules
    .map((module): IntegrationOperation[] => Object.values(module.operations))
    .flat();
};

export const asPrototypes = (
  modules: IntegrationModule[] | undefined
): IntegrationOperationPrototype[] => {
  if (modules === undefined) return [];

  return modules
    .map((module): IntegrationOperationPrototype[] =>
      Object.values(module.operationPrototypes)
    )
    .flat();
};

export const asAuthorizers = (
  modules: IntegrationModule[] | undefined
): RequestAuthorizer[] => {
  if (modules === undefined) return [];

  return modules
    .map((module): RequestAuthorizer[] => {
      if (module.requestAuthorizers === undefined) return [];
      return Object.values(module.requestAuthorizers);
    })
    .flat();
};

/**
 * Parse included action lifecycle notifications from the given SQS -event
 *
 * @param sqsEvent
 */
export function parseActionNotifications(
  sqsEvent: SQSEvent
): IntegrationActionLifecycleNotification[] {
  return sqsEvent.Records.map(record => {
    return parseFromSNSEvent<IntegrationActionLifecycleNotification>(
      parseFromSQSRecord<SNSMessage>(record)
    );
  });
}

export function asIntegrationDefinitionReference(
  integration: IntegrationDefinition
): IntegrationDefinitionReference {
  return {
    integrationId: integration.integrationId,
    name: integration.name,
    triggerType: integration.workflow.trigger.nodeType,
  };
}

export function isPast(s: string | Date | undefined) {
  if (s === undefined) return false;

  const d: Date = typeof s === 'string' ? new Date(s) : s;

  return dateFns.isPast(d);
}

export function isPastOrNow(s: string | Date | undefined) {
  if (s === undefined) return false;

  const d: Date = typeof s === 'string' ? new Date(s) : s;

  if (d === new Date()) return true;

  return dateFns.isPast(d);
}

export function isPastOrToday(s: string | Date | undefined) {
  if (s === undefined) return false;

  const d: Date = typeof s === 'string' ? new Date(s) : s;

  if (dateFns.isToday(d)) return true;

  return dateFns.isPast(d);
}

export function aggregateConfiguration<T>(
  configuration: T | undefined,
  runtimeConfiguration: T | undefined
): T | undefined {
  if (configuration === undefined && runtimeConfiguration === undefined)
    return undefined;

  if (configuration === undefined) return runtimeConfiguration;

  return merge({}, configuration, runtimeConfiguration);
}

export function splitConfigurationSchema(
  prototypeConfigurationSchema?: ConfigurationSchema
): {
  configurationSchema?: ConfigurationSchema;
  runtimeConfigurationSchema?: ConfigurationSchema;
} {
  if (prototypeConfigurationSchema === undefined) return {};

  const runtimeConfigurationKeys = Object.keys(
    prototypeConfigurationSchema.properties
  ).filter(key => {
    const configuration = prototypeConfigurationSchema.properties[key];
    return configuration.runtime === true;
  });

  const configurationKeys = Object.keys(
    prototypeConfigurationSchema.properties
  ).filter(key => {
    const configuration = prototypeConfigurationSchema.properties[key];
    return configuration.runtime !== true;
  });

  const runtimeConfigurationProperties = pick(
    prototypeConfigurationSchema.properties,
    runtimeConfigurationKeys
  );
  const runtimeConfigurationRequired =
    prototypeConfigurationSchema.required === undefined
      ? []
      : prototypeConfigurationSchema.required.filter(key => {
          return runtimeConfigurationKeys.includes(key);
        });

  const configurationProperties = pick(
    prototypeConfigurationSchema.properties,
    configurationKeys
  );
  const configurationRequired =
    prototypeConfigurationSchema.required === undefined
      ? []
      : prototypeConfigurationSchema.required.filter(key => {
          return configurationKeys.includes(key);
        });

  return {
    ...(!isEmpty(configurationProperties) && {
      configurationSchema: {
        ...prototypeConfigurationSchema,
        properties: configurationProperties,
        required: configurationRequired,
      },
    }),
    ...(!isEmpty(runtimeConfigurationProperties) && {
      runtimeConfigurationSchema: {
        ...prototypeConfigurationSchema,
        properties: runtimeConfigurationProperties,
        required: runtimeConfigurationRequired,
      },
    }),
  };
}

export function generateConfiguration(
  configuration: SourceObject | undefined,
  configurationSchema: ConfigurationSchema | undefined
): SourceObject | undefined {
  if (
    configurationSchema === undefined ||
    Object.keys(configurationSchema.properties).length === 0
  )
    return configuration;

  return {
    ...configuration,
    ...Object.keys(configurationSchema.properties).reduce<SourceObject>(
      (res, cur) => {
        const prop = configurationSchema.properties[cur];
        return {
          ...res,
          [cur]: prop.const !== undefined ? prop.const : configuration?.[cur],
        };
      },
      {}
    ),
  };
}

export function toJsonSchemaDefinitionOrUndefined(
  schema: JSONSchema7 | undefined
): JsonSchemaDefinition | undefined {
  if (schema === undefined) return undefined;
  return {
    content: {
      jsonSchema: schema,
    },
    contentType: CustomContentMediaType.JsonSchema,
  };
}

export function toJsonSchemaDefinition(
  schema: JSONSchema7
): JsonSchemaDefinition {
  return {
    content: {
      jsonSchema: schema,
    },
    contentType: CustomContentMediaType.JsonSchema,
  };
}

export function fromJsonSchemaDefinition(
  schema: JsonSchemaDefinition
): JSONSchema7 {
  if (schema.contentType !== CustomContentMediaType.JsonSchema)
    throw new Error(
      'Cannot parse schema definition - wrong content type ' +
        schema.contentType
    );
  return schema.content.jsonSchema;
}

function makeJsonLogicVarFixer(schema: JSONSchema7) {
  return function fixJsonLogicVars(obj: any): any {
    return obj !== Object(obj)
      ? obj
      : Array.isArray(obj)
      ? obj.filter(item => item != null).map(item => fixJsonLogicVars(item))
      : Object.keys(obj).reduce((acc, x) => {
          if (
            x === 'var' &&
            typeof obj[x] === 'string' &&
            obj[x].startsWith('/')
          ) {
            const path = resolveObjectPath(schema, obj[x]);
            return Object.assign(acc, {
              [x]: fixJsonLogicVars(path),
            });
          }
          return Object.assign(acc, {
            [x]: fixJsonLogicVars(obj[x]),
          });
        }, {});
  };
}

export function buildJsonLogicRunner<T = any>(
  condition: string,
  schema: JSONSchema7,
  options?: {
    caseInsensitive?: boolean;
  }
) {
  const fixVars = makeJsonLogicVarFixer(schema);

  const logic = new LogicEngine().build(fixVars(JSON.parse(condition)));

  return function (input: T) {
    const processedInput =
      options?.caseInsensitive === true ? toUpperCase(input) : input;

    const result = logic(processedInput);

    debugLog(`Running json logic`, { condition, result, processedInput });

    if (typeof result !== 'boolean') {
      throw new InternalServerError(`Condition does not return a boolean`, {
        condition,
        result,
      });
    }

    return result;
  };
}

export function removeDuplicateSlashes(s: string) {
  return s.replace(/\/\/+/g, '/');
}

export function removeLeadingSlashes(s: string) {
  return s.replace(/^\/+/g, '');
}

export function removeTrailingSlashes(s: string) {
  return s.replace(/\/+$/g, '');
}

export function asUrl(...tokens: string[]) {
  return tokens.reduce<string>((res, cur) => {
    if (isEmpty(res)) return cur;
    return removeTrailingSlashes(res) + '/' + removeLeadingSlashes(cur);
  }, '');
}
