import { JSONSchema7, JSONSchema7TypeName } from 'json-schema';
import { faker } from '@faker-js/faker';
import {
  minimiseArrays,
  removeEmpties,
  removeNulls,
} from '../../utils/src/data-utils';

function resolveSchemaType(value: unknown): JSONSchema7TypeName {
  if (Array.isArray(value)) return 'array';

  switch (typeof value) {
    case 'object':
      return 'object';
    case 'bigint':
      return 'number';
    case 'string':
      return 'string';
    case 'boolean':
      return 'boolean';
    case 'number':
      return 'number';
    default:
      throw new Error(
        `Unsupported type '${typeof value}' for JSON Schema -generation`
      );
  }
}

function generatePrimitive(schema: JSONSchema7, initValue: any) {
  if (schema.const) {
    return schema.const;
  } else if (schema.default === undefined && Array.isArray(schema.enum)) {
    return schema.enum[0];
  } else if (
    schema.default === undefined &&
    schema.type === 'string' &&
    schema.format !== undefined
  ) {
    switch (schema.format) {
      case 'date-time':
        return new Date().toISOString();
      case 'time':
        return new Date().toISOString().split('T')[1];
      case 'date':
        return new Date().toISOString().split('T')[0];
      case 'duration':
        return 'P3D';
      case 'idn-email':
      case 'email':
        return 'test.person@test.fi';
      case 'ipv4':
        return '192.168.1.1';
      case 'ipv6':
        return '2001:0db8:85a3:0000:0000:8a2e:0370:7334';
      case 'uuid':
        return '00000000-0000-0000-0000-000000000000';
      case 'iri':
      case 'uri':
        return 'foo://example.com:8042';
      case 'json-pointer':
        return '/properties/name';
      case 'relative-json-pointer':
        return 'properties/name';
      case 'regex':
        return '.*?';
      default:
        return initValue;
    }
  } else if (schema.default === undefined) {
    return initValue;
  }
  return schema.default;
}

function generateObject(
  schema: JSONSchema7,
  useFaker = true,
  pointer: string
): unknown {
  // TODO: use default value or example

  if (schema.properties === undefined) return {};

  return Object.keys(schema.properties).reduce((res, key) => {
    const childSchema = schema.properties?.[key] as JSONSchema7;

    if (childSchema === undefined)
      throw new Error(
        `getTemplate: cannot traverse object property - schema missing`
      );

    return {
      ...res,
      [key]: generateJson(
        childSchema,
        useFaker,
        `${pointer}/properties/${key}`
      ),
    };
  }, {});
}

function generateArray(
  schema: JSONSchema7,
  useFaker = true,
  pointer: string
): unknown[] {
  // TODO: use default value or example

  if (schema.type !== 'array') throw new Error(`getTemplate: not an array`);

  if (schema.items == null) {
    throw new Error(`getTemplate: missing schema for array`);
  }

  if (Array.isArray(schema.items)) {
    throw new Error(
      `getTemplate: unsupported schema for array ${JSON.stringify(
        schema.items
      )}`
    );
  }

  // TODO: min items?

  return [
    generateJson(schema.items as JSONSchema7, useFaker, `${pointer}/items`),
  ];
}

function generateSchema(data: any): JSONSchema7 {
  const schema: JSONSchema7 = {
    type: resolveSchemaType(data),
  };

  if (schema.type === 'object') {
    schema.properties = Object.keys(data).reduce((res, key) => {
      return {
        ...res,
        [key]: generateSchema(data[key]),
      };
    }, {});
  }

  if (schema.type === 'array' && data.length === 1) {
    schema.items = generateSchema(data[0]);
  } else if (schema.type === 'array') {
    schema.items = data.map(generateSchema);
  }

  return schema;
}

function generateJson(
  schema: JSONSchema7,
  useFaker = true,
  pointer: string = '#'
) {
  if (schema == null) {
    throw new Error(`generateJson: missing schema}`);
  }
  if (pointer == null) {
    throw new Error('Missing pointer');
  }

  const type = schema.type as string;

  if (type === undefined)
    throw new Error(
      `generateJson: type is undefined: ${JSON.stringify(schema)}`
    );

  switch (type) {
    case null:
      return generatePrimitive(schema, null);
    case 'string':
      return generatePrimitive(schema, useFaker ? faker.random.words() : '');
    case 'number':
      return generatePrimitive(
        schema,
        useFaker ? faker.number.float({ precision: 0.001 }) : 0
      );
    case 'integer':
      return generatePrimitive(schema, useFaker ? faker.number.int() : 0);
    case 'boolean':
      return generatePrimitive(
        schema,
        useFaker ? faker.datatype.boolean() : true
      );
    case 'object':
      return generateObject(schema, useFaker, pointer);
    case 'array':
      return generateArray(schema, useFaker, pointer);
  }
}

function resolveSchema(
  schema: JSONSchema7,
  pointer: string | undefined = undefined,
  pointerStack: string[] = ['']
): JSONSchema7 | undefined {
  if (pointer === undefined && pointerStack.length === 1) return schema;
  if ((pointer ?? '/') === [...pointerStack].join('/')) return schema;

  if (schema.type === 'object' && schema.properties !== undefined) {
    for (const prop of Object.keys(schema.properties)) {
      const result = resolveSchema(
        schema.properties?.[prop] as JSONSchema7,
        `${pointer}`,
        [...pointerStack, 'properties', `${prop}`]
      );
      if (result !== undefined) return result;
    }
  } else if (schema.type === 'array' && schema.items !== undefined) {
    return resolveSchema(schema.items as JSONSchema7, `${pointer}`, [
      ...pointerStack,
      'items',
    ]);
  }

  return undefined;
}

export function eachSchema(
  schema: JSONSchema7,
  callback: (subSchema: JSONSchema7, pointer: string) => void,
  pointerStack: string[] = []
) {
  callback(
    schema,
    pointerStack.length > 0 ? '/' + [...pointerStack].join('/') : '#'
  );

  if (schema.type === 'object' && schema.properties !== undefined) {
    for (const prop of Object.keys(schema.properties)) {
      eachSchema(schema.properties?.[prop] as JSONSchema7, callback, [
        ...pointerStack,
        'properties',
        `${prop}`,
      ]);
    }
  } else if (schema.type === 'array' && schema.items !== undefined) {
    eachSchema(schema.items as JSONSchema7, callback, [
      ...pointerStack,
      'items',
    ]);
  }
}

export function getSchema(schema: JSONSchema7, pointer = '/') {
  if (pointer === '#' || pointer === '' || pointer === '/')
    return resolveSchema(schema);
  return resolveSchema(schema, pointer);
}

export function jsonFromSchema(schema: JSONSchema7, useFaker = true) {
  return generateJson(schema, useFaker);
}

export function schemaFromJson(data: any) {
  // clean the data before generating the schema
  const cleansed = minimiseArrays(
    removeEmpties(
      removeNulls(typeof data === 'string' ? JSON.parse(data) : data)
    )
  );

  return generateSchema(cleansed);
}
