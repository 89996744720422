import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { AWSConfiguration } from '@apus/common-lib/integrations/src/aws/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReplayIcon from '@mui/icons-material/Replay';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { v4 as uuid } from 'uuid';

import { ModuleConfigurationFormProps } from './interface';

const AWSConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<AWSConfiguration>();

  useEffect(() => {
    form.reset({
      accountId: '',
      externalId: uuid(),
      roleName: '',
    });

    if (moduleConfiguration !== undefined) {
      form.reset(
        moduleConfiguration.configuration as unknown as AWSConfiguration
      );
    }
  }, [form, moduleConfiguration]);

  const onCopyExternalId = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const value = form.getValues('externalId');
    if (value !== undefined) {
      await navigator.clipboard.writeText(String(value));
    }
  };

  const onSubmit = async (formData: AWSConfiguration) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure AWS Web Services -connection
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="accountId"
            form={form}
            label="AWS Account id"
            defaultValue={''}
            helperText={
              'The integration service will use this to connect to the given AWS Account'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="roleName"
            form={form}
            label="AWS Role name"
            defaultValue={''}
            helperText={
              'The integration service will try to assume this role and use that to obtain temporary credentials'
            }
            disabled={!edit}
          />
          <Grid item>
            <HookFormTextInput
              name="externalId"
              form={form}
              inputProps={{ readOnly: true }}
              label="AWS External id"
              defaultValue={''}
              helperText={
                "The external id is used as a unique identifier for the 'assumeRole' requests"
              }
              disabled={!edit}
            />
            <IconButton
              aria-label="Copy id to clipboard"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={onCopyExternalId}
            >
              <ContentCopyIcon />
            </IconButton>
            <IconButton
              aria-label="Generate new id"
              style={{ marginLeft: 16 }}
              onClick={() => form.setValue('externalId', uuid())}
            >
              <ReplayIcon />
            </IconButton>
          </Grid>
        </Stack>
      </Grid>
      <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
        Save
      </Button>
    </Grid>
  );
};

export default AWSConfigurationForm;
