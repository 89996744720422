import React, { JSX } from 'react';

import {
  IntegrationNode,
  IntegrationNodeType,
} from '@apus/common-lib/integration-engine/src/interface';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ContentPasteGoTwoToneIcon from '@mui/icons-material/ContentPasteGoTwoTone';

import WorkflowNodeIcon from './WorkflowNodeIcon';
import { omit } from 'lodash';

export const newNodeId = '1';

export interface NewNode {
  id: string;
  name: string;
  nodeType: IntegrationNodeType;
  prev?: string;
  isPasted?: boolean;
}

interface Props {
  disabled?: boolean;
  parent?: IntegrationNode;
  onAddNewNode: (node: NewNode) => void;
}

function AddErrorTrigger({
  disabled,
  onAddNewNode,
  parent,
}: Props): JSX.Element {
  const node = {
    id: newNodeId,
    name: `Create new error -trigger`,
    nodeType: IntegrationNodeType.ErrorTrigger,
  };

  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={() => onAddNewNode(node)}
      startIcon={<WorkflowNodeIcon node={node} />}
      disabled={disabled || parent !== undefined}
    >
      Error -trigger
    </Button>
  );
}

function AddWebhookTrigger({
  disabled,
  onAddNewNode,
  parent,
}: Props): JSX.Element {
  const node = {
    id: newNodeId,
    name: `Create new webhook -trigger`,
    nodeType: IntegrationNodeType.WebhookTrigger,
  };

  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={() => onAddNewNode(node)}
      startIcon={<WorkflowNodeIcon node={node} />}
      disabled={disabled || parent !== undefined}
    >
      Webhook -trigger
    </Button>
  );
}

function AddPollingTrigger({
  disabled,
  onAddNewNode,
  parent,
}: Props): JSX.Element {
  const node = {
    id: newNodeId,
    name: `Create new polling -trigger`,
    nodeType: IntegrationNodeType.PollingTrigger,
  };

  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={() => onAddNewNode(node)}
      startIcon={<WorkflowNodeIcon node={node} />}
      disabled={disabled || parent !== undefined}
    >
      Polling -trigger
    </Button>
  );
}

function AddApiEndpointTrigger({
  disabled,
  onAddNewNode,
  parent,
}: Props): JSX.Element {
  const node = {
    id: newNodeId,
    name: `Create new api endpoint -trigger`,
    nodeType: IntegrationNodeType.ApiEndpointTrigger,
  };
  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={() => onAddNewNode(node)}
      startIcon={<WorkflowNodeIcon node={node} />}
      disabled={disabled || parent !== undefined}
    >
      API endpoint -trigger
    </Button>
  );
}

function AddOperationNode({
  disabled,
  onAddNewNode,
  parent,
}: Props): JSX.Element {
  const node = {
    id: newNodeId,
    name: `Create new operation -node`,
    nodeType: IntegrationNodeType.Operation,
  };

  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={() =>
        onAddNewNode({
          ...node,
          prev: parent?.id,
        })
      }
      startIcon={<WorkflowNodeIcon node={node} />}
      disabled={disabled || parent === undefined}
    >
      Operation
    </Button>
  );
}

function PasteNode({ disabled, onAddNewNode, parent }: Props): JSX.Element {
  const onClick = async () => {
    const clipboard = await navigator.clipboard.readText();
    if (clipboard !== undefined && clipboard.trim() !== '') {
      const node = {
        ...omit(JSON.parse(clipboard), 'next'),
        id: newNodeId,
      } as IntegrationNode;

      if (node.nodeType === undefined)
        throw new Error(`Cannot create node - no nodeType found`);

      if (
        parent !== undefined &&
        [
          IntegrationNodeType.WebhookTrigger,
          IntegrationNodeType.PollingTrigger,
          IntegrationNodeType.ApiEndpointTrigger,
          IntegrationNodeType.ErrorTrigger,
        ].includes(node.nodeType)
      )
        throw new Error(
          `Cannot create node - pasted node is a trigger, but workflow already has a trigger`
        );

      onAddNewNode({
        ...node,
        prev: parent?.id,
        isPasted: true,
      });
    }
  };

  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={onClick}
      startIcon={<ContentPasteGoTwoToneIcon color={'warning'} />}
      disabled={disabled || parent === undefined}
    >
      Paste
    </Button>
  );
}

function AddDispatchNode({
  disabled,
  onAddNewNode,
  parent,
}: Props): JSX.Element {
  const node = {
    id: newNodeId,
    name: `Create new dispatch -node`,
    nodeType: IntegrationNodeType.Dispatch,
  };
  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={() =>
        onAddNewNode({
          ...node,
          prev: parent?.id,
        })
      }
      startIcon={<WorkflowNodeIcon node={node} />}
      disabled={disabled || parent === undefined}
    >
      Dispatch
    </Button>
  );
}

function AddApiResultNode({
  disabled,
  onAddNewNode,
  parent,
}: Props): JSX.Element {
  const node = {
    id: newNodeId,
    name: `Create new api result -node`,
    nodeType: IntegrationNodeType.ApiResult,
  };

  return (
    <Button
      variant={'outlined'}
      color={'secondary'}
      onClick={() =>
        onAddNewNode({
          ...node,
          prev: parent?.id,
        })
      }
      startIcon={<WorkflowNodeIcon node={node} />}
      disabled={disabled || parent === undefined}
    >
      API result
    </Button>
  );
}

const AddWorkflowNode = (props: Props): JSX.Element => {
  return (
    <Stack
      spacing={1}
      direction={'row'}
      marginTop={'12px'}
      alignItems={'center'}
    >
      <Typography variant={'body1'} fontWeight={'bold'}>
        Add to workflow:{' '}
      </Typography>
      {props.parent === undefined && (
        <>
          <AddErrorTrigger {...props} />
          <AddWebhookTrigger {...props} />
          <AddPollingTrigger {...props} />
          <AddApiEndpointTrigger {...props} />
        </>
      )}
      {props.parent !== undefined && (
        <>
          <PasteNode {...props} />
          <AddOperationNode {...props} />
          <AddDispatchNode {...props} />
          <AddApiResultNode {...props} />
        </>
      )}
    </Stack>
  );
};

export default AddWorkflowNode;
