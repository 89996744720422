import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { DelfoiConfiguration } from '@apus/common-lib/integrations/src/delfoi/interface';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';

const AWSConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<DelfoiConfiguration>();

  useEffect(() => {
    form.reset({
      url: '',
      username: '',
      password: '',
    });

    if (moduleConfiguration !== undefined) {
      form.reset(
        moduleConfiguration.configuration as unknown as DelfoiConfiguration
      );
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (formData: DelfoiConfiguration) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Delfoi Planner -connection
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="url"
            form={form}
            label="Base url for the API"
            defaultValue={''}
            helperText={
              'This will be used as the basis for individual REST API endpoints'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="username"
            form={form}
            label="Basic auth username"
            defaultValue={''}
            helperText={
              'This will be used for identifying the user - this is provided by the Delfoi'
            }
            disabled={!edit}
          />
          <HookFormTextInput
            name="password"
            form={form}
            label="Basic auth password"
            defaultValue={''}
            helperText={
              'This will be used for authenticating the user - this is provided by the Delfoi'
            }
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
        Save
      </Button>
    </Grid>
  );
};

export default AWSConfigurationForm;
