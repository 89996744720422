import { BankingFileTemplate } from '@apus/common-lib/api/interface/banking-service';
import { AxiosInstance } from 'axios';

export interface BankingService {
  createTemplate(template: BankingFileTemplate): Promise<void>;

  updateTemplate(template: BankingFileTemplate): Promise<void>;

  getTemplate(templateId: string): Promise<BankingFileTemplate>;

  listTemplates(): Promise<BankingFileTemplate[]>;
}

export const createBankingService = (client: AxiosInstance): BankingService => {
  return {
    createTemplate(template: BankingFileTemplate): Promise<void> {
      return client.post(`/banking/templates`, template);
    },
    updateTemplate(template: BankingFileTemplate): Promise<void> {
      return client.put(`/banking/templates/${template.id}`, template);
    },
    getTemplate(templateId: string): Promise<BankingFileTemplate> {
      return client
        .get<BankingFileTemplate>(`/banking/templates/${templateId}`)
        .then(({ data }) => data);
    },
    listTemplates(): Promise<BankingFileTemplate[]> {
      return client
        .get<BankingFileTemplate[]>(`/banking/templates`)
        .then(({ data }) => data);
    },
  };
};
