import React from 'react';

import { SupportedMappingSchemaDefinition } from '@apus/common-lib/api/interface/files';
import { nodeHandlerFactory } from '@apus/common-lib/integration-engine/src/handler-factory';
import { DispatchNode } from '@apus/common-lib/integration-engine/src/interface';
import { JSONSchema7 } from 'json-schema';

import { SupportedMappingSchemaFileEdit } from '../../../../types/stored-files';
import { asSchemaDefinition } from '../../../../utils/stored-files-helpers';
import DefineMapping from '../../../mapping/DefineMapping';

interface Props {
  integrationContextSchema: JSONSchema7;
  node?: DispatchNode;
  onChange: (mapping?: SupportedMappingSchemaDefinition) => void;
}

const DefineOperationMapping = ({
  integrationContextSchema,
  node,
  onChange,
}: Props): JSX.Element => {
  if (node === undefined) return <></>;

  if (node.triggerSchema === undefined)
    throw new Error(`Cannot define dispatch mapping - missing trigger schema`);

  return (
    <DefineMapping
      // TODO: check if the edit-format is needed
      value={node.mappingSchema as SupportedMappingSchemaFileEdit}
      sourceSchema={
        nodeHandlerFactory(
          node,
          integrationContextSchema
        ).getFullTransformedInputSchema() ?? {}
      }
      targetSchema={node.triggerSchema.content.jsonSchema}
      onChange={mappingSchema => {
        onChange(
          asSchemaDefinition<SupportedMappingSchemaDefinition>(mappingSchema)
        );
      }}
    />
  );
};

export default DefineOperationMapping;
