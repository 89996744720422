import { useMemo } from 'react';

import { BadRequest } from '@apus/common-lib/utils/src/error';

import useAxios from '../hooks/useAxios';
import {
  createTenantService,
  TenantService,
} from '../api-client/tenant-service';

const ErrorThrowingTenantService = (message: string): TenantService => ({
  createTenant() {
    throw new BadRequest(message);
  },
  getTenant() {
    throw new BadRequest(message);
  },
  listTenants() {
    throw new BadRequest(message);
  },
  updateTenant() {
    throw new BadRequest(message);
  },
});

export default function useTenantService(): TenantService {
  const axiosInstance = useAxios();

  return useMemo(() => {
    if (axiosInstance === undefined)
      return ErrorThrowingTenantService('Axios instance could not be defined');

    return createTenantService(axiosInstance);
  }, [axiosInstance]);
}
