import {
  AccessTokenStorage,
  ApiCall,
  EnvironmentConfiguration,
  IntegrationEventStatus,
  Recorder,
  TenantIntegrationClient,
  WorkflowNodeResult,
} from '../../api/interface/integration-service';
import {
  ApiResultHandler,
  ConnectionResolver,
  EventContext,
  HandlerResolver,
  IntegrationDispatcher,
  ModuleConfigurations,
  OperationResolver,
  RequestAuthorizer,
  RunContext,
  WorkflowOperation,
} from '../../integrations/src/interface';
import {
  SupportedMappingSchemaDefinition,
  SupportedSchemaDefinition,
} from '../../api/interface/files';
import { JSONSchema7 } from 'json-schema';
import { MappingFunction } from '@apus/common-lib/json-data-mapper/src/interface';
import { TenantSubscriptions } from '@apus/common-lib/api/interface/tenant-service';

export type UUID = string;

/**
 * @title Integration node type
 * @description Integration is a workflow of interconnected nodes
 */
export enum IntegrationNodeType {
  WebhookTrigger = 'WebhookTrigger',
  PollingTrigger = 'PollingTrigger',
  ErrorTrigger = 'ErrorTrigger',
  ApiEndpointTrigger = 'ApiEndpointTrigger',
  Operation = 'Operation',
  /**
   * Dispatch a new integration event to another integration
   */
  Dispatch = 'Dispatch',
  ApiResult = 'ApiResult',
}

export interface SchemaChrootConfiguration {
  /**
   * Change root path in triggerSchema
   *
   * If enabled, trigger schema root will be re-translated using the chrootSchemaPath.
   *
   * Default is 'false'
   */
  enabled?: boolean;

  /**
   * Use the schema under this path as the new trigger schema root
   */
  schemaPath?: string;
}

export interface TriggerNodeBase {
  /**
   * @title Node id
   * @description Autogenerated UUID
   */
  id: UUID;
  /**
   * @title Unique node name
   * @description Will be used to identify node output in integration context
   */
  name: string;
  /**
   * @title Node description
   * @description Provides in-depth explanation or comment about the node
   */
  description?: string;
  /**
   * @title Id of the next node
   * @description Defines the next step in workflow or is undefined if workflow is to end
   */
  next?: UUID;
  /**
   * @title Optional setting to disable validation on the node
   */
  disableValidation?: boolean;
}

export interface WebhookTriggerNode extends TriggerNodeBase {
  /**
   * @title Node type
   * @description Type of the node
   */
  nodeType: IntegrationNodeType.WebhookTrigger;
  /**
   * Internal trigger cannot be invoked from the rest api
   *
   * By default, webhook triggers are external
   */
  internal?: boolean;
  triggerSchema: SupportedSchemaDefinition;
  authorizer?: RequestAuthorizer;
}

export interface ErrorTriggerNode extends TriggerNodeBase {
  /**
   * @title Node type
   * @description Type of the node
   */
  nodeType: IntegrationNodeType.ErrorTrigger;
  triggerSchema: SupportedSchemaDefinition;
}

export interface ApiEndpointTriggerNode extends TriggerNodeBase {
  nodeType: IntegrationNodeType.ApiEndpointTrigger;
  internal?: boolean;
  triggerSchema: SupportedSchemaDefinition;
  authorizer?: RequestAuthorizer;
}

export interface PollingTriggerNode extends TriggerNodeBase {
  /**
   * @title Node type
   * @description Type of the node
   */
  nodeType: IntegrationNodeType.PollingTrigger;
  /**
   * Cron schedule
   */
  schedule: string;
  /**
   * Operation to run when scheduled
   */
  operation: WorkflowOperation;
}

interface BaseNode {
  /**
   * @title Id of the previous node
   */
  prev: UUID;
  /**
   * @title Node type
   * @description Type of the node
   */
  nodeType: IntegrationNodeType;
  /**
   * @title Node id
   * @description Autogenerated UUID
   */
  readonly id: UUID;
  /**
   * @title Unique node name
   * @description Will be used to identify node output in integration context
   */
  name: string;
  /**
   * @title Node description
   * @description Provides in-depth explanation or comment about the node
   */
  description?: string;
  /**
   * @title Optional setting to disable validation on the node
   */
  disableValidation?: boolean;
}

export interface LinkableNode extends BaseNode {
  /**
   * @title Id of the next node
   * @description Defines the next step in workflow or is undefined if workflow is to end
   */
  next?: UUID;
}

/**
 * Possible transformations to be applied to input data
 *
 * The order of the transformations is fixed:
 *
 * 1. Chroot
 * 2. Iterate
 */
export interface InputTransformations {
  /**
   * Configure chrooting
   *
   * If chrooting is enabled, the input root will be reset to the provided path.
   *
   * This allows us to for instance select an individual array item from an object.
   *
   */
  chroot?: SchemaChrootConfiguration;
  /**
   * Configure iteration
   *
   * If iteration is enabled, the input will be processed one item at a time.
   *
   * This allows us to for instance execute a single operation for all array items.
   *
   * Note: iteration is only allowed when actual input is an array - otherwise an exception is thrown.
   */
  iterate?: boolean;
  /**
   * Configure filtering rule
   *
   * If filtering is enabled, then only items that match the condition are included.
   *
   * Note: filtering is applied to iterated items.
   */
  filter?: FilterCondition;
}

/**
 * Condition to run an operation
 */
export interface FilterCondition {
  /**
   * Configure Json Logic rule as the condition
   */
  condition?: string;
  /**
   * Is condition enabled?
   */
  enabled?: boolean;
}

/**
 * Condition to run an operation
 */
export interface RunCondition {
  /**
   * Configure Json Logic rule as the condition
   */
  condition?: string;
  /**
   * Is condition enabled?
   */
  enabled?: boolean;
}

export interface OperationNode extends LinkableNode {
  nodeType: IntegrationNodeType.Operation;
  operation: WorkflowOperation;
  inputTransformations?: InputTransformations;
  runCondition?: RunCondition;
}

export interface DispatchNode extends LinkableNode {
  nodeType: IntegrationNodeType.Dispatch;
  /**
   * Schema for the trigger into which messages are pushed
   */
  triggerSchema: SupportedSchemaDefinition;
  /**
   * Mapping rules to generate trigger data from current context
   */
  mappingSchema: SupportedMappingSchemaDefinition;
  /**
   * Id of the integration containing the trigger
   */
  integrationId: string;
  inputTransformations?: InputTransformations;
  runCondition?: RunCondition;
  isSynchronous?: boolean;
}

export interface ApiResultNode extends LinkableNode {
  nodeType: IntegrationNodeType.ApiResult;
  /**
   * Schema for the result data
   */
  resultSchema: SupportedSchemaDefinition;
  /**
   * Mapping rules to generate result data
   */
  mappingSchema: SupportedMappingSchemaDefinition;
}

export type IntegrationNode =
  | ApiEndpointTriggerNode
  | WebhookTriggerNode
  | ErrorTriggerNode
  | PollingTriggerNode
  | OperationNode
  | DispatchNode
  | ApiResultNode;

export interface NodeResult {
  started: Date;
  input: unknown | undefined;
  output: unknown | undefined;
  status: IntegrationEventStatus;
  error?: Error;
  callRecord: ApiCall[] | undefined;
}

export interface WorkflowSchemaHandler {
  /**
   * The schema of the data that a workflow node expects to receive - includes the whole integration context
   *
   * This is used when the workflow is run and the current integration context is mapped to suit operation's needs.
   * In effect, this is the contract for the incoming data for the node.
   */
  getFullInputSchema: () => JSONSchema7 | undefined;
  /**
   * The schema of the full data that a workflow node produces - includes the whole integration context
   *
   * This is the contract for outgoing data for the node i.e. what will be added to the integration context
   * after the workflow node is finished.
   */
  getFullOutputSchema: () => JSONSchema7 | undefined;
  /**
   * The schema of the data provided to the actual operation prototype handler
   *
   * Most often this is the same as the operation's input schema but can also be a subset of it - for instance when
   * operation is iterated over a chrooted part of the incoming data.
   */
  getFullTransformedInputSchema: () => JSONSchema7 | undefined;
  /**
   * The full schema of the data outputted by the actual operation prototype handler - includes the whole integration context
   *
   * Most often this is the same as the operation's output schema included but can also be a subset of it - for instance when
   * operation is iterated over a chrooted part of the incoming data.
   */
  getFullTransformedOutputSchema: () => JSONSchema7 | undefined;
  /**
   * The schema of the data outputted by the actual operation prototype handler
   *
   * Most often this is the same as the operation's output schema but can also be a subset of it - for instance when
   * operation is iterated over a chrooted part of the incoming data.
   */
  getTransformedOutputSchema: () => JSONSchema7 | undefined;
  /**
   * The schema of the data outputted by the actual operation prototype handled - with all transformations reversed
   *
   * Most often this is the same as the operation's output schema but can also be a subset of it - for instance when
   * operation is iterated over a chrooted part of the incoming data.
   */
  getOutputSchema: () => JSONSchema7 | undefined;
}

export interface WorkflowRunContext {
  map: MappingFunction;
  operationResolver: OperationResolver;
  handlerResolver: HandlerResolver;
  connectionResolver: ConnectionResolver;
  integrationDispatcher?: IntegrationDispatcher;
  apiResultHandler: ApiResultHandler;
  tenantSubscriptions?: TenantSubscriptions;
  tenantModuleConfigurations?: ModuleConfigurations;
  serviceProviderModuleConfigurations?: ModuleConfigurations;
  recorder?: Recorder;
  environmentConfiguration: EnvironmentConfiguration;
  accessTokenStorage: AccessTokenStorage;
  tenantIntegrationClient: TenantIntegrationClient;
  integrationContext: RunContext;
  integrationContextSchema: JSONSchema7;
  eventContext: EventContext;
  eventContextSchema: JSONSchema7;
  results: WorkflowNodeResult[];
  previousResults: WorkflowNodeResult[];
}

export interface WorkflowResultContext {
  status: IntegrationEventStatus;
  integrationContext: RunContext;
  integrationContextSchema: JSONSchema7;
  results: WorkflowNodeResult[];
}

export type NodeHandlerRunFunc = (
  ctx: WorkflowRunContext
) => Promise<WorkflowResultContext>;

export type NodeHandlerRunAfterErrorFunc = (
  ctx: WorkflowRunContext,
  errorResult: WorkflowNodeResult
) => Promise<void>;

export interface NodeHandler extends WorkflowSchemaHandler {
  run: NodeHandlerRunFunc;
  runAfterError: NodeHandlerRunAfterErrorFunc;
}
