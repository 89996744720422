import React, { JSX } from 'react';

import {
  IntegrationEventStatus,
  WorkflowNodeResult,
} from '@apus/common-lib/api/interface/integration-service';
import {
  IntegrationNode,
  IntegrationNodeType,
} from '@apus/common-lib/integration-engine/src/interface';
import { EventContext } from '@apus/common-lib/integrations/src/interface';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Timeline, TimelineContent, TimelineItem } from '@mui/lab';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Grid, SxProps, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled, Theme } from '@mui/material/styles';
import { isEmpty } from 'lodash';

import WorkflowNodeStatusIcon from './WorkflowNodeStatusIcon';
import DefineJsonHandler from '../../input/editor/json/DefineJsonHandler';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: '0.9rem', pointerEvents: 'auto' }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

interface Props {
  eventContext?: EventContext;
  workflowResults: WorkflowNodeResult[];
  node: IntegrationNode;
}

function validationFailed(result: WorkflowNodeResult): boolean {
  if (result.status !== 'Error') return false;
  // TODO: needs better way to sort out validation errors...
  return (
    result.error !== undefined && result.error.error?.name === 'ValidationError'
  );
}

function runConditionFailed(result: WorkflowNodeResult): boolean {
  return result.status === 'Omitted';
}

const ResultHeader = ({
  children,
  width,
}: {
  width?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Typography
      display={'flex'}
      flexGrow={1}
      justifyContent={'center'}
      variant={'subtitle2'}
      fontWeight={'bold'}
      sx={{ width }}
    >
      {children}
    </Typography>
  );
};

const NodeIcon = ({ status }: { status?: IntegrationEventStatus }) => {
  switch (status) {
    case 'Error':
      return (
        <TimelineDot color="error" variant="outlined">
          <WorkflowNodeStatusIcon sx={{ fontSize: '0.9rem' }} status={status} />
        </TimelineDot>
      );
    case 'Finished':
      return (
        <TimelineDot color="success" variant="outlined">
          <WorkflowNodeStatusIcon sx={{ fontSize: '0.9rem' }} status={status} />
        </TimelineDot>
      );
    case 'Omitted':
      return (
        <TimelineDot color="grey" variant="outlined">
          <WorkflowNodeStatusIcon sx={{ fontSize: '0.9rem' }} status={status} />
        </TimelineDot>
      );
  }
  return <TimelineDot color="grey" variant="outlined"></TimelineDot>;
};

const ResultBase = ({
  label,
  disabled,
  children,
  sx,
}: {
  label: string;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
  sx?: SxProps<Theme>;
}): JSX.Element => {
  return (
    <Accordion
      sx={{
        borderColor: 'secondary.main',
        border: 1,
        margin: 0,
        padding: 0,
        borderRadius: 2,
        ...sx,
      }}
      disabled={disabled}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box flexGrow={1} display={'flex'} alignItems={'center'}>
              <Typography>{label}</Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const DefaultJsonView = ({ data }: { data: unknown | undefined }) => {
  if (isEmpty(data)) return <></>;

  const value = typeof data === 'string' ? JSON.parse(data) : data;

  return (
    <DefineJsonHandler
      title={undefined}
      onChange={() => {}}
      value={value}
      readOnly={true}
    />
  );
};

const EventContextResult = ({
  eventContext,
}: {
  eventContext: EventContext;
}) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        <NodeIcon status={'Finished'} />
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <ResultBase label={`EventContext`}>
          <DefaultJsonView data={eventContext}></DefaultJsonView>
        </ResultBase>
      </TimelineContent>
    </TimelineItem>
  );
};

const ValidationResult = ({ result }: { result: WorkflowNodeResult }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'transparent' }} />
        <NodeIcon status={validationFailed(result) ? 'Error' : 'Finished'} />
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <ResultBase
          label={`Validation: ${validationFailed(result) ? 'Failed' : 'Ok'}`}
        />
      </TimelineContent>
    </TimelineItem>
  );
};

const RunConditionResult = ({
  result,
  node,
}: {
  result: WorkflowNodeResult;
  node: IntegrationNode;
}) => {
  if (
    !(
      node.nodeType === IntegrationNodeType.Operation ||
      node.nodeType === IntegrationNodeType.Dispatch
    )
  )
    return <></>;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'transparent' }} />
        <NodeIcon
          status={
            node.runCondition?.enabled
              ? runConditionFailed(result)
                ? 'Error'
                : 'Finished'
              : 'Omitted'
          }
        />
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <ResultBase
          disabled={isEmpty(node.runCondition)}
          label={`Run condition: ${
            node.runCondition?.enabled
              ? runConditionFailed(result)
                ? 'Failed'
                : 'Succeeded'
              : 'Disabled'
          }`}
        >
          <Grid container>
            <Grid item xs={12}>
              <ResultHeader width={'100%'}>Condition</ResultHeader>
            </Grid>
            <Grid item xs={12}>
              <Box
                width={'100%'}
                display={'flex'}
                flexGrow={1}
                justifyContent={'left'}
              >
                <DefaultJsonView
                  data={node.runCondition?.condition}
                ></DefaultJsonView>
              </Box>
            </Grid>
          </Grid>
        </ResultBase>
      </TimelineContent>
    </TimelineItem>
  );
};

const Result = ({
  result,
  idx,
}: {
  result: WorkflowNodeResult;
  idx: number;
}) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        <NodeIcon status={result.status} />
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <ResultBase
          label={`Execution #${idx}: ${
            result.error !== undefined ? 'Failed' : 'Ok'
          }`}
        >
          <ResultBase label={'Input data'} sx={{ marginBottom: 1 }}>
            <DefaultJsonView data={result.input}></DefaultJsonView>
          </ResultBase>
          <ResultBase
            label={'API Call(s)'}
            disabled={result.callRecord === undefined}
            sx={{ marginBottom: 1 }}
          >
            <DefaultJsonView data={result.callRecord}></DefaultJsonView>
          </ResultBase>
          <ResultBase
            disabled={result.error === undefined}
            label={'Error'}
            sx={{ marginBottom: 1 }}
          >
            <DefaultJsonView data={result.error}></DefaultJsonView>
          </ResultBase>
          <ResultBase label={'Output data'} sx={{ marginBottom: 1 }}>
            <DefaultJsonView data={result.output}></DefaultJsonView>
          </ResultBase>
        </ResultBase>
      </TimelineContent>
    </TimelineItem>
  );
};

const FinalResult = ({ result }: { result: WorkflowNodeResult }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        <NodeIcon status={result.status} />
        <TimelineConnector sx={{ bgcolor: 'transparent' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <ResultBase
          label={`Output: ${result.error !== undefined ? 'Failed' : 'Ok'}`}
        />
      </TimelineContent>
    </TimelineItem>
  );
};

const WorkflowNodeResultViewer = ({
  workflowResults,
  eventContext,
  node,
}: Props): JSX.Element => {
  const results: WorkflowNodeResult[] = workflowResults.filter(
    r => r.workflowNodeId === node.id
  );
  const firstResult = results.length > 0 ? results[0] : undefined;
  const lastResult =
    results.length > 0 ? results[results.length - 1] : undefined;

  if (results.filter(r => r.workflowNodeId !== node.id).length > 0)
    throw new Error(
      `Cannot display workflow node results - wrong results included`
    );

  if (firstResult === undefined || lastResult === undefined) {
    return <></>;
  }

  return (
    <Timeline
      sx={{
        padding: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {eventContext !== undefined && (
        <EventContextResult eventContext={eventContext} />
      )}

      <ValidationResult result={firstResult} />

      <RunConditionResult node={node} result={firstResult} />

      {results.map((result, idx) => (
        <Result result={result} idx={idx + 1} />
      ))}

      <FinalResult result={lastResult} />
    </Timeline>
  );
};

export default WorkflowNodeResultViewer;
