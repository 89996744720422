import { JSONSchema7 } from 'json-schema';
import { SchemaMappingRule } from '../../json-data-mapper/src/interface';
import { FileId } from './integration-service';

export interface JsonSchema {
  jsonSchema: JSONSchema7;
}

export interface TypescriptFunction {
  typescriptCode: string;
  transpiledCode: string;
  typescriptVersion?: string;
}

/**
 * Custom content media types used in the application
 *
 * Used to define the various data types stored as string values.
 *
 * NOTE: remember that the media types are stored in database and also used in jsdoc -tags to be included in generated
 * json schemas (see common/lib/integrations), so be extra careful when changing these values.
 */
export enum CustomContentMediaType {
  /**
   * Json schema definition as serialised JSON ({@link JsonSchema})
   */
  JsonSchema = 'application/vnd.apus.string-content.json-schema+json',
  /**
   * Schema mapping rule ({@link SchemaMappingRule})
   */
  SchemaMappingRule = 'application/vnd.apus.string-content.schema-mapping-rule+string',
  /**
   * Typescript -code stored as serialised JSON ({@link TypescriptFunction})
   */
  TypescriptFunction = 'application/vnd.apus.string-content.typescript-function+json',
  /**
   * LiquidJS -template string
   */
  LiquidTemplate = 'application/vnd.apus.string-content.liquid-template+text',
}

interface FileBase {
  readonly fileId?: FileId;
  name?: string;
  description?: string;
}

export interface StoredFile extends FileBase {
  contentType: CustomContentMediaType;
  content: string;
}

export interface TypedFile<
  CONTENT,
  CONTENT_TYPE extends CustomContentMediaType = CustomContentMediaType
> extends FileBase {
  contentType: CONTENT_TYPE;
  content: CONTENT;
}

export type JsonSchemaDefinition = TypedFile<
  JsonSchema,
  CustomContentMediaType.JsonSchema
>;

export type SchemaMappingRuleDefinition = TypedFile<
  SchemaMappingRule,
  CustomContentMediaType.SchemaMappingRule
>;

export type TypescriptFunctionDefinition = TypedFile<
  TypescriptFunction,
  CustomContentMediaType.TypescriptFunction
>;

export type SupportedSchemaDefinition = JsonSchemaDefinition;

export type SupportedMappingSchemaDefinition =
  | SchemaMappingRuleDefinition
  | TypescriptFunctionDefinition;
