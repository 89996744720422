import { JSX, useContext } from 'react';

import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { Box, CircularProgress, Typography } from '@mui/material';

import DefineIntegration from '../../../components/workflow/timeline/DefineIntegration';
import { AppContext } from '../../../state/appContext';
import { setIntegrations } from '../../../state/appReducer';

const IntegrationCreateView = (): JSX.Element => {
  const integrationService = useIntegrationService();
  const [appState, dispatch] = useContext(AppContext);

  async function onSave() {
    dispatch(setIntegrations(await integrationService.listIntegrations()));
  }

  if (appState.integrationModules === undefined)
    return (
      <Box>
        <Typography variant={'subtitle1'}>Loading modules...</Typography>
        <CircularProgress />
      </Box>
    );

  return (
    <DefineIntegration
      modules={appState.integrationModules}
      integrations={appState.integrations}
      onSave={onSave}
    />
  );
};

export default IntegrationCreateView;
