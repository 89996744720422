import { MapperRoute } from '../components/dashboard/SideNavItems';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import SettingsEthernetOutlinedIcon from '@mui/icons-material/SettingsEthernetOutlined';
import NatIcon from '@mui/icons-material/Nat';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AppsTwoToneIcon from '@mui/icons-material/AppsTwoTone';
import * as React from 'react';

export const integrationRoutes = [
  {
    text: 'Create integration',
    path: '/integrations/create',
  },
];

export const apiRoutes = [
  {
    text: 'Create API',
    path: '/apis/create',
  },
];

export const toolsRoutes = [
  {
    text: 'Banking templates',
    path: '/tools/banking-template-management',
  },
  {
    text: 'Error handling',
    path: '/tools/error-handling',
  },
  {
    text: 'Manage operations',
    path: '/tools/operation-management',
  },
  {
    text: 'Manage apps',
    path: '/tools/app-management',
  },
  {
    text: 'Import operations',
    path: '/tools/operation-import',
  },
];

export const tenantRoutes = [
  {
    text: 'Create tenant',
    path: '/tenants/create',
  },
];

export const monitorRoutes = [
  {
    text: 'Live feed',
    path: '/monitor',
  },
  {
    text: 'Search actions',
    path: '/monitor/search',
  },
];

export const serviceProviderRoutes: MapperRoute[] = [
  {
    text: 'Tools',
    path: '/tools',
    icon: <HandymanOutlinedIcon />,
    subRoutes: toolsRoutes,
  },
  {
    text: 'Tenants',
    path: '/tenants',
    icon: <GroupsTwoToneIcon />,
    subRoutes: tenantRoutes,
  },
  {
    text: 'Integrations',
    path: '/integrations',
    icon: <SettingsEthernetOutlinedIcon />,
    subRoutes: integrationRoutes,
  },
  {
    text: 'Connections',
    path: '/connections',
    icon: <AppsTwoToneIcon />,
  },
  {
    text: 'Apis',
    path: '/apis',
    icon: <NatIcon />,
    subRoutes: apiRoutes,
  },
  {
    text: 'View monitor',
    path: '/monitor',
    icon: <MonitorHeartOutlinedIcon />,
    subRoutes: monitorRoutes,
  },
];
