import React, { JSX, useCallback, useEffect, useState } from 'react';

import {
  IntegrationOperation,
  IntegrationOperationInstance,
  IntegrationOperationPrototype,
  WorkflowOperationType,
} from '@apus/common-lib/integrations/src/interface';

import DefineIntegrationOperation from '../../../operation/DefineIntegrationOperation';
import OperationSelector from '../../../operation/OperationSelector';

interface Props {
  operations: IntegrationOperation[];
  instance?: IntegrationOperationInstance;
  prototypes: IntegrationOperationPrototype[];
  onChange: (operation: IntegrationOperationInstance) => void;
}

const DefineOperationInstance = ({
  onChange,
  instance,
  operations,
  prototypes,
}: Props): JSX.Element => {
  const [operation, setOperation] = useState<
    IntegrationOperation | undefined
  >();

  const findOperation = useCallback(
    (op?: IntegrationOperationInstance): IntegrationOperation | undefined => {
      return operations.find(
        o => o.moduleId === op?.moduleId && o.operationId === op?.operationId
      );
    },
    [operations]
  );

  useEffect(() => {
    const op = findOperation(instance);
    setOperation(op);
  }, [instance, findOperation, setOperation]);

  const operationSelected = (operation: IntegrationOperation) => {
    if (instance === undefined) {
      onChange({
        inputSchema: operation.inputSchema,
        moduleId: operation.moduleId,
        operationId: operation.operationId,
        operationType: WorkflowOperationType.INSTANCE,
        outputSchema: operation.outputSchema,
      });
    } else {
      onChange({
        ...instance,
        inputSchema: operation.inputSchema,
        moduleId: operation.moduleId,
        operationId: operation.operationId,
        operationType: WorkflowOperationType.INSTANCE,
        outputSchema: operation.outputSchema,
      });
    }
  };

  if (operation === undefined)
    return (
      <OperationSelector<IntegrationOperation>
        name="modify-operation"
        label="Select operation"
        onOperationSelected={operationSelected}
        operations={operations}
      />
    );

  return (
    <DefineIntegrationOperation
      mode={'modify'}
      readOnly={true}
      prototypes={prototypes}
      operation={operation}
    />
  );
};

export default DefineOperationInstance;
