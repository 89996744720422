import React, { JSX } from 'react';

import { Box } from '@mui/material';

/**
 * Footer to offset the fixed margin used in side Drawers
 */
const NodeFooter = (): JSX.Element => {
  return <Box minHeight={'48px'} />;
};

export default NodeFooter;
