import React, {
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
  JSX,
  useContext,
} from 'react';

import 'react-json-view-lite/dist/index.css';
import { ApiError } from '@apus/common-lib/api/interface/common';
import {
  IntegrationAction,
  IntegrationActionLifecycleNotification,
  IntegrationDefinition,
} from '@apus/common-lib/api/interface/integration-service';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button } from '@mui/material';
import { isEmpty } from 'lodash';

import { AppContext } from '../../state/appContext';
import { setIntegrations } from '../../state/appReducer';
import WorkflowResult from '../workflow/timeline/WorkflowResult';

interface EditorInstance {
  setValue: (value: string) => void;
}

interface Props {
  notification: IntegrationActionLifecycleNotification | undefined;
}

const ActionViewer = ({ notification }: Props): JSX.Element => {
  const editorRef = useRef<EditorInstance | undefined>(undefined);
  const [appState, dispatch] = useContext(AppContext);

  const [action, setAction] = useState<IntegrationAction | undefined>(
    undefined
  );
  const [integration, setIntegration] = useState<IntegrationDefinition>();
  const [editorContent, setEditorContent] = useState<string | undefined>(
    undefined
  );
  const [cachedEditorContent, setCachedEditorContent] = useState<
    string | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const integrationService = useIntegrationService();

  useEffect(() => {
    (async () => {
      if (isEmpty(appState.integrations)) {
        dispatch(setIntegrations(await integrationService.listIntegrations()));
      }
    })();
  }, [appState, dispatch, integrationService]);

  useEffect(() => {
    if (action !== undefined && !isEmpty(appState.integrations)) {
      const integ = appState.integrations.find(
        i => i.integrationId === action.integration.integrationId
      );
      setEditorContent(JSON.stringify(action, null, 2));
      setCachedEditorContent(undefined);
      setIntegration(integ);
    }
  }, [
    action,
    appState,
    setEditorContent,
    setCachedEditorContent,
    setIntegration,
  ]);

  useEffect(() => {
    if (notification !== undefined)
      (async () => {
        setEditorContent(undefined);

        await executeApiCall<IntegrationAction | undefined>({
          callFunction: () =>
            integrationService.getAction(notification.actionId),
          setResult: setAction,
          setPending: setIsLoading,
          setError,
        });
      })();
  }, [notification, integrationService]);

  const onCopyContent: MouseEventHandler<Element> = async e => {
    e.preventDefault();
    if (action !== undefined) {
      await navigator.clipboard.writeText(JSON.stringify(action));
    }
  };

  const onRetryClicked: MouseEventHandler<Element> = async e => {
    e.preventDefault();
    if (notification !== undefined)
      await integrationService.retryActions(
        notification.integration.integrationId,
        [notification?.actionId]
      );
  };

  const onEditClicked: MouseEventHandler<Element> = async e => {
    e.preventDefault();
    if (!isEditing) {
      setCachedEditorContent(editorContent);
      setIsEditing(true);
    } else {
      if (editorContent !== undefined) {
        const modifiedAction: IntegrationAction = JSON.parse(editorContent);
        await integrationService.editAction(modifiedAction);
        setCachedEditorContent(undefined);
      }
      setIsEditing(false);
    }
  };

  const onCancelClicked: MouseEventHandler<Element> = async e => {
    e.preventDefault();
    if (cachedEditorContent !== undefined && editorRef.current != null) {
      editorRef.current.setValue(cachedEditorContent);
      setCachedEditorContent(undefined);
    }
    setIsEditing(false);
  };

  // TODO: add some info to user...
  if (isLoading) return <Box></Box>;
  if (error) return <Box></Box>;
  if (action === undefined || integration === undefined) return <Box></Box>;

  console.log(JSON.stringify(action.content.eventContext));
  return (
    <Box sx={{ display: 'flex-start', padding: 1 }}>
      <Button
        variant={'text'}
        onClick={onCopyContent}
        startIcon={<ContentCopyIcon />}
      >
        Copy
      </Button>
      <Button
        variant={'text'}
        disabled={isEditing || action.status !== 'Failed'}
        onClick={onRetryClicked}
        startIcon={<RestartAltIcon />}
      >
        Retry action
      </Button>
      <Button
        variant={'text'}
        disabled={action.status !== 'Failed'}
        onClick={onEditClicked}
        color={isEditing ? 'success' : 'warning'}
        startIcon={
          isEditing ? <CheckCircleOutlineTwoToneIcon /> : <EditTwoToneIcon />
        }
      >
        {isEditing ? 'Ok' : 'Edit action'}
      </Button>
      <Button
        variant={'text'}
        disabled={!isEditing}
        onClick={onCancelClicked}
        color={'error'}
        startIcon={<ClearTwoToneIcon />}
      >
        Cancel
      </Button>
      <Box>
        <WorkflowResult
          eventContext={action.content.eventContext}
          workflow={integration.workflow}
          results={action.content.output?.workflowResults ?? []}
        />
      </Box>
    </Box>
  );
};

export default ActionViewer;
