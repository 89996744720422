import { JSX, useEffect, useReducer } from 'react';

import {
  SignedInUser,
  Tenant,
} from '@apus/common-lib/api/interface/tenant-service';
import { isBefore, subHours } from 'date-fns';

import useTenantService from '../hooks/useTenantService';
import { initialTenantState, TenantContext } from '../state/tenantContext';
import {
  setCurrentTenant,
  setTenants,
  tenantContextReducer,
} from '../state/tenantReducer';
import { executeApiCall } from '../utils/api-call';
import useUser from '../hooks/useUser';
import { UserRole } from '@apus/common-lib/api/interface/integration-service';

interface Props {
  children: JSX.Element | JSX.Element[];
}

function selectTenant(
  user: SignedInUser | undefined,
  tenants: Tenant[]
): Tenant | undefined {
  if (user === undefined) return undefined;
  if (user.roles.includes(UserRole.PlatformAdmin)) {
    const tenantId = localStorage.getItem(`tenant-id`);
    if (tenantId != null) {
      return tenants.find(t => t.tenantId === tenantId);
    }
  }

  const tenant = tenants.find(t => t.tenantId === user.tenantId);
  if (tenant === undefined)
    throw new Error(
      `User tenant could not be selected - not found in tenant listing!`
    );

  return tenant;
}

const TenantContextProvider = ({ children }: Props): JSX.Element => {
  const tenantService = useTenantService();
  const user = useUser();
  const [state, dispatch] = useReducer(
    tenantContextReducer,
    initialTenantState
  );

  useEffect(() => {
    (async () => {
      if (
        state.lastUpdated.tenants === undefined ||
        isBefore(state.lastUpdated.tenants, subHours(new Date(), 24))
      ) {
        await executeApiCall<Tenant[]>({
          callFunction: tenantService.listTenants,
          setResult: (tenants: Tenant[]) => {
            dispatch(setTenants(tenants));
            const tenant = selectTenant(user, tenants);
            if (tenant !== undefined) {
              dispatch(setCurrentTenant(tenant));
            }
          },
        });
      }
    })();
  }, [user, state, tenantService, dispatch]);

  return (
    <TenantContext.Provider value={[state, dispatch]}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContextProvider;
