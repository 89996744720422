import { JSX } from 'react';

import { Alert, AlertTitle, Box, CircularProgress } from '@mui/material';

interface Props {
  infoText: string;
  displaySpinner?: boolean;
  loading: boolean;
  error?: any;
}

const ShowApiCallStatus = ({
  infoText,
  displaySpinner = true,
  loading,
  error,
}: Props): JSX.Element => {
  if (displaySpinner && loading && !error)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {`Something went wrong when ${infoText}`}
        </Alert>
      </Box>
    );

  return <></>;
};

export default ShowApiCallStatus;
