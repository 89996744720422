import React, { JSX } from 'react';

import {
  AppSubscription,
  SystemglueApp,
} from '@apus/common-lib/api/interface/subscriptions';
import useSubscriptionService from '@apus/common-ui/hooks/useSubscriptionService';
import { Box, Typography } from '@mui/material';

import { systemglueAppSubscriptionNames } from './apps/names';
import ConfigureApp from './ConfigureApp';

interface Props {
  app: SystemglueApp;
  onCreated: (subscription: AppSubscription) => void;
  onCancelled: () => void;
}

function ConfigureNewSubscription({
  app,
  onCreated,
  onCancelled,
}: Props): JSX.Element {
  const subscriptionService = useSubscriptionService();

  async function onSave(app: SystemglueApp, subscriptionName?: string) {
    const subscription = await subscriptionService.createSubscription({
      app,
      name: subscriptionName,
    });

    onCreated(subscription);
  }

  return (
    <Box>
      <Typography variant={'h6'} marginBottom={3}>
        Configure new {systemglueAppSubscriptionNames[app.name]}
      </Typography>
      <ConfigureApp
        app={app}
        edit={true}
        onSave={onSave}
        onCancel={onCancelled}
      />
    </Box>
  );
}

export default ConfigureNewSubscription;
