import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { useEffect, useState } from 'react';
import { VersionedIntegrations } from '@apus/common-lib/api/interface/integration-service';
import AppVersion from '../app/AppVersion';

interface Props {
  open: boolean;
  handleClose: () => void;
  integrationIds: string[];
}

export default function CreateAppVersionDialog({
  open,
  handleClose,
  integrationIds,
}: Props) {
  const [app, setApp] = useState<VersionedIntegrations | undefined>(undefined);
  const integrationService = useIntegrationService();

  useEffect(() => {
    (async () => {
      const exported = await integrationService.exportIntegrations({
        integrationIds,
      });
      setApp(exported);
    })();
  }, [integrationIds, integrationService, setApp]);

  async function save() {
    console.log(`Save: ${JSON.stringify(app)}`);
    if (app !== undefined) {
      await integrationService.createAppVersion(app);
      handleClose();
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>Create new app version</DialogTitle>
        <DialogContent>
          {app !== undefined && (
            <AppVersion
              edit={true}
              data={app}
              onChange={data => {
                setApp({
                  ...app,
                  ...data,
                });
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={save}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
