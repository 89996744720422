import React, { useContext, useEffect, useState, JSX } from 'react';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import useTenant from '@apus/common-ui/hooks/useTenant';
import { Box, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';

import AWSConfigurationForm from './module/AWSConfigurationForm';
import DelfoiConfigurationForm from './module/DelfoiConfigurationForm';
import NetsuiteConfigurationForm from './module/NetsuiteConfigurationForm';
import ServiceProviderOsuuspankkiConfigurationForm from './module/ServiceProviderOsuuspankkiConfigurationForm';
import SystemglueConfigurationForm from './module/SystemglueConfigurationForm';
import TenantOsuuspankkiConfigurationForm from './module/TenantOsuuspankkiConfigurationForm';
import VariPDMConfigurationForm from './module/VariPDMConfigurationForm';
import { AppContext } from '../../state/appContext';
import { setIntegrationConnections } from '../../state/appReducer';
import AirtableConfigurationForm from './module/AirtableConfigurationForm';
import NetvisorConfigurationForm from './module/NetvisorConfigurationForm';
import ServiceProviderPageroConfigurationForm from './module/ServiceProviderPageroConfigurationForm';
import TenantPageroConfigurationForm from './module/TenantPageroConfigurationForm';
import MicrosoftGraphConfigurationForm from './module/MicrosoftGraphConfigurationForm';
import MRPEasyConfigurationForm from './module/MRPEasyConfigurationForm';
import SkyPlannerConfigurationForm from './module/SkyPlannerConfigurationForm';
import HubspotConfigurationForm from './module/HubspotConfigurationForm';
import ServiceProviderNordeaBusinessConfigurationForm from './module/ServiceProviderNordeaBusinessConfigurationForm';
import TenantNordeaBusinessConfigurationForm from './module/TenantNordeaBusinessConfigurationForm';
import CargosonConfigurationForm from './module/CargosonConfigurationForm';
import ClickUpConfigurationForm from './module/ClickUpConfigurationForm';
import OngoingConfigurationForm from './module/OngoingConfigurationForm';
import SalesforceConfigurationForm from './module/SalesforceConfigurationForm';

interface ModuleFormProps {
  moduleName: SupportedModule;
  moduleConfiguration?: ModuleConfiguration;
  onSave: (moduleConfiguration: ModuleConfiguration) => void;
  edit?: boolean;
  hideTitle?: boolean;
  titleProps?: TypographyProps;
  subtitleProps?: TypographyProps;
}

const ModuleForm = ({
  moduleName,
  moduleConfiguration,
  onSave,
  edit = true,
  hideTitle,
  titleProps,
  subtitleProps,
}: ModuleFormProps): JSX.Element => {
  const tenant = useTenant();

  if (tenant === undefined) {
    return <Typography>No tenant selected</Typography>;
  }

  switch (moduleName) {
    case 'internal':
      return (
        <SystemglueConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'osuuspankki':
      if (tenant.isServiceProvider)
        return (
          <ServiceProviderOsuuspankkiConfigurationForm
            moduleId={moduleName}
            moduleConfiguration={moduleConfiguration}
            onSave={onSave}
            edit={edit}
            hideTitle={hideTitle}
            titleProps={titleProps}
            subtitleProps={subtitleProps}
          />
        );
      return (
        <TenantOsuuspankkiConfigurationForm
          moduleId={moduleName}
          onSave={onSave}
          moduleConfiguration={moduleConfiguration}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'netsuite':
      return (
        <NetsuiteConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'aws':
      return (
        <AWSConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'delfoi':
      return (
        <DelfoiConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'vari-pdm':
      return (
        <VariPDMConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'hubspot':
      return (
        <HubspotConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'mrpeasy':
      return (
        <MRPEasyConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'skyplanner':
      return (
        <SkyPlannerConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'airtable':
      return (
        <AirtableConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'netvisor':
      return (
        <NetvisorConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'cargoson':
      return (
        <CargosonConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'clickup':
      return (
        <ClickUpConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'ongoing':
      return (
        <OngoingConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'salesforce':
      return (
        <SalesforceConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'pagero':
      if (tenant.isServiceProvider)
        return (
          <ServiceProviderPageroConfigurationForm
            moduleId={moduleName}
            moduleConfiguration={moduleConfiguration}
            onSave={onSave}
            edit={edit}
            hideTitle={hideTitle}
            titleProps={titleProps}
            subtitleProps={subtitleProps}
          />
        );
      return (
        <TenantPageroConfigurationForm
          moduleId={moduleName}
          onSave={onSave}
          moduleConfiguration={moduleConfiguration}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'nordea-business':
      if (tenant.isServiceProvider)
        return (
          <ServiceProviderNordeaBusinessConfigurationForm
            moduleId={moduleName}
            moduleConfiguration={moduleConfiguration}
            onSave={onSave}
            edit={edit}
            hideTitle={hideTitle}
            titleProps={titleProps}
            subtitleProps={subtitleProps}
          />
        );
      return (
        <TenantNordeaBusinessConfigurationForm
          moduleId={moduleName}
          onSave={onSave}
          moduleConfiguration={moduleConfiguration}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    case 'microsoft-graph':
      return (
        <MicrosoftGraphConfigurationForm
          moduleId={moduleName}
          moduleConfiguration={moduleConfiguration}
          onSave={onSave}
          edit={edit}
          hideTitle={hideTitle}
          titleProps={titleProps}
          subtitleProps={subtitleProps}
        />
      );
    default:
      return <Box>No configuration needed for connection '{moduleName}'</Box>;
  }
};

interface Props {
  moduleName: SupportedModule;
  edit?: boolean;
  hideTitle?: boolean;
  titleProps?: TypographyProps;
  subtitleProps?: TypographyProps;
}

const ModuleConfigurator = ({
  moduleName,
  edit,
  hideTitle,
  titleProps,
  subtitleProps,
}: Props): JSX.Element => {
  const integrationService = useIntegrationService();
  const [appState, appDispatch] = useContext(AppContext);
  const [configuration, setConfiguration] = useState<
    ModuleConfiguration | undefined
  >();

  const onSave = async (moduleConfiguration: ModuleConfiguration) => {
    const result = await integrationService.upsertModuleConfiguration(
      moduleConfiguration
    );
    setConfiguration(result);
    // update module configurations in the app context
    appDispatch(
      setIntegrationConnections(
        await integrationService.listModuleConfigurations()
      )
    );
  };

  useEffect(() => {
    const connection = appState.integrationConnections.find(
      c => c.moduleId === moduleName
    );

    if (connection !== undefined) {
      setConfiguration(connection);
    } else {
      setConfiguration(undefined);
    }
  }, [moduleName, appState.integrationConnections]);

  return (
    <ModuleForm
      moduleName={moduleName}
      moduleConfiguration={configuration}
      onSave={onSave}
      edit={edit}
      hideTitle={hideTitle}
      titleProps={titleProps}
      subtitleProps={subtitleProps}
    />
  );
};

export default ModuleConfigurator;
