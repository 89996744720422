import { useMemo } from 'react';

import { BadRequest } from '@apus/common-lib/utils/src/error';

import useAxios from '../hooks/useAxios';
import { createOAuthService, OAuthService } from '../api-client/oauth-service';

const ErrorThrowingOAuthService = (message: string): OAuthService => ({
  beginAuthorizationCodeFlow() {
    throw new BadRequest(message);
  },
  handleAuthorizationCode() {
    throw new BadRequest(message);
  },
});

export default function useOAuthService(): OAuthService {
  const axiosInstance = useAxios();

  return useMemo(() => {
    if (axiosInstance === undefined)
      return ErrorThrowingOAuthService('Axios instance could not be defined');

    return createOAuthService(axiosInstance);
  }, [axiosInstance]);
}
