import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import {
  AppSubscription,
  BeginSubscriptionRequest,
  CancelSubscriptionRequest,
  CreateSubscriptionRequest,
  DeleteSubscriptionRequest,
  UpdateSubscriptionRequest,
  AppUpdateRequest,
  AppUpdateExecution,
} from '@apus/common-lib/api/interface/subscriptions';
import { Tenant } from '@apus/common-lib/api/interface/tenant-service';
import { AxiosInstance } from 'axios';

export interface SubscriptionService {
  createSubscription(
    request: CreateSubscriptionRequest
  ): Promise<AppSubscription>;

  beginSubscription(
    request: BeginSubscriptionRequest
  ): Promise<AppSubscription>;

  endSubscription(request: CancelSubscriptionRequest): Promise<AppSubscription>;

  deleteSubscription(request: DeleteSubscriptionRequest): Promise<void>;

  updateSubscription(
    request: UpdateSubscriptionRequest
  ): Promise<AppSubscription>;

  getSubscription(subscriptionId: string): Promise<AppSubscription>;

  listSubscriptions(tenantId: string): Promise<AppSubscription[]>;

  executeAppUpdate(request: AppUpdateRequest): Promise<AppUpdateExecution>;

  getAppUpdate(
    appName: SystemglueAppName,
    appUpdateId: string
  ): Promise<AppUpdateExecution>;
}

export const createSubscriptionService = (
  tenant: Tenant,
  client: AxiosInstance
): SubscriptionService => {
  return {
    createSubscription(
      request: CreateSubscriptionRequest
    ): Promise<AppSubscription> {
      return client
        .post<AppSubscription>(
          `/tenants/${tenant.tenantId}/subscriptions/create`,
          request
        )
        .then(({ data }) => data);
    },

    beginSubscription(
      request: BeginSubscriptionRequest
    ): Promise<AppSubscription> {
      return client
        .post<AppSubscription>(
          `/tenants/${tenant.tenantId}/subscriptions/begin`,
          request
        )
        .then(({ data }) => data);
    },

    endSubscription(
      request: CancelSubscriptionRequest
    ): Promise<AppSubscription> {
      return client
        .post<AppSubscription>(
          `/tenants/${tenant.tenantId}/subscriptions/cancel`,
          request
        )
        .then(({ data }) => data);
    },

    deleteSubscription(request: DeleteSubscriptionRequest): Promise<void> {
      return client.post(
        `/tenants/${tenant.tenantId}/subscriptions/delete`,
        request
      );
    },

    updateSubscription(
      request: UpdateSubscriptionRequest
    ): Promise<AppSubscription> {
      return client
        .post<AppSubscription>(
          `/tenants/${tenant.tenantId}/subscriptions/update`,
          request
        )
        .then(({ data }) => data);
    },

    getSubscription(subscriptionId: string): Promise<AppSubscription> {
      return client
        .get<AppSubscription>(
          `/tenants/${tenant.tenantId}/subscriptions/${subscriptionId}`
        )
        .then(({ data }) => data);
    },

    listSubscriptions(): Promise<AppSubscription[]> {
      return client
        .get<AppSubscription[]>(`/tenants/${tenant.tenantId}/subscriptions`)
        .then(({ data }) => data);
    },

    executeAppUpdate(request: AppUpdateRequest): Promise<AppUpdateExecution> {
      return client
        .post(`/subscriptions/apps/${request.app}/updates`, request)
        .then(({ data }) => data);
    },

    getAppUpdate(
      appName: SystemglueAppName,
      appUpdateId: string
    ): Promise<AppUpdateExecution> {
      return client
        .get(`/subscriptions/apps/${appName}/updates/${appUpdateId}`)
        .then(({ data }) => data);
    },
  };
};
