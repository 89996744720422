import React, { JSX, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExposedApi } from '@apus/common-lib/api/interface/exposed-api';
import useApiCall from '@apus/common-ui/hooks/useApiCallHook';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';

import useSetAppBarRoutes from '../../../hooks/useSetAppBarRoutes';
import { apiRoutes } from '../../../routes/service-provider-routes';
import { bodyMaxHeight } from '../../../theme/theme';

const ApiListView = (): JSX.Element => {
  useSetAppBarRoutes(apiRoutes);

  const navigate = useNavigate();
  const integrationService = useIntegrationService();

  const listApis = useCallback(
    () => integrationService.listApis(),
    [integrationService]
  );

  const [, apis] = useApiCall<ExposedApi[] | undefined>(listApis, [listApis]);

  if (apis === undefined) return <></>;

  return (
    <List
      sx={{ maxHeight: bodyMaxHeight, maxWidth: '100%', overflowY: 'scroll' }}
    >
      {apis?.map((api, index) => (
        <ListItem key={index}>
          <ListItemButton onClick={() => navigate(`/apis/${api.apiId}`)}>
            <ListItemText
              primaryTypographyProps={{
                noWrap: true,
              }}
              primary={api.apiName}
              secondaryTypographyProps={{
                noWrap: true,
              }}
              secondary={api.apiDescription}
            ></ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ApiListView;
