import React from 'react';

import { SystemglueAppName } from '@apus/common-lib/api/interface/common';
import { SystemglueApp } from '@apus/common-lib/api/interface/subscriptions';
import { Typography } from '@mui/material';

import ConfigureBankTransactionIntegrationApp from './apps/ConfigureBankTransactionIntegrationApp';
import ConfigureEInvoiceIntegrationApp from './apps/ConfigureEInvoiceIntegrationApp';
import ConfigurePaymentIntegrationApp from './apps/ConfigurePaymentIntegrationApp';
import ConfigureSystemglueIntegrationApp from './apps/ConfigureSystemglueIntegrationApp';

interface Props {
  app: SystemglueApp;
  edit?: boolean;
  onSave: (app: SystemglueApp, subscriptionName?: string) => void;
  onCancel: () => void;
  pending?: boolean;
}

function ConfigureApp({ app, edit, onSave, onCancel, pending }: Props) {
  switch (app.name) {
    case SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP:
      return (
        <ConfigureBankTransactionIntegrationApp
          value={app.configuration}
          edit={edit}
          onCancel={onCancel}
          onSave={(configuration, subscriptionName) =>
            onSave(
              {
                name: SystemglueAppName.BANK_TRANSACTION_INTEGRATION_APP,
                configuration,
              },
              subscriptionName
            )
          }
          pending={pending}
        />
      );
    case SystemglueAppName.E_INVOICE_INTEGRATION_APP:
      return (
        <ConfigureEInvoiceIntegrationApp
          value={app.configuration}
          edit={edit}
          onCancel={onCancel}
          onSave={(configuration, subscriptionName) =>
            onSave(
              {
                name: SystemglueAppName.E_INVOICE_INTEGRATION_APP,
                configuration,
              },
              subscriptionName
            )
          }
          pending={pending}
        />
      );
    case SystemglueAppName.PAYMENT_INTEGRATION_APP:
      return (
        <ConfigurePaymentIntegrationApp
          value={app.configuration}
          edit={edit}
          onCancel={onCancel}
          onSave={(configuration, subscriptionName) =>
            onSave(
              {
                name: SystemglueAppName.PAYMENT_INTEGRATION_APP,
                configuration,
              },
              subscriptionName
            )
          }
          pending={pending}
        />
      );
    case SystemglueAppName.BASE_INTEGRATION_APP:
      return (
        <ConfigureSystemglueIntegrationApp
          value={app.configuration}
          edit={edit}
          onCancel={onCancel}
          onSave={(configuration, subscriptionName) =>
            onSave(
              {
                name: SystemglueAppName.BASE_INTEGRATION_APP,
                configuration,
              },
              subscriptionName
            )
          }
          pending={pending}
        />
      );
    default:
      return (
        <Typography variant={'body1'} color={'red'}>
          Unsupported app type {JSON.stringify(app)}
        </Typography>
      );
  }
}

export default ConfigureApp;
