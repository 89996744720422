import { JSX } from 'react';

import { IntegrationActionLifecycleNotification } from '@apus/common-lib/api/interface/integration-service';
import { List } from '@mui/material';

import ActionNotification from './ActionNotification';

interface Props {
  actions: IntegrationActionLifecycleNotification[];
  onActionSelected: (
    action: IntegrationActionLifecycleNotification | undefined
  ) => void;
}

const ActionNotificationList = ({
  actions,
  onActionSelected,
}: Props): JSX.Element => {
  return (
    <List>
      {actions.map(action => (
        <ActionNotification
          key={action.actionId}
          action={action}
          onActionSelected={onActionSelected}
        />
      ))}
    </List>
  );
};

export default ActionNotificationList;
