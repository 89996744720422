import React, { JSX } from 'react';
import { useForm } from 'react-hook-form';

import {
  BankTransactionHandlingRule,
  BankTransactionHandlingRuleHandler,
} from '@apus/common-lib/api/interface/apps/bank-transaction-integration';

import DefineBankTransactionHandlingRuleCondition from './condition/DefineBankTransactionHandlingRuleCondition';
import JournalEntryConfigurationForm from './form/JournalEntryConfigurationForm';
import RuleBaseForm from './form/RuleBaseForm';
import {
  BankTransactionRuleConfigurationData,
  ConfigurationComponent,
} from './interface';

interface Props<T extends BankTransactionHandlingRuleHandler> {
  disabled?: boolean;
  rule: BankTransactionHandlingRule<T, any>;
  configurationData?: BankTransactionRuleConfigurationData;
  onChange: (rule: BankTransactionHandlingRule<T, any>) => void;
}

function resolveConfigurationComponent(
  useCase: BankTransactionHandlingRuleHandler
): React.ComponentType<ConfigurationComponent> | undefined {
  switch (useCase) {
    case BankTransactionHandlingRuleHandler.GENERATE_CUSTOMER_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE:
    case BankTransactionHandlingRuleHandler.GENERATE_VENDOR_PAYMENT_PER_IDENTIFIED_TRANSACTION_AND_A_JOURNAL_ENTRY_OF_DIFFERENCE:
      return JournalEntryConfigurationForm;
    default:
      return undefined;
  }
}

const DefineBankTransactionHandlingRule = <
  T extends BankTransactionHandlingRuleHandler
>({
  rule,
  configurationData,
  disabled,
  onChange,
}: Props<T>): JSX.Element => {
  const form = useForm<BankTransactionHandlingRule<T>>();

  return (
    <RuleBaseForm
      rule={rule}
      configurationData={configurationData}
      form={form}
      conditionComponent={DefineBankTransactionHandlingRuleCondition}
      configurationComponent={resolveConfigurationComponent(rule.handler)}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

export default DefineBankTransactionHandlingRule;
